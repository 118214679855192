import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import classnames from 'classnames';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';

import { connect } from 'react-redux';
import FilesTemplate from '../Templates/FilesTemplate';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import anatomia from '../../assets/img/components/panel/anatomia.png';
import hierarquia from '../../assets/img/components/panel/hierarquia.png';
import subnivel from '../../assets/img/components/panel/subnivel.png';
import caution from '../../assets/img/components/panel/caution.png';
import gif from '../../assets/img/components/panel/gif.gif';
import gif2 from '../../assets/img/components/panel/gif2.gif';
import gif3 from '../../assets/img/components/panel/gif3.gif';
import destaque from '../../assets/img/components/panel/destaque.png';
import disable from '../../assets/img/components/panel/disable.gif';

const files = [
    { 'less': ['panel.less'] },
    { 'js': ['panel.js'] },
    { 'angular': ['panel.module.ts'] }
];

class Panel extends Component {

    state = {
        title: undefined,
        expansible: undefined,
        open: undefined,
        level: undefined,
        button: undefined,
        forbidden: undefined,
        highlight: false,
        currentIndex: undefined,
        horizontal: undefined,
        hasTable: undefined,
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Blocos');
        this.setState({currentIndex: index});
    }

    toggleOpenClass = (event) => {
        event.stopPropagation();
        event.target.parentElement.classList.toggle('open');
    }

    renderTable(){
        return(
            <div className="panel table table-responsive">
                <div className="panel-body">
                    <div className="panel-table-outer">
                    <table className="panel-table">
                        <thead>
                        <tr>
                            <th className="">Coluna 1</th>
                            <th className=" undefined">Coluna 2</th>
                            <th className="">Coluna 3</th>
                            <th className="">Coluna 4</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="  ">
                            <td className=" undefined"><b className="td-label">Lorem ipsum</b><span className="td-content ">Lorem ipsum lorem ipsum lorem ipsum lorem</span></td>
                            <td className=" undefined"><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                            <td className=""><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                            <td className=""><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                        </tr>
                        <tr className="">
                            <td className=""><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                            <td className=" undefined"><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                            <td className=""><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                            <td className=""><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                        </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div> 
        )
    }

    expand = () => {
        const { open, expansible } = this.state;
        if(!open && expansible) {
            this.setState({'open': 'open'});
        }
    }

    renderComponent = () => {
        const { title, expansible, open, level, button, forbidden, highlight, horizontal, hasTable } = this.state;

        const classPanel = classnames(
            'panel',
            open,
            {
                expansible: expansible ? 'expansible' : '',
                horizontal: horizontal ? 'horizontal' : '',
                forbidden: forbidden,
                highlight
            },
        )

        return (
            <div className={hasTable ? 'horizontal-container' : ''}>
                {expansible && horizontal && hasTable && this.renderTable()}
                <div className={classPanel} onClick={this.expand}>
                    {expansible &&
                        <span className="dropdown-arrow" onClick={() => {
                            this.setState(state => {
                                return {
                                    'open': state.open ? undefined : 'open'
                                }
                            })
                        }}></span>
                    }
                    {title &&
                        <div className="panel-title">
                            Lorem Ipsum
                            {button &&
                                <button className="btn circle pull-right module-color" type="button" onClick={e => e.stopPropagation()}>
                                    <em className="fa fa-plus"></em>
                                    <div className="btn-actions-label"> Novo </div>
                                </button>}
                        </div>
                    }
                    <div className="panel-body">
                        {!level &&
                            'Lorem Ipsum'
                        }
                        {level &&
                            <div className="panel inside-panel" onClick={this.toggleOpenClass}>
                                <h2 className="panel-title">
                                    Nível 1
                                </h2>
                                <div className="panel-body">
                                    <div className="panel inside-panel" onClick={this.toggleOpenClass}>
                                        <h2 className="panel-title">Nível 2</h2>
                                        <div className="panel-body">
                                            Lorem ipsum
                                        </div>
                                    </div>
                                    <div className="panel inside-panel" onClick={this.toggleOpenClass}>
                                        <h2 className="panel-title">Nível 2</h2>
                                        <div className="panel-body">
                                            <div className="panel inside-panel" onClick={this.toggleOpenClass}>
                                                <h2 className="panel-title">Nível 3</h2>
                                                <div className="panel-body">
                                                    Lorem ipsum
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderTypesOptions = () => {
        return (
            <React.Fragment>
                <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange} >
                    <SwitchOptions attr='title' value='Com Título' stateName='title' />
                    <SwitchOptions attr='type' value='Expansível' stateName='expansible' />
                    <SwitchOptions attr='level' value='Com Níveis' stateName='level' />
                    <SwitchOptions attr='button' value='Com Botão' stateName='button' />
                    <SwitchOptions attr='highlight' value={true} stateName='highlight' label="Com Destaque" />
                    <SwitchOptions attr='horizontal' value={true} stateName='horizontal' label="Horizontal" />
                    {this.state.horizontal && this.state.expansible &&
                        <SwitchOptions attr='hasTable' value={true} stateName='hasTable' label="Com Tabela" />
                    }
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label='Validações' state={this.state} onChange={this.onChange} >
                    <SwitchOptions attr='forbidden' value='Bloqueado' stateName='forbidden' />
                </SwitchOptionsGroup>
            </React.Fragment>
        );
    }

    optionalClass = () => {
        return (
            <React.Fragment>
                {this.renderTypesOptions()}
            </React.Fragment>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    files={files}
                    codeToRender={this.renderComponent}
                    optionalClasses={this.optionalClass}
                    mainTitle='Blocos'
                    mainDescription='Os blocos contêm conteúdo e ações de um único assunto.'
                    theme={theme}
                >

                <HowToUse
                    title='Uso'
                    description='Os cartões são superfícies que exibem conteúdo e ações em um único tópico. <br /> Eles devem ser fáceis de escanear informações relevantes e interativas. Elementos, como texto e imagens, devem ser colocados sobre eles de maneira a indicar claramente a hierarquia.'
                />

                <HowToUse
                    title='Anatomia'
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg='1. Container <br/> 2. Título (Opcional) <br/> 3. Conteúdo <br/> 4. Ação (Opcional) <br/> 5. Botão de Expansão (Opcional)'
                    />
                </HowToUse>

                <HowToUse
                    title='Hierarquia'
                    subtitle='Tabelas'
                    description='Blocos podem ter tabelas como conteúdo.'
                >
                    <HowToUseFigure
                        srcImg={hierarquia}
                        captionImg='Tabela dentro de um bloco'
                    />

                </HowToUse>

                <HowToUse
                    subtitle='Subníveis'
                    description='Blocos podem ter outros blocos como conteúdo.'
                >
                    <HowToUseFigure
                        srcImg={subnivel}
                        captionImg='Blocos recursivos'
                    />

                    <HowToUseFigure
                        srcImg={caution}
                        captionImg='Evite usar mais de 3 níveis.'
                        status='caution'
                    />

                </HowToUse>

                <HowToUse
                    title='Aplicação'
                    subtitle='Local'
                    description='Os blocos aplicam a função de agrupar um assunto relacionado. Formulários, listas, ou visualizações que sejam relacionados, podem ficar contidos em um bloco.'
                >
                    <HowToUseFigure
                        srcImg={gif}
                        captionImg='Bloco expansivo com conteúdo relacionado'
                    />

                </HowToUse>

                <HowToUse
                    title='Relacionamentos'
                    description='Os blocos podem aplicar a função de relacionar. Formulários, listas, ou visualizações que sejam relacionados, podem ficar contidos em um bloco.'
                >
                    <HowToUseFigure
                        srcImg={gif2}
                        captionImg='Bloco expansivo com tabela usando subníveis'
                    />

                </HowToUse>

                <HowToUse
                    title='Comportamento'
                    subtitle='Expandir Bloco'
                    description='Ao usar um bloco expansivo, clique no botão para expandir ou retrair o bloco.'
                >
                    <HowToUseFigure
                        srcImg={gif3}
                        captionImg='Animação de um bloco expansivo'
                    />
                </HowToUse>

                <HowToUse
                    title='Variações'
                    subtitle='Com Destaque'
                    description='Bloco com título de cor destacada. Atrai a atenção de outros conteúdos para o bloco e o seu conteúdo.'
                >
                    <HowToUseFigure
                        srcImg={destaque}
                        captionImg='Bloco com cor de destaque'
                    />
                </HowToUse>

                <HowToUse
                    subtitle='Bloqueado'
                    description='Bloco bloqueado, interações e/ou animações do bloco ou do conteúdo ficam desabilitadas.'
                >
                    <HowToUseFigure
                        srcImg={disable}
                        captionImg='Bloco desabilitado'
                    />
                </HowToUse>

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Panel);
