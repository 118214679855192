import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

import ComponentTemplate from '../Templates/ComponentTemplate';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import anatomia from '../../assets/img/components/shortcut-wheel/anatomia.png';
import gif from '../../assets/img/components/shortcut-wheel/gif.gif';
import gif2 from '../../assets/img/components/shortcut-wheel/gif2.gif';

const files = [{ 'less': ['shortcut-wheel.less'] }, { 'angular': ['roda-atalhos.module.ts'] }];

const menu1 = [
    {
        icon: 'sitemap',
        name: 'Ação Coletiva',
        disabled: false
    },
    {
        icon: 'plane',
        name: 'Licença Prêmio',
        disabled: false
    },
    {
        icon: 'upload',
        name: 'Gerenciador Social',
        disabled: false
    },
    {
        icon: 'calendar',
        name: 'Período Aquisitivo',
        disabled: false
    },
    {
        icon: 'money',
        name: 'Planos de Cargos e Salários',
        disabled: false
    },
    {
        icon: 'cogs',
        name: 'Configurações',
        disabled: false
    },
    {
        icon: 'calculator',
        name: 'Cálculo',
        disabled: false
    },
    {
        icon: 'search',
        name: 'Ficha Financeira',
        disabled: false
    }
];


const menu2 = [
    {
        icon: 'calendar-plus-o',
        name: 'Afastamentos',
        disabled: false
    },
    {
        icon: 'calendar-minus-o',
        name: 'Férias',
        disabled: false
    },
    {
        icon: 'calendar-check-o',
        name: 'Licença Prêmio',
        disabled: false
    },
    {
        icon: 'bar-chart',
        name: 'Médias',
        disabled: false
    },
    {
        icon: 'file-text-o',
        name: 'eSocial',
        disabled: false
    },
    {
        icon: 'undo',
        name: 'Sair das Configurações',
        disabled: false
    },
    {
        icon: '',
        name: '',
        disabled: true
    },
    {
        icon: '',
        name: '',
        disabled: true
    }
];


const defaultMessage = 'Selecione ou digite o número da opção desejada';

class ShortcutWheel extends Component {
    state = {
        hidden: true,
        actualWheel: menu1,
        hoveredName: defaultMessage,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        document.addEventListener('keyup', event => {
            if (event.shiftKey && event.keyCode === 65) {
                this.toggleShortcutWheel();
            }
            if (event.key === 'Escape' && !this.state.hidden) {
                this.toggleShortcutWheel();
            }
        })

        const index = ListMenu.findIndex(item => item.name === 'Roda de Atalhos');
        this.setState({ currentIndex: index });
    }

    toggleShortcutWheel = () => {
        this.setState(state => {
            return {
                hidden: !state.hidden
            }
        })
    }

    handleMouseEnter = (name) => {
        this.setState({ hoveredName: name });
    }

    handleMouseLeave = () => {
        this.setState({ hoveredName: defaultMessage });
    }

    handleConfigurationClick = () => {
        this.setState(state => {
            return {
                actualWheel: state.actualWheel === menu1 ? menu2 : menu1
            }
        });
    }

    renderComponent = () => {
        const { hidden, actualWheel, hoveredName } = this.state;
        const shortcutWheel = classnames('shortcut-wheel-container', { hidden })

        return (
            <>
                <div className="chip-container module-color">
                    <span className="fa fa-lightbulb-o chip-icon-left"></span>
                    <p className="chip-text">Conheça a nova central de atalhos pressionando as teclas SHIFT + A</p>
                </div>
                <div className={shortcutWheel}>
                    <div className="shortcut-wheel">
                        {actualWheel.map((item, index) => {
                            return (
                                <div key={index + item.icon} className={`shortcut-wheel-item ${item.disabled ? 'disabled' : ''} ${item.icon === 'undo' ? 'actived' : ''}`} onMouseEnter={() => this.handleMouseEnter(item.name)} onMouseLeave={this.handleMouseLeave} onClick={item.icon === 'cogs' || item.icon === 'undo' ? this.handleConfigurationClick : undefined}>
                                    <i className={`fa fa-${item.icon}`} />
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 179.02 91.18">
                                        <g id={`Camada_2_${index}`} data-name={`Camada_2_${index}`}>
                                            <g id={`Camada_1-2_${index}`} data-name={`Camada_1-2_${index}`}>
                                                <path d="M176.33,15A247.08,247.08,0,0,0,2.63,16.53,4.08,4.08,0,0,0,.32,21.92l1.13,2.65a4,4,0,0,1,2.32-5.16,243.91,243.91,0,0,1,171.41-1.5,4,4,0,0,1,2.37,5.28L151.4,87.9a2.29,2.29,0,0,0,.14-.27l27.18-67.27A4.07,4.07,0,0,0,176.33,15Z" /><path d="M175.18,17.91A243.91,243.91,0,0,0,3.77,19.41a4,4,0,0,0-2.32,5.16L28.67,88.7a4,4,0,0,0,5.1,2.23,166.78,166.78,0,0,1,112.7-1,4,4,0,0,0,4.93-2l26.15-64.71A4,4,0,0,0,175.18,17.91Z" /><path d="M175.18,17.91A243.91,243.91,0,0,0,3.77,19.41a4,4,0,0,0-2.32,5.16L28.67,88.7a4,4,0,0,0,5.1,2.23,166.78,166.78,0,0,1,112.7-1,4,4,0,0,0,4.93-2l26.15-64.71A4,4,0,0,0,175.18,17.91Z" />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            )
                        })}
                        <p className="shortcut-wheel-description">
                            {hoveredName}
                        </p>
                    </div>
                </div>
            </>
        );
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    theme={theme}
                    mainTitle="RODA DE ATALHOS"
                    mainDescription="Acesse telas favoritas com mais facilidade"
                >
                <HowToUse
                    title="Uso"
                    description="A roda de atalhos permite o acesso facilitado de telas específicas do sistema, selecionadas pelo usuário. <br /> As telas podem ser organizadas, e selecionadas pelas posições, ou números que estão na roda. <br /> São no máximo 8 opções disponíveis no primeiro nível."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Item da roda de atalhos <br /> 2. Ícone do item <br />3. Descrição do item selecionado (hover) <br />4. Número de acessibilidade"
                    />
                </HowToUse>
                <HowToUse
                    title="Hierarquia"
                    description="Para indicar ao usuário sobre a roda de atalhos deve-se utilizar o componente de chip, para instruir o usuário de como ele irá ativar o atalho. <br /> Componente de chip deve ser exibido somente no primeiro acesso do usuário."
                >
                    <HowToUseFigure
                        srcImg={gif}
                        captionImg="Instrução para utilizar a roda de atalhos."
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    description="As opções de atalho podem conter no máximo dois níveis. <br /> Permitindo assim agrupar no máximo mais 7 opções, no segundo nível."
                >
                    <HowToUseFigure
                        srcImg={gif2}
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(ShortcutWheel);
