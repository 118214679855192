import { combineReducers } from 'redux';
import ThemeReducer from './reducer_theme';
import FullscreenReducer from './reducer_fullscreen';

const rootReducer = combineReducers({
  theme: ThemeReducer,
  fullScreen: FullscreenReducer
});

export default rootReducer;
