import React, { Component } from 'react';

import { connect } from 'react-redux';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from './../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import anatomia from '../../assets/img/components/dash-title/anatomia.png';
import aplicacao from '../../assets/img/components/dash-title/aplicacao.png';
import gif from '../../assets/img/components/dash-title/gif.gif';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const files = [{ 'less': ['dashboard.less'] }];


class TitleDashboard extends Component {

    state = {
        hasButtons: false,
        hasSearch: false,
        actived: false,
        subtitle: false,
        currentIndex: undefined,
        hasIconPicker: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Título Dashboard');
        this.setState({ currentIndex: index });
    }

    toggleFilter = () => {
        this.setState(state => {
            return { actived: !state.actived }
        })
    }

    renderComponent = () => {
        const { hasButtons, hasSearch, actived, subtitle, hasIconPicker } = this.state;

        return (
            <div className="col-md-7">
                <div className="panel">
                    <div className="panel-body">
                        <div className="title-dash">
                            {hasIconPicker &&
                                <i className="fa fa-plus-circle">
                                    <div className={`card icon-picker`}>
                                        <div className="icon-item fa fa-address-book"></div>
                                        <div className="icon-item fa fa-address-book-o"></div>
                                        <div className="icon-item fa fa-address-card"></div>
                                        <div className="icon-item fa fa-address-card-o"></div>
                                        <div className="icon-item fa fa-bandcamp"></div>
                                        <div className="icon-item fa fa-bath"></div>
                                        <div className="icon-item fa fa-bathtub"></div>
                                        <div className="icon-item fa fa-drivers-license"></div>
                                        <div className="icon-item fa fa-drivers-license-o"></div>
                                        <div className="icon-item fa fa-eercast"></div>
                                        <div className="icon-item fa fa-envelope-open"></div>
                                        <div className="icon-item fa fa-envelope-open-o"></div>
                                        <div className="icon-item fa fa-etsy"></div>
                                        <div className="icon-item fa fa-free-code-camp"></div>
                                        <div className="icon-item fa fa-grav"></div>
                                        <div className="icon-item fa fa-handshake-o"></div>
                                        <div className="icon-item fa fa-id-badge"></div>
                                        <div className="icon-item fa fa-id-card"></div>
                                        <div className="icon-item fa fa-id-card-o"></div>
                                        <div className="icon-item fa fa-imdb"></div>
                                        <div className="icon-item fa fa-linode"></div>
                                        <div className="icon-item fa fa-meetup"></div>
                                        <div className="icon-item fa fa-microchip"></div>
                                        <div className="icon-item fa fa-podcast"></div>
                                        <div className="icon-item fa fa-quora"></div>
                                        <div className="icon-item fa fa-ravelry"></div>
                                        <div className="icon-item fa fa-s15"></div>
                                        <div className="icon-item fa fa-shower"></div>
                                        <div className="icon-item fa fa-snowflake-o"></div>
                                        <div className="icon-item fa fa-superpowers"></div>
                                    </div>
                                </i>
                            }
                            {!hasIconPicker &&
                                <FontAwesomeIcon icon="paper-plane" className="icon" />
                            }
                            <h2>
                                Lorem ipsum lorem
                                {subtitle &&
                                    <span> Lorem ipsum lorem</span>
                                }
                            </h2>
                            {hasButtons &&
                                <React.Fragment>
                                    <i className="action fa fa-list">
                                        <div className="title">Lista</div>
                                    </i>
                                    <i className="action fa fa-line-chart" >
                                        <div className="title">Linha do tempo</div>
                                    </i>
                                </React.Fragment>
                            }
                            {hasSearch &&
                                <div className={`filter ${actived ? 'actived' : ''}`}>
                                    <input type="text" placeholder="Digite para pesquisar" />
                                    <i className="action fa fa-search" onClick={this.toggleFilter}>
                                        <div className="title">Filtrar</div>
                                    </i>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange} >
                <SwitchOptions attr="hasButtons" value={true} stateName="hasButtons" label="Com Botões" />
                <SwitchOptions attr="hasSearch" value={true} stateName="hasSearch" label="Com Botão de Busca" />
                <SwitchOptions attr="subtitle" value={true} stateName="subtitle" label="Com Subtítulo" />
                <SwitchOptions attr="hasIconPicker" value={true} stateName="hasIconPicker" label="Com Seletor de Ícone" />
            </SwitchOptionsGroup>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Título Dashboard"
                    mainDescription="Responsável por identificar uma determinada seção no dashboard."
                >
                <HowToUse
                    title="Uso"
                    description="É indicado para identificar e agrupar seções no dashboard. <br /> Seu uso facilita a identificação do conteúdo, e é possível disponibilizar algumas ações."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Ícone <br /> 3. Título <br /> 4. Subtítulo (opcional) <br /> 5. Botões de filtro (opcional)"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                >
                    <HowToUseFigure
                        srcImg={aplicacao}
                        captionImg="Títulos utilizados no folha, com botões de filtro."
                    />
                    <HowToUseFigure
                        srcImg={gif}
                        captionImg="Filtros em funcionamento."
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(TitleDashboard);
