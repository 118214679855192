import React, { Component } from 'react';
import { connect } from 'react-redux';
import ComponentTemplate from '../Templates/ComponentTemplate';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';

import anatomia from '../../assets/img/components/modal/anatomia.png';

const files = [{ 'less': ['modal.less'] }, { 'js': ['modal.js'] }, {'angular': ['elo-modal.module.ts']}];

class Modal extends Component {

    state = {
        open: false,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Modal');
        this.setState({ currentIndex: index });
    }

    componentDidUpdate() {
        window.scrollTo(0,0);
    }

    toggleModal = () => {
        this.setState(state => {
            return { open: !state.open };
        })
    }

    renderComponent = () => {
        const { open } = this.state;

        return (
            <React.Fragment>
                <button className="btn neutral" onClick={this.toggleModal}>Abrir modal</button>
                <div className={`modal ${open ? 'open' : ''}`}>
                    <div className="container">
                        <span className="close close-modal" onClick={this.toggleModal}></span>
                        <div className="info">
                            Coloque qualquer coisa aqui
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;
        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    theme={theme}
                    mainTitle="Modal"
                    mainDescription="Apresente uma tarefa de curto prazo em uma camada iniciada pelo usuário."
                >
                <HowToUse
                    title="Uso"
                    description="Um modal desativa o restante da interface, é sempre dispensável e vem com ações opcionais. Os modais geralmente são abusados, portanto, use-os com cuidado.<br /> &nbsp;&nbsp;&nbsp;&nbsp;1. Um modal não deve aparecer sem a ação de um usuário e deve sempre fornecer contexto explicando o que o modal faz. <br />&nbsp;&nbsp;&nbsp; 2. Todos os modais podem ser descartados de três a quatro maneiras: botão Dispensar, a tecla ESC, clicando na tela (fora da área modal) ou no botão Cancelar (opcional). <br />&nbsp;&nbsp;&nbsp; 3. O modal é uma tarefa de bloqueio. O usuário deve executar uma ação, concluindo a subtarefa ou dispensando a janela, para retornar à interface principal. <br />&nbsp;&nbsp;&nbsp; 4. O usuário abrirá um modal com uma ação em mente. Permita que eles concluam a subtarefa única e escolham o próximo passo para eles mesmos. <br />&nbsp;&nbsp;&nbsp; 5. Um modal nunca deve abrir outro, e o usuário nunca deve ver mais de um de cada vez."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Modal <br /> 2. Título (Opcional) <br /> 3. Conteúdo <br /> 4. Botões de ação (Opcional) <br /> 5. Fundo do Modal"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Modal);
