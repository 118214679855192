import React from 'react';
import classnames from 'classnames';

const HowToUseFigure = ({ srcImg, captionImg, descriptionImg, status, col, title }) => {
    // const { srcImg, captionImg, descriptionImg, status, col, title } = this.props;
    const sectionClasses = classnames('section-figure', status, { col });

    return (
        <>
            <section className={sectionClasses}>
                <figure>
                    <img src={srcImg} alt={captionImg ? captionImg : title} />
                    <figcaption> {captionImg} </figcaption>
                </figure>
                <p className="section-description" dangerouslySetInnerHTML={{ __html: descriptionImg }} />
            </section>
        </>
    )

}

export default HowToUseFigure;
