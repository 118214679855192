import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from './../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import { connect } from 'react-redux';
import HowToUse from './../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import anatomia from '../../assets/img/components/widget/anatomia.png';
import hierarquia from '../../assets/img/components/widget/hierarquia.png';
import fixo from '../../assets/img/components/widget/fixo.png';
import gif from '../../assets/img/components/widget/gif.gif';

const files = [{'less': ['widget.less']}, {'js': ['widget.js']}, {'angular': ['widget.module.ts']}];

class Widget extends Component {

    state = {
        type: '',
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Widget');
        this.setState({ currentIndex: index });
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderClasses = () => {
        return (
            <SwitchOptionsGroup label="Posição" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="type" value="fixed on" stateName="type" label="Fixo" />
            </SwitchOptionsGroup>
        );
    }

    renderComponent = () => {
        const { type } = this.state;

        return (
            <div className="col-md-4">
                <div className={`panel widget widget-same-height ${type}`}>
                    <span className="widget-icon">
                        <em className="fa fa-file-text"></em>
                    </span>
                    <h2 className="panel-title">
                        Widget Simples
                    </h2>
                    <div className="panel-body">
                        <div className="panel-body">
                            <article className="widget-numeric">
                                <b className="widget-numeric-number">344,014</b>
                                <p className="widget-numeric-subtitle">
                                    orem ipsum dolor sit amet, consectetur adipisicing orem ipsum dolor sit amet, consectetur adipisicing orem
                                    ipsum dolor sit amet, consectetur adipisicing
                                </p>
                            </article>
                        </div>
                    </div>
                    <button className="btn">
                        Visualizar
                    </button>
                </div>
            </div>
        );
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;
        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderClasses}
                    theme={theme}
                    mainTitle="Widget"
                    mainDescription="Informação compacta para dashboard"
                >
                <HowToUse
                    title="Uso"
                    description="O widget é utilizado para agrupar e organizar informações em dashboards, pode conter outros componentes e exibir informações relevantes, e de mesmo nível."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg=" 1. Container <br /> 2. Ícone do Widget<br /> 3. Tìtulo do Widget<br /> 4. Título do conteúdo<br /> 5.Descrição do conteúdo<br /> 6. Botão, componente extra (Opcional)"
                    />
                </HowToUse>
                <HowToUse
                    title="Hierarquia"
                    description="Widgets podem conter outros componentes variados."
                >
                    <HowToUseFigure
                        srcImg={hierarquia}
                        captionImg="Widget com gráfico"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    descriptionImg="Widgets são usados em dashboards"
                >
                    <HowToUseFigure
                        srcImg={gif}
                        captionImg="Widget com gráfico"
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Fixo"
                    description="Widget pode ser com posição fixa."
                >
                    <HowToUseFigure
                        srcImg={fixo}
                        captionImg="Widget Fixo"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        );
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Widget);
