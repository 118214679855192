import React, { Component } from 'react';
import PropTypes from 'prop-types';

class SearchMenu extends Component {

    handleOnBlur = () => {
        const {toggleMenu} = this.props;
        setTimeout(() => {
            toggleMenu();
        }, 100)
    }

    render() {
        const { searchMenu, searchInputRef, onKeyDown, toggleMenu } = this.props;
        return (
            <div className="search">
                <input className="arc-search" aria-label="Input Search" onChange={elem => searchMenu(elem)} type="text" placeholder="Buscar" ref={searchInputRef} autoComplete="off" onKeyDown={onKeyDown} onBlur={this.handleOnBlur} />
                <div className="icon-input" onClick={toggleMenu}>
                    <span className="iconify" data-icon="ic:outline-search"></span>
                </div>
            </div>
        )

    }
}

SearchMenu.propTypes = {
    searchMenu: PropTypes.func
}

export default SearchMenu;
