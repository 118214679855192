import React from 'react';


const SwitchOptions = ({attr, value, stateName, state, onChange, label, hidden}) => {
    let objState = {}
    objState[stateName] = value;

    return (
        <label className={hidden ? 'hidden' :''}>
            <input aria-label={`Switch option ${label}`} className="hidden" type="checkbox" name={attr} value={value} onClick={() => {
                if (objState[stateName] === state[stateName]) {
                    objState[stateName] = undefined;
                }
                onChange(objState);
            }} />
            <span className={`switch ${state[stateName] === value ? 'active' : ''}`} role="switch" aria-checked={state[stateName] === value}></span>
            {label ? label : value}
        </label>
    )
}

export default SwitchOptions;
