import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import ComponentTemplate from '../Templates/ComponentTemplate';
import classnames from 'classnames';
import SwitchOptionsGroup from './../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';

import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';

import anatomia from '../../assets/img/components/fab/anatomia.png';
import do1 from '../../assets/img/components/fab/do1.png';
import dont1 from '../../assets/img/components/fab/dont1.png';
import dont2 from '../../assets/img/components/fab/dont2.png';
import dont3 from '../../assets/img/components/fab/dont3.png';
import dont4 from '../../assets/img/components/fab/dont4.png';
import dont5 from '../../assets/img/components/fab/dont5.png';
import hover from '../../assets/img/components/fab/hover.gif';
import hover2 from '../../assets/img/components/fab/hover2.gif';

const files = [
    { 'less': ['button.less'] },
    { 'angular': ['float-button.module.ts'] },
    { 'react': ['FabSpeedDial.jsx'] }
];


class FloatingActionButton extends Component {

    state = {
        verticalOptions: false,
        color: 'module-color',
        showActions: false,
        highlight: false,
        show: false,
        currentIndex: undefined,
        disabled: false,
        extended: false
    }


    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Botões Flutuantes');
        this.setState({ currentIndex: index });
    }

    renderComponent = () => {
        const { verticalOptions, color, showActions, highlight, show, disabled, extended } = this.state;

        const btnSave = classnames(
            'btn-save',
            {
                'vertical-options': verticalOptions,
                hover: showActions,
                'has-details': highlight,
                'show': show,
                'extended': extended
            }
        )
        const btnClasses = classnames(
            'btn',
            'hint',
            'fa fa-ellipsis-v',
            {
                [`${color}`]: color,
                'hideHintOnMobile': verticalOptions,
                'show-hint-on-focus': highlight
               
            }
        )
        return (
            <div style={{ height: verticalOptions ? '200px' : '' }}>
                <div className={btnSave} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                    {verticalOptions &&
                        <div className="options">
                            <button className={`btn hint clean ${color} lg`} type="button">
                                <i className="fa fa-usd"></i>
                                <div className="hint-content">Lorem ipsum</div>
                            </button>
                            <button className={`btn hint clean ${color} lg`} type="button">
                                <i className="fa fa-user-plus"></i>
                                <div className="hint-content">Lorem ipsum</div>
                            </button>
                            <button className={`btn hint clean ${color} lg`} type="button">
                                <i className="fa fa-print"></i>
                                <div className="hint-content">Lorem ipsum</div>
                            </button>
                        </div>
                    }
                    <button className={btnClasses} type="button" disabled={disabled}>
                        <div className="hint-content">Lorem ipsum </div>
                    </button>
                    {highlight &&
                        <div className="details">
                            <h1>Novo por aqui?</h1>
                            <p className="mt-xs">
                                Esta é uma dica para você entender o que esse botão faz.
                            </p>
                        </div>
                    }
                </div>
            </div>
        );
    }

    handleMouseEnter = () => {
        if (window.innerWidth > 768 && !this.state.disabled) {
            this.setState({
                showActions: true
            });
        }
    };

    handleMouseLeave = () => {
        if (window.innerWidth > 768) {
            this.setState({
                showActions: false
            });
        }
    };

    onChange = newState => {
        this.setState(newState, () => {
            setTimeout(() => {
                this.setState({ show: this.state.highlight });
            }, 500);
        });
    }

    renderOptionalClasses = () => {
        return (
            <React.Fragment>
                <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr='verticalOptions' value={true} stateName='verticalOptions' label='Múltiplas Opções' />
                    <SwitchOptions attr='highlight' value={true} stateName='highlight' label='Com Destaque' />
                    <SwitchOptions attr='extended' value={true} stateName='extended' label='extendido' />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Cores" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr='color' value='module-color' stateName='color' label='Cor do Módulo' />
                    <SwitchOptions attr='color' value='positive' stateName='color' label='Verde' />
                    <SwitchOptions attr='color' value='negative' stateName='color' label='Vermelho' />
                    <SwitchOptions attr='color' value='' stateName='color' label='Neutral' />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Validações" state={this.state} onChange={this.onChange} >
                    <SwitchOptions attr='disabled' value={true} stateName='disabled' label='Desabilitado' />
                </SwitchOptionsGroup>
            </React.Fragment>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Botões Flutuantes"
                    mainDescription="Um botão de ação flutuante (FAB) representa a ação principal de uma tela."
                >
                <HowToUse
                    title="Uso"
                    description="Um botão de ação flutuante (FAB) executa a ação principal ou mais comum em uma tela. Ele aparece na frente de todo o conteúdo da tela, normalmente como uma forma circular com um ícone no centro.<br />Os FABs (floating action buttons) vêm em três tipos: regular, múltiplo e estendido. Use um FAB apenas se for a maneira mais adequada de apresentar a ação principal de uma tela."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Ícone"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    description="Os FABs podem ser anexados ao canto inferior direto das telas."
                >
                    <HowToUseFigure
                        srcImg={do1}
                        status="do"
                        descriptionImg="Mantenha o FAB fixo ao canto, mesmo durante o scroll"
                    />
                    <HowToUseFigure
                        srcImg={dont1}
                        status="dont"
                        descriptionImg="Componentes individuais, como blocos, não podem ter cada um seu próprio FAB."
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={dont2}
                        status="dont"
                        col
                        descriptionImg="Não coloque badges ou outros elementos na frente de uma FAB."
                    />
                    <HowToUseFigure
                        srcImg={dont3}
                        status="dont"
                        col
                        descriptionImg="Não coloque texto em um FAB normal."
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    description="Um botão de ação flutuante (FAB) pode acionar uma ação na tela atual ou executar uma ação que cria uma nova tela.<br /> Um FAB promove uma ação construtiva importante, como: <br />&nbsp;&nbsp;&nbsp;&nbsp;• Criar <br />&nbsp;&nbsp;&nbsp;&nbsp;• Favoritar <br />&nbsp;&nbsp;&nbsp;&nbsp;• Compartilhar <br />&nbsp;&nbsp;&nbsp;&nbsp;• Iniciar um processo <br />Evite usar um FAB para ações menores ou destrutivas, como: <br />&nbsp;&nbsp;&nbsp;&nbsp;• Arquivar ou lixeira <br />&nbsp;&nbsp;&nbsp;&nbsp;• Alertas ou erros <br />&nbsp;&nbsp;&nbsp;&nbsp;• Tarefas limitadas, como cortar texto <br />Ações de controle são mais adequadas a uma barra de ferramentas (como controles para ajustar o volume ou a cor da fonte)."
                >
                    <HowToUseFigure
                        srcImg={hover}
                        captionImg="Hover em um FAB"
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Múltiplas Opções"
                    descriptionImg="Quando pressionado, um FAB pode exibir três a seis ações relacionadas na forma de uma discagem rápida. Essa transição pode ocorrer após pressionar o FAB que se transforma em um menu contendo ações relacionadas. Se forem necessárias mais de seis ações, algo diferente de um FAB deve ser usado para apresentá-las."
                >
                    <HowToUseFigure
                        srcImg={hover2}
                        descriptionImg="FAB com múltiplas opções"
                    />
                    <HowToUseFigure
                        srcImg={dont4}
                        descriptionImg="Não use mais de 6 opções"
                    />
                    <HowToUseFigure
                        srcImg={dont5}
                        descriptionImg="Use pelo menos 2 opções"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(FloatingActionButton);
