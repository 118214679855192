import less from 'less/dist/less';

const themeLight = {
    "@primaryColor": "@ArcRed",
    "@primaryColorLight": "lighten(@primaryColor, 10%)",
    "@primaryColorDark": "darken(@primaryColor, 10%)",
    "@secondaryColor": "@ArcBlue",
    "@secondaryColorDark": "darken(@secondaryColor, 10%)",
    "@textColor": "@Gray9",
    "@textColorInvert": "@Gray1",
    "@backgroundColor": "@Gray2",
    "@backgroundColorOut": "@White",
    "@backgroundColorIn": "@Gray3",
    "@headerColor": "@primaryColor",
    "backgroundLogo": "@Gray2"
};

const themeDark = {
    "@primaryColor": "@Gray10",
    "@primaryColorLight": "lighten(@Gray10, 10%)",
    "@primaryColorDark": "darken(@Gray10, 10%)",
    "@secondaryColor": "@ArcBlue",
    "@secondaryColorDark": "darken(@secondaryColor, 10%)",
    "@textColor": "@Gray9",
    "@textColorInvert": "@Gray1",
    "@backgroundColor": "@Gray2",
    "@backgroundColorOut": "@White",
    "@backgroundColorIn": "@Gray3",
    "@headerColor": "@primaryColor",
    "backgroundLogo": "@Gray2"
};

export function selectTheme(theme) {

    if (theme === 'light') {
        less.modifyVars(themeLight);
    }

    if (theme === 'dark') {
        less.modifyVars(themeDark);
    }

    less.refreshStyles();

    return {
        type: 'THEME_SELECTED',
        payload: theme
    }
}

export function toggleFullScreen(currentState) {
    return {
        type: 'FULLSCREEN',
        payload: currentState
    }
}