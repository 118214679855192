import React, { Component } from 'react';
import Coder from '../Coder/Coder';
import SwitchTheme from "../SwitchTheme/SwitchTheme";
import AsideDesign from './AsideDesign';
class ComponentTemplate extends Component {

    render() {
        const { codeToRender, optionalClasses, mainTitle, mainDescription, theme, panel, overflowHidden, fullScreen, children } = this.props;

        return (
            <>
                {!fullScreen && 
                    <>
                        <article>
                            <nav aria-label="Breadcrumbs" className="nav-breadcrumbs arc">
                            <ol>
                                <li>
                                    <a href='/componentes'>Componentes</a>
                                </li>
                                <li><a href="/" rel="noreffer" aria-current="page"><span>{mainTitle}</span></a></li>
                            </ol>
                            </nav> 
                            <SwitchTheme />
                            <h1 className="main-title">{mainTitle}</h1>
                            <p className="main-description">{mainDescription}</p>

                            {theme === 'dark' &&
                                <>
                                    <div className="flex-direction-row">
                                        <div className={`component ${overflowHidden ? 'overflow-hidden' : ''}`}>
                                            <div className="col-md-12 pl-none">
                                                {panel &&
                                                    <div className="panel">
                                                        <div className="panel-body">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    {codeToRender()}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                {!panel &&
                                                    codeToRender()
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="configurations">
                                        {optionalClasses &&
                                            <div className="tab">
                                                <div className="title-tab">CLASSES</div>
                                                <div className="code">
                                                    {optionalClasses()}
                                                </div>
                                            </div>
                                        }
                                        <div className="tab">
                                            <div className="title-tab">HTML</div>
                                            <Coder codeToRender={codeToRender} />
                                        </div>
                                    </div>
                                </>
                            }

                            {React.Children.map(children, child => {
                                if (!child) {
                                    return child;
                                }
                                return React.cloneElement(child);
                            })}
                            
                        </article>

                        {theme !== 'dark' && 
                            <>
                                <AsideDesign />
                            </>
                        }
                    </>
                }
                {fullScreen && 
                    <>
                        {codeToRender()}
                    </>
                }
            </>
        )
    }
}

export default (ComponentTemplate);
