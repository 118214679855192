import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import classname from 'classnames';
import { connect } from 'react-redux';

import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import anatomia from '../../assets/img/components/cards/anatomia.png';
import gif from '../../assets/img/components/cards/gif.gif';
import gif2 from '../../assets/img/components/cards/gif2.gif';
import gif3 from '../../assets/img/components/cards/gif3.gif';

import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

const files = [{ 'less': ['card.less'] }];

class Card extends Component {

    state = {
        loading: false,
        active: false,
        clickable: false,
        action: false,
        scroll: false,
        currentIndex: undefined,
        minHeight: false,
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Card');
        this.setState({currentIndex: index});
    }

    onChange = newState => {
        this.setState(newState);
    }

    toggleActive = () => {
        const { clickable } = this.state;
        if (clickable) {
            this.setState(state => {
                return {
                    active: !state.active
                }
            })
        }
    }

    optionBadge = () => {
        return (
            <SwitchOptionsGroup label="Variação" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="loading" value={true} stateName="loading" label="Loading" />
                <SwitchOptions attr="clickable" value={true} stateName="clickable" label="Clicável" />
                <SwitchOptions attr="action" value={true} stateName="action" label="Ação" />
                <SwitchOptions attr="scroll" value={true} stateName="scroll" label="Com Scroll" />
                <SwitchOptions attr="minHeight" value={true} stateName="minHeight" label="Sem min-height" />
            </SwitchOptionsGroup>
        )
    }

    renderComponent = () => {
        const { loading, active, clickable, action, scroll, minHeight } = this.state;
        const rowClasses = classname('row', { 'scroll': scroll });
        const cardClasses = classname('card', { 'placeholder-loader': loading, 'active': active, 'pointer': clickable, 'action': action, 'mh-unset': minHeight });

        return (
            <div className={rowClasses}>
            <div className="col-md-8">
                <div className={cardClasses} onClick={this.toggleActive}>
                    {loading &&
                        <div className="loading"></div>
                    }

                    {!loading && !action && !minHeight &&
                        <>
                            {active &&
                                <i className="fa fa-check-circle pull-right hint module-color clean active-button" />
                            }
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>
                                        Lorem ipsum, dolor sit amet
                                        <div className="hint fa-exclamation-circle inline module-color clean sm">
                                            <div className="hint-content">Lorem ipsum, dolor sit amet</div>
                                        </div>
                                    </h4>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="label">
                                            Label
                            </label>
                                        <p>
                                            Lorem ipsum lorem
                                </p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="label">
                                            Label
                            </label>
                                        <p>
                                            Lorem ipsum lorem
                                </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-xs">
                                    <div className="chip-container module-color inline">
                                        <p className="chip-text">Lorem Ipsum Dolor</p>
                                    </div>
                                    <div className="chip-container module-color outlined interactive inline">
                                        <p className="chip-text">Lorem Ipsum Dolor</p>
                                    </div>
                                    <div className="chip-container module-color outlined interactive inline">
                                        <p className="chip-text">Lorem Ipsum Dolor</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-xs">
                                    <button className="btn text module-color">Lorem ipsum</button>
                                </div>
                            </div>
                        </>
                    }
                    {action &&
                        <>
                            <i className="fa fa-plus-circle"/>Adicionar item
                        </>
                    }
                    {minHeight && 
                        <>
                            Conteúdo
                        </>
                    }
                        
                    </div>
                </div>
                {scroll &&
                    <div className="col-md-8">
                        <div className="card">
                            <div className="row">
                                <div className="col-md-12">
                                    <h4>
                                        Lorem ipsum, dolor sit amet
                                        <div className="hint fa-exclamation-circle inline module-color clean sm">
                                            <div className="hint-content">Lorem ipsum, dolor sit amet</div>
                                        </div>
                                    </h4>
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="label">Label</label>
                                        <p>Lorem ipsum lorem</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className="label">Label</label>
                                        <p>Lorem ipsum lorem</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-xs">
                                    <div className="chip-container module-color inline">
                                        <p className="chip-text">Lorem Ipsum Dolor</p>
                                    </div>
                                    <div className="chip-container module-color outlined interactive inline">
                                        <p className="chip-text">Lorem Ipsum Dolor</p>
                                    </div>
                                    <div className="chip-container module-color outlined interactive inline">
                                        <p className="chip-text">Lorem Ipsum Dolor</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12 mt-xs">
                                    <button className="btn text module-color">Lorem ipsum</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.optionBadge}
                    theme={theme}
                    mainTitle="Card"
                    mainDescription="Componente exibe conteúdo e ações sobre um único assunto."
                >
                <HowToUse
                    title="Uso"
                    description="Cards são superfícies que exibem conteúdo e ação de um único tópico. <br /> As informações de um card não podem ser divididas em múltiplos cards."
                />
                <HowToUse
                    title="Anatomia"
                    description="O  container do card é o único elemento obrigatório, todo seu conteúdo é opcional e dinâmico. <br /> Card suporta quase todo tipo de conteúdo, abaixo está o conteúdo mais comum usado."
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        description="1. Container (obrigatório) <br /> 2. Título <br /> 3. Descrição <br /> 4. Botão de Ação"
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    description="Cards tem tamanho mínimo mas não máximo, podem expandir seu conteúdo."
                >
                    <HowToUseFigure
                        srcImg={gif}
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Clicável"
                    description="Cards podem ser utilizados somente para exibir informações, mas também podem ser do tipo clicável, sendo assim possível a seleção."
                >
                    <HowToUseFigure
                        srcImg={gif2}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Placeholder Loader"
                    description="Enquanto as informações de um card estão sendo carregadas, deve-se utilizar o placeholder loader, onde é aplicado uma animação simulando o carregamento."
                >
                    <HowToUseFigure
                        srcImg={gif3}
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Card);
