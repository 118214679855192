import React, { Component } from 'react';
import classnames from 'classnames';

const primaryOptions = [
    {
        name: 'Autor',
        icon: 'fa-pencil',
        childOptions: [
            {
                name: 'Maria da Silva',
            },
            {
                name: 'João da Silva'
            },
            {
                name: 'Teresa da Silva'
            }
        ]
    },
    {
        name: 'Assinado',
        icon: 'fa-user',
        childOptions: [
            {
                name: 'Maria da Silva',
            },
            {
                name: 'João da Silva'
            },
            {
                name: 'Teresa da Silva'
            }
        ]
    },
    {
        name: 'Status',
        icon: 'fa-tag',
        childOptions: [
            {
                name: 'Aberto',
            },
            {
                name: 'Cancelado'
            },
            {
                name: 'Fechado'
            }
        ]
    }
]

export default class AdvancedFilter extends Component {
    filterInput = React.createRef();

    state = {
        recents: false,
        options: false,
        extraOptions: false,
        applied: [],
        temp: undefined,
        filter: undefined
    }

    toggleRecents = () => {
        const { recents } = this.state;
        this.hideModals();
        this.setState({ recents: !recents });
    }

    toggleOptionsSearchs = () => {
        const { options } = this.state;
        const { category } = this.props;
        if(!category) {
            this.hideModals();
            this.setState({ options: !options });
        }
    }

    closeExtraOptionsSearchs = () => {
        this.setState({ temp: undefined, extraOptions: false });
    }

    openExtraOptionsSearchs = () => {
        document.querySelector('.extra-options-searchs').classList.add('open')
    }

    hideModals = () => {
        this.setState({ recents: false, options: false, extraOptions: false });
    }

    applyFilter = () => {
        document.querySelector('.applied').classList.add('open')
    }

    clearAll = () => {
        this.hideModals();
        this.setState({ applied: [] });
    }

    removeItem = i => {
        const applied = [...this.state.applied];
        applied.splice(i, 1);

        this.setState({ applied });
        this.hideModals();
    }

    selectOption = key => {
        this.filterInput.current.value = '';
        this.setState({ options: false, temp: key, extraOptions: true });
    }

    selectValue = value => {
        this.filterInput.current.value = '';
        this.setState(state => {
            let temp = { option: state.temp, value: value };
            return { temp: undefined, applied: [temp, ...state.applied], extraOptions: false }
        })
    }

    selectFilter = (value) => {
        console.log(value);
        this.setState({filter: value});
    }

    renderComponent = () => {
        const { recents, options, extraOptions, temp, applied, filter } = this.state;
        const { category } = this.props;

        const recentSearchsClasses = classnames('recent-searchs', { 'open': recents });
        const optionsSearchsClasses = classnames('options-searchs', { 'open': options });

        return (
            <div className="row">
                <div className="col-md-10">
                    <div className="filter-input">
                        <div className="recent" onBlur={() => setTimeout(() => { this.toggleRecents(); }, 200)}>
                            {!category &&
                                <button className="button fa fa-clock-o hint clean module-color top-left" onClick={this.toggleRecents}>
                                    <div className="hint-content">
                                        Pesquisas Recentes
                                    </div>
                                </button>
                            }
                            {category &&
                                <button className="button typography-size-11px" onClick={this.toggleRecents}>
                                    {filter ? filter : 'Tipo de Cadastro'}
                                    <i className="fa fa-chevron-down typography-size-8px ml-xs" />
                                </button>
                            }
                            <div className={recentSearchsClasses}>
                                {!category &&
                                    <>
                                        <div className="title">
                                            Pesquisas Recentes
                                            <button className="close-recent fa fa-times hint clean module-color center-right sm" onClick={this.toggleRecents}>
                                                <div className="hint-content">
                                                    Fechar Pesquisas Recentes
                                                </div>
                                            </button>
                                        </div>
                                        <ul>
                                            <li>
                                                Autor: <b> João da Silva </b>
                                            </li>
                                            <li>
                                                Status <b> Aberto </b>
                                            </li>
                                            <li>
                                                Assinado <b> Maria da Silva </b>
                                            </li>
                                            <li className="clear-option">Limpar pesquisas recentes</li>
                                        </ul>
                                    </>
                                }
                                {category &&
                                    <>
                                        <ul>
                                            <li onClick={() => this.selectFilter(undefined)}>
                                                Todos
                                            </li>
                                            <li onClick={() => this.selectFilter('Avulso (60)')}>
                                                Avulso (60)
                                            </li>
                                            <li onClick={() => this.selectFilter('Imobiliário (279)')}>
                                                Imobiliário (279)
                                            </li>
                                            <li onClick={() => this.selectFilter('Mobiliário (152)')}>
                                                Mobiliário (152)
                                            </li>
                                        </ul>
                                    </>
                                }
                            </div>
                        </div>
                        <div className="applied">
                            <ul>
                                {temp &&
                                    <li>
                                        <div className="chip-container module-color interactive">
                                            <p className="chip-text">{temp}</p>
                                        </div>
                                    </li>
                                }
                                {applied.map((el, i) => {
                                    return (
                                        <li key={`${el.option} - ${i}`}>
                                            <div className="chip-container module-color interactive" onClick={() => this.removeItem(i)}>
                                                <p className="chip-text">{el.option} : {el.value}</p>
                                                <span className="fa fa-times-circle chip-icon-right"></span>
                                            </div>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div className="filter-container">
                            <input aria-label="input-search" type="text" onFocus={this.toggleOptionsSearchs} ref={this.filterInput} />
                            {!!applied.length &&
                                <button className="button fa fa-times hint clean module-color top-right" onClick={this.clearAll} >
                                    <div className="hint-content">Limpar Filtros</div>
                                </button>
                            }
                            <div className={optionsSearchsClasses} data-dropdown-active={options}>
                                <ul data-dropdown>
                                    {primaryOptions.map((el, i) => {
                                        return (
                                            <li key={`${el.name} - ${i}`} onClick={() => this.selectOption(el.name)}>
                                                <i className={`fa ${el.icon}`} />
                                                {el.name}
                                            </li>
                                        )
                                    })}
                                </ul>
                            </div>
                            <div className={`extra-options-searchs ${extraOptions ? 'open' : ''}`} onBlur={this.closeExtraOptionsSearchs}>
                                <ul>
                                    {
                                        primaryOptions.filter(el => el.name === temp).map(el => {
                                            return (
                                                el.childOptions.map((child, i) => {
                                                    return (
                                                        <li key={`${child.name} - ${i}`} onClick={() => this.selectValue(child.name)}>
                                                            <i className={`fa ${el.icon}`} />
                                                            {child.name}
                                                        </li>
                                                    )
                                                })
                                            )
                                        })

                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="btn black icon-right inline">
                    <em className="fa fa-filter"></em>
                    Filtrar
                </button>
            </div>
        )
    }
    render() {
        return (
            this.renderComponent()
        )
    }
}
