import React, { Component } from 'react';
import classnames from 'classnames';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import anatomia from '../../assets/img/components/advanced-tree/anatomia.png';
import local from '../../assets/img/components/advanced-tree/local.png';
import gif from '../../assets/img/components/advanced-tree/gif.gif';
import gif2 from '../../assets/img/components/advanced-tree/gif2.gif';
import variacao from '../../assets/img/components/advanced-tree/variacao.png';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

const files = [
    { 'less': ['advanced-tree.less'] },
    { 'angular': ['arvore-avancada.module.ts'] }
];

const obj =
    [
        { "id": 1, "title": "Nível 1", "description": "CENTRO CUSTO", "child": "" },
        { "id": 2, "title": "Nível 2", "description": "CENTRO CUSTO", "child": "" },
        { "id": 3, "title": "Nível 3", "description": "CENTRO CUSTO", "child": "" },
        { "id": 4, "title": "Nível 4", "description": "CENTRO CUSTO", "child": "" },
        { "id": 5, "title": "Nível 5", "description": "CENTRO CUSTO", "child": "" },
        { "id": 6, "title": "Nível 6", "description": "CENTRO CUSTO", "child": "" },
        { "id": 7, "title": "Nível 7", "description": "CENTRO CUSTO", "child": "" },
        { "id": 8, "title": "Nível 8", "description": "CENTRO CUSTO", "child": "" },
        { "id": 9, "title": "Nível 9", "description": "CENTRO CUSTO", "child": "" },
        { "id": 10, "title": "LOREMLOREMLOREMLOREMLOREMLOREMLOREMLOREMLOREM 16", "description": "CENTRO CUSTO", "child": "" },
    ];


class AdvancedTree extends Component {

    state = {
        horizontal: undefined,
        isForm: false,
        checked: 10,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Árvore Avançada');
        this.setState({currentIndex: index});
    }

    handleShowForm = e => {
        e.stopPropagation();
        this.setState(state => {
            return {
                isForm: true
            }
        })
    }

    handleHideForm = () => {
        this.setState(state => {
            return {
                isForm: false
            }
        })
    }

    onChange = newState => {
        this.setState(newState);
    }

    handleClick = (e, id) => {
        e.stopPropagation();
        this.setState({ checked: id });
    }

    renderComponent = () => {
        const {
            horizontal,
            isForm,
            checked
        } = this.state;

        const classNamesAdvtree = classnames(
            "advtree",
            horizontal,
        )

        return (
            <div className={classNamesAdvtree}>
                <div className="advtree-container">
                    {
                        obj.map(element => {
                            let currentId = element.id;
                            let nextId = currentId + 1;
                            return (currentId <= checked) ?
                                <div key={element.id} onClick={(e) => this.handleClick(e, element.id)} className={"advtree-item " + ((checked === element.id) ? "checked" : '')}>
                                    <div className="advtree-header">
                                        <div className={"advtree-title " + ((!horizontal && obj.length.toString() === element.id.toString()) ? "with-ellipsis" : '')}>
                                            <span className={"id hint module-color " + (horizontal ? 'center-left' : 'top-left')}>
                                                {element.id}
                                                <div className="hint-content">
                                                    {element.id + ' - ' + element.title}
                                                </div>
                                            </span>
                                            <span className="title">
                                                {element.title}
                                            </span>
                                            <span className="ellipsis">...</span>
                                        </div>
                                        <div className="advtree-actions">
                                            {(checked === element.id) &&
                                                <button onClick={this.handleShowForm} className="btn circle">
                                                    <i className="fa fa-plus"></i>
                                                    <div className="btn-actions-label">
                                                        Adicionar
                                                        </div>
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div className="advtree-content">
                                        {!isForm &&
                                            <ul className="list ">
                                                <li onClick={(e) => this.handleClick(e, nextId)}>
                                                    <div className="description">
                                                        {element.description}
                                                    </div>
                                                    <div className="btn-actions">
                                                        <button onClick={this.handleShowForm} className="btn circle">
                                                            <i className="fa fa-pencil"></i>
                                                            <div className="btn-actions-label">
                                                                Editar
                                                                    </div>
                                                        </button>
                                                    </div>
                                                </li>
                                            </ul>
                                        }
                                        {isForm &&
                                            <div className="panel panel-form">
                                                <h2 className="panel-title ">
                                                    Dados do Centro de Custo
                                                        </h2>
                                                <div className="panel-body">
                                                    <div className="row">
                                                        <div className="col-sm-4">
                                                            <div className="col-md-">
                                                                <div className="form-group">
                                                                    <label className="label">
                                                                        Código
                                                                            </label>
                                                                    <input elonumber="" formcontrolname="codigo" formcontrolvalidationmsg="" id="codigo" type="text" />
                                                                    <p className="help-message">(requerido) </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-8">
                                                            <div className="col-md-">
                                                                <div className="form-group">
                                                                    <label className="label">
                                                                        Descrição
                                                                            </label>
                                                                    <input formcontrolname="descricao" formcontrolvalidationmsg="" id="descricao" type="text" />
                                                                    <p className="help-message">
                                                                        (requerido)
                                                                            </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-12 mt-xs">
                                                            <button onClick={this.handleHideForm} className="btn positive inline" id="btnSalvarForm" type="button" disabled="">
                                                                Salvar
                                                                    </button>
                                                            <button onClick={this.handleHideForm} className="btn negative inline" id="btnCancelarForm" type="button">
                                                                Cancelar
                                                                    </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>

                                : null;
                        })
                    }
                </div>
            </div>
        );
    }

    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="horizontal" value="horizontal" stateName="horizontal" label="Horizontal" />
            </SwitchOptionsGroup>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    files={files}
                    mainTitle='Árvore Avançada'
                    mainDescription='A Árvore Avançada facilita as ações dentro de uma árvore'
                    theme={theme}
                >
                <HowToUse
                    title='Uso'
                    description='Árvore Avançada é uma visualização de estrutura de árvore que agrega as ações de cadastro, edição, e remoção, além de organizar facilmente a estrutura.'
                >
                    <HowToUseFigure
                        title='Anotomia'
                        srcImg={anatomia}
                        descriptionImg='1. Container <br/> 2. Lista de níveis <br/> 3. Nível atual <br/> 4. Ações nível atual 5. Lista de subníveis <br/> 6. Ações item subnível'
                    />
                </HowToUse>
                <HowToUse
                    title='Aplicação'
                    subtitle='Local'
                    description='Árvores Avançadas geralmente são utilizadas individualmente em páginas, ou podem ser aplicadas junto à outros componentes.'
                >
                    <HowToUseFigure
                        srcImg={local}
                        captionImg='Árvore Avançada no Folha de Pagamento'
                    />
                </HowToUse>
                <HowToUse
                    title='Comportamento'
                    subtitle='Navegação'
                    description='É possível descer pela árvore clicando na lista de subníveis ou, subir clicando na lista de níveis.'
                >
                    <HowToUseFigure
                        srcImg={gif}
                        captionImg='Cliques em uma Árvore Avançada'
                    />
                    <HowToUseFigure
                        srcImg={gif2}
                        captionImg='Formulários em uma Árvore Avançada'
                    />
                </HowToUse>
                <HowToUse
                    title='Variações'
                    subtitle='Horizontal'
                    description='A Árvore Avançada pode ser exibida horizontalmente.'
                >
                    <HowToUseFigure
                        srcImg={variacao}
                        captionImg='Árvore Avançada Horizontal'
                    />
                </HowToUse>

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(AdvancedTree);
