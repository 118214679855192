import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import { connect } from 'react-redux';
import anatomia from '../../assets/img/components/tabs/anatomia.png';
import layout from '../../assets/img/components/tabs/layout.png';
import caution1 from '../../assets/img/components/tabs/caution1.png';
import dont1 from '../../assets/img/components/tabs/dont1.png';
import dont2 from '../../assets/img/components/tabs/dont2.png';
import gif from '../../assets/img/components/tabs/gif1.gif';
import espacamento from '../../assets/img/components/tabs/espacamento.png';
import espacamento2 from '../../assets/img/components/tabs/espacamento2.png';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

const files = [
    { 'less': ['tabs.less'] },
    { 'js': ['tabs.js'] },
    { 'angular': ['tab-control.module.ts'] }
];

const currentIndex = ListMenu.findIndex(item => item.name === 'Abas');
class Tabs extends Component {

    state = {
        active: 0,
        badge: false,
        table: undefined,
        hasScroll: false,
        insideTable: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    changeTab = item => {
        this.setState({
            active: item
        })
    }

    onChange = newState => {
        this.setState(newState);
    }

    optionBadge = () => {
        return (
            <SwitchOptionsGroup label="Notificação" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="badge" value="badge" stateName="badge" label="Com Erro" />
                <SwitchOptions attr="table" value="table" stateName="table" label="Com Tabela" />
                <SwitchOptions attr="table" value="tableTitle" stateName="table" label="Com Tabela com Título"/>
                <SwitchOptions attr="hasScroll" value={true} stateName="hasScroll" label="Com Scroll" />
                <SwitchOptions attr="insideTable" value={true} stateName="insideTable" label="Com Abas Dentro" hidden/>
            </SwitchOptionsGroup>
        )
    }

    renderTab = () => {
        const {active, badge, table, hasScroll} = this.state;

        return (
            <div className="panel mt-xs">
                <div className="panel-body">
                    <div className="panel-tab">
                        <div className={`panel-tab-selector ${badge ? 'has-badge' : ''}`}>
                            <div className={`tab-item-selector ${active === 0 ? 'active' : ''}`} data-position="0" onClick={() => this.changeTab(0)}>
                                Tab 1
                            </div>
                            <div className={`tab-item-selector has-badge ${active === 1 ? 'active' : ''}`} data-position="1" onClick={() => this.changeTab(1)}>
                                Tab 2
                                {badge &&
                                    <div className="badge">22</div>
                                }
                            </div>
                            <div className={`tab-item-selector ${active === 2 ? 'active' : ''}`} data-position="2" onClick={() => this.changeTab(2)}>
                                Tab 3
                            </div>
                            {hasScroll &&
                                <>
                                    <div className={`tab-item-selector has-badge ${active === 3 ? 'active' : ''}`} data-position="3" onClick={() => this.changeTab(3)}>
                                        Tab 4
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 4 ? 'active' : ''}`} data-position="4" onClick={() => this.changeTab(4)}>
                                        Tab 5
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 5 ? 'active' : ''}`} data-position="5" onClick={() => this.changeTab(5)}>
                                        Tab 6
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 6 ? 'active' : ''}`} data-position="6" onClick={() => this.changeTab(6)}>
                                        Tab 7
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 7 ? 'active' : ''}`} data-position="7" onClick={() => this.changeTab(7)}>
                                        Tab 8
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 8 ? 'active' : ''}`} data-position="8" onClick={() => this.changeTab(8)}>
                                        Tab 9
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 9 ? 'active' : ''}`} data-position="9" onClick={() => this.changeTab(9)}>
                                        Tab 10
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 10 ? 'active' : ''}`} data-position="10" onClick={() => this.changeTab(10)}>
                                        Tab 11
                                        {badge &&
                                        <div className="badge">22</div>
                                    }
                                    </div>
                                    <div className={`tab-item-selector ${active === 11 ? 'active' : ''}`} data-position="11" onClick={() => this.changeTab(11)}>
                                        Tab 12
                                    </div>
                                    <div className={`tab-item-selector ${active === 12 ? 'active' : ''}`} data-position="12" onClick={() => this.changeTab(12)}>
                                        Tab 13
                                    </div>
                                    <div className={`tab-item-selector ${active === 13 ? 'active' : ''}`} data-position="13" onClick={() => this.changeTab(13)}>
                                        Tab 14
                                    </div>
                                    <div className={`tab-item-selector ${active === 14 ? 'active' : ''}`} data-position="14" onClick={() => this.changeTab(14)}>
                                        Tab 15
                                    </div>
                                    <div className={`tab-item-selector ${active === 15 ? 'active' : ''}`} data-position="15" onClick={() => this.changeTab(15)}>
                                        Tab 16
                                    </div>
                                    <div className={`tab-item-selector ${active === 16 ? 'active' : ''}`} data-position="16" onClick={() => this.changeTab(16)}>
                                        Tab 17
                                    </div>
                                    <div className={`tab-item-selector ${active === 17 ? 'active' : ''}`} data-position="17" onClick={() => this.changeTab(17)}>
                                        Tab 18
                                    </div>
                                </>
                            }
                        </div>
                        <div className="panel-tab-content">
                            <div className={`tab-item-content ${active === 0 ? 'active' : ''} ${table ? 'table mt-mb-none' : ''}`} data-position="0">
                                {!table &&
                                    <>
                                        <h1>Tab 1</h1>
                                        <p>
                                            Lorem ipsum dolor sit amet
                                        </p>
                                    </>
                                }
                                {table === 'table' &&
                                    <>
                                        <div className="panel-table-filter">
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <select aria-label="search input">
                                                            <option>Código</option>
                                                            <option>Requerente</option>
                                                            <option>CPF/CNPJ</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <select aria-label="search input">
                                                            <option>Contém</option>
                                                            <option>Igual</option>
                                                            <option>Diferente de</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="form-group">
                                                        <input aria-label="search button" type="text" name="" placeholder="Digite o filtro..." className="button-input" />
                                                        <button className="btn filter ">Filtrar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-table-outer">
                                            <table className="panel-table">
                                                <thead>
                                                    <tr>
                                                        <th>Coluna 1</th>
                                                        <th>Coluna 2</th>
                                                        <th>Coluna 3</th>
                                                        <th>Coluna 4</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content ">Lorem ipsum lorem ipsum lorem ipsum lorem</span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="panel-pagination">
                                            <div className="sisters">
                                                <div className="number-itens"><b>Mostrar</b><select aria-label="Selector quantidade de páginas">
                                                    <option>10</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                    <option>150</option>
                                                </select><b>Itens</b></div>
                                            </div>
                                            <div className="sisters">
                                                <ul className="panel-pagination-inner">
                                                    <li><a href="#abas">«</a></li>
                                                    <li><a href="#abas">‹</a></li>
                                                    <li className="active"><a href="#abas">1</a></li>
                                                    <li><a href="#abas">2</a></li>
                                                    <li>...</li>
                                                    <li><a href="#abas">2344</a></li>
                                                    <li><a href="#abas">2345</a></li>
                                                    <li><a href="#abas">›</a></li>
                                                    <li><a href="#abas">»</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className={`tab-item-content ${this.state.active === 1 ? 'active' : ''}`} data-position="1">
                                <h1>Tab 2</h1>
                                <p>
                                    Lorem ipsum dolor sit amet
                                </p>
                            </div>
                            <div className={`tab-item-content ${this.state.active === 2 ? 'active' : ''}`} data-position="2">
                                <h1>Tab 3</h1>
                                <p>
                                    Lorem ipsum dolor sit amet
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderComponent = () => {
        const { badge, table, active, hasScroll, insideTable } = this.state;

        return (
            <div className="panel">
                <div className="panel-body">
                    <div className="panel-tab">
                        <div className={`panel-tab-selector ${badge ? 'has-badge' : ''}`}>
                            <div className={`tab-item-selector ${active === 0 ? 'active' : ''}`} data-position="0" onClick={() => this.changeTab(0)}>
                                Tab 1
                            </div>
                            <div className={`tab-item-selector has-badge ${active === 1 ? 'active' : ''}`} data-position="1" onClick={() => this.changeTab(1)}>
                                Tab 2
                                {badge &&
                                    <div className="badge">22</div>
                                }
                            </div>
                            <div className={`tab-item-selector ${active === 2 ? 'active' : ''}`} data-position="2" onClick={() => this.changeTab(2)}>
                                Tab 3
                            </div>
                            {hasScroll &&
                                <>
                                    <div className={`tab-item-selector has-badge ${active === 3 ? 'active' : ''}`} data-position="3" onClick={() => this.changeTab(3)}>
                                        Tab 4
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 4 ? 'active' : ''}`} data-position="4" onClick={() => this.changeTab(4)}>
                                        Tab 5
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 5 ? 'active' : ''}`} data-position="5" onClick={() => this.changeTab(5)}>
                                        Tab 6
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 6 ? 'active' : ''}`} data-position="6" onClick={() => this.changeTab(6)}>
                                        Tab 7
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 7 ? 'active' : ''}`} data-position="7" onClick={() => this.changeTab(7)}>
                                        Tab 8
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 8 ? 'active' : ''}`} data-position="8" onClick={() => this.changeTab(8)}>
                                        Tab 9
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 9 ? 'active' : ''}`} data-position="9" onClick={() => this.changeTab(9)}>
                                        Tab 10
                                        {badge &&
                                            <div className="badge">22</div>
                                        }
                                    </div>
                                    <div className={`tab-item-selector has-badge ${active === 10 ? 'active' : ''}`} data-position="10" onClick={() => this.changeTab(10)}>
                                        Tab 11
                                        {badge &&
                                        <div className="badge">22</div>
                                    }
                                    </div>
                                    <div className={`tab-item-selector ${active === 11 ? 'active' : ''}`} data-position="11" onClick={() => this.changeTab(11)}>
                                        Tab 12
                                    </div>
                                    <div className={`tab-item-selector ${active === 12 ? 'active' : ''}`} data-position="12" onClick={() => this.changeTab(12)}>
                                        Tab 13
                                    </div>
                                    <div className={`tab-item-selector ${active === 13 ? 'active' : ''}`} data-position="13" onClick={() => this.changeTab(13)}>
                                        Tab 14
                                    </div>
                                    <div className={`tab-item-selector ${active === 14 ? 'active' : ''}`} data-position="14" onClick={() => this.changeTab(14)}>
                                        Tab 15
                                    </div>
                                    <div className={`tab-item-selector ${active === 15 ? 'active' : ''}`} data-position="15" onClick={() => this.changeTab(15)}>
                                        Tab 16
                                    </div>
                                    <div className={`tab-item-selector ${active === 16 ? 'active' : ''}`} data-position="16" onClick={() => this.changeTab(16)}>
                                        Tab 17
                                    </div>
                                    <div className={`tab-item-selector ${active === 17 ? 'active' : ''}`} data-position="17" onClick={() => this.changeTab(17)}>
                                        Tab 18
                                    </div>
                                </>
                            }
                        </div>
                        <div className="panel-tab-content">
                            <div className={`tab-item-content ${active === 0 ? 'active' : ''} ${table ? 'table mt-mb-none' : ''}`} data-position="0">
                                {!table && !insideTable &&
                                    <>
                                        <h1>Tab 1</h1>
                                        <p>
                                            Lorem ipsum dolor sit amet
                                        </p>
                                    </>
                                }
                                {table && !insideTable &&
                                    <>
                                        {table === 'tableTitle' &&
                                            <div className="panel-title inside-tab">Lorem Ipsum
                                                <button className="btn circle pull-right module-color" type="button">
                                                    <em className="fa fa-plus"></em>
                                                    <div className="btn-actions-label"> Novo </div>
                                                </button>
                                            </div>
                                        }
                                        <div className="panel-table-filter">
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <select aria-label="search input">
                                                            <option>Código</option>
                                                            <option>Requerente</option>
                                                            <option>CPF/CNPJ</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group">
                                                        <select aria-label="search input">
                                                            <option>Contém</option>
                                                            <option>Igual</option>
                                                            <option>Diferente de</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="form-group">
                                                        <input aria-label="search button" type="text" name="" placeholder="Digite o filtro..." className="button-input" />
                                                        <button className="btn filter ">Filtrar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="panel-table-outer">
                                            <table className="panel-table">
                                                <thead>
                                                    <tr>
                                                        <th>Coluna 1</th>
                                                        <th>Coluna 2</th>
                                                        <th>Coluna 3</th>
                                                        <th>Coluna 4</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content ">Lorem ipsum lorem ipsum lorem ipsum lorem</span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                        <td>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="panel-pagination">
                                            <div className="sisters">
                                                <div className="number-itens"><b>Mostrar</b><select aria-label="Selector quantidade de páginas">
                                                    <option>10</option>
                                                    <option>50</option>
                                                    <option>100</option>
                                                    <option>150</option>
                                                </select><b>Itens</b></div>
                                            </div>
                                            <div className="sisters">
                                                <ul className="panel-pagination-inner">
                                                    <li><a href="#abas">«</a></li>
                                                    <li><a href="#abas">‹</a></li>
                                                    <li className="active"><a href="#abas">1</a></li>
                                                    <li><a href="#abas">2</a></li>
                                                    <li>...</li>
                                                    <li><a href="#abas">2344</a></li>
                                                    <li><a href="#abas">2345</a></li>
                                                    <li><a href="#abas">›</a></li>
                                                    <li><a href="#abas">»</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </>
                                }
                                {insideTable && 
                                    <>
                                        {this.renderTab()}
                                    </>
                                }
                            </div>
                            <div className={`tab-item-content ${this.state.active === 1 ? 'active' : ''}`} data-position="1">
                                <h1>Tab 2</h1>
                                <p>
                                    Lorem ipsum dolor sit amet
                                </p>
                            </div>
                            <div className={`tab-item-content ${this.state.active === 2 ? 'active' : ''}`} data-position="2">
                                <h1>Tab 3</h1>
                                <p>
                                    Lorem ipsum dolor sit amet
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBoxItems() {

        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.optionBadge}
                    mainTitle='Abas'
                    mainDescription='As Abas facilitam a exploração e alternam entre diferentes visualizações.'
                    theme={theme}
                >

                    <HowToUse
                        title='Uso'
                        description='As Abas organizam e permitem a navegação entre grupos de conteúdo relacionados e no mesmo nível de hierarquia.'
                    />

                    <HowToUse
                        title='Anatomia'
                    >
                        <HowToUseFigure
                            srcImg={anatomia}
                            descriptionImg='1. Container <br /> 2. Aba Ativa <br /> 3. Texto da Aba <br /> 4. Aba Inativa <br /> 5. Badge de Notificação da Aba'
                        />
                    </HowToUse>

                    <HowToUse
                        title='Aplicação'
                        subtitle='Layout'
                        description='As Abas são exibidas em uma única linha, com cada Aba conectada ao conteúdo que representa. Como um conjunto, todas as Abas são unificadas por um tópico compartilhado.'
                    >
                        <HowToUseFigure
                            srcImg={layout}
                            captionImg='Abas acima do conteúdo'
                        />
                        <HowToUseFigure
                            srcImg={dont1}
                            status='dont'
                            col
                            descriptionImg='Não use Abas verticalmente.'
                        />
                        <HowToUseFigure
                            srcImg={caution1}
                            status='caution'
                            col
                            descriptionImg='Evite usar Abas dentro de uma Aba.'
                        />
                        <HowToUseFigure
                            srcImg={dont2}
                            status='dont'
                            descriptionImg='Não use as Abas para percorrer um conteúdo sequencial que precisa ser seguido em uma ordem específica.'
                        />
                    </HowToUse>

                    <HowToUse
                        title='Comportamento'
                        subtitle='Mover entre Abas'
                        description='Usuários podem mover entre as Abas ao clicar nelas.'
                    >
                        <HowToUseFigure
                            srcImg={gif}
                            captionImg='Clique em Abas'
                        />
                    </HowToUse>

                    <HowToUse
                        title='Especificações'
                        subtitle='Espaçamento'
                    >
                        <HowToUseFigure
                            title='Espaçamento'
                            srcImg={espacamento}
                            captionImg='Espaçamento em Abas'
                            descriptionImg='Abas possuem <i>padding</i> para manter o conteúdo alinhado.'
                        />
                        <HowToUseFigure
                            srcImg={espacamento2}
                            captionImg='Espaçamento em Abas com Tabela'
                            descriptionImg='O <i>padding</i> é sobrescrito caso o conteúdo seja apenas uma tabela.'
                        />
                    </HowToUse>

                    <HowToUse
                        title='Arquivos'
                        code
                    >
                        <FilesTemplate files={files} />
                    </HowToUse>

                    <HowToUse
                        title='Implementação'
                        description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                        code
                    />

                    <HowToUse
                        title='Outros Componentes'
                        both
                    >
                        <BoxContainerTemplate seeAll>
                            {this.renderBoxItems()}
                        </BoxContainerTemplate>
                    </HowToUse>
 
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Tabs);
