import React, { Component } from 'react';
import slugify from 'react-slugify';
import ComponentTemplate from '../Templates/ComponentTemplate';
import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import anatomia from '../../assets/img/components/module-access/anatomia.png';
import local from '../../assets/img/components/module-access/local.png';
import gif from '../../assets/img/components/module-access/gif.gif';
import ListMenu from '../../ListMenu';

const files = [
	{ 'less': ['module-access.less'] },
	{ 'js': ['module-access.js'] }
];

class AccessModule extends Component {

	state = {
		currentIndex: undefined
	}

	componentDidMount() {
		window.scrollTo(0, 0);
		const index = ListMenu.findIndex(item => item.name === 'Acesso aos Módulos');
		this.setState({ currentIndex: index });
	}

	renderComponent = () => {
		return (
			<div className="col-md-3">
				<div className="module-access">
					<div className="ma-item">
						<div className="ma-item-icon">
							<i className="fa fa-bar-chart"/>
						</div>
						<div className="ma-item-title-outer">
							<h2 className="ma-item-title">Receitas Não Tributários</h2>
						</div>
						<ul className="ma-item-list">
							<li>
								<a href="#acesso-aos-modulos">
									Item
								</a>
							</li>
							<li>
								<a href="#acesso-aos-modulos">
									Item
								</a>
							</li>
							<li>
								<a href="#acesso-aos-modulos">
									Item
								</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		)
	}

	renderBoxItems() {
		const { currentIndex } = this.state;
		const { theme } = this.props;
		let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
		let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
		let boxes = [];

		for (let x = initIndex; x < endIndex; x++) {
			if (x !== currentIndex && ListMenu[x]) {
				let name = ListMenu[x].name;
				let nameSlug = slugify(name);
				let svg = ListMenu[x].svg;
				boxes.push(
					<BoxItemTemplate
						key={nameSlug}
						link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
						name={name}
						svg={svg}
					/>
				)
			}
		}
		return boxes;
	}

	render() {
		const { theme } = this.props;

		return (
			<>
				<ComponentTemplate
					mainTitle='Módulos de Acesso'
					mainDescription='O acesso de módulos gera visualização e acesso funcionalidades rapidamente.'
					codeToRender={this.renderComponent}
					files={files}
					theme={theme}>

					<HowToUse
						title='Uso'
						description='O Acesso de Módulos organiza, separa e exibe funcionalidades em forma de cards simples, e facilita o acesso às opções de cada funcionalidade.'
					/>

					<HowToUse
						title='Anatomia'
					>
						<HowToUseFigure
							srcImg={anatomia}
							descriptionImg='1. Container <br /> 2. Ícone do Módulo <br /> 3. Título <br /> 4. Botão CTA'
						/>

					</HowToUse>

					<HowToUse
						title='Aplicação'
						subtitle='Local'
						description='O Acesso de Módulos é exibido geralmente em homepages, ou telas principais de uma funcionalidade.'
					>
						<HowToUseFigure
							srcImg={local}
							captionImg='Acesso de Módulos na Home do Portal Transparência'
						/>

					</HowToUse>

					<HowToUse
						title='Comportamento'
						subtitle='Hover'
						description='O componente exibe uma lista de ações ao fazer hover.'
					>
						<HowToUseFigure
							srcImg={gif}
						/>

					</HowToUse>

					<HowToUse
						title='Arquivos'
						code
					>
						<FilesTemplate files={files} />
					</HowToUse>

					<HowToUse
						title='Implementação'
						description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
						code
					/>

					<HowToUse
						title='Outros Componentes'
						both
					>
						<BoxContainerTemplate seeAll>
							{this.renderBoxItems()}
						</BoxContainerTemplate>
					</HowToUse>
				</ComponentTemplate>
			</>
		)
	}
}

function mapStateToProps(state) {
	return {
		theme: state.theme
	}
}

export default connect(mapStateToProps)(AccessModule);
