import React, { Component } from 'react';
import { connect } from 'react-redux';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import classnames from 'classnames';

import anatomia from '../../assets/img/components/dashboard/anatomia.png';
import anatomia2 from '../../assets/img/components/dashboard/anatomia2.png';
import dashboard from '../../assets/img/components/dashboard/dashboard.png';
import gif1 from '../../assets/img/components/dashboard/gif1.gif';
import dashboard2 from '../../assets/img/components/dashboard/dashboard2.png';

const files = [
    { 'less': ['dashboard.less'] },
    { 'react': ['BlockDash.tsx'] }
];


class Dashboard extends Component {
    state = {
        hasTitle: true,
        hasNumber: true,
        hasIcon: true,
        hasDescription: false,
        hasList: false,
        align: 'center',
        active: false,
        report: false,
        hasHint: false,
        hasError: false,
        hasErrorHint: false,
        colored: undefined,
        currentIndex: undefined,
        disabled: false,
        withTabs: false,
        activeTab: 0,
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Dashboard');
        this.setState({ currentIndex: index });
    }

    toggleClicked = () => {
        this.setState(state => {
            return { active: !state.active }
        })
    }

    changeTab = item => {
        this.setState({
            activeTab: item
        })
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderOptionalClasses = () => {
        return (
            <React.Fragment>
                <SwitchOptionsGroup label='Variações' state={this.state} onChange={this.onChange} >
                    <SwitchOptions attr='hasTitle' value={true} stateName='hasTitle' label='Com Título' />
                    <SwitchOptions attr='hasNumber' value={true} stateName='hasNumber' label='Com Número' />
                    <SwitchOptions attr='hasIcon' value={true} stateName='hasIcon' label='Com ícone' />
                    <SwitchOptions attr='hasDescription' value={true} stateName='hasDescription' label='Com Descrição' />
                    <SwitchOptions attr='hasList' value={true} stateName='hasList' label='Com Lista' />
                    <SwitchOptions attr='report' value={true} stateName='report' label='Ao lado de Lista' />
                    <SwitchOptions attr='hasHint' value={true} stateName='hasHint' label='Com Dica' />
                    <SwitchOptions attr='hasError' value={true} stateName='hasError' label='Com Erro' />
                    <SwitchOptions attr='hasErrorHint' value={true} stateName='hasErrorHint' label='Com Dica no Erro' />
                    <SwitchOptions attr='disabled' value={true} stateName='disabled' label='Desabilitado' />
                    <SwitchOptions attr='withTabs' value={true} stateName='withTabs' label='Com Abas' />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label='Alinhamento' state={this.state} onChange={this.onChange} >
                    <SwitchOptions attr='align' value='center' stateName='align' label='Centro' />
                    <SwitchOptions attr='align' value='left' stateName='align' label='Esquerda' />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label='Cores' state={this.state} onChange={this.onChange} >
                    <SwitchOptions attr='colored' value='red' stateName='colored' label='Vermelho' />
                    <SwitchOptions attr='colored' value='blue' stateName='colored' label='Azul' />
                    <SwitchOptions attr='colored' value='green' stateName='colored' label='Verde' />
                    <SwitchOptions attr='colored' value='yellow' stateName='colored' label='Amarelo' />
                    <SwitchOptions attr='colored' value='orange' stateName='colored' label='Laranja' />
                    <SwitchOptions attr='colored' value='pink' stateName='colored' label='Rosa' />
                    <SwitchOptions attr='colored' value='purple' stateName='colored' label='Roxo' />
                    <SwitchOptions attr='colored' value='cyan' stateName='colored' label='Ciano' />
                </SwitchOptionsGroup>
            </React.Fragment>
        )
    }
    renderComponent = () => {
        const { report, withTabs } = this.state;

        return (
            <React.Fragment>
                {report && !withTabs &&
                    <div className="list-dash">
                        <div className="table-report row" height="20vh">
                            {this.renderBlock()}
                            <div className="sc-dxgOiQ eXTDHK col-md-8">
                                <div className="row">
                                    <div className="col-md-12 hidden-xs">
                                        <div className="group-header">
                                            <div className="col-md-4">Data</div>
                                            <div className="col-md-5">Número do Pedido</div>
                                            <div className="col-md-3">Tipo</div>
                                        </div>
                                    </div>
                                </div>
                                <ul className="group-itens scroll">
                                    <div className="group-item nowrap error">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div data-header="Data" className="group-column col-md-4">
                                                    <p><span>04/02/2019</span></p>
                                                </div>
                                                <div data-header="Número" className="group-column col-md-5">
                                                    <p>103</p>
                                                </div>
                                                <div data-header="Tipo" className="group-column col-md-3">
                                                    <p>LAUDO</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group-item nowrap error">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div data-header="Data" className="group-column col-md-4">
                                                    <p><span>04/02/2019</span></p>
                                                </div>
                                                <div data-header="Número" className="group-column col-md-5">
                                                    <p>104</p>
                                                </div>
                                                <div data-header="Tipo" className="group-column col-md-3">
                                                    <p>LAUDO</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group-item nowrap error">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div data-header="Data" className="group-column col-md-4">
                                                    <p><span>04/02/2019</span></p>
                                                </div>
                                                <div data-header="Número" className="group-column col-md-5">
                                                    <p>105</p>
                                                </div>
                                                <div data-header="Tipo" className="group-column col-md-3">
                                                    <p>LAUDO</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group-item nowrap error">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div data-header="Data" className="group-column col-md-4">
                                                    <p><span>04/02/2019</span></p>
                                                </div>
                                                <div data-header="Número" className="group-column col-md-5">
                                                    <p>107</p>
                                                </div>
                                                <div data-header="Tipo" className="group-column col-md-3">
                                                    <p>LAUDO</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group-item nowrap error">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div data-header="Data" className="group-column col-md-4">
                                                    <p><span>04/02/2019</span></p>
                                                </div>
                                                <div data-header="Número" className="group-column col-md-5">
                                                    <p>109</p>
                                                </div>
                                                <div data-header="Tipo" className="group-column col-md-3">
                                                    <p>LAUDO</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group-item nowrap error">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div data-header="Data" className="group-column col-md-4">
                                                    <p><span>05/02/2019</span></p>
                                                </div>
                                                <div data-header="Número" className="group-column col-md-5">
                                                    <p>111</p>
                                                </div>
                                                <div data-header="Tipo" className="group-column col-md-3">
                                                    <p>LAUDO</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </ul>
                            </div>
                        </div>
                    </div>
                }
                {!report && !withTabs && this.renderBlock()}
                {!report && withTabs && this.renderTabs()}
            </React.Fragment>
        );
    }

    renderBlock = () => {
        const { hasTitle, hasNumber, hasIcon, hasDescription, hasList, align, active, report, hasHint, hasError, hasErrorHint, colored, disabled } = this.state;

        const classes = classnames(
            'block-dash',
            align,
            colored,
            {
                'list': hasList,
                'error': hasError,
                'hint negative top-right': hasErrorHint,
                active,
                report,
                disabled,
            }
        );
        return (
            <div className="col-md-4">
                <div className={classes} onClick={this.toggleClicked}>
                    {!report && hasIcon &&
                        <i className="fa fa-user"></i>
                    }
                    {hasNumber &&
                        <div className={"number " + (hasHint ? 'hint module-color top-left' : '')}>
                            1200
                        {hasHint &&
                                <div className="hint-content">Lorem ipsum</div>
                            }
                        </div>
                    }
                    {hasTitle &&
                        <div className="title">Lorem ipsum lorem ipsum lorem</div>
                    }
                    {hasIcon &&
                        <i className="fa fa-user"></i>
                    }
                    {hasDescription &&
                        <div className="description">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Deleniti nemo eos quia consequatur, veritatis id in rerum, quasi provident consectetur commodi magni hic consequuntur quo sit porro atque nam error?</div>
                    }
                    {hasList &&
                        <ul>
                            <li>link1</li>
                            <li>link2</li>
                            <li>link3</li>
                            <li>link4</li>
                            <li>link5</li>
                            <li>link6</li>
                            <li>link7</li>
                        </ul>
                    }
                    {hasErrorHint &&
                        <div className="hint-content">Lorem ipsum</div>
                    }
                </div>
            </div>
        )

    }
    renderTabs = () => {

        const {activeTab} = this.state;

        return(
            <div className="col-md-6">
                <div className="panel mt-xs">
                    <div className="panel-body">
                        <div className="panel-tab dashboard">
                            <div className={`panel-tab-selector`}>
                                <div className={`tab-item-selector ${activeTab === 0 ? 'active' : ''}`} data-position="0" onClick={() => this.changeTab(0)}>
                                    <p className="tab-item-description">Arrecadação</p>
                                    <h1 className="tab-item-total">100M</h1>
                                    <div className="tab-item-metric up">
                                        <i className="fa fa-long-arrow-up"></i>
                                        <p>10.5%</p>
                                    </div>
                                </div>
                                <div className={`tab-item-selector ${activeTab === 1 ? 'active' : ''}`} data-position="1" onClick={() => this.changeTab(1)}>
                                <p className="tab-item-description">Reabilitações</p>
                                    <h1 className="tab-item-total">250Mi</h1>
                                    <div className="tab-item-metric down">
                                        <i className="fa fa-long-arrow-down"></i>
                                        <p>10.5%</p>
                                    </div>
                                </div>
                                <div className={`tab-item-selector ${activeTab === 2 ? 'active' : ''}`} data-position="2" onClick={() => this.changeTab(2)}>
                                <p className="tab-item-description">Cancelamentos</p>
                                    <h1 className="tab-item-total">500</h1>
                                    <div className="tab-item-metric equal">
                                        <i className="fa fa-minus"></i>
                                        <p>10.5%</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel-tab-content">
                                <div className={`tab-item-content ${activeTab === 0 ? 'active' : ''}`} data-position="0">
                                    <h1>Tab 1</h1>
                                    <p>
                                        Gráfico vai aqui
                                    </p>
                                </div>
                                <div className={`tab-item-content ${activeTab === 1 ? 'active' : ''}`} data-position="1">
                                    <h1>Tab 2</h1>
                                    <p>
                                        Gráfico vai aqui
                                    </p>
                                </div>
                                <div className={`tab-item-content ${activeTab === 2 ? 'active' : ''}`} data-position="2">
                                    <h1>Tab 3</h1>
                                    <p>
                                        Gráfico vai aqui
                                    </p>
                                </div>
                            </div>
                            <div className="panel-tab-footer">
                                <button className="btn text neutral">VER RELATÓRIO DETALHADO</button> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    mainTitle="Dashboard"
                    mainDescription="Visualização de dados exibida em uma série gráficos"
                    theme={theme}
                >
                <HowToUse
                    title="Uso"
                    description="A visualização de dados pode ser exibida em uma série de vários gráficos, nas UIs chamadas <b> Dashboards </b>. Às vezes, vários gráficos separados podem comunicar melhor uma história, em vez de um gráfico complexo. <br /> <br /> O objetivo de um dashboard deve refletir-se em seu layout, estilo e padrões de interação. Seu design deve se adequar à forma como será usado, seja uma ferramenta para fazer uma apresentação ou explorar profundamente os dados. <br /><br /> <b>Um dashboard deve:</b> <br /> • Priorizar as informações mais importantes (usando o layout) <br /> • Exibir um ponto focal que priorize as informações de acordo com a hierarquia (usando cor, posição, tamanho e peso visual)"
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        captionImg="Componente de Dashboard"
                    />
                    <HowToUseFigure
                        srcImg={anatomia2}
                        descriptionImg="As informações devem ser priorizadas e organizadas com base nas perguntas dos dados. Neste exemplo de Dashboard de Operações, as seguintes perguntas do usuário são consideradas: <br /> 1. Questões que precisam de atenção <br /> 2. Momento em que o problema ocorreu <br /> 3. Local em que os problemas ocorreram <br /> 4. Outras variáveis afetadas pelo problema"

                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    subtitle="Dashboard Analíticos"
                    description=" Os Dashboards Analíticos permitem aos usuários explorar vários conjuntos de dados e descobrir tendências. Geralmente esses painéis incluem gráficos complexos que permitem a descoberta de informações de dados. <br /> <b> Os casos de uso incluem: </b> <br /> • Destacando tendências ao longo do tempo <br /> • Respondendo perguntas 'por que' e 'e se' <br /> • Previsão <br /> • Criando relatórios detalhados <br /> <b>Exemplos de Dashboards Analíticos:</b> <br /> • Acompanhamento do desempenho da campanha de publicidade ao longo do tempo <br /> • Acompanhamento das vendas e receitas geradas por um produto ao longo do seu ciclo de vida <br /> • Mostrando tendências da população da cidade ao longo do tempo <br /> • Rastreando dados climáticos ao longo do tempo <br /> "
                />
                <HowToUse
                    subtitle="Dashboards de Operações"
                    description=" Os Dashboards de Operações foram projetados para responder a um conjunto predefinido de perguntas. Eles geralmente são usados para concluir tarefas relacionadas ao monitoramento. Na maioria dos casos, esses tipos de painéis apresentam informações atuais organizadas um conjunto de gráficos simples. <br /> <b> Os casos de uso incluem: </b> <br /> • Acompanhar o progresso atual em relação a uma meta <br /> • Rastreando o desempenho do sistema em tempo real <br /> <b>Exemplos de painel de operações:</b> <br /> • Rastrear atividades da central de atendimento, como volume de chamadas, tempos de espera, duração da chamada ou tipos de chamada <br /> • Monitorando a integridade de um aplicativo em execução na nuvem. <br /> • Exibindo o desempenho do mercado de ações <br /> • Monitorando dados de telemetria em um carro de corrida"
                />
                <HowToUse
                    subtitle="Dashboards de Apresentação"
                    description="Os Dashboards de Apresentação fornecem uma captura instantânea com curadoria de um tópico de interesse. Esses painéis geralmente incluem alguns gráficos pequenos ou um scorecard, com títulos dinâmicos que explicam as tendências e as informações fornecidas em cada gráfico de suporte. <br/>  <b> Os casos de uso incluem: </b> • Fornecendo uma visão geral dos principais indicadores de desempenho <br /> • Criando um Resumo Executivo de Alto Nível <br /> <b>Exemplos do Dashboards de Apresentação:</b> <br /> • Fornecendo uma visão geral do desempenho da conta de investimento <br /> • Fornecer um resumo das vendas de produtos e dados de participação de mercado"
                />
                <HowToUse
                    title="Variações"
                    subtitle="Dashboard"
                >
                    <HowToUseFigure
                        srcImg={dashboard}
                        captionImg="Componente de Dashboard"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Dashboard com lista interna"
                >
                    <HowToUseFigure
                        srcImg={gif1}
                        captionImg="Componente de Dashboard"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Dashboard com lista externa"
                >
                    <HowToUseFigure
                        srcImg={dashboard2}
                        captionImg="Componente de Dashboard"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}


function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Dashboard);
