import React from 'react';

const SwitchOptionsGroup = ({ children, label, state, onChange }) => {
    return (
        <div className="condition">
            <div className="condition-label">{label}:</div>
                {React.Children.map(children, child => {
                    if (!child) {
                        return child;
                    }
                    return React.cloneElement(child, { state: state, onChange: onChange })
                })}
        </div>
    )
}

export default SwitchOptionsGroup;
