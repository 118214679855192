import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';

import { connect } from 'react-redux';
import HowToUseFigure from '../Templates/HowToUseFigure';
import HowToUse from './../Templates/HowToUse';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';

import anatomia from '../../assets/img/components/advanced-selector/anatomia.png';
import gif from '../../assets/img/components/advanced-selector/gif.gif';
import gif2 from '../../assets/img/components/advanced-selector/gif2.gif';
import hierarquia from '../../assets/img/components/advanced-selector/hierarquia.png';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

const files = [{ 'less': ['advanced-selector.less'] }];

const obj = [{ "id": "1", "value": "1 - LOTE COM NOME GRANDE" }, { "id": "2", "value": "2 - LOTE COM NOME GRANDE" }, { "id": "3", "value": "3 - LOTE COM NOME GRANDE" }, { "id": "4", "value": "4 - LOTE COM NOME GRANDE" }, { "id": "5", "value": "5 - LOTE COM NOME GRANDE" }, { "id": "6", "value": "6 - LOTE COM NOME GRANDE" }, { "id": "7", "value": "7 - LOTE COM NOME GRANDE" }, { "id": "8", "value": "8 - LOTE COM NOME GRANDE" }, { "id": "9", "value": "9 - LOTE COM NOME GRANDE" }, { "id": "10", "value": "10 - LOTE COM NOME GRANDE" }, { "id": "11", "value": "11 - LOTE COM NOME GRANDE" }, { "id": "12", "value": "12 - LOTE COM NOME GRANDE" },];

class AdvancedSelector extends Component {

    state = {
        selected: '1 - LOTE COM NOME GRANDE',
        idSelected: '1',
        open: false,
        currentIndex: undefined,
        hasInput: true,
        color: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Seletor Avançado');
        this.setState({ currentIndex: index });
    }

    onChange = newState => {
        this.setState(newState);
    }

    handleSelectorEnter = () => {
        this.setState(state => {
            return {
                open: true
            }
        })
    }

    handleSelectorLeave = () => {
        this.setState(state => {
            return {
                open: false
            }
        })
    }

    handleSelectorValue = (e) => {
        let id = e.target.value ? e.target.value : e.target.id;
        let temp = {};
        obj.forEach(element => {
            if (id === element.id) {
                temp = element;
            }
        });
        this.setState(state => {
            return {
                selected: temp.value,
                idSelected: temp.id,
                open: false
            }
        })
    }

    optionalClass = () => {
        return (
            <>
                <SwitchOptionsGroup label="Cores" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr='color' value='positive' stateName='color' label='positive/module-color' />
                    <SwitchOptions attr='color' value='negative' stateName='color' />
                    <SwitchOptions attr='color' value='highlight' stateName='color' />
                    <SwitchOptions attr='color' value='warning' stateName='color' />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr='hasInput' value={true} stateName='hasInput' label='Com input' />
                </SwitchOptionsGroup>
            </>
        );
    }

    renderComponent = () => {
        const {
            selected,
            idSelected,
            open,
            hasInput,
            color
        } = this.state;


        return (
            <div className="temp">
                <div className="row">
                    <div className={`${hasInput ? 'col-md-4' : 'col-md-2'} pull-right`}>
                        <div className={`${color} advancedselector-container`} onMouseLeave={this.handleSelectorLeave}>
                            {hasInput && 
                                <div className="advancedselector-container-number" >
                                    <p>LOTE: </p>
                                    <input aria-label="Número do lote" type="number" min="1" max={obj.length} value={idSelected} onChange={this.handleSelectorValue} />
                                    <p>de {obj.length}</p>
                                </div>
                            }
                            <div className="advancedselector-container-selector" onMouseEnter={this.handleSelectorEnter}>
                                <div className="advancedselector-selector-field">
                                    <p>{selected}</p>
                                    <i className={open ? 'fa fa-chevron-up' : 'fa fa-chevron-down'}></i>
                                </div>
                            </div>
                            <div className={"advancedselector-selector-field-menu " + (open ? 'open' : '')}>
                                {
                                    obj.map(element => {
                                        return (
                                            <div key={element.id} className={this.state.idSelected === element.id ? 'selected' : ''} id={element.id} onClick={this.handleSelectorValue} >{element.value}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="panel table">
                    <div className="panel-body">
                        <br />
                    </div>
                </div>
            </div>
        );
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.optionalClass}
                    theme={theme}
                    mainTitle="Seletor Avançado"
                    mainDescription="Componente responsável por permitir a navegação entre grupos, assim filtrando o conteúdo."
                >
                <HowToUse
                    title="Uso"
                    description="Usado como uma espécie de filtro, permitindo filtrar o conteúdo entre inúmeras opções já definidas."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Identificador de quantidade total, item atual e seletor <br /> 3. Identificador do item atual <br /> 4. Lista de itens possíveis de seleção"
                    />
                </HowToUse>
                <HowToUse
                    title="Hierarquia"
                    description="O seletor avançado normalmente é utilizado junto com um componente de bloco, filtrando assim seu conteúdo."
                >
                    <HowToUseFigure
                        srcImg={hierarquia}
                        captionImg="Seletor avançado permitindo navegação entre lotes, e filtrando seus itens."
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    subtitle="Seleção ao digitar"
                    description="Seletor permite a digitação facilitando caso haja muitos itens."
                >
                    <HowToUseFigure
                        srcImg={gif}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Seleção ao passar o mouse"
                    description="Permite a seleção no efeito de hover."
                >
                    <HowToUseFigure
                        srcImg={gif2}
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(AdvancedSelector);
