import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import changelog from '../changelog.md';

export default class News extends Component {

    state = {
        markdown: ''
    }

    componentDidMount() {
        fetch(changelog).then(res => res.text()).then(text => this.setState({ markdown: text }));
    }

    render() {
        const { markdown } = this.state;
        return (
            <>
                <article>
                    <h1 className="main-title center top">
                        <small>Conheça nossas</small>
                        Novidades
                    </h1>
                    <div className="changelog">
                        <ReactMarkdown source={markdown} />
                    </div>
                </article>
            </>
        )
    }
}
