import React, { Component, useContext } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import ComponentTemplate from '../Templates/ComponentTemplate';
import HowToUse from '../Templates/HowToUse';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import {ShepherdTour, ShepherdTourContext} from 'react-shepherd'

const files = [{ 'less': ['introduction.less'] },{ 'less': ['introduction-oxy.less'] },{ 'js': ['oxy.js'] } ];

const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true
        }
    },
    useModalOverlay: true
};

const steps = [
    {
        id: 'intro',
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
            setTimeout(function() {
                window.scrollTo(0, 0);
                resolve();
            }, 500);
            });
        },
        buttons: [
            {
            classes: 'btn inline module-color',
            text: 'Próximo',
            type: 'next'
            }
        ],
        classes: 'custom-onboarding-1',
        highlightClass: 'highlight',
        scrollTo: false,
        showCancelLink: false,
        text: '<i class="onboarding-image-1"></i> <div>Conheça a Nova Marca </br> <i class="onboarding-logo-1"></i></div>'
    },
    {
        id: 'intro2',
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
            setTimeout(function() {
                window.scrollTo(0, 0);
                resolve();
            }, 500);
            });
        },
        buttons: [
            {
                classes: 'btn inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
            classes: 'btn inline module-color',
            text: 'Próximo',
            type: 'next'
            }
        ],
        classes: 'custom-onboarding-2',
        highlightClass: 'highlight',
        scrollTo: false,
        showCancelLink: false,
        text: '<i class="onboarding-image-2"></i> <div>Para sua melhor experiência </br> atualizamos todos os produtos </br><i class="onboarding-logo-2"></i></div>'
    },
    {
        id: 'step1',
        attachTo: {
            element: '.icon-toggle',
            on: 'bottom'
        },
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
            setTimeout(function() {
                window.scrollTo(0, 0);
                resolve();
            }, 500);
            });
        },
        buttons: [
            {
            classes: 'btn inline text negative shepherd-button-secondary',
            text: 'Sair do Tutorial',
            type: 'cancel'
            },
            {
            classes: 'btn inline neutral outlined shepherd-button-primary',
            text: 'Voltar',
            type: 'back'
            },
            {
            classes: 'btn inline module-color shepherd-button-primary',
            text: 'Próximo',
            type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Este é o passo 1']
    },
    {
        id: 'step2',
        attachTo: {
            element: 'nav ul',
            on: 'bottom'
        },
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
            setTimeout(function() {
                window.scrollTo(0, 0);
                resolve();
            }, 500);
            });
        },
        buttons: [
            {
            classes: 'btn inline text negative shepherd-button-secondary',
            text: 'Sair do Tutorial',
            type: 'cancel'
            },
            {
            classes: 'btn inline neutral outlined shepherd-button-primary',
            text: 'Voltar',
            type: 'back'
            },
            {
            classes: 'btn inline module-color shepherd-button-primary',
            text: 'Próximo',
            type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Este é o passo 2']
    },
    {
        id: 'step3',
        attachTo: {
            element: '.toggle-theme',
            on: 'bottom'
        },
        beforeShowPromise: function() {
            return new Promise(function(resolve) {
            setTimeout(function() {
                window.scrollTo(0, 0);
                resolve();
            }, 500);
            });
        },
        buttons: [
            {
            classes: 'btn inline neutral outlined shepherd-button-primary',
            text: 'Voltar',
            type: 'back'
            },
            {
            classes: 'btn inline positive shepherd-button-primary',
            text: 'Concluir',
            type: 'next'
            }
        ],
        classes: 'custom-class-name-1 custom-class-name-2',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Este é o passo 3']
    }
];

function RenderButton() {

    const tour = useContext(ShepherdTourContext);
    
    return (
        <button className="btn module-color icon-right center mt-xs" onClick={tour.start}>
            Iniciar Introdução
        </button>
    )
}

class Introduction extends Component {
    
    state = {
        currentIndex: undefined,
    }
    
    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Estados Vazios');
        this.setState({currentIndex: index});
        console.log(JSON.stringify(steps));
    }
    
    onChange = newState => {
        return this.setState(newState);
    }

    renderComponent = () => {

        return (
            <>
                <ShepherdTour steps={steps} tourOptions={tourOptions}>
                    <RenderButton/>
                </ShepherdTour>
            </>
        )
    }


    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
            </SwitchOptionsGroup>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Introdução"
                    mainDescription="A introdução mostra ao usuário como utilizar elementos básicos do sistema"
                >

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='Importe o arquivo JS que contém o JSON relacionado ao passo-a-passo que você deseja apresentar.</br>Todos os JSONs estão disponíveis no pacote NPM do Arc, na pasta: /assets/components/onboarding/seu-passo-a-passo.js.</br>Utilize o arquivo Less relacionado para estilizar este componente.</br>Acesse <a href="https://shepherdjs.dev/docs/" target="_blank">este link</a> para encontrar a documentação deste componente. </br> Disponível para React & Angular.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Introduction);
