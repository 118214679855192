import React, { Component } from 'react';
import slugify from 'react-slugify';
import classnames from 'classnames';
import ListMenu from '../../ListMenu';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import { connect } from 'react-redux';

import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import anatomia from '../../assets/img/components/button/anatomia.png';
import hierarquia from '../../assets/img/components/button/hierarquia.png';
import posicionamento from '../../assets/img/components/button/posicionamento.png';
import caution from '../../assets/img/components/button/caution.png';
import do1 from '../../assets/img/components/button/do1.png';
import do2 from '../../assets/img/components/button/do2.png';
import do3 from '../../assets/img/components/button/do3.png';
import dont1 from '../../assets/img/components/button/dont1.png';
import dont2 from '../../assets/img/components/button/dont2.png';
import dont3 from '../../assets/img/components/button/dont3.png';
import dont4 from '../../assets/img/components/button/dont4.png';
import dont5 from '../../assets/img/components/button/dont5.png';
import variacoes from '../../assets/img/components/button/variacoes.png';
import gif from '../../assets/img/components/button/gif.gif';
import gif2 from '../../assets/img/components/button/gif2.gif';
import gif3 from '../../assets/img/components/button/gif3.gif';

const files = [{'less': ['buttons.less']}, {'js': ['buttons.js']}];

class Button extends Component {
    state = {
        color: "module-color",
        aligment: undefined,
        type: undefined,
        icon: undefined,
        toggleDropdown: false,
        full: '',
        sociais: false,
        round: undefined,
        variation: undefined,
        currentIndex: undefined,
        disabled: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Botões');
        this.setState({currentIndex: index});
    }

    toggleDropdown = () => {
        if (this.state.type === 'dropdown')
            this.setState(state => {
                return {
                    toggleDropdown: !state.toggleDropdown
                }
            })
    }


    renderComponent = () => {
        const { color, aligment, type, icon, inline, toggleDropdown, full, round, variation, disabled } = this.state;

        const classNamesDiv = classnames(
            {
                [`${aligment}`]: type && aligment,
                [`btn-${type}`]: type === 'group' || type === 'dropdown',
                'open': type === 'dropdown' && toggleDropdown,
            }
        );

        const classNamesButton = classnames(
            'btn',
            variation,
            color,
            icon,
            inline,
            full,
            round,
            {
                [`${aligment}`]: !type && aligment,
                [`${type}`]: type === 'inline' ? type : '',
                'btn-dropdown-opener': type === 'dropdown'
            }
        )

        return (
            <React.Fragment>
                {type &&
                    <div className={classNamesDiv} onClick={this.toggleDropdown}>

                        {type === 'sociais' &&
                            <div className="btn-group export">
                                <button className="btn icon-left">
                                    <em className="fa fa-facebook"></em>Facebook
                                </button>
                                <button className="btn icon-left">
                                    <em className="fa fa-twitter"></em>Twitter
									</button>
                                <button className="btn icon-left">
                                    <em className="fa fa-google-plus"></em>Plus
								</button>
                            </div>
                        }

                        {type === 'dropdown' &&
                            <React.Fragment>
                                <button className={classNamesButton} disabled={disabled}>
                                    {icon && <em className="fa fa-dot-circle-o"></em>}
                                    Botão
                                </button>
                                <ul className="btn-dropdown-list">
                                    <li>
                                        Texto
                                    </li>
                                    <li>
                                        <a href="#botoes"> Link </a>
                                    </li>
                                    <li>
                                        <input type="radio" /> Escolha
                                    </li>
                                    <li>
                                        <input type="checkbox" /> Opções
                                    </li>
                                    <li>
                                        <i className="with-icon fa fa-fw fa-pencil" /> Editar
                                    </li>
                                    <li>
                                        <i className="with-icon fa fa-fw fa-trash" /> Excluir
                                    </li>
                                </ul>
                            </React.Fragment>
                        }


                        {(type === 'group' || type === 'inline') &&
                            <React.Fragment>
                                <button className={classNamesButton}>
                                    Botão
                                </button>
                                <button className={classNamesButton}>
                                    Botão
                                </button>
                                <button className={classNamesButton}>
                                    Botão
                                </button>
                            </React.Fragment>
                        }

                    </div>
                }
                {!type &&
                    <React.Fragment>
                        <button className={classNamesButton} disabled={disabled}>
                            {icon &&
                                <>
                                    <em className="fa fa-plus"></em>
                                    {icon === 'circle' &&
                                        <div className="btn-actions-label"> Novo </div>
                                    }
                                </>}
                            {icon !== 'circle' && 'Botão'}
                        </button>
                    </React.Fragment>
                }

            </React.Fragment>
        );
    }

    onChange = newState => {
        return this.setState(newState);
    }

    renderColorOptions = () => {
        return (
            <SwitchOptionsGroup label="Cores" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr='color' value='positive' stateName='color' label='positive/module-color' />
                <SwitchOptions attr='color' value='negative' stateName='color' />
                <SwitchOptions attr='color' value='neutral' stateName='color' />
                <SwitchOptions attr='color' value='highlight' stateName='color' />
                <SwitchOptions attr='color' value='warning' stateName='color' />
                <SwitchOptions attr='color' value='black' stateName='color' />
            </SwitchOptionsGroup>
        );
    }

    renderAlignmentOptions = () => {
        const { icon } = this.state;

        return (
            <SwitchOptionsGroup label="Alinhamentos" state={this.state} onChange={this.onChange} >
        
                <SwitchOptions attr='aligment' value='top-left' stateName='aligment' label='Topo a esquerda' hidden={icon !== 'circle'}/>
                <SwitchOptions attr='aligment' value='top-right' stateName='aligment' label='Topo a direita' hidden={icon !== 'circle'}/>
                <SwitchOptions attr='aligment' value='bottom-left' stateName='aligment' label='Embaixo a esquerda' hidden={icon !== 'circle'}/>
                <SwitchOptions attr='aligment' value='bottom-right' stateName='aligment' label='Embaixo a direita' hidden={icon !== 'circle'}/>
                <SwitchOptions attr='aligment' value='left' stateName='aligment' label='Esquerda' hidden={icon === 'circle'}/>
                <SwitchOptions attr='aligment' value='center' stateName='aligment' label='Centro' hidden={icon === 'circle'}/>
                <SwitchOptions attr='aligment' value='right' stateName='aligment' label='Direita' hidden={icon === 'circle'}/>
            </SwitchOptionsGroup>
        )
    }

    renderGroupOptions = () => {
        return (
            <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange} >
                <SwitchOptions attr='type' value='group' stateName='type' label='Agrupado' />
                <SwitchOptions attr='type' value='inline' stateName='type' label='Lado a Lado' />
                <SwitchOptions attr='type' value='dropdown' stateName='type' label='Dropdown' />
                <SwitchOptions attr='type' value='sociais' stateName='type' label='Redes Sociais' />
                <SwitchOptions attr='round' value='round' stateName='round' label='Arredondado' />
                <SwitchOptions attr='variation' value='outlined' stateName='variation' label='Contornado' />
                <SwitchOptions attr='variation' value='text' stateName='variation' label='Apenas Texto' />
            </SwitchOptionsGroup>
        )
    }

    renderIconsOptions = () => {
        return (
            <SwitchOptionsGroup label="Ícones" state={this.state} onChange={this.onChange} >
                <SwitchOptions attr='icon' value='icon-left' stateName='icon' label='Ícone à esquerda' />
                <SwitchOptions attr='icon' value='icon-right' stateName='icon' label='Ícone à direita' />
                <SwitchOptions attr='icon' value='circle' stateName='icon' label='Somente ícone' />
            </SwitchOptionsGroup>
        )
    }

    renderResponsiveOptions = () => {
        return (
            <SwitchOptionsGroup label="Botões Responsivos" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="full" value="full-xs" stateName="full" label="Resoluções extra pequenas (xs)" />
                <SwitchOptions attr="full" value="full-sm" stateName="full" label="Resoluções pequenas (sm)" />
                <SwitchOptions attr="full" value="full-md" stateName="full" label="Resoluções médias (sm)" />
                <SwitchOptions attr="full" value="full-lg" stateName="full" label="Resoluções grandes (lg)" />
            </SwitchOptionsGroup>
        )
    }

    renderValidationOption = () => {
        return (
            <SwitchOptionsGroup label="Validações" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="disabled" value={true} stateName="disabled" label="Desabilitado" />
            </SwitchOptionsGroup>
        )
    }

    optionalClass = () => {
        return (
            <React.Fragment>
                {this.renderColorOptions()}
                {this.renderAlignmentOptions()}
                {this.renderGroupOptions()}
                {this.renderIconsOptions()}
                {this.renderResponsiveOptions()}
                {this.renderValidationOption()}
            </React.Fragment>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;
        return (
            <>
                <ComponentTemplate
                    mainTitle='Botões'
                    mainDescription='Os botões permitem que os usuários executem ações e façam escolhas com um único toque.'
                    optionalClasses={this.optionalClass}
                    codeToRender={this.renderComponent}
                    theme={theme}
                >
                <HowToUse
                    title='Uso'
                    description='Os botões comunicam ações que os usuários podem executar. Eles geralmente são colocados em toda a interface do usuário, em locais como: <br /> • Diálogos<br /> • Modais<br /> • Formulários<br />• Blocos<br /> • Barras de ferramentas'
                />
                <HowToUse
                    title='Anatomia'
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg='1. Container <br /> 2. Ícone (opcional) <br /> 3. Label'
                    />
                </HowToUse>
                <HowToUse
                    title='Hierarquia'
                    subtitle='Um único botão'
                    description='Um layout deve conter um único botão destacado que deixe claro que outros botões têm menos importância na hierarquia. Este botão de alta ênfase exige mais atenção.'
                />
                <HowToUse
                    subtitle='Outros Botões'
                    description='Um aplicativo pode mostrar mais de um botão em um layout de cada vez. Portanto, um botão de alta ênfase pode ser acompanhado por botões de média e baixa ênfase que executam ações menos importantes. Ao usar vários botões, verifique se o estado disponível de um botão não se parece com o estado desativado de outro.'
                >
                    <HowToUseFigure
                        srcImg={hierarquia}
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Posicionamento'
                >
                    <HowToUseFigure
                        srcImg={posicionamento}
                        captionImg='Tela de Cadastro de Formas de Correção'
                        descriptionImg='Vários tipos de botão podem ser usados para expressar diferentes níveis de ênfase. <br /> <br /> Esse layout de tela usa: <br /> 1. Um botão de ação flutuante para a maior ênfase <br /> 2. Um botão contido para alta ênfase <br /> 3. Um botão contornado para pouca ênfase'
                    />
                </HowToUse>
                <HowToUse
                    title='Aplicação'
                >
                    <HowToUseFigure
                        srcImg={caution}
                        col
                        status='caution'
                        descriptionImg='Evite usar dois botões contidos um ao lado do outro se eles não tiverem a mesma cor de preenchimento.'
                    />
                    <HowToUseFigure
                        srcImg={do1}
                        col
                        status='do'
                        descriptionImg='Em uma barra inferior, ao usar vários botões, indique a ação mais importante colocando-a em um botão contido (ao lado de um botão de texto).'
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={do2}
                        col
                        status='do'
                        descriptionImg='Em uma barra inferior, ao usar vários botões, você pode colocar um botão contornado (ênfase média) ao lado de um botão contido (ênfase alta).'
                    />
                    <HowToUseFigure
                        srcImg={do3}
                        col
                        status='do'
                        descriptionImg='Ao usar vários botões em uma barra inferior, você pode colocar um botão de texto (ênfase baixa) ao lado de um botão contornado (ênfase média).'
                    />
                    <HowToUseFigure
                        srcImg={dont1}
                        status='dont'
                        descriptionImg='Não coloque um botão abaixo de outro botão, se houver espaço para colocá-los lado a lado.'
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={dont2}
                        col
                        status='dont'
                        descriptionImg='Não alinhe verticalmente um ícone e texto no centro de um botão contido.'
                    />
                    <HowToUseFigure
                        srcImg={dont3}
                        col
                        status='dont'
                        descriptionImg='Não use dois ícones no mesmo botão.'
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={dont4}
                        col
                        status='dont'
                        descriptionImg='Não utilize perguntas como texto, pode confundir o usuário quanto a ação. Utilize sempre que possível verbos.'
                    />
                    <HowToUseFigure
                        srcImg={dont5}
                        col
                        status='dont'
                        descriptionImg='Não utilize acentuações, se houver necessidade de descatar por algum motivo o botão, um ícone correspondente a ação deve ser utilizado.'
                    />
                </HowToUse>
                <HowToUse
                    title='Variações'
                    subtitle='Tipos'
                >
                    <HowToUseFigure
                        srcImg={variacoes}
                        descriptionImg='1. <b> Botão contido </b> (alta ênfase)<br /> Os botões contidos têm mais ênfase, pois usam um preenchimento de cor e sombra. <br /> 2. <b>Botão contornado</b> (ênfase média) <br /> Os botões destacados são usados para dar mais ênfase do que os botões de texto devido ao traçado. <br />  3. <b> Botão de texto</b> (ênfase baixa) <br /> Os botões de texto são normalmente usados para ações menos importantes.'
                    />
                </HowToUse>
                <HowToUse
                    title='Comportamento'
                    subtitle='Botão de Texto'
                    description='Os botões de texto são normalmente usados para ações menos pronunciadas, incluindo as localizadas: <br/> • Nas caixas de diálogo <br /> • Em cards  <br /> Nos cartões, os botões de texto ajudam a manter a ênfase no conteúdo do cartão. Como não possuem um contêiner, eles não desviam a atenção do conteúdo das proximidades. O rótulo de texto deste tipo de botão é o elemento mais importante, pois é o único elemento visível quando não há interação. Os botões de texto podem ser colocados na frente de vários fundos. Até o botão interagir com ele, seu contêiner não fica visível.'
                >
                    <HowToUseFigure
                        srcImg={gif}
                        descriptionImg='Comportamento de um botão de texto'
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Botão Contornado'
                    description='Os botões destacados são de ênfase média. Eles contêm ações importantes, mas não são a ação principal em um aplicativo. Estes também são uma alternativa de menor ênfase aos botões contidos ou uma alternativa de maior ênfase aos botões de texto. Eles podem ser colocados em cima de uma variedade de planos de fundo. Seu contêiner é transparente e, até que o botão receba uma interação, uma cor não é visível.'
                >
                    <HowToUseFigure
                        srcImg={gif2}
                        descriptionImg='Comportamento de um botão contornado'
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Botão Contido'
                    description='Os botões contidos são de alta ênfase, distinguidos pelo uso de elevação e preenchimento. Eles contêm ações principais para seu aplicativo. Eles são a melhor opção para colocar ícones ao lado dos rótulos de texto para esclarecer uma ação e chamar atenção para um botão.'
                >
                    <HowToUseFigure
                        srcImg={gif3}
                        descriptionImg='Comportamento de um botão contido'
                    />
                </HowToUse>

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Button);
