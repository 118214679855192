import React, { Component } from 'react';

import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import ComponentTemplate from '../Templates/ComponentTemplate';

import anatomia from '../../assets/img/components/workflow/anatomia.png';
import aplicacao from '../../assets/img/components/workflow/aplicacao.png';
import gif from '../../assets/img/components/workflow/gif.gif';
import itemdisabled from '../../assets/img/components/workflow/itemdisable.png';
import itemdisabledselecao from '../../assets/img/components/workflow/itemdisableselecao.png';
import fluxodesativado from '../../assets/img/components/workflow/fluxodesativado.png';

const files = [{ 'less': ['workflow.less'] }];

class Workflow extends Component {

    state = {
        blocks: [
            { "id": "1", "title": "Solicitação de Despesa 1", "inserted": false, "position": 0 },
            { "id": "2", "title": "Solicitação de Despesa 2", "inserted": false, "position": 1 },
            { "id": "3", "title": "Solicitação de Despesa 3", "inserted": false, "position": 2 },
            { "id": "4", "title": "Solicitação de Despesa 4", "inserted": false, "position": 3 },
            { "id": "5", "title": "Solicitação de Despesa 5", "inserted": false, "position": 4 },
            { "id": "6", "title": "Solicitação de Despesa 6", "inserted": false, "position": 5 },
            { "id": "7", "title": "Solicitação de Despesa 7", "inserted": false, "position": 6 },
            { "id": "8", "title": "Solicitação de Despesa 8", "inserted": false, "position": 7 },
            { "id": "9", "title": "Solicitação de Despesa 9", "inserted": false, "position": 8 },
            { "id": "10", "title": "Solicitação de Despesa 10", "inserted": false, "position": 9 },
            { "id": "11", "title": "Solicitação de Despesa 11", "inserted": false, "position": 10 },
            { "id": "12", "title": "Solicitação de Despesa 12", "inserted": false, "position": 11 },
        ],
        element: {},
        containerTitle: undefined,
        disabledBlock: undefined,
        disabledContainer: undefined,
        currentIndex: undefined,
        stepDone: undefined,
        customCard: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Fluxo de Processo');
        this.setState({ currentIndex: index });
    }

    onChange = newState => {
        this.setState(newState);
    }

    handleDragStart = (e, id) => {
        e.stopPropagation();
        let bc = e.currentTarget;
        e.dataTransfer.setData('id', id);
        bc.classList.add('hold');
        setTimeout(() => {
            bc.classList.replace('hold', 'wait');
        }, 0);
    }

    handleDragEnd = (e) => {
        e.stopPropagation();
        e.currentTarget.classList.remove('wait');
    }

    handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    }

    handleDragEnter = (e) => {
        e.preventDefault();
        e.stopPropagation();
        e.currentTarget.classList.toggle('hovered');
    }

    handleDragLeave = (e) => {
        e.stopPropagation();
        e.currentTarget.classList.remove('hovered');
    }

    handleDrop = (e, inserted) => {
        e.stopPropagation();
        e.currentTarget.classList.remove('hovered');
        let id = e.dataTransfer.getData("id");

        this.state.blocks.filter((block) => {
            if ((block.id === id) && (block.inserted !== inserted)) {
                let oldPosition = block.position;
                block.inserted = !block.inserted;
                block.position = this.state.blocks.length;
                let newPosition = block.position;
                this.adjustPosition(oldPosition, newPosition);
            }
            return block;
        });

        this.setState({
            ...this.state,
        })
    }

    handleBlockDrop = (e, position, location, inserted) => {
        e.stopPropagation();
        e.currentTarget.classList.remove('hovered');
        let id = e.dataTransfer.getData("id");

        this.state.blocks.filter((block) => {
            if (block.id === id) {
                let oldPosition = block.position;
                if (location === "before") {
                    block.position = position;
                } else if ((location === "after") && (position !== this.state.blocks.length)) {
                    block.position = position + 1;
                } else if ((location === "after") && (position === this.state.blocks.length)) {
                    block.position = position;
                }
                let newPosition = block.position;
                this.adjustPosition(oldPosition, newPosition);
                if (block.inserted !== inserted) {
                    block.inserted = inserted;
                }
            }
            return block;
        });

        this.setState({
            ...this.state,
        })
    }

    adjustPosition = (oldP, newP) => {
        let blocks = this.state.blocks;
        let newState = {}

        if (oldP < newP) {
            for (let i = oldP; i < newP; i++) {
                blocks[i].position -= 1;
            }
        } else if (oldP > newP) {
            for (let i = newP; i < oldP; i++) {
                blocks[i].position += 1;
            }
        }
        newState = { blocks };
        this.setState(newState);
    }

    renderComponent = () => {

        const {
            blocks,
            containerTitle,
            disabledBlock,
            disabledContainer,
            stepDone,
            customCard
        } = this.state;

        return (
            <React.Fragment>
                <div className="wf-container" onDragOver={this.handleDragOver} onDragEnter={this.handleDragEnter} onDragLeave={this.handleDragLeave} onDrop={(e) => this.handleDrop(e, false)}>
                    {containerTitle &&
                        <h3>Opções de montagem de fluxo</h3>
                    }
                    <div className="wf-block-list">
                        {
                            blocks.sort(function (a, b) { return a.position - b.position }).map((t) => {
                                if (t.inserted === false)
                                    return (
                                        <div key={t.id} className={`wf-block ${customCard ? 'custom' : ''} `} draggable onDragStart={(e) => this.handleDragStart(e, t.id)} onDragEnd={this.handleDragEnd}>
                                            <div className="wf-block-droparea" onDragOver={this.handleDragOver} onDragEnter={this.handleDragEnter} onDragLeave={this.handleDragLeave} onDrop={(e) => this.handleBlockDrop(e, t.position, "before", t.inserted)} />
                                            {customCard &&
                                                <div className="title-dash">
                                                    <i className="fa fa-plus-circle">
                                                        <div className="icon-container">
                                                            <div className={`card icon-picker`}>
                                                                <div className="icon-item fa fa-address-book"></div>
                                                                <div className="icon-item fa fa-address-book-o"></div>
                                                                <div className="icon-item fa fa-address-card"></div>
                                                                <div className="icon-item fa fa-address-card-o"></div>
                                                                <div className="icon-item fa fa-bandcamp"></div>
                                                                <div className="icon-item fa fa-bath"></div>
                                                                <div className="icon-item fa fa-bathtub"></div>
                                                                <div className="icon-item fa fa-drivers-license"></div>
                                                                <div className="icon-item fa fa-drivers-license-o"></div>
                                                                <div className="icon-item fa fa-eercast"></div>
                                                                <div className="icon-item fa fa-envelope-open"></div>
                                                                <div className="icon-item fa fa-envelope-open-o"></div>
                                                                <div className="icon-item fa fa-etsy"></div>
                                                                <div className="icon-item fa fa-free-code-camp"></div>
                                                                <div className="icon-item fa fa-grav"></div>
                                                                <div className="icon-item fa fa-handshake-o"></div>
                                                                <div className="icon-item fa fa-id-badge"></div>
                                                                <div className="icon-item fa fa-id-card"></div>
                                                                <div className="icon-item fa fa-id-card-o"></div>
                                                                <div className="icon-item fa fa-imdb"></div>
                                                                <div className="icon-item fa fa-linode"></div>
                                                                <div className="icon-item fa fa-meetup"></div>
                                                                <div className="icon-item fa fa-microchip"></div>
                                                                <div className="icon-item fa fa-podcast"></div>
                                                                <div className="icon-item fa fa-quora"></div>
                                                                <div className="icon-item fa fa-ravelry"></div>
                                                                <div className="icon-item fa fa-s15"></div>
                                                                <div className="icon-item fa fa-shower"></div>
                                                                <div className="icon-item fa fa-snowflake-o"></div>
                                                                <div className="icon-item fa fa-superpowers"></div>
                                                            </div>
                                                        </div>
                                                    </i>
                                                    <h2>
                                                        Lorem ipsum lorem
                                                        <span> Lorem ipsum lorem</span>
                                                    </h2>
                                                </div>
                                            }
                                            {!customCard &&
                                                <div className="wf-block-title">
                                                    <p>{t.title}</p>
                                                </div>
                                            }
                                            <div className="wf-block-droparea" onDragOver={this.handleDragOver} onDragEnter={this.handleDragEnter} onDragLeave={this.handleDragLeave} onDrop={(e) => this.handleBlockDrop(e, t.position, "after", t.inserted)} />
                                        </div>
                                    )
                                return '';
                            })
                        }
                    </div>
                </div>
                <div className="wf-container with-arrows" onDragOver={this.handleDragOver} onDragEnter={this.handleDragEnter} onDragLeave={this.handleDragLeave} onDrop={(e) => this.handleDrop(e, true)}>
                    {containerTitle &&
                        <h3>Arraste uma opção aqui para criar um fluxo</h3>
                    }
                    <div className="wf-block-list">
                        {
                            blocks.sort(function (a, b) { return a.position - b.position }).map((t) => {
                                if (t.inserted === true)
                                    return (
                                        <div key={t.id} className="wf-block " draggable onDragStart={(e) => this.handleDragStart(e, t.id)} onDragEnd={this.handleDragEnd}>
                                            <div className="wf-block-droparea" onDragOver={this.handleDragOver} onDragEnter={this.handleDragEnter} onDragLeave={this.handleDragLeave} onDrop={(e) => this.handleBlockDrop(e, t.position, "before", t.inserted)} />
                                            <div className="wf-block-title">
                                                <p className="clickable">
                                                    {t.title}
                                                    <i className="fa fa-external-link" />
                                                </p>
                                                <i className="fa fa-clock-o hint warning sm">
                                                    <div className="hint-content">Passo Pendente</div>
                                                </i>
                                            </div>
                                            <div className="wf-block-droparea" onDragOver={this.handleDragOver} onDragEnter={this.handleDragEnter} onDragLeave={this.handleDragLeave} onDrop={(e) => this.handleBlockDrop(e, t.position, "after", t.inserted)} />
                                        </div>
                                    )
                                return '';
                            })
                        }
                        {disabledBlock &&
                            <div className="wf-block disabled">
                                <div className="wf-block-title">
                                    <p>Fase Externa</p>
                                    <i className="fa fa-level-down" />
                                </div>
                            </div>
                        }
                        {stepDone &&
                            <div className="wf-block disabled">
                                <div className="wf-block-title step-done">
                                    <p className="clickable">
                                        Solicitação de Despesa 1
                                        <i className="fa fa-external-link" />
                                    </p>
                                    <i className="fa fa-check hint positive sm">
                                        <div className="hint-content">Passo Concluído</div>
                                    </i>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {disabledContainer &&
                    <div className="wf-container with-arrows disabled" onDragOver={this.handleDragOver} onDragEnter={this.handleDragEnter} onDragLeave={this.handleDragLeave} onDrop={(e) => this.handleDrop(e, true)}>
                        {containerTitle &&
                            <h3>Fase Externa</h3>
                        }
                        <div className="wf-block-list">
                            <div className="wf-block disabled">
                                <div className="wf-block-title">
                                    <p>Fase Externa</p>
                                </div>
                            </div>
                            <div className="wf-block disabled">
                                <div className="wf-block-title">
                                    <p>Fase Externa</p>
                                </div>
                            </div>
                            <div className="wf-block disabled">
                                <div className="wf-block-title">
                                    <p>Fase Externa</p>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

    renderOptionalClasses = () => {
        return (
            <React.Fragment>
                <SwitchOptionsGroup label="Tipo" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="container-title" value={true} stateName="containerTitle" label="Com Título" />
                    <SwitchOptions attr="disabled-block" value={true} stateName="disabledBlock" label="Bloco Desabilitado" />
                    <SwitchOptions attr="disabled-container" value={true} stateName="disabledContainer" label="Container Desabilitado" />
                    <SwitchOptions attr="step-done" value={true} stateName="stepDone" label="Passo Concluído" />
                    <SwitchOptions attr="customCard" value={true} stateName="customCard" label="Card Customizado" />
                </SwitchOptionsGroup>
            </React.Fragment>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Fluxo de Processos"
                    mainDescription="Componente responsável por permitir a organização e criação de um fluxo de modo visual e intuitivo."
                >
                <HowToUse
                    title="Uso"
                    description="Componente deve ser utilizado para mostrar determinados fluxos ou até mesmo permitir a criação dos mesmos.<br /> Facilita o entendimento de todo um processo. <br />Atualmente utilizado para determinar o fluxo de processo de algumas funcionalidades."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Título (opcional) <br /> 3. Etapa do fluxo <br /> 4. Etapa do fluxo bloqueada"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    description="Exemplo onde o componente está aplicado para determinar o fluxo do <b>Processo Administrativo.</b>"
                >
                    <HowToUseFigure
                        srcImg={aplicacao}
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    description="A criação/organização de um fluxo é feita através do drag and drop, onde é possível: <br /> • Arrastar e soltar para ordenar o novo fluxo <br /> • Ordenar um item a direita ou à esquerda de outro"
                >
                    <HowToUseFigure
                        srcImg={gif}
                        captionImg="Fluxo drag e drop"
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Item Desativado"
                    description="É utilizado para setar determinado item como obrigatório não permitindo a remoção/alteração deste item em um fluxo. <br/> É utilizado também para não permitir que um item seja utilizado em determinado fluxo."
                >
                    <HowToUseFigure
                        srcImg={itemdisabled}
                        captionImg="Item desabilitado (obrigatório) no fluxo "
                    />
                    <HowToUseFigure
                        srcImg={itemdisabledselecao}
                        captionImg="Item desabilitado para seleção"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Fluxo Desativado"
                    description="É utilizado para desativar a remoção e alteração de qualquer item de um determinado fluxo."
                >
                    <HowToUseFigure
                        srcImg={fluxodesativado}
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Workflow);
