import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';

import anatomia from '../../assets/img/components/display-data/anatomia.png';
import dont1 from '../../assets/img/components/display-data/dont1.png';
import do1 from '../../assets/img/components/display-data/do1.png';
import reduced from '../../assets/img/components/display-data/reduced.png';
import buttons from '../../assets/img/components/display-data/botoes.gif';
import columns from '../../assets/img/components/display-data/columns.png';
import highlight from '../../assets/img/components/display-data/highlight.png';
import highlightdont from '../../assets/img/components/display-data/highlightdont.png';
import highlightdo from '../../assets/img/components/display-data/highlightdo.png';
import columnhightlight from '../../assets/img/components/display-data/columnhighlight.png';
import columnhightlight2 from '../../assets/img/components/display-data/columnhighlight2.png';

const files = [
    { 'less': ['display-data.less'] },
    { 'react': ['DisplayData.jsx'] }
];


class DisplayData extends Component {

    state = {
        types: undefined,
        buttons: undefined,
        column: null,
        highlight: undefined,
        columnStyle: undefined,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Estados Vazios');
        this.setState({ currentIndex: index });
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="types" value="reduced" stateName="types" label="Reduzido" />
                <SwitchOptions attr="buttons" value="has-btn-actions" stateName="buttons" label="Com Botões" />
                <SwitchOptions attr="column" value="column" stateName="column" label="Colunas" />
                <SwitchOptions attr="highlight" value="highlight" stateName="highlight" label="Tema de Destaque" />
                <SwitchOptions attr="columnStyle" value="highlight" stateName="columnStyle" label="Com Coluna Destacada" />
                <SwitchOptions attr="columnStyle" value="changed" stateName="columnStyle" label="Com Coluna Alterada" />
            </SwitchOptionsGroup>
        )
    }

    renderComponent = () => {
        const { types, buttons, column, highlight, columnStyle } = this.state;
        return (
            <div className={`display-data border ${types ? types : ''} ${buttons ? buttons : ''} ${column ? column : ''} ${highlight ? highlight : ''}`}>
                <div className="row">
                    <div className="col-sm-6 col-md-4">
                        <b className="dd-title">
                            Nome
									</b>
                        <div className="dd-content">
                            Roberto Joaquim Alves
									</div>
                    </div>
                    <div className={`col-sm-6 col-md-4 ${columnStyle}`}>
                        <b className="dd-title">
                            Profissão
									</b>
                        <div className="dd-content">
                            Ator
									</div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                        <b className="dd-title">
                            Tipo
									</b>
                        <div className="dd-content">
                            Filme, TV e Teatro
									</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6 col-md-3">
                        <b className="dd-title">
                            Início de Carreira
									</b>
                        <div className="dd-content">
                            04/11/2016
									</div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <b className="dd-title">
                            Fim de Carreira
									</b>
                        <div className="dd-content">
                            26/03/2031
									</div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <b className="dd-title">
                            Materiais Lançados
									</b>
                        <div className="dd-content">
                            5
									</div>
                    </div>
                    <div className="col-sm-6 col-md-3">
                        <b className="dd-title">
                            N# de Prêmios
									</b>
                        <div className="dd-content">
                            3
									</div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-12">
                        <b className="dd-title">
                            Descrição Rápida
									</b>
                        <div className="dd-content">
                            Sed accumsan congue ex, sit amet efficitur erat rhoncus ut. Phasellus sed rhoncus magna.
                        </div>
                    </div>
                    {buttons &&
                        <div className="btn-actions">
                            <div className="btn-actions-inner">
                                <button className="btn circle links">
                                    <i className="fa fa-pencil"></i>
                                    <div className="btn-actions-label">
                                        <a href="#display-data" onClick={() => { }}>option 1</a>
                                        <a href="#display-data" onClick={() => { }}>option 2</a>
                                    </div>
                                </button>
                                <button className="btn circle">
                                    <i className="fa fa-trash"></i>
                                    <div className="btn-actions-label">
                                        Excluir
                                        </div>
                                </button>
                                <button className="btn circle">
                                    <i className="fa fa-eye"></i>
                                    <div className="btn-actions-label">
                                        Visualizar
                                        </div>
                                </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Display Data"
                    mainDescription="Componente responsável por exibir informações não editáveis com o intuito de destacá-las."
                >
                <HowToUse
                    title="Uso"
                    description="Bastante utilizado em formulários onde existem informações importantes a serem mostradas ao usuário, e também páginas de resumo, onde os campos são substituídos pelo display data. <br />Deve ser utilizado para exibir informações que necessitam de destaque e principalmente que não são editáveis."
                />

                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                >
                    <HowToUseFigure
                        srcImg={dont1}
                        col
                        status="dont"
                        descriptionImg="Não utilizar display data para exibição de informações repetidas, que deveriam ser utilizadas por tabelas."
                    />
                    <HowToUseFigure
                        srcImg={do1}
                        col
                        status="do"
                        descriptionImg="Utilize display data para mostrar resumos."
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    description="As variações do display data podem ser utilizadas em conjuntos. <br /> Ex. Pode-se utilizar estilo coluna com tamanho reduzido e botões."
                />
                <HowToUse
                    subtitle="Tamanho Reduzido"
                    description="Opção altamente utilizada por ocupar menos espaço em tela, essa variação reduz o espaçamento interno das colunas do display data"
                >
                    <HowToUseFigure
                        srcImg={reduced}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Com Botões"
                    description="Variação com botões deve ser usada com cuidado. <br /> Um display data nunca deve substituir uma tabela que já contém botões de ações e visualização. <br /> Com botões permite que no evento hover botões assim como na tabela são exibidos informando possíveis ações a serem tomadas."
                >
                    <HowToUseFigure
                        srcImg={buttons}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Estilo Colunas"
                    description="Variação com estilo colunas possibilita a melhor visualização e clareza dos dados, principalmente dados com valores extensos."
                >
                    <HowToUseFigure
                        srcImg={columns}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Destacado"
                    description="Variação altera a cor de fundo, utilizando a cor do módulo. Destacando assim as informações na tela."
                >
                    <HowToUseFigure
                        srcImg={highlight}
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={highlightdont}
                        col
                        status="dont"
                        descriptionImg="Não se deve utilizar mais de um display data destacado por página, pois assim perde o intuito de destaque."
                    />
                    <HowToUseFigure
                        srcImg={highlightdo}
                        col
                        status="do"
                        descriptionImg="Caso tenha que utilizar mais de um display data, um deles poderá ser destacado."
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Colunas Destacadas"
                    description="Existem variações para destacar colunas do display data individualmente."
                >
                    <HowToUseFigure
                        srcImg={columnhightlight}
                        captionImg="Colunas destacada padrão"
                    />
                    <HowToUseFigure
                        srcImg={columnhightlight2}
                        captionImg="Colunas destacada que indica alteração"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>

        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(DisplayData);
