import React, { Component, useContext } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import classnames from 'classnames';

import { ShepherdTour, ShepherdTourContext } from 'react-shepherd';

import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from './../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import MenuJuggernaut from './../MenuJuggernaut/MenuJuggernaut.jsx'

import anatomia from '../../assets/img/components/header/anatomia.png';
import vari1 from '../../assets/img/components/header/vari1.png';
import vari2 from '../../assets/img/components/header/vari2.png';
import areaImg from '../../assets/img/modules/recursoshumanos-invertido.png';
import moduleImg from '../../assets/img/modules/recursoshumanos-invertido-portalservidor.png';
import anatomia2 from '../../assets/img/components/menu/anatomia.png';
import dont1 from '../../assets/img/components/menu/dont1.png';
import dont2 from '../../assets/img/components/menu/dont2.png';
import padrao from '../../assets/img/components/menu/padrao.png';
import destacado from '../../assets/img/components/menu/destacado.png';
import brasao from '../../assets/img/components/header/brasao.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as OXYADMINISTRATIVO } from '../../assets/img/modules/oxy-administrativo.svg';
import { ReactComponent as OXYARRECADACAO } from '../../assets/img/modules/oxy-arrecadacao.svg';
import { ReactComponent as OXYCONTABIL } from '../../assets/img/modules/oxy-contabil.svg';
import { ReactComponent as OXYEDUCACIONAL } from '../../assets/img/modules/oxy-educacional.svg';
import { ReactComponent as OXYRH } from '../../assets/img/modules/oxy-rh.svg';
import { ReactComponent as OXYSOCIAL } from '../../assets/img/modules/oxy-social.svg';
import oxyLogo from '../../assets/img/modules/oxy-logo.svg';
import { ReactComponent as OXYLOGO } from '../../assets/img/modules/oxy-logo.svg';

import { ReactComponent as LOGOFOOTERSVG } from '../../assets/img/common/logo-footer.svg';
import { bindActionCreators } from 'redux';
import { toggleFullScreen, selectTheme } from '../../actions/index';

import json from '../../assets/components/onboarding/oxy.js';

const tourOptions = {
    defaultStepOptions: {
        cancelIcon: {
            enabled: true
        }
    },
    useModalOverlay: true
};

const MyTour = ({ completeTour }) => {
    let context = useContext(ShepherdTourContext);
    if (!context.isActive()) {
        context.start();
    }
    completeTour();
    return null;
}

const files = [
    { 'less': ['header.less'] },
    { 'less': ['sidebar.less'] },
    { 'angular': ['sidebar.module.ts'] },
    { 'react': ['Sidebar.jsx'] }
];

class HeaderJuggernaut extends Component {
    constructor(props) {
        super(props);
        this.completeTour = this.completeTour.bind(this)
    }

    state = {
        active: undefined,
        activeTab: 0,
        type: 'new',
        notification: undefined,
        shake: false,
        currentIndex: undefined,
        process: undefined,
        estado: "sidebar-toggle",
        fullscreen: false,
        float: true,
        notificationDetails: false,
        parametersDetails: false,
        openDate: false,
        contrastDetails: false,
        sistemDetails: false,
        accessibilityDetails: false,
        toastVisible: false,
        loadingNotification: false,
        arrecadacaoDetails: false,
        educacionalDetails: false,
        logoOxy: false,
        fancy: false,
        geralDetails: false,
        contrastType: 'normal',
        isTourComplete: false,
        order: false,
        openDropDownButton: false,
        toggleTaskList: false,
        taskListLoading: false,
        taskContentLoading: false,
        taskSelect: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Topo');
        this.setState({ currentIndex: index, notification: 'warning'});
    }

    componentDidUpdate() {
        const { fullscreen } = this.state;
        if (fullscreen) {
            document.querySelector('#root').classList.add('full');
        } else {
            document.querySelector('#root').classList.remove('full');
        }
    }

    componentWillUnmount() {
        this.props.toggleFullScreen(false);
    }

    openDropDownButton = e => {
        e.stopPropagation();
        this.setState(state => {
            return { openDropDownButton: !state.openDropDownButton }
        })
    }

    toggleTaskList = () => {
        this.setState(state => {
            return { toggleTaskList: !state.toggleTaskList }
        })
    }

    openTaskList = task => {
        console.log('clicou na task => ', task);

        this.setState({
            taskContentLoading: true,
            taskSelect: task
        }, () => {
            setTimeout(() => {
                this.setState({taskContentLoading: false})
            }, 6000);
        })
    }

    renderTasks = () => {
        const {taskSelect} = this.state;
        let tasks = [];
        tasks.push(<div key="0" className={`task-item ${taskSelect === 0 ? 'selected read' : ''}`} onClick={() => this.openTaskList(0)}> <div className="row flex mr-none ml-none"> <p className="task-title"> Gerar boletim de alteração </p><p className="task-datehour right"> 10m </p></div><div className="row"> <div className="col-md-12"> <a href="/#FERS-02" className="task-id"> FERS-02 </a> </div></div><div className="row"> <div className="col-md-12"> <p className="task-author"> João da silva </p></div></div></div>)
        for (let x = 1; x < 10; x++) {
            tasks.push(<div key={x} className={`task-item ${taskSelect === x ? 'selected read' : ''}`} onClick={() => this.openTaskList(x)}> <div className="row flex mr-none ml-none"> <p className="task-title"> Gerar boletim de alteração </p><p className="task-datehour right"> 10m </p></div><div className="row"> <div className="col-md-12"> <a href="/#FERS-02" className="task-id"> FERS-02 </a> </div></div><div className="row"> <div className="col-md-12"> <p className="task-author"> João da silva </p></div></div></div>)
        }
        return tasks;
    }

    selectTab = tab => {
        this.setState({ activeTab: tab });
    }

    openDetails = item => {
        this.setState(state => {
            if (item === state.active) {
                return { active: undefined }
            }
            return { active: item }
        });
    }

    toggleMenu = () => {
        const { type, float } = this.state;
        this.setState(state => {
            if ('sidebar-toggle' === state.estado) {
                return { estado: undefined, float: type === 'new' ? false : float }
            }
            return { estado: 'sidebar-toggle', float: type === 'new' ? true : float }
        })
    }

    onChange = newState => {
        const { toggleFullScreen, selectTheme } = this.props;


        if (newState.type === 'new') {
            newState.float = true;
        }

        if (newState.fullscreen) {
            toggleFullScreen(true);
            selectTheme('light');
            this.listenerKeyDown();
            this.showToast();
            this.loadingTask();
        } else {
            toggleFullScreen(false);
        }

        this.setState(newState, () => {
            console.log('state => ', this.state);
        });
    }

    loadingTask = () => {
        this.setState({taskListLoading: true}); 
        setTimeout(() => { 
            this.setState({taskListLoading: false}); 
        }, 6000);
    }

    toggleNotificationDetails = () => {
        this.setState(prevState => {
            return {
                notificationDetails: !prevState.notificationDetails
            }
        })
    }

    toggleDate = () => {
        this.setState(prevState => {
            return {
                openDate: !prevState.openDate
            }
        })
    }

    toggleContrastDetails = () => {
        this.setState(prevState => {
            return {
                contrastDetails: !prevState.contrastDetails
            }
        })
    }

    toggleSistemDetails = () => {
        this.setState(prevState => {
            return {
                sistemDetails: !prevState.sistemDetails
            }
        })
    }

    toggleParametersDetails = () => {
        this.setState(prevState => {
            return {
                parametersDetails: !prevState.parametersDetails
            }
        })
    }

    showToast = () => {
        this.setState({ toastVisible: true });
        setTimeout(() => {
            this.setState({ toastVisible: false })
        }, 3000)
    }

    completeTour = () => {
        console.log("COMPLETING");
        this.setState({ isTourComplete: true });
    }

    optionalClasses = () => {
        return (
            <>
                <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="type" value="styled" stateName="type" label="Remodelado" hidden />
                    <SwitchOptions attr="type" value="new" stateName="type" label="Novo Layout" hidden />
                    <SwitchOptions attr="fancy" value="fancy" stateName="fancy" label="Destacado" />
                    <SwitchOptions attr="logoOxy" value={true} stateName="logoOxy" label="Com logo do Oxy" />
                    <SwitchOptions attr="order" value={true} stateName="order" label="Com dois botões no header" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Variações da Notificação" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="shake" value={true} stateName="shake" label="Em Movimento" />
                    <SwitchOptions attr="notification" value="warning" stateName="notification" label="Alerta" />
                    <SwitchOptions attr="loadingNotification" value="loading" stateName="loadingNotification" label="Carregando" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Variações de Estado" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="estado" value="sidebar-toggle" stateName="estado" label="Menu Recolhido" />
                    <SwitchOptions attr="float" value={true} stateName="float" label="Float-type" hidden />
                    <SwitchOptions attr="fullscreen" value="fullscreen" stateName="fullscreen" label="Preencher tela" />
                </SwitchOptionsGroup>
            </>
        )
    }

    codeToRender = () => {
        const { active, activeTab, type, notification, shake, estado, fullscreen, float, notificationDetails, parametersDetails, openDate, contrastDetails, sistemDetails, accessibilityDetails, loadingNotification, arrecadacaoDetails, educacionalDetails, logoOxy, fancy, geralDetails, contrastType, isTourComplete, order, openDropDownButton, toggleTaskList, taskListLoading, taskSelect, taskContentLoading } = this.state;

        const headerClasses = classnames(
            'flex',
            'header',
            estado,
            type,
            fullscreen,
            { fancy }
        )

        const bubbleClasses = classnames(
            'bubble-notification',
            notification,
            { shake },
            loadingNotification
        )

        const featureGroupClasses = classnames(
            'features-group'
        )

        return (
            <>
                {type !== 'new' &&
                    <>
                        <header className={headerClasses} >
                            {type !== 'styled' &&
                                <button className="header-toggle-sidebar toggle-control sidebar-toggle" aria-label="Botão para expandir o menu" onClick={this.toggleMenu}>
                                    <em className="fa fa-bars"></em>
                                </button>
                            }

                            <div className="header-title">
                                <div className="content-title">
                                    {type === 'fancy' &&
                                        <div className="header-module">
                                            <div className="module-main">
                                                <img src={areaImg} alt="Logo área recursos humanos" />
                                            </div>
                                            <div className="module-module">
                                                <img src={moduleImg} alt="Logo módulo portal servidor" />
                                            </div>
                                        </div>
                                    }
                                    {type !== 'styled' &&
                                        <>
                                            <article className={`${type === 'fancy' ? 'column' : ''}`}>
                                                <span>Lorem ipsum lorem</span>
                                                {type === 'fancy' &&
                                                    <small>
                                                        Lorem ipsum lorem
                                                </small>
                                                }
                                            </article>
                                        </>
                                    }
                                    {type === 'styled' &&
                                        <nav aria-label="Breadcrumbs" className="nav-breadcrumbs">
                                            <ol>
                                                <li><a href="/#breadcrumb"><i className="fa fa-list-alt"></i><span>Pedidos de Compra</span></a></li>
                                                <li><a href="/#breadcrumb"><i className="fa fa-newspaper-o"></i><span>Lorem ipsum</span></a></li>
                                                <li><a href="/#breadcrumb" aria-current="page"><i className="fa fa-circle"></i><span>Lorem ipsum</span></a></li>
                                            </ol>
                                            <div className="nav-buttons" role="group">
                                                <button className="hint clean module-color fa fa-star bottom-right">
                                                    <div className="hint-content">Lorem ipsum</div>
                                                </button>

                                                <button className="hint clean module-color fa fa-expand bottom-right">
                                                    <div className="hint-content">Lorem ipsum</div>
                                                </button>
                                            </div>
                                        </nav>
                                    }
                                </div>
                            </div>

                            {type === 'styled' &&
                                <ul className="header-features">
                                    <li onClick={() => this.openDetails('process')} className={`${active === 'process' ? 'active' : ''}`} title="Processos">
                                        <i className="header-spinner fa fa-cogs"></i>
                                        <span className={bubbleClasses}>17</span>
                                        <small>Processos</small>
                                    </li>
                                    <li onClick={() => this.openDetails('accessibility')} className={`${active === 'accessibility' ? 'active' : ''}`} title="Acessibilidade">
                                        <i className="fa fa-universal-access"></i>
                                        <small>Acessibilidade</small>
                                    </li>
                                    <li onClick={() => this.openDetails('notifications')} className={`${active === 'notifications' ? 'active' : ''}`} title="Notificações">
                                        <i className="fa fa-bell"></i>
                                        <span className={bubbleClasses}>17</span>
                                        <small>Notificações</small>
                                    </li>
                                    <li onClick={() => this.openDetails('user')} className={`${active === 'user' ? 'active' : ''}`} title="Usuário">
                                        <i className="fa fa-user-circle-o"></i>
                                        <small>Usuário</small>
                                    </li>
                                    <li onClick={() => this.openDetails('modules')} className={`${active === 'modules' ? 'active' : ''}`} title="Módulos">
                                        <i className="fa fa-th"></i>
                                        <small>Módulos</small>
                                    </li>
                                    <li onClick={() => this.openDetails('flag')} className={`${active === 'flag' ? 'active' : ''}`} title="Competência">
                                        <i className="fa fa-flag"></i>
                                        <small>Competência</small>
                                    </li>
                                </ul>
                            }

                            {type !== 'styled' &&
                                <ul className="header-features">
                                    <li onClick={() => this.openDetails('process')} className={`header-spinner fa fa-cogs ${active === 'process' ? 'active' : ''}`} title="Processos">
                                        <span className={bubbleClasses}>17</span>
                                    </li>
                                    <li onClick={() => this.openDetails('accessibility')} className={`fa fa-universal-access ${active === 'accessibility' ? 'active' : ''}`} title="Acessibilidade"></li>
                                    <li onClick={() => this.openDetails('notifications')} className={`fa fa-bell ${active === 'notifications' ? 'active' : ''}`} title="Notificações">
                                        <span className={bubbleClasses}>17</span>
                                    </li>
                                    <li onClick={() => this.openDetails('user')} className={`fa fa-user-circle-o ${active === 'user' ? 'active' : ''}`}></li>
                                    <li onClick={() => this.openDetails('modules')} className={`fa fa-th ${active === 'modules' ? 'active' : ''}`}></li>
                                    <li onClick={() => this.openDetails('flag')} className={`fa fa-flag ${active === 'flag' ? 'active' : ''}`}></li>
                                </ul>
                            }
                        </header>

                        <div className={featureGroupClasses}>
                            <div style={{ right: "190px" }} className={`features-notifications ${active === 'process' ? 'active' : ''} reduced auto-height read-options`}>
                                <h2 className="title">
                                    Processos
                            </h2>
                                <div className="features-body">
                                    <ul className="notification-list">
                                        <li>
                                            <div className="datehour">
                                                <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                    <span>há 40 minutos</span>
                                                </time>
                                            </div>
                                            <article className="nowrap">O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.</article>
                                        </li>
                                        <li>
                                            <div className="datehour">
                                                <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                    <span>há 40 minutos</span>
                                                </time>
                                            </div>
                                            <article className="nowrap">O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.</article>
                                        </li>
                                        <li className="unread">
                                            <div className="datehour">
                                                <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                    <span>há 40 minutos</span>
                                                </time>
                                            </div>
                                            <article className="nowrap">O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.</article>
                                        </li>
                                        <li className="unread">
                                            <div className="datehour">
                                                <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                    <span>há 40 minutos</span>
                                                </time>
                                            </div>
                                            <article className="nowrap">O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.</article>
                                        </li>
                                        <li>
                                            <button>
                                                Ver mais
                                            <em className="fa fa-chevron-down"></em>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div style={{ right: "70px" }} className={`features-user auto-height ${active === 'accessibility' ? 'active' : ''}`}>
                                <ul className="user-actions">
                                    <li>
                                        <button id="decrease-zoom">
                                            <i className="fa fa-search-minus"></i>
                                            Diminuir Zoom
                                    </button>
                                    </li>
                                    <li>
                                        <button id="increase-zoom">
                                            <i className="fa fa-search-plus"></i>
                                            Aumentar Zoom
                                    </button>
                                    </li>
                                    <li>
                                        <button id="reset-zoom">
                                            <i className="fa fa-expand"></i>
                                            Restaurar Zoom
                                        </button>
                                    </li>
                                    <li>
                                        <button id="contrast-toggle">
                                            <i className="fa fa-adjust"></i>
                                            Contraste
                                    </button>
                                    </li>
                                </ul>
                            </div>
                            <div className={`features-notifications ${active === 'notifications' ? 'active' : ''} reduced auto-height read-options`}>
                                <h2 className="title">
                                    <div className="read hint clean center-left neutral">
                                        <em className="fa fa-envelope-open"></em>
                                        <div className="hint-content">Não há novas mensagens</div>
                                    </div>
                                    Notificações
                            </h2>
                                <div className="features-body">
                                    <ul className="notification-list">
                                        <li>
                                            <div className="datehour">
                                                <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                    <span>há 40 minutos</span>
                                                </time>
                                            </div>
                                            <article className="nowrap">O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.</article>
                                        </li>
                                        <li>
                                            <div className="datehour">
                                                <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                    <span>há 40 minutos</span>
                                                </time>
                                            </div>
                                            <article className="nowrap">O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.</article>
                                        </li>
                                        <li className="unread">
                                            <div className="datehour">
                                                <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                    <span>há 40 minutos</span>
                                                </time>
                                            </div>
                                            <article className="nowrap">O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.</article>
                                        </li>
                                        <li className="unread">
                                            <div className="datehour">
                                                <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                    <span>há 40 minutos</span>
                                                </time>
                                            </div>
                                            <article className="nowrap">O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.</article>
                                        </li>
                                        <li>
                                            <button>
                                                Ver mais
                                            <em className="fa fa-chevron-down"></em>
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className={`features-user auto-height ${active === 'user' ? 'active' : ''}`}>
                                <h2 className="title">
                                    <i aria-hidden="true"></i>
                                    Lorem ipsum
                            </h2>
                                <ul className="user-actions">
                                    <li>
                                        <a href="#topo">
                                            <i className="fa fa-comment"></i>
                                            Fale Conosco
                                    </a>
                                    </li>
                                    <li>
                                        <a href="#topo">
                                            <i className="fa fa-user"></i>
                                            Minha Conta
                                    </a>
                                    </li>
                                    <li>
                                        <a href="#topo">
                                            <i className="fa fa-question"></i>
                                            FAQ
                                    </a>
                                    </li>
                                    <li>
                                        <a href="#topo">
                                            <i className="fa fa-sign-out"></i>
                                            Sair
                                    </a>
                                    </li>
                                </ul>
                            </div>
                            <div className={`features-modules ${active === 'modules' ? 'active' : ''}`}>
                                <h2 className="title">
                                    Módulos
                            </h2>
                                <ul className="tab-header">
                                    <li onClick={() => this.selectTab(0)} className={`0 ${activeTab === 0 ? 'active' : ''}`}>
                                        Relacionados
                                </li>
                                    <li onClick={() => this.selectTab(1)} className={`1 ${activeTab === 1 ? 'active' : ''}`}>
                                        Favoritos
                                </li>
                                </ul>
                                <ul className="tab">
                                    <li className={`0 ${activeTab === 0 ? 'active' : ''}`}>
                                        <div className="features-body">
                                            <ul className="module-list">
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Módulo Controle de Obras" />
                                                        <span>
                                                            Controle de Obras
                                                    </span>
                                                    </a>
                                                </li>
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Módulo Almoxarifado e Logística" />
                                                        <span>
                                                            Almoxarifado e Logística
                                                    </span>
                                                    </a>
                                                </li>
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Módulo Compras e Licitações" />
                                                        <span>
                                                            Compras e Licitações
                                                    </span>
                                                    </a>
                                                </li>
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Módulo Controle Patrimonial" />
                                                        <span>
                                                            Controle Patrimonial
                                                    </span>
                                                    </a>
                                                </li>
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Módulo Controle de Processos" />
                                                        <span>
                                                            Controle de Processos
                                                    </span>
                                                    </a>
                                                </li>
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Módulo Frotas e Veículos" />
                                                        <span>
                                                            Frotas e Veículos
                                                    </span>
                                                    </a>
                                                </li>
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Módulo Legislação" />
                                                        <span>
                                                            Legislação
                                                    </span>
                                                    </a>
                                                </li>
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Módulo Ordem de Serviço" />
                                                        <span>
                                                            Ordem de Serviço
                                                    </span>
                                                    </a>
                                                </li>
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Fornecedores Online" />
                                                        <span>
                                                            Fornecedores Online
                                                    </span>
                                                    </a>
                                                </li>
                                                <li className="sisters">
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Imagem Ouvidoria" />
                                                        <span>
                                                            Ouvidoria
                                                    </span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li className={`1 ${activeTab === 1 ? 'active' : ''}`}>
                                        <div className="features-body">
                                            <div className="search">
                                                <form method="get" id="searchform" action="">
                                                    <input placeholder="Buscar em Módulos.." />
                                                    <button type="submit">
                                                        <i className="fa fa-search"></i>
                                                    </button>
                                                </form>
                                            </div>
                                            <ul className="bookmarks-list">
                                                <li>
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Contabilidade Pública" />
                                                        <b>
                                                            Contabilidade Pública
                                        </b>
                                                    </a>
                                                    <em className="fa fa-times remove-bookmark"></em>
                                                </li>
                                                <li>
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Planejamento Orçamentário" />
                                                        <b>
                                                            Planejamento Orçamentário
                                        </b>
                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <a href="#topo">
                                                                <i className="fa fa-save father"></i>
                                                                <b>Backup</b>
                                                            </a>
                                                            <em className="fa fa-times remove-bookmark"></em>
                                                        </li>
                                                        <li>
                                                            <a href="#topo">
                                                                <i className="fa fa-search child"></i>
                                                                <b>Busca</b>
                                                            </a>
                                                            <em className="fa fa-times remove-bookmark"></em>
                                                        </li>
                                                        <li>
                                                            <a href="#topo">
                                                                <i className="fa fa-search child"></i>
                                                                <b>Busca</b>
                                                            </a>
                                                            <em className="fa fa-times remove-bookmark"></em>
                                                        </li>
                                                    </ul>
                                                    <span className="dropdown-arrow open"> </span></li>
                                                <li>
                                                    <a href="#topo">
                                                        <img src={moduleImg} alt="Frotas e Veículos" />
                                                        <b>
                                                            Frotas e Veículos
                                        </b>
                                                    </a>
                                                    <ul>
                                                        <li>
                                                            <a href="#topo">
                                                                <i className="fa fa-search child"></i>
                                                                <b>Consulta</b>
                                                            </a>
                                                            <em className="fa fa-times remove-bookmark"></em>
                                                        </li>
                                                    </ul>
                                                    <span className="dropdown-arrow open"> </span></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div className={`features-configuracao-entidade ${active === 'flag' ? 'active' : ''}`}>
                                <h2 className="title">
                                    <i aria-hidden="true"></i>Definições de entidade
                            </h2>
                                <div className="user-actions inputs">
                                    <div className="form-group">
                                        <label htmlFor="entidades">Entidade</label>
                                        <select id="entidades" placeholder="Selecione a Entidade">
                                            <option value="PREFEITURA MUNICIPAL DE ELOTECH">PREFEITURA MUNICIPAL DE ELOTECH</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="exercicio">Exercícios</label>
                                        <select id="exercicio" placeholder="Selecione o Exercício">
                                            <option>2019</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="competencia">Competências</label>
                                        <select id="competencia" placeholder="Selecione a Competência">
                                            <option value="Janeiro">Janeiro - Aberto</option>
                                            <option value="Fevereiro">Fevereiro - Aberto</option>
                                            <option value="Março">Março - Aberto</option>
                                            <option value="Abril">Abril - Aberto</option>
                                            <option value="Maio">Maio - Aberto</option>
                                            <option value="Junho">Junho - Aberto</option>
                                            <option value="Julho">Julho - Aberto</option>
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn inline positive" id="btnConfirm" type="button"> Definir </button>
                                        <button className="btn inline negative" id="btnCancel" type="button"> Cancelar </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }

                {type === 'new' &&
                    <>
                        <header className={headerClasses} >
                            <div className={`header-content ${fancy ? 'bg-primary-color' : ''}`}>
                                <div className={`logo ${logoOxy ? 'logo-oxy' : ''}`}>
                                    {logoOxy &&
                                        <img src={oxyLogo} alt="Logo Oxy" className="hidden-xs" />
                                    }
                                    {!logoOxy &&
                                        <OXYARRECADACAO className="hidden-xs" />
                                    }
                                    Tributos
                                </div>
                                <button className="header-toggle-sidebar toggle-control sidebar-toggle hidden-lg hidden-md hidden-sm" aria-label="Botão para expandir o menu" onClick={this.toggleMenu}>
                                    <em className="fa fa-bars"></em>
                                </button>
                                <div className="header-title hidden-xs">
                                    <div className="content-title">
                                        <figure className="brasao">
                                            <img src={brasao} alt="Brasão Prefeitura" />
                                        </figure>
                                        <span onClick={() => {
                                            this.openDetails('user');
                                            this.toggleParametersDetails();
                                        }}>
                                            Pref. Municipal de São Jorge do Ivaí - 02/2020
                                            <label className="label">Informação Extra</label>
                                        </span>
                                    </div>
                                </div>
                                {order &&
                                    <ul className="header-features">
                                        <li tabIndex="0" onClick={() => this.openDetails('accessibility')} className={`${active === 'accessibility' ? 'active' : ''}`} title="Acessibilidade">
                                            <FontAwesomeIcon icon="wheelchair" className="icon" />
                                            <small className="hidden-xs">Acessibilidade</small>
                                        </li>
                                        <li tabIndex="0" onClick={() => this.openDetails('user')} className={`${active === 'user' ? 'active' : ''}`} title="Ajustes">
                                            <FontAwesomeIcon icon="tools" className="icon" />
                                            <small className="hidden-xs">Ajustes</small>
                                        </li>
                                    </ul>
                                }
                                {!order &&
                                    <ul className="header-features">
                                        <li tabIndex="0" onClick={() => this.openDetails('accessibility')} className={`${active === 'accessibility' ? 'active' : ''}`} title="Acessibilidade">
                                            <FontAwesomeIcon icon="wheelchair" className="icon" />
                                            <small className="hidden-xs">Acessibilidade</small>
                                        </li>
                                        <li tabIndex="0" onClick={() => this.openDetails('notifications')} className={`${active === 'notifications' ? 'active' : ''}`} title="Notificações">
                                            <i className="fa fa-bell"></i>
                                            <span className={bubbleClasses}>
                                                999
                                        </span>
                                            <small className="hidden-xs">Notificações</small>
                                        </li>
                                        <li tabIndex="0" onClick={() => this.openDetails('user')} className={`${active === 'user' ? 'active' : ''}`} title="Ajustes">
                                            <FontAwesomeIcon icon="tools" className="icon" />
                                            <small className="hidden-xs">Ajustes</small>
                                        </li>
                                        <li tabIndex="0" onClick={() => this.openDetails('modules')} className={`${active === 'modules' ? 'active' : ''}`} title="Módulos">
                                            <i className="fa fa-th"></i>
                                            <small className="hidden-xs">Módulos</small>
                                        </li>
                                    </ul>
                                }

                            </div>
                            <div className="header-content-mobile hidden-lg hidden-md hidden-sm">
                                <div className="header-title">
                                    <div className={`content-title ${fancy ? 'bg-primary-color' : ''}`}>
                                        <figure className="brasao">
                                            <img src="http://www.pmsjivai.pr.gov.br//images/logo.png" alt="Brasão Prefeitura" />
                                        </figure>
                                        <span onClick={() => {
                                            this.openDetails('user');
                                            this.toggleParametersDetails();
                                        }}>
                                            Pref. Municipal de São Jorge do Ivaí - 02/2020
                                            <label className="label">Informação Extra</label>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </header>

                        {order &&
                            <div className="features-group new">
                                <div className={`features-user auto-height order-1 ${active === 'user' ? 'active' : ''} ${parametersDetails ? 'overflow-visible' : ''}`}>
                                    {!parametersDetails && !contrastDetails && !sistemDetails &&
                                        <>
                                            <h2 className="title">
                                                Ajustes
                                            </h2>
                                            <ul className="user-actions">
                                                <li className="features-section">
                                                    Minha Conta
                                                </li>
                                                <li>
                                                    <FontAwesomeIcon icon="user" className="icon" />
                                                    <article>
                                                        <label>Usuário</label>
                                                        Leonardo Oliveira
                                                    </article>
                                                </li>
                                                <li className="features-section">
                                                    Parâmetros
                                                </li>
                                                <li title="Pref. Municipal de São Jorge do Ivaí - 02/2020" onClick={this.toggleParametersDetails}>
                                                    <FontAwesomeIcon icon="building" className="icon" />
                                                    <article className="nowrap">
                                                        <label> Entidade </label>
                                                        Pref. Municipal de São Jorge do Ivaí - 02/2020
                                                    </article>
                                                </li>
                                                <li onClick={this.toggleParametersDetails}>
                                                    <FontAwesomeIcon icon="calendar-alt" className="icon" />
                                                    <article className="nowrap">
                                                        <label>Competência</label>
                                                        02/2020
                                                    </article>
                                                </li>
                                                <li className="features-section">
                                                    Ajuda
                                                </li>
                                                <li>
                                                    <i className="fa fa-question icon" />
                                                    FAQ - Perguntas Frequentes
                                                </li>
                                                <li onClick={this.toggleSistemDetails}>
                                                    <i className="fa fa-info-circle icon" />
                                                    Sobre o sistema
                                                </li>
                                                <li className="logout">
                                                    <i className="fa fa-sign-out icon" />
                                                    Sair
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    {parametersDetails &&
                                        <>
                                            <h2 className="title">
                                                <div className="read hint clean center-left neutral" onClick={this.toggleParametersDetails}>
                                                    <FontAwesomeIcon icon="chevron-left" />
                                                    <div className="hint-content">Voltar</div>
                                                </div>
                                                Parâmetros
                                                                </h2>
                                            <div className="features-group-container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group"><label className="label" for="entidade">Entidade</label>
                                                            <input type="text" id="entidade" className="has-input-icon autocomplete-input" name="entidade" placeholder="Entidade" />
                                                            <i className="fa fa-search input-icon"></i>
                                                            <ul className="autocomplete">
                                                                <li className="autocomplete-item">Pref. Municipal de São Jorge do Ivaí</li>
                                                                <li className="autocomplete-item">Pref. Municipal de São Paulo</li>
                                                                <li className="autocomplete-item">Pref. Municipal de Iguatemi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="field">Competência</label>
                                                            <input type="text" id="field" className="datepicker-opener undefined " placeholder="00/00/0000" onFocus={this.toggleDate} onBlur={this.toggleDate} />
                                                            <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                                                            <div className={`datepicker right ${openDate ? 'open' : ''}`}>
                                                                <div className="datepicker-header">
                                                                    <div className="datepicker-header-year">

                                                                        <em className="fa fa-chevron-left"></em><b className="year-number">2016</b>

                                                                        <em className="fa fa-chevron-right"></em></div>
                                                                    <div className="datepicker-header-month">

                                                                        <em className="fa fa-chevron-left"></em><b className="month-name">Outubro</b>

                                                                        <em className="fa fa-chevron-right"></em></div>
                                                                </div>
                                                                <div className="datepicker-calendar">
                                                                    <div className="datepicker-calendar-header">
                                                                        <li className="item">Dom</li>
                                                                        <li className="item">Seg</li>
                                                                        <li className="item">Ter</li>
                                                                        <li className="item">Qua</li>
                                                                        <li className="item">Qui</li>
                                                                        <li className="item">Sex</li>
                                                                        <li className="item">Sab</li>
                                                                    </div>
                                                                    <li className="item not-this-month">25</li>
                                                                    <li className="item not-this-month">26</li>
                                                                    <li className="item not-this-month">27</li>
                                                                    <li className="item not-this-month">28</li>
                                                                    <li className="item not-this-month">29</li>
                                                                    <li className="item not-this-month">30</li>
                                                                    <li className="item">1</li>
                                                                    <li className="item">2</li>
                                                                    <li className="item">3</li>
                                                                    <li className="item">4</li>
                                                                    <li className="item">5</li>
                                                                    <li className="item">6</li>
                                                                    <li className="item">7</li>
                                                                    <li className="item">8</li>
                                                                    <li className="item">9</li>
                                                                    <li className="item">10</li>
                                                                    <li className="item">11</li>
                                                                    <li className="item">12</li>
                                                                    <li className="item">13</li>
                                                                    <li className="item">14</li>
                                                                    <li className="item">15</li>
                                                                    <li className="item">16</li>
                                                                    <li className="item">17</li>
                                                                    <li className="item">18</li>
                                                                    <li className="item">19</li>
                                                                    <li className="item">20</li>
                                                                    <li className="item">21</li>
                                                                    <li className="item">22</li>
                                                                    <li className="item">23</li>
                                                                    <li className="item">24</li>
                                                                    <li className="item">25</li>
                                                                    <li className="item">26</li>
                                                                    <li className="item">27</li>
                                                                    <li className="item">28</li>
                                                                    <li className="item">29</li>
                                                                    <li className="item">30</li>
                                                                    <li className="item">31</li>
                                                                    <li className="item not-this-month">1</li>
                                                                    <li className="item not-this-month">2</li>
                                                                    <li className="item not-this-month">3</li>
                                                                    <li className="item not-this-month">4</li>
                                                                    <li className="item not-this-month">5</li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <button className="btn module-color right">
                                                            definir
                                                                            </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {sistemDetails &&
                                        <>
                                            <h2 className="title">
                                                <div className="read hint clean center-left neutral" onClick={this.toggleSistemDetails}>
                                                    <FontAwesomeIcon icon="chevron-left" />
                                                    <div className="hint-content">Voltar</div>
                                                </div>
                                                Sobre o sistema
                                                                </h2>
                                            <ul className="user-actions">
                                                <div className="col-md-12 form-group mb-xs">
                                                    <article>
                                                        <label>Versão</label>
                                                        <p>81.0.4004.129</p>
                                                    </article>
                                                </div>
                                                <li className="form-group">
                                                    <i className="fa fa-info-circle icon" />
                                                    Confira as novidades da versão
                                                                    </li>
                                            </ul>
                                            <div className="footer-oxy-logo hidden-sm hidden-xs">
                                                <LOGOFOOTERSVG />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={`features-user auto-height order-2 ${active === 'accessibility' ? 'active accessibility' : ''}`}>
                                    {!parametersDetails && !contrastDetails && !sistemDetails && !accessibilityDetails &&
                                        <>
                                            <h2 className="title">
                                                Acessibilidade
                                            </h2>
                                            <ul className="user-actions">
                                                <li className="no-link zoom">
                                                    <FontAwesomeIcon icon="search" className="icon" />
                                                    Zoom
                                                    <div className="btn-group right">
                                                        <button className="btn neutral pt-none pb-none fa fa-plus" />
                                                        <button className="btn neutral pt-none pb-none fa fa-minus" />
                                                        <button className="btn neutral pt-none pb-none fa fa-expand" />
                                                    </div>
                                                </li>
                                                <li onClick={this.toggleContrastDetails}>
                                                    <FontAwesomeIcon icon="adjust" className="icon" />
                                                    Contraste
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    {contrastDetails &&
                                        <>
                                            <h2 className="title">
                                                <div className="read hint clean center-left neutral" onClick={this.toggleContrastDetails}>
                                                    <FontAwesomeIcon icon="chevron-left" />
                                                    <div className="hint-content">Voltar</div>
                                                </div>
                                                Contraste
                                            </h2>
                                            <div className="features-group-container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('normal')}>
                                                            <em className={`switch module-color ${contrastType === 'normal' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Normal</span>
                                                        </div>
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('invert')}>
                                                            <em className={`switch module-color ${contrastType === 'invert' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Escuro</span>
                                                        </div>
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('protanopia')}>
                                                            <em className={`switch module-color ${contrastType === 'protanopia' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Protanopia</span>
                                                        </div>
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('deuteranopia')}>
                                                            <em className={`switch module-color ${contrastType === 'deuteranopia' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Deuteranopia</span>
                                                        </div>
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('tritanopia')}>
                                                            <em className={`switch module-color ${contrastType === 'tritanopia' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Tritanopia</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <button className="btn text neutral right">
                                                            restaurar definições
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                        }

                        {!order &&
                            <div className="features-group new">
                                <div className={`features-notifications ${active === 'notifications' ? 'active' : ''} reduced auto-height read-options`}>
                                    {!notificationDetails &&
                                        <>
                                            <h2 className="title">
                                                <div className="read hint clean center-left">
                                                    <em className="fa fa-envelope"></em>
                                                    <div className="hint-content">1 nova mensagem</div>
                                                </div>
                                                Notificações
                                            </h2>
                                            <div className="features-body">
                                                <ul className="notification-list">
                                                    <li className="has-icon">
                                                        <div className="chip-container inline module-color outlined">
                                                            <OXYRH className="chip-icon-left" />
                                                            <p className="chip-text">Folha de Pagamento</p>
                                                        </div>
                                                        <div className="datehour">
                                                            <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                                <span>1m</span>
                                                            </time>
                                                        </div>
                                                        <article>
                                                            <p className="nowrap">
                                                                O relatório está sendo gerado
                                                            </p>
                                                        </article>
                                                        <FontAwesomeIcon icon="spinner" className="icon negative fa-spin" />

                                                    </li>
                                                    <li className="unread has-icon" onClick={this.toggleNotificationDetails}>
                                                        <div className="chip-container inline module-color outlined">
                                                            <OXYADMINISTRATIVO className="chip-icon-left" />
                                                            <p className="chip-text">Frotas</p>
                                                        </div>
                                                        <div className="datehour">
                                                            <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                                <span>10m</span>
                                                            </time>
                                                        </div>
                                                        <article>
                                                            <p className="nowrap">
                                                                O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.
                                                            </p>
                                                        </article>
                                                        <FontAwesomeIcon icon="check" className="icon module-color fa-check" />
                                                    </li>
                                                    <li className="has-icon" onClick={this.toggleNotificationDetails}>
                                                        <div className="chip-container inline module-color outlined">
                                                            <OXYSOCIAL className="chip-icon-left" />
                                                            <p className="chip-text">Saúde</p>
                                                        </div>
                                                        <div className="datehour">
                                                            <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                                <span>1h</span>
                                                            </time>
                                                        </div>
                                                        <article>
                                                            <p className="nowrap">
                                                                Não foi possivel gerar o relatório 1210.
                                                            </p>
                                                        </article>
                                                        <FontAwesomeIcon icon="times" className="icon negative" />
                                                    </li>
                                                    <li className="has-icon" onClick={this.toggleNotificationDetails}>
                                                        <div className="chip-container inline module-color outlined">
                                                            <OXYARRECADACAO className="chip-icon-left" />
                                                            <p className="chip-text">Fiscalização</p>
                                                        </div>
                                                        <div className="datehour">
                                                            <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                                <span>2h</span>
                                                            </time>
                                                        </div>
                                                        <article>
                                                            <p className="nowrap">
                                                                O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.
                                                            </p>
                                                        </article>
                                                        <FontAwesomeIcon icon="check" className="icon module-color" />
                                                    </li>
                                                    <li className="has-icon" onClick={this.toggleNotificationDetails}>
                                                        <div className="chip-container inline module-color outlined">
                                                            <OXYARRECADACAO className="chip-icon-left" />
                                                            <p className="chip-text">Fiscalização</p>
                                                        </div>
                                                        <div className="datehour">
                                                            <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                                <span>2h</span>
                                                            </time>
                                                        </div>
                                                        <article>
                                                            <p className="nowrap">
                                                                O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.
                                                            </p>
                                                        </article>
                                                        <FontAwesomeIcon icon="check" className="icon module-color" />
                                                    </li>
                                                    <li className="has-icon" onClick={this.toggleNotificationDetails}>
                                                        <div className="chip-container inline module-color outlined">
                                                            <OXYARRECADACAO className="chip-icon-left" />
                                                            <p className="chip-text">Fiscalização</p>
                                                        </div>
                                                        <div className="datehour">
                                                            <time dateTime="Tue Jul 09 2019 15:37:28 GMT-0300 (Horário Padrão de Brasília)">
                                                                <span>2h</span>
                                                            </time>
                                                        </div>
                                                        <article>
                                                            <p className="nowrap">
                                                                O seu pedido de declaração de ITBI 168 foi analisado e seu boleto já está disponível.
                                                            </p>
                                                        </article>
                                                        <FontAwesomeIcon icon="check" className="icon module-color" />
                                                    </li>
                                                    <li className="more">
                                                        <button>
                                                            Ver mais
                                                            <em className="fa fa-chevron-down"></em>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </div>
                                        </>
                                    }
                                    {notificationDetails &&
                                        <>
                                            <h2 className="title">
                                                <div className="read hint clean center-left neutral" onClick={this.toggleNotificationDetails}>
                                                    <FontAwesomeIcon icon="chevron-left" />
                                                    <div className="hint-content">Voltar</div>
                                                </div>
                                                Notificações
                                                                </h2>
                                            <div className="features-body">
                                                <div className="features-group-container">
                                                    <div className="datehour">
                                                        <time dateTime="Thu Oct 10 2019 14:25:00 GMT-0300 (Horário Padrão de Brasília)">
                                                            <span>há 1 ano</span>
                                                        </time>
                                                    </div>
                                                    <article>Pedido de declaração de ITBI 222 transferido</article>
                                                    <p>
                                                        O cadastro imobiliário 1000700 do pedido de declaração de ITBI 222 foi transferido.
                                                                            </p>
                                                    <button className="btn module-color mt-xs right">
                                                        Visualizar
                                                                            </button>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={`features-user auto-height ${active === 'user' ? 'active' : ''} ${parametersDetails ? 'overflow-visible' : ''}`}>
                                    {!parametersDetails && !contrastDetails && !sistemDetails &&
                                        <>
                                            <h2 className="title">
                                                Ajustes
                                            </h2>
                                            <ul className="user-actions">
                                                <li className="features-section">
                                                    Minha Conta
                                                </li>
                                                <li>
                                                    <FontAwesomeIcon icon="user" className="icon" />
                                                    <article>
                                                        <label>Usuário</label>
                                                        Leonardo Oliveira
                                                    </article>
                                                </li>
                                                <li className="features-section">
                                                    Parâmetros
                                                </li>
                                                <li title="Pref. Municipal de São Jorge do Ivaí - 02/2020" onClick={this.toggleParametersDetails}>
                                                    <FontAwesomeIcon icon="building" className="icon" />
                                                    <article className="nowrap">
                                                        <label> Entidade </label>
                                                        Pref. Municipal de São Jorge do Ivaí - 02/2020
                                                    </article>
                                                </li>
                                                <li onClick={this.toggleParametersDetails}>
                                                    <FontAwesomeIcon icon="calendar-alt" className="icon" />
                                                    <article className="nowrap">
                                                        <label>Competência</label>
                                                        02/2020
                                                    </article>
                                                </li>
                                                <li className="features-section">
                                                    Ajuda
                                                </li>
                                                <li>
                                                    <i className="fa fa-question icon" />
                                                    FAQ - Perguntas Frequentes
                                                </li>
                                                <li onClick={this.toggleSistemDetails}>
                                                    <i className="fa fa-info-circle icon" />
                                                    Sobre o sistema
                                                </li>
                                                <li className="logout">
                                                    <i className="fa fa-sign-out icon" />
                                                    Sair
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    {parametersDetails &&
                                        <>
                                            <h2 className="title">
                                                <div className="read hint clean center-left neutral" onClick={this.toggleParametersDetails}>
                                                    <FontAwesomeIcon icon="chevron-left" />
                                                    <div className="hint-content">Voltar</div>
                                                </div>
                                                Parâmetros
                                                                </h2>
                                            <div className="features-group-container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group"><label className="label" for="entidade">Entidade</label>
                                                            <input type="text" id="entidade" className="has-input-icon autocomplete-input" name="entidade" placeholder="Entidade" />
                                                            <i className="fa fa-search input-icon"></i>
                                                            <ul className="autocomplete">
                                                                <li className="autocomplete-item">Pref. Municipal de São Jorge do Ivaí</li>
                                                                <li className="autocomplete-item">Pref. Municipal de São Paulo</li>
                                                                <li className="autocomplete-item">Pref. Municipal de Iguatemi</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="label" htmlFor="field">Competência</label>
                                                            <input type="text" id="field" className="datepicker-opener undefined " placeholder="00/00/0000" onFocus={this.toggleDate} onBlur={this.toggleDate} />
                                                            <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                                                            <div className={`datepicker right ${openDate ? 'open' : ''}`}>
                                                                <div className="datepicker-header">
                                                                    <div className="datepicker-header-year">

                                                                        <em className="fa fa-chevron-left"></em><b className="year-number">2016</b>

                                                                        <em className="fa fa-chevron-right"></em></div>
                                                                    <div className="datepicker-header-month">

                                                                        <em className="fa fa-chevron-left"></em><b className="month-name">Outubro</b>

                                                                        <em className="fa fa-chevron-right"></em></div>
                                                                </div>
                                                                <div className="datepicker-calendar">
                                                                    <div className="datepicker-calendar-header">
                                                                        <li className="item">Dom</li>
                                                                        <li className="item">Seg</li>
                                                                        <li className="item">Ter</li>
                                                                        <li className="item">Qua</li>
                                                                        <li className="item">Qui</li>
                                                                        <li className="item">Sex</li>
                                                                        <li className="item">Sab</li>
                                                                    </div>
                                                                    <li className="item not-this-month">25</li>
                                                                    <li className="item not-this-month">26</li>
                                                                    <li className="item not-this-month">27</li>
                                                                    <li className="item not-this-month">28</li>
                                                                    <li className="item not-this-month">29</li>
                                                                    <li className="item not-this-month">30</li>
                                                                    <li className="item">1</li>
                                                                    <li className="item">2</li>
                                                                    <li className="item">3</li>
                                                                    <li className="item">4</li>
                                                                    <li className="item">5</li>
                                                                    <li className="item">6</li>
                                                                    <li className="item">7</li>
                                                                    <li className="item">8</li>
                                                                    <li className="item">9</li>
                                                                    <li className="item">10</li>
                                                                    <li className="item">11</li>
                                                                    <li className="item">12</li>
                                                                    <li className="item">13</li>
                                                                    <li className="item">14</li>
                                                                    <li className="item">15</li>
                                                                    <li className="item">16</li>
                                                                    <li className="item">17</li>
                                                                    <li className="item">18</li>
                                                                    <li className="item">19</li>
                                                                    <li className="item">20</li>
                                                                    <li className="item">21</li>
                                                                    <li className="item">22</li>
                                                                    <li className="item">23</li>
                                                                    <li className="item">24</li>
                                                                    <li className="item">25</li>
                                                                    <li className="item">26</li>
                                                                    <li className="item">27</li>
                                                                    <li className="item">28</li>
                                                                    <li className="item">29</li>
                                                                    <li className="item">30</li>
                                                                    <li className="item">31</li>
                                                                    <li className="item not-this-month">1</li>
                                                                    <li className="item not-this-month">2</li>
                                                                    <li className="item not-this-month">3</li>
                                                                    <li className="item not-this-month">4</li>
                                                                    <li className="item not-this-month">5</li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <button className="btn module-color right">
                                                            definir
                                                                            </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                    {sistemDetails &&
                                        <>
                                            <h2 className="title">
                                                <div className="read hint clean center-left neutral" onClick={this.toggleSistemDetails}>
                                                    <FontAwesomeIcon icon="chevron-left" />
                                                    <div className="hint-content">Voltar</div>
                                                </div>
                                                Sobre o sistema
                                                                </h2>
                                            <ul className="user-actions">
                                                <div className="col-md-12 form-group mb-xs">
                                                    <article>
                                                        <label>Versão</label>
                                                        <p>81.0.4004.129</p>
                                                    </article>
                                                </div>
                                                <li className="form-group">
                                                    <i className="fa fa-info-circle icon" />
                                                    Confira as novidades da versão
                                                                    </li>
                                            </ul>
                                            <div className="footer-oxy-logo hidden-sm hidden-xs">
                                                <LOGOFOOTERSVG />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={`features-user auto-height ${active === 'accessibility' ? 'active accessibility' : ''}`}>
                                    {!parametersDetails && !contrastDetails && !sistemDetails && !accessibilityDetails &&
                                        <>
                                            <h2 className="title">
                                                Acessibilidade
                                            </h2>
                                            <ul className="user-actions">
                                                <li className="no-link zoom">
                                                    <FontAwesomeIcon icon="search" className="icon" />
                                                    Zoom
                                                    <div className="btn-group right">
                                                        <button className="btn neutral pt-none pb-none fa fa-plus" />
                                                        <button className="btn neutral pt-none pb-none fa fa-minus" />
                                                        <button className="btn neutral pt-none pb-none fa fa-expand" />
                                                    </div>
                                                </li>
                                                <li onClick={this.toggleContrastDetails}>
                                                    <FontAwesomeIcon icon="adjust" className="icon" />
                                                    Contraste
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    {contrastDetails &&
                                        <>
                                            <h2 className="title">
                                                <div className="read hint clean center-left neutral" onClick={this.toggleContrastDetails}>
                                                    <FontAwesomeIcon icon="chevron-left" />
                                                    <div className="hint-content">Voltar</div>
                                                </div>
                                                Contraste
                                            </h2>
                                            <div className="features-group-container">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('normal')}>
                                                            <em className={`switch module-color ${contrastType === 'normal' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Normal</span>
                                                        </div>
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('invert')}>
                                                            <em className={`switch module-color ${contrastType === 'invert' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Escuro</span>
                                                        </div>
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('protanopia')}>
                                                            <em className={`switch module-color ${contrastType === 'protanopia' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Protanopia</span>
                                                        </div>
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('deuteranopia')}>
                                                            <em className={`switch module-color ${contrastType === 'deuteranopia' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Deuteranopia</span>
                                                        </div>
                                                        <div className="switch-container form-group" onClick={() => this.setContrast('tritanopia')}>
                                                            <em className={`switch module-color ${contrastType === 'tritanopia' ? 'active' : ''}`}></em>
                                                            <span className="switch-caption">Tritanopia</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12 form-group">
                                                        <button className="btn text neutral right">
                                                            restaurar definições
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className={`features-modules ${active === 'modules' ? 'active' : ''}`}>
                                    <h2 className="title">
                                        Módulos
                                    {(arrecadacaoDetails || educacionalDetails || geralDetails) &&
                                            <div className="read hint clean center-left neutral" onClick={() => { this.setState({ educacionalDetails: false, arrecadacaoDetails: false, geralDetails: false }) }}>
                                                <FontAwesomeIcon icon="chevron-left" />
                                                <div className="hint-content">Voltar</div>
                                            </div>
                                        }
                                    </h2>
                                    {!arrecadacaoDetails && !educacionalDetails && !geralDetails &&
                                        <ul className="tab">
                                            <li className="active">
                                                <div className="features-body">
                                                    <ul className="module-list no-logo expansible open">
                                                        <li className="features-section" onClick={(e) => { e.target.parentElement.classList.toggle('open') }}>
                                                            <OXYADMINISTRATIVO className="icon" />
                                                        Administrativo
                                                    </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Almoxarifado
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Compras
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#Tributos" target="_blank">
                                                                <span>
                                                                    Frotas
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    Obras
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    Patrimônio
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    Protocolo
                                                            </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="module-list no-logo expansible open">
                                                        <li className="features-section" onClick={(e) => { e.target.parentElement.classList.toggle('open') }}>
                                                            <OXYARRECADACAO className="icon" />
                                                        Arrecadação
                                                    </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Fiscalização
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    ISS
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#Tributos" target="_blank">
                                                                <span>
                                                                    ISS Admin
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    ITBI Cidadão
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    ITBI Servidor
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    Painel Administrativo
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    Tributos
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    Portal Contribuinte
                                                            </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="module-list no-logo expansible open">
                                                        <li className="features-section" onClick={(e) => { e.target.parentElement.classList.toggle('open') }}>
                                                            <OXYCONTABIL className="icon" />
                                                        Contábil
                                                    </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Atos
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Contabilidade
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#Tributos" target="_blank">
                                                                <span>
                                                                    Custos
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    Orçamento
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ISS" target="_blank">
                                                                <span>
                                                                    Prestação de Contas
                                                            </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="module-list no-logo expansible open">
                                                        <li className="features-section" onClick={(e) => { e.target.parentElement.classList.toggle('open') }}>
                                                            <OXYRH className="icon" />
                                                        Recursos Humanos
                                                    </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Folha de Pagamento
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Portal Servidor
                                                            </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="module-list no-logo expansible open">
                                                        <li className="features-section" onClick={(e) => { e.target.parentElement.classList.toggle('open') }}>
                                                            <OXYSOCIAL className="icon" />
                                                        Social
                                                    </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Saúde
                                                            </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="module-list no-logo expansible open">
                                                        <li className="features-section" onClick={(e) => { e.target.parentElement.classList.toggle('open') }}>
                                                            <OXYLOGO className="icon oxy" />
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Painel
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#ITBI" target="_blank">
                                                                <span>
                                                                    Único
                                                            </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    }
                                    {arrecadacaoDetails &&
                                        <>
                                            <ul className="tab">
                                                <li className="active">
                                                    <div className="features-body">
                                                        <ul className="module-list no-logo">
                                                            <li className="features-section">
                                                                <OXYARRECADACAO className="icon" />
                                                        Arrecadação
                                                    </li>
                                                            <li className="sisters">
                                                                <a href="#ITBI" target="_blank">
                                                                    <span>
                                                                        ITBI Cidadão
                                                            </span>
                                                                </a>
                                                            </li>
                                                            <li className="sisters">
                                                                <a href="#ITBI" target="_blank">
                                                                    <span>
                                                                        ITBI Servidor
                                                            </span>
                                                                </a>
                                                            </li>
                                                            <li className="sisters">
                                                                <a href="#Tributos" target="_blank">
                                                                    <span>
                                                                        Tributos
                                                            </span>
                                                                </a>
                                                            </li>
                                                            <li className="sisters">
                                                                <a href="#ISS" target="_blank">
                                                                    <span>
                                                                        ISS
                                                            </span>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </li>
                                            </ul>
                                        </>
                                    }
                                    {educacionalDetails &&
                                        <ul className="tab">
                                            <li className="active">
                                                <div className="features-body">
                                                    <ul className="module-list no-logo">
                                                        <li className="features-section">
                                                            <OXYEDUCACIONAL className="icon" />
                                                        Educacional
                                                    </li>
                                                        <p className="typography-size-12px mr-xs ml-xs mt-xs">
                                                            Esses módulos ainda não estão disponíveis, estamos trabalhando nisso.
                                                    </p>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    }
                                    {geralDetails &&
                                        <ul className="tab">
                                            <li className="active">
                                                <div className="features-body">
                                                    <ul className="module-list no-logo">
                                                        <li className="features-section">Geral</li>
                                                        <li className="sisters">
                                                            <a href="#portal">
                                                                <span>
                                                                    Portal Transparência
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#console">
                                                                <span>
                                                                    Console
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#painel">
                                                                <span>
                                                                    Painel
                                                            </span>
                                                            </a>
                                                        </li>
                                                        <li className="sisters">
                                                            <a href="#unico">
                                                                <span>
                                                                    Único
                                                            </span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    }
                                </div>
                            </div>
                        }
                    </>
                }


                <MenuJuggernaut
                    type={type}
                    open={estado}
                    floatType={float}
                    upAction={this.toggleMenu}
                />

                {fullscreen &&
                    <main-section>
                        <section className={`main-content ${estado === 'sidebar-toggle' ? 'sidebar-toggle' : ''} tasks`}>
                            <header className="main-content-header new">
                                <nav aria-label="Breadcrumbs" className="nav-breadcrumbs">
                                    <ol>
                                        <li>
                                            <a href="/#breadcrumb" aria-current="page">
                                                <i className="fa fa-home" />
                                                <span>Início</span>
                                            </a>
                                        </li>
                                    </ol>
                                </nav>
                            </header>
                            <div className="content-wrapper">
                                {!isTourComplete &&
                                    <ShepherdTour steps={json} tourOptions={tourOptions}>
                                        <MyTour completeTour={this.completeTour} />
                                    </ShepherdTour>
                                }
                                <div className="panel table task-filter">
                                    <div className="panel-body">
                                        <div className="panel-table-filter">
                                            <div className="row">
                                                <div className="col-md-12 form-group">
                                                    <div className="switch-buttons black">
                                                        <button className="btn active">atribuídas a  mim</button>
                                                        <button className="btn ">atribuídas a todos</button>
                                                        <button className="btn ">todas concluídas</button></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-2">
                                                    <div className="form-group"><select aria-label="search input">
                                                        <option>Código</option>
                                                        <option>Requerente</option>
                                                        <option>CPF/CNPJ</option>
                                                    </select></div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="form-group"><select aria-label="search input">
                                                        <option>Contém</option>
                                                        <option>Igual</option>
                                                        <option>Diferente de</option>
                                                    </select></div>
                                                </div>
                                                <div className="col-sm-5">
                                                    <div className="form-group">
                                                        <input type="text" aria-label="campo de pesquisa" name="" placeholder="Digite o filtro..." className="button-input" />
                                                        <button className="btn filter ">Filtrar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className={`task-list ${toggleTaskList ? 'close' : ''} ${taskListLoading ? 'placeholder-loader' : ''}`}>
                                    <div className="task-header" onClick={this.toggleTaskList}>
                                        <em className="fa fa-caret-left task-icon"></em>
                                        Tarefas
                                        <div className={`btn-dropdown right ${openDropDownButton ? 'open' : ''}`}>
                                            <button className="btn neutral circle btn-dropdown-opener" onClick={this.openDropDownButton}>
                                                <FontAwesomeIcon icon="sort-amount-down" className="btn-icon" />
                                            </button>
                                            <ul className="btn-dropdown-list right-all">
                                                <li>Mais recentes</li>
                                                <li>Mais antigas</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="task-list-container">
                                        {this.renderTasks()}

                                        <div className="panel-pagination">
                                            Exibindo 1-10 de 30
                                            <ul className="panel-pagination-inner">
                                                <li><a href="#paginacao">‹</a></li>
                                                <li className="active"><a href="#paginacao">1</a></li>
                                                <li><a href="#paginacao">2</a></li>
                                                <li><a href="#paginacao">3</a></li>
                                                <li><a href="#paginacao">›</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="task-container">
                                    <div className="task-header">
                                        {taskSelect >= 0 && !taskContentLoading &&
                                            'Gerar boletim de alteração   •   FERS-02'
                                        }
                                    </div>
                                    <div className="task-content">
                                        {taskSelect >= 0 && !taskContentLoading &&
                                            <>
                                                <div className="display-data border">
                                                    <div className="row">
                                                        <div className="col-sm-6 col-md-4"><b className="dd-title">Nome</b>
                                                            <div className="dd-content">Roberto Joaquim Alves</div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4 undefined"><b className="dd-title">Profissão</b>
                                                            <div className="dd-content">Ator</div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4"><b className="dd-title">Tipo</b>
                                                            <div className="dd-content">Filme, TV e Teatro</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6 col-md-3"><b className="dd-title">Início de Carreira</b>
                                                            <div className="dd-content">04/11/2016</div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-3"><b className="dd-title">Fim de Carreira</b>
                                                            <div className="dd-content">26/03/2031</div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-3"><b className="dd-title">Materiais Lançados</b>
                                                            <div className="dd-content">5</div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-3"><b className="dd-title">N# de Prêmios</b>
                                                            <div className="dd-content">3</div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-12"><b className="dd-title">Descrição Rápida</b>
                                                            <div className="dd-content">Sed accumsan congue ex, sit amet efficitur erat rhoncus ut. Phasellus sed rhoncus magna.</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="panel mt-xs">
                                                <div className="panel-body">
                                                    <div className="panel-tab">
                                                        <div className="panel-tab-selector ">
                                                            <div className="tab-item-selector active" data-position="0">Tab 1</div>
                                                            <div className="tab-item-selector has-badge " data-position="1">Tab 2</div>
                                                            <div className="tab-item-selector " data-position="2">Tab 3</div>
                                                        </div>
                                                        <div className="panel-tab-content">
                                                            <div className="tab-item-content active table mt-mb-none" data-position="0">
                                                                <div className="panel-table-filter">
                                                                    <div className="row">
                                                                        <div className="col-sm-2">
                                                                            <div className="form-group"><select aria-label="search input">
                                                                                <option>Código</option>
                                                                                <option>Requerente</option>
                                                                                <option>CPF/CNPJ</option>
                                                                            </select></div>
                                                                        </div>
                                                                        <div className="col-sm-2">
                                                                            <div className="form-group"><select aria-label="search input">
                                                                                <option>Contém</option>
                                                                                <option>Igual</option>
                                                                                <option>Diferente de</option>
                                                                            </select></div>
                                                                        </div>
                                                                        <div className="col-sm-5">
                                                                            <div className="form-group"><input type="text" aria-label="search button" name="" placeholder="Digite o filtro..." className="button-input" />

                                                                                <button className="btn filter ">Filtrar</button></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="panel-table-outer">
                                                                    <table className="panel-table">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Coluna 1</th>
                                                                                <th>Coluna 2</th>
                                                                                <th>Coluna 3</th>
                                                                                <th>Coluna 4</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td><b className="td-label">Lorem ipsum</b><span className="td-content ">Lorem ipsum lorem ipsum lorem ipsum lorem</span></td>
                                                                                <td><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                                                                                <td><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                                                                                <td><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                                                                                <td><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                                                                                <td><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                                                                                <td><b className="td-label">Lorem ipsum</b><span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span></td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div className="panel-pagination">
                                                                    <div className="sisters">
                                                                        <div className="number-itens"><b>Mostrar</b><select aria-label="Selector quantidade de páginas">
                                                                            <option>10</option>
                                                                            <option>50</option>
                                                                            <option>100</option>
                                                                            <option>150</option>
                                                                        </select><b>Itens</b></div>
                                                                    </div>
                                                                    <div className="sisters">
                                                                        <ul className="panel-pagination-inner">
                                                                            <li><a href="#abas">«</a></li>
                                                                            <li><a href="#abas">‹</a></li>
                                                                            <li className="active"><a href="#abas">1</a></li>
                                                                            <li><a href="#abas">2</a></li>
                                                                            <li>...</li>
                                                                            <li><a href="#abas">2344</a></li>
                                                                            <li><a href="#abas">2345</a></li>
                                                                            <li><a href="#abas">›</a></li>
                                                                            <li><a href="#abas">»</a></li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="tab-item-content " data-position="1">
                                                                <h1>Tab 2</h1>
                                                                <p>Lorem ipsum dolor sit amet</p>
                                                            </div>
                                                            <div className="tab-item-content " data-position="2">
                                                                <h1>Tab 3</h1>
                                                                <p>Lorem ipsum dolor sit amet</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            </>
                                        }
                                        {taskSelect === undefined && !taskContentLoading &&
                                             <div className="not-found neutral">
                                                {!taskContentLoading && 
                                                    <>
                                                        <FontAwesomeIcon icon="clipboard-check" />
                                                        <h2>Nenhuma tarefa selecionada</h2>
                                                        <small>Selecione uma tarefa ao lado para ver seus detalhes</small>
                                                    </>
                                                }
                                           </div>
                                        }
                                        {taskContentLoading && 
                                            <>
                                                <div className="content-loader loadOxy contain">
                                                    <svg
                                                        version="1.2"
                                                        className="content-align"
                                                        baseProfile="tiny"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xlink="http://www.w3.org/1999/xlink"
                                                    >
                                                        <defs>
                                                        <g className="animateDraw drawText" id="Logo-Text">
                                                            <path
                                                            id="Caminho_129"
                                                            data-name="Caminho 129"
                                                            d="M206.58,240.235a19.448,19.448,0,1,0,19.448,19.448,19.448,19.448,0,0,0-19.448-19.448M208,270.5a10.909,10.909,0,1,1,9.4-9.4,10.914,10.914,0,0,1-9.4,9.4"
                                                            transform="translate(-187.132 -240.234)"
                                                            ></path>
                                                            <path
                                                            id="Caminho_130"
                                                            data-name="Caminho 130"
                                                            d="M352.5,240.234v19.43a10.906,10.906,0,1,1-21.811,0V245.131l-8.54-4.9v19.43A19.448,19.448,0,0,0,352.5,275.77v3.344a10.91,10.91,0,0,1-10.911,10.9H327.216l4.9,8.543h9.474a19.45,19.45,0,0,0,19.451-19.447V245.131Z"
                                                            transform="translate(-231.064 -240.234)"
                                                            ></path>
                                                            <path
                                                            id="Caminho_131"
                                                            data-name="Caminho 131"
                                                            d="M266.245,259.675l-11.96,8.825v10.615l19.154-14.132,19.155,14.132V268.5l-11.961-8.825,11.961-8.825V240.235l-19.155,14.132-19.154-14.132V250.85Z"
                                                            transform="translate(-208.982 -240.234)"
                                                            ></path>
                                                        </g>
                                                        </defs>
                                                        <svg className="logo_loading" viewBox="0 0 100 100" space="preserveAspectRatio">
                                                        <use id="Draw-Text" className="drawText animateDraw" href="#Logo-Text"></use>
                                                        </svg>
                                                    </svg>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>

                            </div>
                        </section>
                    </main-section>
                }
            </>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    listenerKeyDown = () => {
        const { toggleFullScreen, selectTheme } = this.props;

        document.addEventListener('keydown', event => {
            if (event.key === 'Escape') {
                this.setState({ fullscreen: undefined });
                toggleFullScreen(false);
                selectTheme('dark');
            }
        })
    }

    setContrast = (type) => {
        this.setState({ contrastType: type });
        document.querySelector('body').classList = '';
        document.querySelector('body').classList.add(type);
    }

    render() {
        const { theme, fullScreen } = this.props;
        const { toastVisible } = this.state;

        const toastClasses = classnames(
            "chip-container",
            "module-color",
            "toast",
            { "toast-visible": toastVisible }
        )

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.codeToRender}
                    optionalClasses={this.optionalClasses}
                    theme={theme}
                    mainTitle="Layout"
                    mainDescription="O layout fornece identificação da aplicação e acesso aos destinos do aplicativo. "
                    overflowHidden
                    fullScreen={fullScreen}
                >

                {!fullScreen &&
                    <>
                        <HowToUse
                            title="Uso"
                            description="No topo irá acessos rápidos do sistema, como: notificações, dados do usuário, entre outros. <br /> Seu uso deve ser estudado de acordo com suas variações. <br/> O menu fornece acesso aos destinos e à funcionalidades do aplicativo. Eles podem estar permanentemente na tela ou controlados pelo ícone do menu de navegação."
                        />
                        <HowToUse
                            title="Anatomia"
                        >
                            <HowToUseFigure
                                srcImg={anatomia}
                                descriptionImg="1. Container <br /> 2. Botão de interação com menu <br /> 3. Título (opcional) <br /> 4. Links (opcionais)"
                            />
                            <HowToUseFigure
                                srcImg={anatomia2}
                                descriptionImg="1. Container <br /> 2. Header do sistema <br /> 3. Barra de pesquisa <br /> 4. Item Início do sistema <br /> 5. Item do menu"
                            />
                            <HowToUseFigure
                                srcImg={dont1}
                                descriptionImg="Não use muito texto para que o item tenha mais de uma linha"
                                status="dont"
                                col
                            />
                            <HowToUseFigure
                                srcImg={dont2}
                                descriptionImg="Não utilize o mesmo ícone para representar vários Itens com destino diferente"
                                status="dont"
                                col
                            />
                        </HowToUse>
                        <HowToUse
                            title="Variações"
                            subtitle="Layout Padrão"
                            description="Utilizado na maioria dos módulos, cujo o intuito seja ser um cms, em que o usuário passe boa parte do dia trabalhando, mantendo o visual mais minimalista, não chamando a atenção permitindo assim que o usuário foque mais em suas tarefas."
                        >
                            <HowToUseFigure
                                srcImg={vari1}
                            />
                            <HowToUseFigure
                                srcImg={padrao}
                                captionImg="Menu padrão no folha de pagamentos"
                            />
                        </HowToUse>
                        <HowToUse
                            subtitle="Layout Destacado"
                            description="Layout utilizado em portais, cujo o módulo não seja somente um cms, que necessite de mais destaque visual."
                        >
                            <HowToUseFigure
                                srcImg={vari2}
                            />
                            <HowToUseFigure
                                srcImg={destacado}
                                captionImg="Menu destacado no Portal do Servidor"
                            />
                        </HowToUse>
                        <HowToUse
                            title='Arquivos'
                            code
                        >
                            <FilesTemplate files={files} />
                        </HowToUse>

                        <HowToUse
                            title='Implementação'
                            description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                            code
                        />

                        <HowToUse
                            title='Outros Componentes'
                            both
                        >
                            <BoxContainerTemplate seeAll>
                                {this.renderBoxItems()}
                            </BoxContainerTemplate>
                        </HowToUse>
                    </>
                }

                <div className={toastClasses}>
                    <span className="fa fa-lightbulb-o chip-icon-left"></span>
                    <p className="chip-text">Precione a tecla ESC para sair do modo fullscreen</p>
                </div>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme,
        fullScreen: state.fullScreen
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ toggleFullScreen: toggleFullScreen, selectTheme: selectTheme }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderJuggernaut);
