import React, { Component } from 'react';

class Example extends Component {

    render() {

        return (
            <>
                <br /><br /><br /><br /><br /><br /><br /><br />
                <div className="container" style={{ minWidth: "1851px", maxWidth: "100%", position: "relative", zIndex: 4 }}>
                    <div className="panel">
                        <div className="panel-body">

                            <div className="table-report small">
                                <ul className="has-subnivel">
                                    <li className="group-title col-xs-12">
                                        <div className="col-sm-10 col-xs-6"><b>Centro de Custo:</b>  1001001 - DIRETORIA DA ASSESSORIA JURÍDICA À ADMINISTRAÇÃO - DAJAD </div>
                                        <div className="col-md-2 col-xs-4 group-extra text-right">
                                            <b>Total Anual: R$ </b>846.886,00
                                        </div>
                                    </li>
                                    <ul>
                                        <div className="row">
                                            <div className="col-sm-12 hidden-xs">

                                                <div className="group-header">
                                                    <div className="row">
                                                        <div className="col-sm-4">Ano</div>
                                                        <div className="col-sm-4">Janeiro</div>
                                                        <div className="col-sm-4">Fevereiro</div>
                                                        <div className="col-sm-4">Março</div>
                                                        <div className="col-sm-4">Abril</div>
                                                        <div className="col-sm-4">Maio</div>
                                                        <div className="col-sm-4">Junho</div>
                                                        <div className="col-sm-4">Julho</div>
                                                        <div className="col-sm-4">Agosto</div>
                                                        <div className="col-sm-4">Setembro</div>
                                                        <div className="col-sm-4">Outubro</div>
                                                        <div className="col-sm-4">Novembro</div>
                                                        <div className="col-sm-4">Dezembro</div>
                                                        <div className="col-sm-4">Total</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>

                                        <ul className="group-itens">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    
                                                <li className="group-title col-xs-12">
                                                    <div className="col-md-10 col-xs-6">
                                                    <b>Objeto de Custo:</b> 7 - Folha de Pagamento
                                                    </div>
                                                    <div className="col-md-2 col-xs-6 group-extra text-right">
                                                    <b>Total Anual: R$ </b>1.112.014,00
                                                    </div>
                                                </li>

                                                    <li className="group-item nowrap">
                                                        <div className="row">
                                                            <div className="col-sm-4 group-column" data-header="Ano">2021</div>
                                                            <div className="col-sm-4 group-column" data-header="Janeiro">00.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Fevereiro">100.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Março">1.000.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Abril">10.000.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Maio">100.000.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Junho">00.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Julho">10.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Agosto">100.000</div>
                                                            <div className="col-sm-4 group-column" data-header="Setembro">1.000.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Outubro">10.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Novembro">00.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Dezembro">00.00</div>
                                                            <div className="col-sm-4 group-column" data-header="Total">1.000.000.00</div>
                                                        </div>
                                                    </li>

                                                    <li className="total nowrap">
                                                        <div className="row">
                                                            <div className="total-column col-sm-4 text-left" data-header="Jan">
                                                                <b>118.342,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Jan">
                                                                <b>118.342,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Fev">
                                                                <b>79.394,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Mar">
                                                                <b>0,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Abr">
                                                                <b>0,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Mai">
                                                                <b>77.011,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Jun">
                                                                <b>0,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Jul">
                                                                <b>0,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Ago">
                                                                <b>77.029,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Set">
                                                                <b>52.015,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Out">
                                                                <b>291.843,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Nov">
                                                                <b>85.978,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Dez">
                                                                <b>65.274,00</b>
                                                            </div>
                                                            <div className="total-column col-sm-4 text-left" data-header="Jan">
                                                                <b>118.342,00</b>
                                                            </div>
                                                        </div>
                                                    </li>


                                                </div>
                                            </div>
                                        </ul>

                                    </ul>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </>
        )
    }
}

export default Example;