import React, { Component } from 'react';

import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import ComponentTemplate from '../Templates/ComponentTemplate';
import classnames from 'classnames';
import anatomia from '../../assets/img/components/report/anatomia.png';
import hierarquia from '../../assets/img/components/report/hierarquia.png';
import aplicacao from '../../assets/img/components/report/aplicacao.png';
import hover from '../../assets/img/components/report/hover.gif';
import variacoes from '../../assets/img/components/report/variacoes.gif';
import adicionais from '../../assets/img/components/report/adicionais.png';

const files = [{'less': ['table-report.less']}];

const Group = ({ state, children }) => {
    const { subnivel, group } = state;
    return (
        <React.Fragment>
            {group ?
                <ul className={subnivel ? "has-subnivel" : ''}>
                    <li className="group-title col-xs-12">
                        <div className="col-sm-10 col-xs-6">
                            <b>Grupo</b> Nome Grupo
                        </div>
                        <div className="group-extra col-sm-2 col-xs-6">
                            <b>Extra</b> Info
                        </div>
                    </li>
                    {subnivel ?
                        <ul>
                            <li className="group-title col-xs-12">
                                <div className="col-sm-10 col-xs-6">
                                    <b>Grupo</b> Nome Grupo
                                </div>
                                <div className="group-extra col-sm-2 col-xs-6">
                                    <b>Extra</b>Info
                                </div>
                            </li>
                            {children}
                        </ul>
                        : children}
                </ul> :
                children}
        </React.Fragment>
    )
}

const GroupItems = ({ state, children }) => {
    const { scroll, header } = state;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-sm-12 hidden-xs">
                    {header &&
                        <div className="group-header" >
                            <div className="row">
                                <div className="col-sm-3">
                                    Coluna
                                    </div>
                                <div className="col-sm-3">
                                    Coluna
                                    </div>
                                <div className="col-sm-3">
                                    Coluna
                                    </div>
                                <div className="col-sm-3">
                                    Coluna
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <ul className={`group-itens ${scroll ? 'scroll' : ''}`} style={{ maxHeight: scroll ? '200px' : '' }}>
                {children}
            </ul>
        </React.Fragment>
    )
}

const Item = ({ state, trType }) => {
    const { buttons, clickable, nowrap, rightAlign } = state;

    const itemClasses = classnames('group-item', trType, { nowrap, clickable, 'text-right': rightAlign });

    return (
        <React.Fragment>
            <li className={itemClasses}>
                <div className="row">
                    
                        <div className="col-sm-3 group-column" data-header="Data">
                            <div className="group-date">
                                01/01/2019
                            </div>
                        </div>
                        <div className="col-sm-3 group-column" data-header="Coluna">
                            <p>Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum</p>
                        </div>
                        <div className="col-sm-3 group-column" data-header="Coluna">
                            <p>Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum</p>
                        </div>
                        <div className="col-sm-3 group-column" data-header="Coluna">
                            <p>Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum Lorem ipsum lorem ipsum</p>
                        </div>
                        {buttons &&
                            <div className="btn-actions">
                                <div className="btn-actions-inner">
                                    <button className="btn circle" type="button">
                                        <i className="fa fa-filter"></i>
                                        <div className="btn-actions-label">Lorem ipsum lorem</div>
                                    </button>
                                    <button className="btn circle" type="button">
                                        <i className="fa fa-plus"></i>
                                        <div className="btn-actions-label">Lorem ipsum lorem</div>
                                    </button>
                                    <button className="btn circle" type="button">
                                        <i className="fa fa-trash"></i>
                                        <div className="btn-actions-label">Lorem ipsum lorem</div>
                                    </button>
                                </div>
                            </div>
                        }
                </div>
            </li>
        </React.Fragment>
    )
}

const Total = ({ geral = false }) => {
    return (
        <div className={`total nowrap ${geral ? 'geral' : ''}`}>
            <div className="row">
                <div className="total-column col-sm-9" data-header="Total">
                    <b>Total</b>
                </div>
                <div className="total-column col-sm-3" data-header="Total">
                    <b>R$ 30.000,00</b>
                </div>
            </div>

        </div>
    )
}


class TableReport extends Component {

    state = {
        subnivel: false,
        group: false,
        total: false,
        buttons: false,
        clickable: false,
        scroll: false,
        trType: '',
        nowrap: false,
        rightAlign: false,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Relatório');
        this.setState({currentIndex: index});
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderOptionalClasses = () => {
        return (
            <React.Fragment>
                <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="subnivel" value={true} stateName="subnivel" label="Com subnível" />
                    <SwitchOptions attr="group" value={true} stateName="group" label="Com grupo" />
                    <SwitchOptions attr="total" value={true} stateName="total" label="Com total" />
                    <SwitchOptions attr="buttons" value={true} stateName="buttons" label="Com botões" />
                    <SwitchOptions attr="clickable" value={true} stateName="clickable" label="Linha clicável" />
                    <SwitchOptions attr="scroll" value={true} stateName="scroll" label="Com tamanho fixo" />
                    <SwitchOptions attr="header" value={true} stateName="header" label="Com cabeçalho" />
                    <SwitchOptions attr="nowrap" value={true} stateName="nowrap" label="Limitar texto" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Opções de Linha" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="small" value={true} stateName="small" label="Reduzido" />
                    <SwitchOptions attr="trType" value='success' stateName="trType" label="Com destaque (success)" />
                    <SwitchOptions attr="trType" value='highlight' stateName="trType" label="Com destaque" />
                    <SwitchOptions attr="trType" value='error' stateName="trType" label="Com erro" />
                    <SwitchOptions attr="trType" value='warning' stateName="trType" label="Com aviso" />
                    <SwitchOptions attr="rightAlign" value={true} stateName="rightAlign" label="Alinha à direita" />
                </SwitchOptionsGroup>
            </React.Fragment>
        )
    }

    renderComponent = () => {
        const { total, small, group, subnivel, trType } = this.state;
        return (
            <div className={`table-report ${small ? 'small' : ''}`}>
                <Group state={this.state}>
                    <GroupItems state={this.state}>
                        <Item state={this.state} trType={trType} />
                        <Item state={this.state} />
                        <Item state={this.state} />
                        <Item state={this.state} />
                        <Item state={this.state} />
                        {total &&
                            <Total />
                        }
                    </GroupItems>
                    {total && group && subnivel &&
                        <Total geral />
                    }
                </Group>
            </div>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    panel
                    theme={theme}
                    mainTitle="Relatório"
                    mainDescription="Mostre informações relevante de forma organizada"
                >
                <HowToUse
                    title="Uso"
                    description="O relatório é utilizado em dashboards e telas de relatórios comuns para exibir grandes listas de dados com totalizadores mais simplificados e fáceis de imprimir."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Detalhe da linha (Opcional) <br /> 3. Header da tabela (Opcional) <br/> 4. Ações da linha (Opcional) <br /> 5. Título do grupo (Opcional) 6. Linha <br /> 7. Totalizador (Opcional)"
                    />
                </HowToUse>
                <HowToUse
                    title="Hierarquia"
                    description="Relatórios podem aninhar outros relatórios, criando um sub-nível. O pai fica com o total da soma de seus filhos."
                >
                    <HowToUseFigure
                        srcImg={hierarquia}
                        captionImg="Relatório com Sub-nível"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    description="Relatórios podem ser utilizados em Dashboards e telas para impressão de documentos."
                >
                    <HowToUseFigure
                        srcImg={aplicacao}
                        captionImg="Relatório em um Dashboard"
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    subtitle="Hover"
                    description="Relatórios com botões tem uma animação de hover."
                >
                    <HowToUseFigure
                        srcImg={hover}
                        captionImg="Hover em um relatório"
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Gerais"
                    description="As variações gerais do relatório incluem: <br /> • Com tamanho fixo <br /> • Limitar texto <br /> • Reduzido"
                >
                    <HowToUseFigure
                        srcImg={variacoes}
                        captionImg="Variações Gerais"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Componentes Adicionais"
                    description="As variações de componentes extras do relatório incluem: <br /> • Com subnível <br /> • Com Grupo <br /> • Com Total <br /> • Com botões <br /> • Com cabeçalho"
                >
                    <HowToUseFigure
                        srcImg={adicionais}
                        captionImg="Relatório com Sub-nível"
                    />
                </HowToUse>
                <HowToUse
                    title="Estilo"
                    description="As variações de estilo do relatório incluem: <br /> • Linha clicável <br /> • Com destaque <br /> • Alinhado à direita"
                />
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(TableReport);
