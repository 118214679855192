import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import ComponentTemplate from '../Templates/ComponentTemplate';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import classname from 'classnames';

import uso from '../../assets/img/components/info/uso.png';
import anatomia from '../../assets/img/components/info/anatomia.png';
import hierarquia from '../../assets/img/components/info/hierarquia.png';
import dont1 from '../../assets/img/components/info/dont1.png';
import dont2 from '../../assets/img/components/info/dont2.png';
import do1 from '../../assets/img/components/info/do1.png';
import caution from '../../assets/img/components/info/caution.png';
import caution2 from '../../assets/img/components/info/caution2.png';
import aplicacao from '../../assets/img/components/info/aplicacao.gif';

const files = [{ 'less': ['main.less'] }]

class Info extends Component {

    state = {
        type: 'primary',
        icon: false,
        button: false,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Informativos');
        this.setState({ currentIndex: index });
    }

    renderComponent = () => {
        const { type, icon, button } = this.state;

        const iconClasse = classname('fa ', {
            'fa-info-circle': type === 'primary',
            'fa-check-circle': type === 'positive',
            'fa-times-circle': type === 'negative',
            'fa-exclamation-triangle': type === 'warning'
        });

        const infoClasses = classname('info', type, { icon })

        const buttonClasses = classname('btn right ', {
            'module-color': type === 'primary',
            [`${type}`]: type !== 'primary'
        })

        return (
            <div className={infoClasses}>
                {icon &&
                    <>
                        <div className="icon">
                            <em className={iconClasse}></em>
                        </div>
                        <article>
                            <h4>Lorem ipsum dolor sit amet, consetetur.</h4>
                            <p>Sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut</p>
                        </article>
                        {button &&
                            <button className={buttonClasses}>Lorem</button>
                        }
                        <button className="button-close">
                            <em className="fa fa-times"></em>
                        </button>
                    </>
                }
                {!icon &&
                    <>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Beatae ipsam iure maxime atque. Porro, corrupti. Quis molestiae.
                    </>
                }
            </div>
        )
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderClasses = () => {
        return (
            <>
                <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange} >
                    <SwitchOptions attr="icon" value={true} stateName="icon" label="Com Ícone" />
                    <SwitchOptions attr="button" value={true} stateName="button" label="Com Botão" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Cores" state={this.state} onChange={this.onChange} >
                    <SwitchOptions attr="type" value="primary" stateName="type" label="Informativo" />
                    <SwitchOptions attr="type" value="positive" stateName="type" label="Sucesso" />
                    <SwitchOptions attr="type" value="negative" stateName="type" label="Erro" />
                    <SwitchOptions attr="type" value="warning" stateName="type" label="Alerta" />
                </SwitchOptionsGroup>
            </>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderClasses}
                    theme={theme}
                    mainTitle="Informativos"
                    mainDescription="Um informativo exibe uma mensagem de destaque e ações opcionais relacionadas."
                >
                <HowToUse
                    title="Uso"
                    description="Um informativo exibe uma mensagem importante e sucinta e fornece ações para os usuários endereçarem (ou dispensarem). Requer que uma ação do usuário para ser descartado. <br /> Os banners devem ser exibidos na parte superior da tela, abaixo da barra superior. Eles são persistentes e não modais, permitindo que o usuário os ignore ou interaja com eles a qualquer momento."
                >

                    <img src={uso} alt="hierarquia" className="mt-xs" />
                </HowToUse>
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Ícone de suporte <br /> 3. Título do informativo <br /> 4. Botão de ação <br /> 5. Fechar informativo <br /> 6. Descrição do informativo"
                    />
                </HowToUse>
                <HowToUse
                    title="Hierarquia"
                    description="Um contêiner de informativo é retangular, estendendo toda a largura de um layout. O contêiner deve ser colocado em um local consistente e de destaque em um aplicativo, compartilhando a mesma elevação que o conteúdo da tela. <br /> A mensagem do informativo comunica uma alteração ou erro dentro de um aplicativo. <br /> Os informativos devem ser considerados como parte de sua estratégia geral de mensagens no aplicativo."
                >
                    <HowToUseFigure
                        srcImg={hierarquia}
                        captionImg="Um informativo aparece acima do conteúdo e abaixo da barra de aplicativos principal."
                    />
                    <HowToUseFigure
                        srcImg={dont1}
                        descriptionImg="Somente um informativo deve ser exibido por vez."
                        status="dont"
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={dont2}
                        descriptionImg="Não combine botões com diferentes níveis de ênfase, o que pode parecer promover uma ação sobre outra."
                        status="dont"
                        col
                    />
                    <HowToUseFigure
                        srcImg={do1}
                        descriptionImg="Os informativos podem ter um ou dois botões de texto de baixa ênfase."
                        status="do"
                        col
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={caution}
                        status="caution"
                        descriptionImg="Os informativos devem ser minimamente interruptivos. Se um botão em um informativo requer ênfase extra, um botão contido pode ser usado para uma única ação proeminente."
                        col
                    />
                    <HowToUseFigure
                        srcImg={caution2}
                        status="caution"
                        descriptionImg="Não empilhe botões, a menos que não haja espaço suficiente para exibi-los lado a lado."
                        col
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    description="Os informativos devem ser colocadas na parte superior de um layout ou tela. Ao rolar, os banners normalmente se movem com o conteúdo e rolam para fora da tela. Somente um banner deve ser exibido por vez."
                >
                    <HowToUseFigure
                        srcImg={aplicacao}
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Info);
