import React, { Component } from 'react';

import ComponentTemplate from '../Templates/ComponentTemplate';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';

import uso from '../../assets/img/components/info/uso.png';
import anatomia from '../../assets/img/components/notification/anatomia.png';
import dont1 from '../../assets/img/components/notification/dont1.png';
import do1 from '../../assets/img/components/notification/do1.png';
import aplicacao from '../../assets/img/components/notification/aplicacao.png';
import gif from '../../assets/img/components/notification/gif.gif';
import info from '../../assets/img/components/notification/info.png';
import suce from '../../assets/img/components/notification/suce.png';
import erro from '../../assets/img/components/notification/erro.png';
import neut from '../../assets/img/components/notification/neut.png';
import aler from '../../assets/img/components/notification/aler.png';

const files = [
    { 'less': ['notification-box.less'] },
    { 'react': ['NotificationContainer.tsx'] }
];

class Notification extends Component {

    state = {
        open: false,
        color: 'neutral',
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Notificação');
        this.setState({ currentIndex: index });
    }

    toggleNotification = () => {
        this.setState(state => {
            return {
                open: !state.open
            }
        })
    }

    hideNotification = () => {
        this.setState({ open: false })
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange} >
                <SwitchOptions attr="color" value="module-color" stateName="color" label="Informativo" />
                <SwitchOptions attr="color" value="positive" stateName="color" label="Sucesso" />
                <SwitchOptions attr="color" value="negative" stateName="color" label="Erro" />
                <SwitchOptions attr="color" value="neutral" stateName="color" label="Neutro" />
            </SwitchOptionsGroup>
        )
    }

    renderComponent = () => {
        const { open, color } = this.state;

        return (
            <React.Fragment>
                <button className={`btn ${color}`} onClick={this.toggleNotification}>Abre a Notificação</button>
                <div className="notification-box">
                    <div className={`notification-box-iten ${color} ${open ? 'open' : ''}`}>
                        <span className="close" onClick={this.hideNotification}></span>
                        <h1 className="notification-box-title">Título aqui</h1>
                        <p className="notification-box-text">Texto aqui. Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
                    </div>
                </div>
            </React.Fragment>
        )
    }
    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;
        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Notificação"
                    mainDescription="A notificação é usada para ressaltar uma mensagem, podendo ter ou não opções de ação."
                >
                <HowToUse
                    title="Uso"
                    description="A notificação exibe uma importante e sucinta mensagem, e pode prover ações para o usuário. <br /> A notificação não bloqueia o uso da página, ele pode simplesmente ignorar a mensagem, provendo assim um feedback ao usuário."
                >
                    <img src={uso} alt="hierarquia" className="mt-xs" />
                </HowToUse>
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Título (opcional) <br /> 3. Mensagem <br /> 4. Botão de ação (opcional) <br /> 5. Botão para fechar"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Botão de Ação"
                    description="Botão na notificação é opcional, deve ser mostrado caso  haja alguma ação possível de ser tomada porém não obrigatória. <br /> A ação do botão deve estar clara através do texto, e deve-se utilizar botões com pouca ênfase, botões somente texto, sem ícone e fundo."
                >
                    <HowToUseFigure
                        srcImg={dont1}
                        col
                        status="dont"
                        descriptionImg="Não utilizar botão com fundo alta ênfase."
                    />

                    <HowToUseFigure
                        srcImg={do1}
                        col
                        status="do"
                        descriptionImg="Usar botão somente texto facilitando a clareza e com baixa ênfase."
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    description="Notificações deverão aparecer ao lado superior direito da tela, abaixo do header."
                >
                    <HowToUseFigure
                        srcImg={aplicacao}
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    description="Por padrão esse tipo de notificação aparece e some automaticamente, ou após o usuário clicar no x. Assim fornece  um feedback ao usuário, porém não interrompe o usuário requerendo uma ação obrigatória."
                >
                    <HowToUseFigure
                        srcImg={gif}
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Informativo"
                >
                    <HowToUseFigure
                        srcImg={info}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Sucesso"
                >
                    <HowToUseFigure
                        srcImg={suce}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Erro"
                >
                    <HowToUseFigure
                        srcImg={erro}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Neutro"
                >
                    <HowToUseFigure
                        srcImg={neut}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Alerta"
                >
                    <HowToUseFigure
                        srcImg={aler}
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Notification);
