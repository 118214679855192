import React, { Component } from 'react';
import { connect } from 'react-redux';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import classnames from 'classnames';

import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';

import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';

import radio from '../../assets/img/components/control-selector/radio.png';
import checkbox from '../../assets/img/components/control-selector/checkbox.png';
import switches from '../../assets/img/components/control-selector/switch.png';
import switchButton from '../../assets/img/components/control-selector/switch-buttons.png';
import HowToUse from '../Templates/HowToUse';

const files = [
    { 'less': ['switch.less', 'radio.less', 'checkbox.less'] },
    { 'angular': ['switch-button.module.ts', 'switch.module.ts', 'elo-radiobutton.module.ts', 'elo-checkbox.module.ts'] },
    { 'react': ['SwitchButtons.tsx', 'Switch.tsx', 'RadioButton.jsx', 'Checkbox.jsx'] }
];


class ControlSelectors extends Component {

    state = {
        type: 'radio',
        btnActived: 1,
        active: false,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Controles de Seleção');
        this.setState({ currentIndex: index });
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderOptionalClasses = () => {
        return (
            <React.Fragment>
                <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="type" value="radio" stateName="type" label="Radio Button" />
                    <SwitchOptions attr="type" value="checkbox" stateName="type" label="Checkbox" />
                    <SwitchOptions attr="type" value="switch" stateName="type" label="Switch" />
                    <SwitchOptions attr="type" value="switch-button" stateName="type" label="Switch Buttons" />
                </SwitchOptionsGroup>
            </React.Fragment>
        )
    }

    renderComponent = () => {
        const { type, btnActived, active } = this.state;

        const classes = classnames('switch', 'module-color',
            {
                'active': type === 'switch' && active,
            }
        )
        return (
            <>
                {type === 'radio' &&
                    <div className="col-md-6">
                        <div className="form-group">
                            <input type="radio" id="input-radio" className="input-radio" />
                            <label htmlFor="input-radio">Radio</label>
                        </div>
                    </div>
                }
                {type === 'checkbox' &&
                    <div className="col-md-6 ">
                        <div className="form-group">
                            <input type="checkbox" id="input-checkbox" className="input-checkbox" />
                            <label htmlFor="input-checkbox">Checkbox</label>
                        </div>
                    </div>
                }
                {type === 'switch' &&
                    <div className="switch-container">
                        <em className={classes} onClick={this.toggleActive} />
                        <span className="switch-caption">
                            Lorem ipsum lorem
                        </span>
                    </div>
                }
                {type === 'switch-button' &&
                    <div className="switch-buttons module-color" onClick={this.toggleActive}>
                        <button className={`btn ${btnActived === 1 ? 'active' : ''}`} onClick={() => this.clickButton(1)}>opção 1</button>
                        <button className={`btn ${btnActived === 2 ? 'active' : ''}`} onClick={() => this.clickButton(2)}>opção 2</button>
                        <button className={`btn ${btnActived === 3 ? 'active' : ''}`} onClick={() => this.clickButton(3)}>opção 3</button>
                    </div>
                }
            </>
        )
    }

    toggleActive = () => {
        this.setState(state => {
            return {
                active: !state.active
            }
        })
    }

    clickButton = (id) => {
        this.setState({ btnActived: id });
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    mainTitle="Controles de Seleção"
                    mainDescription="Controles de seleção permitem o usuário selecionar opções."
                    theme={theme}
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    panel
                >
                <HowToUse
                    title="Uso"
                    description="Seletores permitem que o usuário complete uma tarefa que envolva, selecionar uma ou mais opções."
                />
                <HowToUse
                    subtitle="Radio Buttons"
                    description="Permite a seleção de uma única opção dentre várias."
                >
                    <HowToUseFigure
                        srcImg={radio}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Checkbox"
                    description="Permite a seleção de uma ou mais opções."
                >
                    <HowToUseFigure
                        srcImg={checkbox}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Switches"
                    description="Permite a ativação/desativação imediata, causando algum efeito instantaneamente na aplicação."
                >
                    <HowToUseFigure
                        srcImg={switches}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Switch Buttons"
                    description="Permite a ativação/desativação imediata de uma opção dentre múltiplas, causando algum efeito instantaneamente na aplicação."
                >
                    <HowToUseFigure
                        srcImg={switchButton}
                    />
                </HowToUse>

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code

                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(ControlSelectors);
