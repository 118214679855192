import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import classnames from 'classnames';

import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from './../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import anatomia from '../../assets/img/components/timeline/anatomia1.png';
import anatomia2 from '../../assets/img/components/timeline/anatomia2.png';
import anatomia3 from '../../assets/img/components/timeline/anatomia3.png';

const files = [{ 'less': ['timeline.less'] }, { 'js': ['timeline.js'] }, { 'js': ['timeline-chart.js'] }];

class Timeline extends Component {
    scrollContent = React.createRef();

    state = {
        type: 'vertical',
        scroll: false,
        currentIndex: undefined,
        flex: false,
        lastColumnFixed: false,
        subtitles: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Timeline');
        this.setState({ currentIndex: index });
    }

    componentDidUpdate() {
        document.querySelectorAll('.timeline-chart-second-column .timeline-chart-item').forEach(item => {
            let dataInitial = item.getAttribute('data-initial');
            let dataPercent = item.getAttribute('data-percent');
            console.log(item, dataInitial, dataPercent);
            item.style.marginLeft = dataInitial + '%';
            item.style.width = dataPercent + '%';
        });
    }

    onChange = newState => {
        console.log('newState => ', newState);
        this.setState(newState, () => {
            if(newState.scroll) {
                this.onDragScroll();
            }
        });
    }

    onDragScroll = () => {
        let slider = this.scrollContent.current;
        let isDown = false;
        let startX;
        let scrollLeft;
        
        if(slider && window.screen.width > 769) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('onDrag');
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('onDrag');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('onDrag');
            });
            slider.addEventListener('mousemove', (e) => {
                if(!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = x - startX;
                slider.scrollLeft = scrollLeft - walk;
                console.log(walk);
            });
        }
    }

    renderComponent = () => {
        const { type, scroll, flex, lastColumnFixed, subtitles } = this.state;

        const timelineChart = classnames(
            'timeline-chart',
            { 'last-column-fixed': lastColumnFixed },
            { scroll }
        )

        const verticalTimeline = classnames(
            'timeline-container',
            { flex }
        )

        const chartContainerClasses = classnames(
            'timeline-chart-container',
            { scroll }
        )

        return (
            <React.Fragment>
                {type === 'vertical' &&
                    <div className={verticalTimeline}>
                        <ul className="timeline type2">
                            <li>
                                <div className="datetime">
                                    <b>10/01/19</b>
                                    <small>14:00hs</small>
                                </div>
                                <div className="info">
                                    <div className="head">
                                        <div className="tag">lorem ipsum lorem lorem</div>
                                        <div className="title">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non commodi
                                        iusto modi, eius molestiae officiis iure voluptatum provident qui neque quis
                                        suscipit
											unde. Temporibus quod dolore eveniet ad, fugit minus!</div>
                                    </div>
                                    <div className="description">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci cupiditate tempore
                                        consectetur sed tempora asperiores illum aliquid culpa. Sint delectus nulla reiciendis
                                        architecto corrupti dolore veritatis, non ex aspernatur tempora.
									</div>
                                    <div className="link">
                                        <i className="fa fa-chevron-right hint clean module-color top-right">
                                            <div className="hint-content">Lorem ipsum</div>
                                        </i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="datetime">
                                    <b>10/01/19</b>
                                    <small>14:00hs</small>
                                </div>
                                <div className="info">
                                    <div className="head">
                                        <div className="tag">lorem ipsum lorem lorem</div>
                                        <div className="title">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non commodi
                                        iusto modi, eius molestiae officiis iure voluptatum provident qui neque quis
                                        suscipit
											unde. Temporibus quod dolore eveniet ad, fugit minus!</div>
                                    </div>
                                    <div className="description">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci cupiditate tempore
                                        consectetur sed tempora asperiores illum aliquid culpa. Sint delectus nulla reiciendis
                                        architecto corrupti dolore veritatis, non ex aspernatur tempora.
									</div>
                                    <div className="link">
                                        <i className="fa fa-chevron-right hint clean module-color top-right">
                                            <div className="hint-content">Lorem ipsum</div>
                                        </i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="datetime">
                                    <b>10/01/19</b>
                                    <small>14:00hs</small>
                                </div>
                                <div className="info">
                                    <div className="head">
                                        <div className="tag">lorem ipsum lorem lorem</div>
                                        <div className="title">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non commodi
                                        iusto modi, eius molestiae officiis iure voluptatum provident qui neque quis
                                        suscipit
											unde. Temporibus quod dolore eveniet ad, fugit minus!</div>
                                    </div>
                                    <div className="description">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci cupiditate tempore
                                        consectetur sed tempora asperiores illum aliquid culpa. Sint delectus nulla reiciendis
                                        architecto corrupti dolore veritatis, non ex aspernatur tempora.
									</div>
                                    <div className="link">
                                        <i className="fa fa-chevron-right hint clean module-color top-right">
                                            <div className="hint-content">Lorem ipsum</div>
                                        </i>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div className="datetime">
                                    <b>10/01/19</b>
                                    <small>14:00hs</small>
                                </div>
                                <div className="info">
                                    <div className="head">
                                        <div className="tag">lorem ipsum lorem lorem</div>
                                        <div className="title">Lorem ipsum dolor sit amet consectetur adipisicing elit. Non commodi
                                        iusto modi, eius molestiae officiis iure voluptatum provident qui neque quis
                                        suscipit
											unde. Temporibus quod dolore eveniet ad, fugit minus!</div>
                                    </div>
                                    <div className="description">
                                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci cupiditate tempore
                                        consectetur sed tempora asperiores illum aliquid culpa. Sint delectus nulla reiciendis
                                        architecto corrupti dolore veritatis, non ex aspernatur tempora.
									</div>
                                    <div className="link">
                                        <i className="fa fa-chevron-right hint clean module-color top-right">
                                            <div className="hint-content">Lorem ipsum</div>
                                        </i>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="button-loader-top">
                            <div className="hint fa fa-plus-circle clean top-left module-color">
                                <div className="hint-content">
                                    Carregar mais dados
								</div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'horizontal' &&
                    <div className="timeline-horizontal-container">
                        <ul>
                            <li>
                                <div className="values">
                                    <div className="value positive">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                    <div className="value negative">
                                        <span className="prefix">R$</span>
                                        <span className="number">100,00</span>
                                    </div>
                                    <div className="value module-color">
                                        <span className="prefix">R$</span>
                                        <span className="number">900,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Janeiro
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Fevereiro
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Março
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Abril
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Maio
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Junho
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Julho
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Agosto
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Setembro
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Outubro
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Novembro
                                </div>
                            </li>
                            <li>
                                <div className="values">
                                    <div className="value">
                                        <span className="prefix">R$</span>
                                        <span className="number">1.000,00</span>
                                    </div>
                                </div>
                                <div className="caption">
                                    Dezembro
                                </div>
                            </li>
                        </ul>
                        <div className="subtitles">
                            <div className="subtitle">
                                Médias
                            </div>
                            <div className="subtitle positive">
                                Proventos
                            </div>
                            <div className="subtitle negative">
                                Descontos
                            </div>
                            <div className="subtitle module-color">
                                Totais
                            </div>
                        </div>
                    </div>
                }
                {type === 'chart' &&
                    <div className={timelineChart}>
                        <div className="timeline-chart-first-column">
                            <div className={`timeline-chart-item ${lastColumnFixed ? 'double-height' : ''}`}>
                                {!lastColumnFixed &&
                                    <select>
                                        <option value="2019">2019</option>
                                        <option value="2020">2020</option>
                                        <option value="2021">2021</option>
                                        <option value="2022">2022</option>
                                    </select>
                                }
                                {lastColumnFixed &&
                                    <span className="typography-medium">
                                        Servidor
                                    </span>
                                }
                            </div>
                            <div className="timeline-chart-item">
                                Lorem ipsum
                            </div>
                            <div className="timeline-chart-item">
                                Lorem ipsum
                            </div>
                            <div className="timeline-chart-item">
                                Lorem ipsum
                            </div>
                            <div className="timeline-chart-item">
                                Lorem ipsum
                            </div>
                            {lastColumnFixed &&
                                <div className="timeline-chart-item footer-chart typography-medium">
                                    Totais
                                </div>
                            }
                        </div>

                        <div className="timeline-chart-second-column" ref={this.scrollContent}>
                            {!lastColumnFixed &&
                                <div className={chartContainerClasses}>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-header-column">
                                            <span>Jan</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Fev</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Mar</span>
                                        </div>
                                        <div className="timeline-chart-header-column current-month">
                                            <span>Abr</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Mai</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Jun</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Jul</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Ago</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Set</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Out</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Nov</span>
                                        </div>
                                        <div className="timeline-chart-header-column">
                                            <span>Dez</span>
                                        </div>
                                        {scroll &&
                                            <>
                                                <div className="timeline-chart-header-column">
                                                    <span>Jan</span>
                                                </div>
                                                <div className="timeline-chart-header-column">
                                                    <span>Fev</span>
                                                </div>
                                                <div className="timeline-chart-header-column">
                                                    <span>Mar</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-item hint clean top-left module-color" data-initial="10" data-percent="90">
                                            <div className="description">
                                                <span>Lorem ipsum</span>
                                            </div>
                                            <div className="hint-content">Lorem ipsum lorem</div>
                                        </div>
                                        <div className="timeline-chart-item hint clean top-left module-color" data-initial="5" data-percent="1">
                                            <div className="description">
                                                <span>Lorem ipsum</span>
                                            </div>
                                            <div className="hint-content">Lorem ipsum lorem</div>
                                        </div>
                                        <div className="timeline-chart-item current-month" data-initial={`${scroll ? '20' : '25'}`} data-percent={`${scroll ? '6.63' : '8.33'}`} />
                                    </div>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-item hint clean top-left positive" data-initial="5" data-percent="20">
                                            <div className="description">
                                                <span>Lorem ipsum</span>
                                            </div>
                                            <div className="hint-content">Lorem ipsum lorem</div>
                                        </div>
                                        <div className="timeline-chart-item current-month" data-initial={`${scroll ? '20' : '25'}`} data-percent={`${scroll ? '6.63' : '8.33'}`} />
                                    </div>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-item hint clean top-left neutral" data-initial="40" data-percent="20">
                                            <div className="description">
                                                <span>Lorem ipsum</span>
                                            </div>
                                            <div className="hint-content">Lorem ipsum lorem</div>
                                        </div>
                                        <div className="timeline-chart-item current-month" data-initial={`${scroll ? '20' : '25'}`} data-percent={`${scroll ? '6.63' : '8.33'}`} />
                                    </div>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-item hint clean top-left negative" data-initial="10" data-percent="35">
                                            <div className="description">
                                                <span>Lorem ipsum</span>
                                            </div>
                                            <div className="hint-content">Lorem ipsum lorem</div>
                                        </div>
                                        <div className="timeline-chart-item hint clean top-left negative striped" data-initial="35" data-percent="10">
                                            <div className="description">
                                                <span>Lorem ipsum</span>
                                            </div>
                                            <div className="hint-content">Lorem ipsum lorem</div>
                                        </div>
                                        <div className="timeline-chart-item current-month" data-initial={`${scroll ? '20' : '25'}`} data-percent={`${scroll ? '6.63' : '8.33'}`} />
                                    </div>
                                </div>
                            }
                            {lastColumnFixed &&
                                <div className={chartContainerClasses}>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Jan - 2020</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Fev - 2020</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Mar - 2020</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Abr - 2020</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Mai - 2020</span>
                                        </div>
                                    </div>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Referência</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Valor</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Referência</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Valor</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Referência</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Valor</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Referência</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Valor</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Referência</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span className="typography-medium">Valor</span>
                                        </div>
                                    </div>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                    </div>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                    </div>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                    </div>
                                    <div className="timeline-chart-row">
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                    </div>
                                    <div className="timeline-chart-row footer-chart">
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>62%</span>
                                        </div>
                                        <div className="timeline-chart-column">
                                            <span>R$ 6786,12</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {lastColumnFixed && 
                            <div className="timeline-chart-last-column">
                                <div className="timeline-chart-row">
                                    <div className="timeline-chart-column">
                                        <span className="typography-medium">Totais</span>
                                    </div>
                                </div>
                                <div className="timeline-chart-row">
                                    <div className="timeline-chart-column">
                                        <span className="typography-medium">Referência</span>
                                    </div>
                                    <div className="timeline-chart-column">
                                        <span className="typography-medium">Valor</span>
                                    </div>
                                </div>
                                <div className="timeline-chart-row">
                                    <div className="timeline-chart-column">
                                        <span>62%</span>
                                    </div>
                                    <div className="timeline-chart-column">
                                        <span>R$ 6786,12</span>
                                    </div>
                                </div>
                                <div className="timeline-chart-row">
                                    <div className="timeline-chart-column">
                                        <span>62%</span>
                                    </div>
                                    <div className="timeline-chart-column">
                                        <span>R$ 6786,12</span>
                                    </div>
                                </div>
                                <div className="timeline-chart-row">
                                    <div className="timeline-chart-column">
                                        <span>62%</span>
                                    </div>
                                    <div className="timeline-chart-column">
                                        <span>R$ 6786,12</span>
                                    </div>
                                </div>
                                <div className="timeline-chart-row">
                                    <div className="timeline-chart-column">
                                        <span>62%</span>
                                    </div>
                                    <div className="timeline-chart-column">
                                        <span>R$ 6786,12</span>
                                    </div>
                                </div>
                                <div className="timeline-chart-row footer-chart">
                                    <div className="timeline-chart-column">
                                        <span>62%</span>
                                    </div>
                                    <div className="timeline-chart-column">
                                        <span>R$ 6786,12</span>
                                    </div>
                                </div>


                            </div>
                        }
                        {subtitles && 
                            <div className="subtitles">
                                <div className="subtitle">Médias</div>
                                <div className="subtitle positive">Proventos</div>
                                <div className="subtitle negative">Descontos</div>
                                <div className="subtitle module-color">Totais</div>
                            </div>
                        }
                    </div>
                }
            </React.Fragment>
        )
    }

    optionalClasses = () => {
        const { type } = this.state;
        return (
            <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="type" value="vertical" stateName="type" label="Vertical" />
                <SwitchOptions attr="type" value="horizontal" stateName="type" label="Horizontal" />
                <SwitchOptions attr="subtitles" value={true} stateName="subtitles" label="Com legenda" />
                <SwitchOptions attr="type" value="chart" stateName="type" label="Chart" />
                {type === 'chart' &&
                    <SwitchOptions attr="scroll" value={true} stateName="scroll" label="Com Scroll" />
                }
                {type === 'chart' &&
                    <SwitchOptions attr="lastColumn" value={true} stateName="lastColumnFixed" label="Última coluna fixa" />
                }
                {type === 'vertical' &&
                    <SwitchOptions attr="flex" value={true} stateName="flex" label="Flex" />
                }
            </SwitchOptionsGroup>
        );
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;
        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.optionalClasses}
                    theme={theme}
                    mainTitle="Timeline"
                    mainDescription="Responsável por exibir informações ordenadas cronologicamente."
                >
                <HowToUse
                    title="Uso"
                    description="Utilizado em casos ao qual a ordem cronológica deve ser evidenciada."
                />
                <HowToUse
                    title="Anatomia"
                    subtitle="Vertical"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Item <br /> 3. Identificação data/hora <br /> 4. Badge de identificação (opcional) <br /> 5. Título <br /> 6. Descrição"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Horizontal"
                >
                    <HowToUseFigure
                        srcImg={anatomia2}
                        descriptionImg="1. Container <br /> 2. Item <br /> 3. Identificação mês/data/hora <br /> 4. Descrição <br /> 5. Legendas (opcional)"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Chart"
                >
                    <HowToUseFigure
                        srcImg={anatomia3}
                        descriptionImg="1. Container <br /> 2. Item <br /> 3. Coluna destacada <br /> 4. Header tabela (opcional) <br /> 5. Filtro (opcional) <br /> 6. Legendas (opcional)"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        );
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Timeline);
