import React from 'react';
import FileSaver from 'file-saver';

const onDownload = (file) => {
  const urlFile = file.url ? file.url : URL.createObjectURL(file);
  FileSaver.saveAs(urlFile, file.name);
};

const formattedName = (file) => {
  let formattedName = file.name;

  if (formattedName.length > 47) {
    const fileName = file.name.split('.');
    const extension = fileName[fileName.length - 1];
    const name = formattedName.substr(1, 38 - extension.length) + '...';
    formattedName = name + extension;
  }

  return <div className="name">{formattedName}</div>;
};

const renderPreviewButton = (file) => {
  const urlFile = file.url ? file.url : URL.createObjectURL(file);

  return (
    <a href={urlFile} target="_blank" rel="noopener noreferrer">
      <button type="button" className="btn circle">
        <i className="fa fa-eye" />
        <div className="btn-actions-label">Visualizar</div>
      </button>
    </a>
  );
};

const DragDropItems = ({
  file,
  canDelete,
  sizeDivFile,
  onRemoveFile
}) => {
  return (
    <li key={file.name} className={`col-md-${sizeDivFile}`}>
      <div className="dragdrop-item">
        {formattedName(file)}
        <div className="buttons">
          {renderPreviewButton(file)}
          <button
            type="button"
            className="btn circle"
            onClick={() => onDownload(file)}
          >
            <i className="fa fa-download" />
            <div className="btn-actions-label">Download</div>
          </button>
          {canDelete && (
            <button
              type="button"
              className="btn circle"
              onClick={onRemoveFile}
              id="btn-remove"
            >
              <i className="fa fa-trash" />
              <div className="btn-actions-label">Excluir</div>
            </button>
          )}
        </div>
      </div>
    </li>
  );
};

export default DragDropItems;
