import React, { Component } from 'react';

class AsideDesign extends Component {
    state = {
        titles: [],
        activeKey: 0
    }

    componentDidMount = () => {
        let titles = document.querySelectorAll('.section-title');
        this.setState({titles: titles});

        window.addEventListener("scroll", () => {   
            titles.forEach((title, key) => {
                if(window.pageYOffset >= (title.offsetTop - 200) ) {

                    this.setState({activeKey: key});
                }
            })
        });
    }

    goTo = (key, title) => {
        this.setState({activeKey: key});
        let hash = '#' + title;
        window.location.hash = hash;
    }

    renderLinks = () => {
        const { titles, activeKey } = this.state;
        let links = [];

        titles.forEach((title, key) => {
            links.push(<li key={key}><span href="#" onClick={() => this.goTo(key, title.innerText.toLowerCase())} className={`${activeKey === key ? 'on' : ''}`}>{title.innerText}</span></li>);
        })

        links.splice(-1);

        return links;
    }

    render() {

        return (
            <aside>
                <ul>
                    {this.renderLinks()}
                </ul>
            </aside>
        )
    }
}

export default AsideDesign;