import React from 'react';
import { NavLink } from 'react-router-dom';

const BoxContainerTemplate = ({ children, seeAll }) => {
    return (
        <>
            <ol className="boxes mt-xs">
                {React.Children.map(children, child => {
                    if (!child) {
                        return child;
                    }
                    return React.cloneElement(child);
                })}
            </ol>
            {seeAll &&
                <NavLink to='/componentes' className="btn text mb-xs"> Ver Todos </NavLink>
            }
        </>
    )
}

export default BoxContainerTemplate;
