import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import classnames from 'classnames';
import { connect } from 'react-redux';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import HowToUse from '../Templates/HowToUse';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';

const files = [{ 'less': ['loader.less'] }];

class Loaders extends Component {
    state = {
        open: false,
        type: 'loadOxy',
        contain: false
    }

    componentDidMount() {
        document.addEventListener('click', event => {
            if (!event.target.classList.contains('loader-openner')) {
                this.setState({ open: false })
            }
        })
    }
    toggleOpen = () => {
        this.setState(state => {
            return {
                open: !state.open
            }
        })
    }

    onChange = (newState) => {
        this.setState(newState);
    }

    renderComponent = () => {
        const { open, type, contain } = this.state;
        const classes = classnames(
            'content-loader',
            type,
            {
                'hide': !open
            },
            {contain}
        )
        return (

            <React.Fragment>
                {type === 'loadOxy' &&
                    <div style={open ? {minHeight:'600px'} : {}}>
                        <button className="btn module-color loader-openner" onClick={this.toggleOpen}>Load Oxy</button>
                        <div className={classes}>
                            <svg version="1.2" className="content-align" baseProfile="tiny" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink">
                                <defs>
                                    <g className="animateDraw drawText" id="Logo-Text">
                                        <path id="Caminho_129" data-name="Caminho 129" d="M206.58,240.235a19.448,19.448,0,1,0,19.448,19.448,19.448,19.448,0,0,0-19.448-19.448M208,270.5a10.909,10.909,0,1,1,9.4-9.4,10.914,10.914,0,0,1-9.4,9.4" transform="translate(-187.132 -240.234)" ></path>
                                        <path id="Caminho_130" data-name="Caminho 130" d="M352.5,240.234v19.43a10.906,10.906,0,1,1-21.811,0V245.131l-8.54-4.9v19.43A19.448,19.448,0,0,0,352.5,275.77v3.344a10.91,10.91,0,0,1-10.911,10.9H327.216l4.9,8.543h9.474a19.45,19.45,0,0,0,19.451-19.447V245.131Z" transform="translate(-231.064 -240.234)"></path>
                                        <path id="Caminho_131" data-name="Caminho 131" d="M266.245,259.675l-11.96,8.825v10.615l19.154-14.132,19.155,14.132V268.5l-11.961-8.825,11.961-8.825V240.235l-19.155,14.132-19.154-14.132V250.85Z" transform="translate(-208.982 -240.234)"></path>
                                    </g>
                                </defs>

                                <svg className="logo_loading" viewBox="0 0 100 100" space="preserveAspectRatio">
                                    <use id="Draw-Text" className="drawText animateDraw" href="#Logo-Text" />
                                </svg>
                            </svg>
                        </div>
                    </div>
                }
                {type === 'placeholderLoader' &&
                    <>
                        <div className="panel table">
                            <div className="panel-body ">
                                <div className="table-editable panel-table-outer placeholder-loader">
                                    <div className="table-editable-header">
                                        <div className="row">
                                            <div className="col-md-4 column"></div>
                                            <div className="col-md-4 column"></div>
                                            <div className="col-md-4 column"></div>
                                            <div className="col-md-4 column"></div>
                                        </div>
                                    </div>
                                    <div className="table-editable-content">
                                        <div className="row">
                                            <div className="col-md-4 column"></div>
                                            <div className="col-md-4 column"></div>
                                            <div className="col-md-4 column"></div>
                                            <div className="col-md-4 column"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </React.Fragment >
        )
    }
    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label='Tipos' state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="type" value='loadOxy' stateName="type" label="Load Oxy" />
                <SwitchOptions attr="type" value='placeholderLoader' stateName="type" label="Load tabela" />
                <SwitchOptions attr="contain" value='true' stateName="contain" label="Contido" />
            </SwitchOptionsGroup>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;
        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Carregadores"
                    mainDescription="Os carregadores mostram ao usuário um feedback visual de que está sendo carregado/processado algo no sistema" >

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse title='Outros Componentes' both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Loaders);