import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FilesTemplate = ({ files }) => {
    return (
        files.map(obj => {
            let type = Object.keys(obj)[0];
            return obj[type].map((file, index) => {
                return (
                    <div className={`chip-container inline mt-xs mb-xs ${type}`} key={`${file}-${index}`}>
                        <span className="chip-icon-left">
                            <FontAwesomeIcon icon={['fab', type]} />
                        </span>
                        <p className="chip-text">{file}</p>
                    </div>
                )
            })

        })
    );
}

export default FilesTemplate;
