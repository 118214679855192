import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import classnames from 'classnames';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';

import dont1 from '../../assets/img/components/hint/dont1.png';
import do1 from '../../assets/img/components/hint/do1.png';
import gif1 from '../../assets/img/components/hint/gif1.gif';
import anatomia from '../../assets/img/components/hint/anatomia.png';
import locais from '../../assets/img/components/hint/locais.png';
import aplication1 from '../../assets/img/components/hint/aplication1.png';
import aplication2 from '../../assets/img/components/hint/aplication2.png';
import aplication3 from '../../assets/img/components/hint/aplication3.png';
import aplication4 from '../../assets/img/components/hint/aplication4.png';

const files = [
    { 'less': ['hint.less'] },
    { 'react': ['Hint.tsx'] }
];

class Hint extends Component {

    state = {
        type: undefined,
        colors: undefined,
        background: undefined,
        alignment: undefined,
        reponsive: undefined,
        sizes: undefined,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Dicas');
        this.setState({ currentIndex: index });
    }

    renderComponent = () => {
        const { type, colors, background, alignment, responsive, sizes } = this.state;
        const classes = classnames(
            'hint',
            'fa-exclamation',
            type,
            colors,
            background,
            alignment,
            responsive,
            sizes
        )
        return (
            <React.Fragment>
                {type === 'inline' && 'Lorem ipsum '}
                <div className={classes}>
                    <div className="hint-content">Lorem ipsum</div>
                </div>
            </React.Fragment>
        )
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderOptionClasses = () => {
        return (
            <React.Fragment>
                <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="type" value="inline" stateName="type" label="Inline" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Cores" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="colors" value="positive" stateName="colors" label="Positive/Module-color" />
                    <SwitchOptions attr="colors" value="negative" stateName="colors" label="Negative" />
                    <SwitchOptions attr="colors" value="highlight" stateName="colors" label="Highlight" />
                    <SwitchOptions attr="colors" value="warning" stateName="colors" label="Warning" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Cor de fundo" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="background" value="clean" stateName="background" label="Sem cor de fundo" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Alinhamento" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="alignment" value="top-left" stateName="alignment" label="Topo a esquerda" />
                    <SwitchOptions attr="alignment" value="center-left" stateName="alignment" label="Centro a esquerda" />
                    <SwitchOptions attr="alignment" value="bottom-left" stateName="alignment" label="Embaixo a esquerda" />
                    <SwitchOptions attr="alignment" value="top-right" stateName="alignment" label="Topo a direita" />
                    <SwitchOptions attr="alignment" value="center-right" stateName="alignment" label="Centro a direita" />
                    <SwitchOptions attr="alignment" value="bottom-right" stateName="alignment" label="Embaixo a direita" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Responsivo" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="responsive" value="mobile" stateName="responsive" label="Mostra no mobile" />
                    <SwitchOptions attr="responsive" value="hideHintOnMobile" stateName="responsive" label="Esconde a descrição no mobile" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Tamanhos" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="sizes" value="xs" stateName="sizes" label="Extra pequeno" />
                    <SwitchOptions attr="sizes" value="sm" stateName="sizes" label="Pequeno" />
                    <SwitchOptions attr="sizes" value="md" stateName="sizes" label="Médio" />
                    <SwitchOptions attr="sizes" value="lg" stateName="sizes" label="Grande" />
                    <SwitchOptions attr="sizes" value="xl" stateName="sizes" label="Extra grande" />
                </SwitchOptionsGroup>
            </React.Fragment>
        )
    }
    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    theme={theme}
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionClasses}
                    mainTitle="Dicas"
                    mainDescription="As dicas exibem texto informativo quando os usuários passam o mouse, focam ou tocam em um elemento."
                >
                <HowToUse
                    title="Uso"
                    description="Quando ativadas, as dicas exibem um rótulo de texto identificando um elemento, como uma descrição de sua função."
                >
                    <HowToUseFigure
                        srcImg={dont1}
                        col
                        status="dont"
                        descriptionImg="Não use dicas para redefinir o texto visível da interface do usuário."
                    />
                    <HowToUseFigure
                        srcImg={do1}
                        col
                        status="do"
                        descriptionImg="Use dicas para ícones interativas."
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={gif1}
                        descriptionImg="Não use dicas para redefinir o texto visível da interface do usuário."
                    />
                </HowToUse>
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Label <br /> 3. Elemento “pai”"
                    />
                </HowToUse>
                <HowToUse
                    title="Hierarquia"
                    subtitle="Locais"
                    description="Dicas podem se posicionar diferentemente dependendo do componente pai delas."
                >
                    <HowToUseFigure
                        srcImg={locais}
                        descriptionImg="1. Centro à direita <br /> 2. Centro à esquerda <br /> 3. Topo à esquerda <br /> 4. Baixo à esquerda <br /> 5. Topo à direita <br /> 6. Baixo à direita"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    description="Hints podem ser utilizados separadamente, ou aplicados à outros componentes, como:"
                >
                    <HowToUseFigure
                        srcImg={aplication1}
                        captionImg="Hint em uma Árvore Avançada"
                    />
                    <HowToUseFigure
                        srcImg={aplication2}
                        captionImg="Hint em Botões"
                    />
                    <HowToUseFigure
                        srcImg={aplication3}
                        captionImg="Hint em Campos"
                    />
                    <HowToUseFigure
                        srcImg={aplication4}
                        captionImg="Hint em Tabela"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Hint);
