import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import DragDropItems from './DragDropItems';

const defaultMessage = 'Clique ou arraste arquivos aqui.';

class DragDropFiles extends Component {
  state = {
    errorOnUpload: false,
    errorMessage: '',
    onDragEnter: false
  };

  static defaultProps = {
    maxSize: 20971520,
    canDelete: true,
    canUpload: true,
    sizeDivFile: 12
  };

  componentDidMount() {
    const { acceptedFiles, maxSize } = this.props;
    const bytes = this.bytesToSize(maxSize);

    let message = 'Somente arquivos ';

    if (acceptedFiles) {
      message += 'do(s) tipo(s): ' + acceptedFiles + ' e ';
    }

    message += 'com no máximo ' + bytes;

    this.setState({
      errorMessage: message
    });
  }

  bytesToSize = (bytes) => {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    var i = Math.floor(Math.log(bytes) / Math.log(1024));
    return Math.round((bytes / Math.pow(1024, i), 2)) + sizes[i];
  };

  onDropAccepted = (acceptedFiles) => {
    const { onAddFile } = this.props;

    acceptedFiles.forEach(file => {
      this.setState(
        () => {
          return {
            errorOnUpload: false,
            onDragEnter: false
          };
        },
        () => {
          onAddFile(file);
        }
      );
    });
  };

  onDropRejected = () => {
    this.setState({
      errorOnUpload: true,
      onDragEnter: false
    });
  };

  onDragEnterEffect = () => {
    this.setState({ onDragEnter: true });
  };

  renderFiles = (file, i) => {
    const { canDelete, sizeDivFile, onRemoveFile } = this.props;
    return (
      <DragDropItems
        canDelete={canDelete}
        file={file}
        key={file.name}
        onRemoveFile={() => onRemoveFile(i)}
        sizeDivFile={sizeDivFile}
      />
    );
  };

  render() {
    const { errorMessage, errorOnUpload, onDragEnter } = this.state;
    const { maxSize, acceptedFiles, files, canUpload } = this.props;
    return (
      <div className="dragdrop">
        {canUpload && (
          <Dropzone
            maxSize={maxSize}
            accept={acceptedFiles}
            onDropAccepted={this.onDropAccepted}
            onDropRejected={this.onDropRejected}
            onDragEnter={this.onDragEnterEffect}
          >
            {({ getRootProps, getInputProps }) => {
              return (
                <div
                  {...getRootProps()}
                  className={`dropzone dropzone--isActive ${
                    errorOnUpload ? 'error' : ''
                    }`}
                >
                  <div
                    className={`dragdrop-space ${onDragEnter ? 'hover' : ''}`}
                    data-message={
                      errorOnUpload && errorMessage
                        ? errorMessage
                        : defaultMessage
                    }
                  >
                    <input {...getInputProps()} aria-label="Campo para anexar arquivos" />
                  </div>
                </div>
              );
            }}
          </Dropzone>
        )}
        {files && files.length > 0 && <ul>{files.map(this.renderFiles)}</ul>}
      </div>
    );
  }
}

export default DragDropFiles;
