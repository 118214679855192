import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import DragDropFiles from './DragDropFiles';
import ComponentTemplate from '../Templates/ComponentTemplate';

import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import anatomia from '../../assets/img/components/dragdrop/anatomia.png';
import gif from '../../assets/img/components/dragdrop/gif.gif';


const files = [{ 'less': ['dragdrop.less'] }];

class DragDrop extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'DragDrop');
        this.setState({ currentIndex: index });
    }

    state = {
        files: [],
        currentIndex: undefined
    }

    onAddFile = (file) => {

        this.setState(prevState => {
            return {
                files: [...prevState.files, file]
            }
        })
    }

    onRemoveFile = (index) => {
        this.setState({ files: this.state.files.filter((file, i) => index !== i) })
    }

    renderComponent = () => {
        const { files } = this.state;
        return <DragDropFiles files={files} onAddFile={this.onAddFile} onRemoveFile={this.onRemoveFile} />
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;
        return (
            <>
                <ComponentTemplate
                    theme={theme}
                    codeToRender={this.renderComponent}
                    mainTitle="DragDrop"
                    mainDescription="Selecione e importe múltiplos arquivos facilmente"
                >
                <HowToUse
                    title="Uso"
                    description="DragDrop’s podem ser usados em telas onde é necessário upload de múltiplos arquivos."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br />2. Descrição de ação <br />3. Container de “drop” <br />4. Item anexado <br />5. Ações do item <br />6. Nome do item"
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    subtitle="Animação"
                >
                    <HowToUseFigure
                        srcImg={gif}
                        captionImg="Animação de inserção"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(DragDrop);
