import React, { Component } from "react";

import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import logo from "../../assets/img/logo.png";
import { ReactComponent as SVGMATTERMOST } from '../../assets/img/svgs/mattermost.svg';

class Header extends Component {
  state = {
    actived: false,
  };

  onToggleMenu = () => {
    const { toggleMenu, open } = this.props;
    if (!open) toggleMenu();
  };

  onToggleTheme = () => {
    const { toggleTheme } = this.props;
    toggleTheme();
  };

  render() {
    const { open } = this.props;

    const headerClasses = classnames("header-arc");

    const iconClasses = classnames("icon-toggle", { actived: open });

    return (
      <header className={headerClasses}>
        <div className="header-container">
          <button className={iconClasses} onClick={this.onToggleMenu}>
            <i className="iconify" data-icon="mdi:menu" />
          </button>
          <NavLink to="/componentes" className="link-logo">
            <img className="logo" src={logo} alt="Logo Arc Design" />
          </NavLink>
          <nav>
            <ul>
              <li>
                <NavLink to="/componentes" activeClassName="active">
                  Componentes
                </NavLink>
              </li>
              <li>
                <NavLink to="/versoes" activeClassName="active">
                  Versões
                </NavLink>
              </li>
            </ul>
          </nav>
          <a href="https://elochat.elotech.com.br/suporte-geral/channels/design-ux-ui" target="_blank" rel="noopener noreferrer" className="mattermost-link">
            <SVGMATTERMOST/>
          </a>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  toggleMenu: PropTypes.func,
};

export default Header;
