import React, { Component } from "react";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { selectTheme } from "../../actions/index";

class SwitchTheme extends Component {
  toggleSwitch = () => {
    const { theme } = this.props;

    if (theme === "light") {
      this.props.selectTheme("dark");
      window.location.href = "#codigo";
    } else {
      this.props.selectTheme("light");
      if (window.location.hash === "#codigo") {
        window.location.hash = "";
      }
    }
  }

  render() {
    const { theme } = this.props;

    return (
      <>
        <div className={`switch-container toggle-theme ${theme === 'dark' ? 'active' : '' }`} onClick={() => this.toggleSwitch()}>
          <span className="switch-caption">{theme === 'light' ? 'Design' : 'Código'}</span>
          <em className="switch"></em>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    theme: state.theme,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ selectTheme: selectTheme }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(SwitchTheme);
