export default [
    {
        id: 'intro',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        buttons: [
            {
                classes: 'btn inline module-color',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: 'custom-onboarding-1',
        highlightClass: 'highlight',
        scrollTo: false,
        showCancelLink: false,
        text: '<i class="onboarding-image-1"></i> <div>Conheça a Nova Marca </br> <i class="onboarding-logo-1"></i></div>'
    },
    {
        id: 'intro2',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        buttons: [
            {
                classes: 'btn inline neutral outlined',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline module-color',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: 'custom-onboarding-2',
        highlightClass: 'highlight',
        scrollTo: false,
        showCancelLink: false,
        text: '<i class="onboarding-image-2"></i> <div>Para sua melhor experiência </br> atualizamos todos os produtos </br><i class="onboarding-logo-2"></i></div>'
    },
    {
        id: 'intro3',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        buttons: [
            {
                classes: 'btn inline neutral shepherd-button-primary',
                text: 'Sair do Tutorial',
                type: 'cancel'
            },
            {
                classes: 'btn inline module-color',
                text: 'Iniciar Tutorial',
                type: 'next'
            }
        ],
        classes: 'custom-onboarding-2 custom-onboarding-3',
        highlightClass: 'highlight',
        scrollTo: false,
        showCancelLink: false,
        text: '<i class="onboarding-image-2"></i> <div>Preparamos um tutorial para mostrar as novas funcionalidades da atualização. </br></br> <b>Deseja iniciar o tutorial?</b> </br></div>'
    },
    {
        id: 'step1',
        attachTo: {
            element: 'header .logo',
            on: 'bottom'
        },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        showOn: function () {
            return document.querySelector("header .logo") && document.querySelector("aside.sidebar.new").offsetWidth <= 60 ? true : false;
        },
        buttons: [
            {
                classes: 'btn text neutral shepherd-button-secondary',
                text: 'Concluir Tutorial',
                type: 'complete'
            },
            {
                classes: 'btn right inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline module-color shepherd-button-primary',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Nesta área fica a indicação de qual módulo você está utilizando'],
    },
    {
        id: 'step1mobile',
        attachTo: {
            element: 'header .logo ',
            on: 'bottom'
        },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 10);
            });
        },
        showOn: function () {
            return document.querySelector("header .logo") && document.querySelector("aside.sidebar.new").offsetWidth > 60 ? true : false;
        },
        buttons: [
            {
                classes: 'btn text neutral shepherd-button-secondary',
                text: 'Concluir Tutorial',
                type: 'complete'
            },
            {
                classes: 'btn right inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline module-color shepherd-button-primary',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Nesta área fica a indicação de qual módulo você está utilizando'],
    },
    {
        id: 'step2mobile',
        attachTo: {
            element: '.header-content-mobile .header-title',
            on: 'bottom'
        },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        showOn: function () {
            return document.querySelector(".header-content-mobile .header-title") && document.querySelector("aside.sidebar.new").offsetWidth === 0 ? true : false;
        },
        buttons: [
            {
                classes: 'btn text neutral shepherd-button-secondary',
                text: 'Concluir Tutorial',
                type: 'complete'
            },
            {
                classes: 'btn right inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline module-color shepherd-button-primary',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Nesta área fica a indicação de qual entidade você está logado e a competência/exercício.']
    },
    {
        id: 'step2',
        attachTo: {
            element: 'header .content-title>span',
            on: 'bottom'
        },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        showOn: function () {
            return document.querySelector("header .content-title>span") && document.querySelector("aside.sidebar.new").offsetWidth >= 60 ? true : false;
        },
        buttons: [
            {
                classes: 'btn text neutral shepherd-button-secondary',
                text: 'Concluir Tutorial',
                type: 'complete'
            },
            {
                classes: 'btn right inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline module-color shepherd-button-primary',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Nesta área fica a indicação de qual entidade você está logado e a competência/exercício.']
    },
    {
        id: 'step3',
        attachTo: {
            element: '.header-features',
            on: 'bottom'
        },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        buttons: [
            {
                classes: 'btn text neutral shepherd-button-secondary',
                text: 'Concluir Tutorial',
                type: 'complete'
            },
            {
                classes: 'btn right inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline module-color shepherd-button-primary',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Nesta área fica opções como: <b>Troca de entidade, Minha Conta, Acessibilidade, Ajuda</b>, entre outras...']
    },
    {
        id: 'step4',
        attachTo: {
            element: '.nav-breadcrumbs ol',
            on: 'bottom'
        },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        buttons: [
            {
                classes: 'btn text neutral shepherd-button-secondary',
                text: 'Concluir Tutorial',
                type: 'complete'
            },
            {
                classes: 'btn right inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline module-color shepherd-button-primary',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: '',
        highlightClass: 'highlight',
        showCancelLink: true,
        text: ['Nesta área fica a indicação de página você está e por qual passou. Você pode navegar clicando nos nomes das páginas.']
    },
    {
        id: 'step5',
        attachTo: {
            element: '.header-toggle-sidebar.toggle-control.hidden-xs.hidden-sm',
            on: 'top'
        },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        showOn: function () {
            return document.querySelector("aside.sidebar.new").offsetWidth > 0 ? true : false;
        },
        buttons: [
            {
                classes: 'btn text neutral shepherd-button-secondary',
                text: 'Concluir Tutorial',
                type: 'complete'
            },
            {
                classes: 'btn right inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline module-color shepherd-button-primary',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Agora o botão para recolher ou expandir o menu fica localizado aqui.']
    },
    {
        id: 'step5mobile',
        attachTo: {
            element: '.header-toggle-sidebar.toggle-control.sidebar-toggle.hidden-lg.hidden-md',
            on: 'auto-end'
        },
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        showOn: function () {
            return document.querySelector("aside.sidebar.new").offsetWidth === 0 ? true : false;
        },
        buttons: [
            {
                classes: 'btn text neutral shepherd-button-secondary',
                text: 'Concluir Tutorial',
                type: 'complete'
            },
            {
                classes: 'btn right inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline module-color shepherd-button-primary',
                text: 'Próximo',
                type: 'next'
            }
        ],
        classes: '',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['Agora o botão para recolher ou expandir o menu fica localizado aqui.']
    },
    {
        id: 'stepFinal',
        beforeShowPromise: function () {
            return new Promise(function (resolve) {
                setTimeout(function () {
                    window.scrollTo(0, 0);
                    resolve();
                }, 500);
            });
        },
        buttons: [
            {
                classes: 'btn inline neutral outlined shepherd-button-primary',
                text: 'Voltar',
                type: 'back'
            },
            {
                classes: 'btn inline positive shepherd-button-primary',
                text: 'Concluir',
                type: 'complete'
            }
        ],
        classes: 'custom-onboarding-2 custom-onboarding-3',
        highlightClass: 'highlight',
        scrollTo: true,
        showCancelLink: true,
        text: ['<i class="onboarding-image-2"></i> <div><b>Tutorial concluído.</b> </br></br> Qualquer dúvida ou sugestão acesse nossa área de FAQ.</div>']
    }
];