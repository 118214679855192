import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import ComponentTemplate from '../Templates/ComponentTemplate';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';

import anatomia from '../../assets/img/components/pagination/anatomia.png';
import aplicacao from '../../assets/img/components/pagination/aplicacao.png';
import aplicacao2 from '../../assets/img/components/pagination/aplicacao2.png';

const files = [
    { 'less': ['pagination.less'] },
    { 'angular': ['elo-data-table.module.ts'] },
    { 'react': ['SearchPagination.jsx'] }
];


class Pagination extends Component {

    state = {
        highlight: false,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Paginação');
        this.setState({ currentIndex: index });
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderComponent = () => {
        const { highlight } = this.state;

        return (
            <div className="panel-pagination">
                <div className="sisters">
                    <div className="number-itens">
                        <b>
                            Mostrar
						</b>
                        <select aria-label="Selector quantidade de páginas">
                            <option>
                                10
							</option>
                            <option>
                                50
							</option>
                            <option>
                                100
							</option>
                            <option>
                                150
							</option>
                        </select>
                        <b>
                            Itens
						</b>
                    </div>
                </div>
                <div className="sisters">
                    <ul className="panel-pagination-inner">
                        <li>
                            <a href="#paginacao">«</a>
                        </li>
                        <li>
                            <a href="#paginacao">‹</a>
                        </li>
                        <li className="active">
                            <a href="#paginacao">1</a>
                        </li>
                        <li>
                            <a href="#paginacao">2</a>
                        </li>
                        <li>
                            ...
						</li>
                        <li>
                            <a href="#paginacao">2344</a>
                        </li>
                        <li>
                            <a href="#paginacao">2345</a>
                        </li>
                        <li>
                            <a href="#paginacao">›</a>
                        </li>
                        <li>
                            <a href="#paginacao">»</a>
                        </li>
                    </ul>
                </div>
                {highlight && (
                    <div className="sisters">
                        <div className="highLight">
                            1000
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="highlight" value="destaque" stateName="highlight" label="Destaque" />
            </SwitchOptionsGroup>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Paginação"
                    mainDescription="Componente de paginação permite a navegação entre todas as páginas."
                >
                <HowToUse
                    title="Uso"
                    description="Permite a navegação entre todas as páginas, permite a visualização de: <br /> • Número total de páginas <br /> • Setas que permitem a navegação, tanto para última/primeira quando a próxima/anterior. <br/> • Quantidade de registros por página, permitindo ver mais ou menos."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br/> 2. Identificador de quantidade de registros por página <br/> 3. Identificador da página atual, total de páginas e setas para navegação. <br/>4. Área de destaque (opcional)"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    description="Paginação é sempre utilizado abaixo do componente de tabela, seja ela em table ou em div."
                >
                    <HowToUseFigure
                        srcImg={aplicacao2}
                        status="dont"
                        descriptionImg="Não utilizar a paginação separada da tabela."
                        col
                    />
                    <HowToUseFigure
                        srcImg={aplicacao}
                        status="do"
                        descriptionImg="Utilizar a paginação junto com a tabela."
                        col
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Pagination);
