import React from 'react';
import slugify from 'react-slugify';
import { Link } from 'react-router-dom';

const BoxItemTemplate = ({ link, name, svg, newComponent }) => {
    return (
        <li key={slugify(name)} className={`${newComponent ? 'new' : ''}`}>
            <Link to={link}>
                <figure>
                    {svg &&
                        svg
                    }
                    {!svg &&
                        <svg xmlns="http://www.w3.org/2000/svg" width="86.469" height="86.469" viewBox="0 0 86.469 86.469">
                            <path id="Caminho_119" data-name="Caminho 119" d="M210.234,167a43.234,43.234,0,1,0,43.234,43.234A43.235,43.235,0,0,0,210.234,167Zm14.463,7.066a39.03,39.03,0,0,1,14.5,10.122H222.049Zm-14.447-2.773A38.97,38.97,0,0,1,220.3,172.61l-3.036,11.578H203.977L200.9,172.436a39.08,39.08,0,0,1,9.35-1.143Zm-13.772,2.516,2.715,10.378h-17.88a39.01,39.01,0,0,1,15.168-10.378Zm46.512,15.273-13.108,7.569h-39.3l-13.106-7.569Zm-71.676,21.145a38.851,38.851,0,0,1,1.91-12.049l8.576,14.853L171.718,215.8a39.2,39.2,0,0,1-.405-5.57Zm1.328,10.113,11.547-3.168,6.648,11.513-8.634,8.537a38.846,38.846,0,0,1-9.557-16.882ZM185.6,240.36l7.623-7.532,8.944,15.491a38.773,38.773,0,0,1-16.563-7.959Zm22.719,8.817-32.761-56.733,13.108,7.547,19.653,34.042Zm-11.566-46.74h26.989l-13.494,23.37Zm15.42,31.605,19.677-34.07,13.108-7.567-32.776,56.782Zm6.143,14.277,8.58-14.861,7.437,7.347A38.787,38.787,0,0,1,218.32,248.319Zm19.487-10.592-8.507-8.41,6.652-11.521,11.715,3.213A38.891,38.891,0,0,1,237.807,237.727Zm.537-24.069,8.934-15.474a38.886,38.886,0,0,1,1.4,18.309Z" transform="translate(-167 -167)" fill="rgba(238,55,51,0.3)" />
                        </svg>
                    }
                </figure>
                <p>{name}</p>
            </Link>
        </li>
    )
}

export default BoxItemTemplate;
