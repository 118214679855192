import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';

import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from './../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import uso from '../../assets/img/components/sidebar/gif.gif';
import anatomia from '../../assets/img/components/sidebar/anatomia.png';
import sidee from '../../assets/img/components/sidebar/sidee.png';
import sider from '../../assets/img/components/sidebar/sider.png';
import sidec from '../../assets/img/components/sidebar/sidec.png';
import gif2 from '../../assets/img/components/sidebar/gif2.gif';
import gif3 from '../../assets/img/components/sidebar/gif3.gif';

const files = [{ 'less': ['float-sidebar.less'] }, { 'angular': ['float-sidebar.module.ts'] }];

const obj = [{ 'id': '1', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '2', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '3', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '4', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '5', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '6', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '7', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '8', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '9', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '10', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '11', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },
{ 'id': '12', 'cpf': '893.928.239-02', 'nome': 'Lorem ipsum lorem ipsum lorem' },]

class FloatSidebar extends Component {

    state = {
        active: false,
        selected: false,
        hasHint: false,
        itemSelected: '',
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        document.addEventListener('click', event => {
            this.clickOutside(event);
        })

        document.addEventListener('keyup', event => {
            this.pressEsc(event);
        })

        const index = ListMenu.findIndex(item => item.name === 'Float Sidebar');
        this.setState({currentIndex: index});

    }

    clickOutside = event => {
        if (document.querySelector('.float-sidebar.active') && !document.querySelector('.float-sidebar.active').contains(event.target)) {
            this.setState({ active: false })
        }
    }

    pressEsc = event => {
        if (event.key === 'Escape' && this.state.active) {
            this.setState({ active: false })
        }
    }

    toggleActive = () => {
        this.setState(state => {
            return {
                active: !state.active
            }
        })
    }

    toSelect = () => {
        this.setState({ selected: true, active: false });
    }

    onChange = newState => {
        this.setState(newState);
    }

    selectItem = (id) => {
        if (this.state.selectItem !== id) {
            this.setState({ itemSelected: id, selected: true });
        }
    }

    renderOptionalClasses = () => {
        return (
            <>
                <SwitchOptionsGroup label='Variações' state={this.state} onChange={this.onChange} >
                    <SwitchOptions attr='hasHint' value={true} stateName='hasHint' label='Com Dica' />
                </SwitchOptionsGroup>
            </>
        )
    }

    renderComponent = () => {
        const { active, selected, hasHint, itemSelected } = this.state;

        return (
            <div className={`float-sidebar ${active ? 'active' : ''} ${selected ? 'selected' : ''}`}>
                <div className={"float-button fa fa-user " + (hasHint ? 'hint center-right module-color' : '')} onClick={this.toggleActive}>
                    {
                        hasHint &&
                        <div className="hint-content">
                            <b>Nome: </b> Lorem ipsum lorem <br />
                            <b>CPF: </b> 893.928.239-02
                        </div>
                    }
                </div>
                <div className="title">
                    Seleção de Funcionário
		        </div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="form-group">
                                <input name="search-funcionario" className="has-input-icon" type="text" placeholder="Pesquise por Nome, CPF, etc..." aria-label="Campo de pesquisa de funcionário" />
                                <i className="fa fa-circle-o-notch spinner"></i>
                                <i className="fa fa-search input-icon"></i>
                            </div>
                            <div className="divider"></div>
                        </div>
                    </div>
                    <div className="select-list form-group">
                        <div className="row">
                            <div className="title-block">
                                Pessoa
					        </div>
                        </div>
                        {
                            obj.map((element, i) => {
                                return (
                                    <div className="row" onClick={() => this.selectItem(element.id)} key={i}>
                                        <div className={"col-md-12 select-list-item " + (itemSelected === element.id ? 'selected' : '')}>
                                            <div className="col-md-2 selection">
                                                <div className="form-group">
                                                    <input aria-label={`Radio button para selecionar funcionário ${element.nome}`} id={"selection" + element.id} defaultChecked={itemSelected === element.id ? true : false} name="pessoa" className="input-radio" type="radio" value={element.id} />
                                                    <label htmlFor={"selection" + element.id}></label>
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <b>CPF:</b>
                                                    </div>
                                                    <div className="col-md-8">
                                                        {element.cpf}
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <b>Nome:</b>
                                                    </div>
                                                    <div className="col-md-8">
                                                        {element.nome}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                        <div className="row funcionario">
                            <div className="title-block">
                                Funcionário
					        </div>
                        </div>
                        <div className="row funcionario">
                            <div className="col-md-12 select-list-item">
                                <div className="col-md-2 selection">
                                    <div className="form-group">
                                        <input id="subselection1" name="funcionario" className="input-radio" type="radio" />
                                        <label htmlFor="subselection1"></label>
                                    </div>
                                </div>
                                <div className="col-md-10">
                                    <div className="row">
                                        <div className="col-md-4">
                                            <b>Matrícula:</b>
                                        </div>
                                        <div className="col-md-8">
                                            123123
								        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <b>Classe:</b>
                                        </div>
                                        <div className="col-md-8">
                                            Lorem ipsum lorem
								        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <b>Cargo:</b>
                                        </div>
                                        <div className="col-md-8">
                                            Lorem ipsum lorem
								        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <b>Salário:</b>
                                        </div>
                                        <div className="col-md-8">
                                            R$ 2.800,00
								        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row buttons"
                    >
                        <div className="col-md-12 mt-xs">
                            <div className="btn module-color inline" onClick={this.toSelect}>SELECIONAR</div>
                            <div className="btn neutral inline">CANCELAR</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;
        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Float Sidebar"
                    mainDescription="Componente permite a interação com alguma funcionalidade em qualquer página."
                >
                <HowToUse
                    title="Uso"
                    description="Seu uso é restrito para funcionalidades que devem estar disponíveis em todas as telas, e que sejam editáveis."
                >
                    <HowToUseFigure
                        srcImg={uso}
                        captionImg="Utilizado no sistema folha, permitindo a seleção de funcionário em todas as páginas."
                    />
                </HowToUse>
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Título do sidebar <br /> 3. Botão flutuante <br /> 4. Descrição item selecionado <br /> 5. Conteúdo"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    description="Componente sempre ficará ao lado esquerdo por cima de todos os elementos."
                />
                <HowToUse
                    title="Comportamento"
                    subtitle="Drag and Drop"
                    description="É possível alterar a posição do botão flutuante, facilitando o uso, e principalmente permitindo que o usuário possa tirar o botão flutuante de cima de alguma informação."
                >
                    <HowToUseFigure
                        srcImg={gif2}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Expandir / Retrair"
                    description="É obrigatório que ao clicar no botão flutuante o sidebar seja expandido. <br /> É obrigatório que ao clicar fora do sidebar o mesmo seja fechado, também deve ocorrer ao apertar a tecla esc."
                >
                    <HowToUseFigure
                        srcImg={gif3}
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Botão Flutuante"
                >
                    <HowToUseFigure
                        col
                        captionImg="Botão com sidebar expandido"
                        srcImg={sidee}
                    />
                    <HowToUseFigure
                        col
                        captionImg="Botão com sidebar recolhido"
                        srcImg={sider}
                    />
                    <br />
                    <HowToUseFigure
                        srcImg={sidec}
                        captionImg="Botão com opção selecionada, tanto recolhido como expandido e com dica."
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(FloatSidebar);
