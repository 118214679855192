import React, { Component } from 'react';
import PropTypes from 'prop-types';
import slugify from 'react-slugify';



class Menu extends Component {

    renderList = (listMenu, selectedIndex, theme) => {
        return listMenu.map((item, i) => {
            return (
                <li key={slugify(item.name)}>
                    <a href={`/componentes/${slugify(item.name)}${theme === 'dark' ? '#codigo' : ''}`} className={`${selectedIndex === i ? 'active' : ''}`}>
                        <span className="iconify" data-icon='ic:outline-chevron-right'></span>
                        <span>{item.name}</span>
                        {item.new &&
                            <div className="chip-container negative">
                                <p className="chip-text">NOVO!</p>
                            </div>
                        }
                    </a>
                </li>
            )
        })
    }

    render() {
        const { listMenu, listOption, selectedIndex, theme } = this.props;
        return (
            <ul ref={listOption}>
                {this.renderList(listMenu, selectedIndex, theme)}
            </ul>
        )
    }
}

Menu.propTypes = {
    listMenu: PropTypes.array.isRequired,
    toggleMenu: PropTypes.func.isRequired
}
export default Menu;
