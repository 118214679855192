import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import uso from '../../assets/img/components/info/uso.png';
import anatomia from '../../assets/img/components/dialogue-box/anatomia.png';
import dont1 from '../../assets/img/components/dialogue-box/donttitle.png';
import do1 from '../../assets/img/components/dialogue-box/dotitle.png';
import dont2 from '../../assets/img/components/dialogue-box/dontbutton.png';
import do2 from '../../assets/img/components/dialogue-box/dobutton2.png';
import dont3 from '../../assets/img/components/dialogue-box/dontbutton2.png';
import dont4 from '../../assets/img/components/dialogue-box/dontbutton3.png';
import do3 from '../../assets/img/components/dialogue-box/doitbutton3.png';
import gif from '../../assets/img/components/dialogue-box/gif.gif';

import informativo from '../../assets/img/components/dialogue-box/module.png';
import sucesso from '../../assets/img/components/dialogue-box/positive.png';
import erro from '../../assets/img/components/dialogue-box/negative.png';
import neutro from '../../assets/img/components/dialogue-box/neutral.png';
import alerta from '../../assets/img/components/dialogue-box/warning.png';

const files = [
    { 'js': ['dialogue-box.js'] },
    { 'less': ['dialogue-box.less'] },
    { 'angular': ['dialog.module.ts'] }
];

class DialogueBox extends Component {
    state = {
        type: 'neutral',
        open: false,
        details: false,
        scroll: false,
        currentIndex: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Caixa de Diálogo');
        this.setState({ currentIndex: index });
    }

    toggleDialogue = () => {
        this.setState(state => {
            return {
                'open': !state.open
            }
        })
    }

    toggleDetails = event => {
        event.stopPropagation();

        this.setState(state => {
            return {
                'details': !state.details
            }
        })
    }

    renderComponent = () => {
        const { open, type, details, scroll } = this.state;

        const btnClasses = classnames('btn', 'dialoguebox-opener', type);

        const dialogueClasses = classnames('dialoguebox', 'alt', type, { open });

        const btnCloseClasses = classnames('btn', 'inline', 'mr-none', type);

        const messageClasses = classnames('more-message', 'mt-xs', { 'open': details })

        return (
            <div>
                <button className={btnClasses} onClick={this.toggleDialogue}>
                    Abrir Mensagem
                </button>
                <div className={dialogueClasses} onClick={this.toggleDialogue}>
                    <div className="alt-box">
                        {!scroll &&
                            <div className="alt-box-content">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <h3 className="title"> Lorem ipsum dolor sit amet </h3>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                            tempor icididunt ut labore et dolore
                                        </p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <button className="btn text neutral more-message-opener" onClick={this.toggleDetails}>Mais Detalhes</button>
                                    </div>
                                </div>
                                <div className="row">
                                            <p className={messageClasses}>
                                                Mais Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                                tempo
                                    </p>
                                        </div>
                                <div className="alt-footer right">
                                    <button className="btn text neutral inline">cancelar</button>
                                    <button className={btnCloseClasses}> aceitar </button>
                                </div>
                            </div>
                        }
                        {scroll &&
                        <>
                            <div className="alt-box-content">
                                <div className="row">
                                    <div className="col-md-12 form-group">
                                        <h3 className="title"> Lorem ipsum dolor sit amet </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="alt-box-content alt-box-scroll">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, a atque magni dicta, quasi iusto ducimus eaque iste vel deserunt quae accusantium qui earum. Explicabo asperiores qui repellendus ratione earum?
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, a atque magni dicta, quasi iusto ducimus eaque iste vel deserunt quae accusantium qui earum. Explicabo asperiores qui repellendus ratione earum?
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, a atque magni dicta, quasi iusto ducimus eaque iste vel deserunt quae accusantium qui earum. Explicabo asperiores qui repellendus ratione earum?
                                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum, a atque magni dicta, quasi iusto ducimus eaque iste vel deserunt quae accusantium qui earum. Explicabo asperiores qui repellendus ratione earum?
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="alt-box-content">
                                <div className="alt-footer right">
                                    <button className="btn text neutral inline">cancelar</button>
                                    <button className={btnCloseClasses}> aceitar </button>
                                </div>
                            </div>
                        </>
                        }
                    </div>
                </div>
            </div>
        )
    }

    onChange = newState => {
        this.setState(newState);
    }


    renderOptions = () => {
        return (
            <SwitchOptionsGroup label='Tipo de mensagens' state={this.state} onChange={this.onChange} >
                <SwitchOptions attr='type' value='module-color' stateName='type' label='Informativo' />
                <SwitchOptions attr='type' value='highlight' stateName='type' label='Highlight' />
                <SwitchOptions attr='type' value='positive' stateName='type' label='Sucesso' />
                <SwitchOptions attr='type' value='neutral' stateName='type' label='Neutral' />
                <SwitchOptions attr='type' value='negative' stateName='type' label='Erro' />
                <SwitchOptions attr='type' value='warning' stateName='type' label='Alerta' />
                <SwitchOptions attr='scroll' value='true' stateName='scroll' label='Com Scroll' />
            </SwitchOptionsGroup>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {

        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptions}
                    mainTitle='Caixa de Diálogo'
                    mainDescription='Caixa de diálogo informa o usuário sobre uma tarefa e pode conter uma informação crítica, requerer decisões ou conter múltiplas tarefas'
                    theme={theme}
                >
                <HowToUse
                    title='Uso'
                    description='Uma caixa de diálogo é um tipo de modal que aparece contendo uma informação crítica ou requerendo alguma decisão. <br /> Diálogos desabilitam todas funcionalidades enquanto está visível, e mantém, até que seja confirmado, cancelado, ou até que a ação requisitada seja feita. <br /> <br /> Diálogos são propositalmente interruptivos, por isso devem ser usados com moderação.'
                >
                    <img src={uso} alt="hierarquia" className="mt-xs" />
                </HowToUse>
                <HowToUse
                    title='Anatomia'
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg='1. Container <br /> 2. Ícone <br /> 3. Título (opcional) <br /> 4. Mensagem <br /> 5. Botão de mais detalhes (opcional) <br /> 6. Mais detalhes (opcional) <br /> 7. Botões'
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Título'
                    description='Deve ter uma mensagem ou questão, clara e breve. <br /> Evite desculpas: ‘Desculpe pela interrupção’, alertas: ‘Aviso’, ou algo genérico: ‘Você tem certeza?’.'
                >
                    <HowToUseFigure
                        srcImg={dont1}
                        col
                        status='dont'
                        descriptionImg='Não use títulos com questões ambíguas.'
                    />
                    <HowToUseFigure
                        srcImg={do1}
                        col
                        status='do'
                        descriptionImg='Utilize títulos com questões específicas, explicação consistente, e que facilite claramente a ação a ser tomada.'
                    />
                </HowToUse>
                <HowToUse
                    title='Botões'>
                    <HowToUseFigure
                        srcImg={dont2}
                        col
                        status='dont'
                        descriptionImg='Botões responsáveis por ignorar ou cancelar a ação devem ficar ao lado esquerdo, e não devem ter o mesmo estilo visual dos de confirmação.'
                    />
                    <HowToUseFigure
                        srcImg={do1}
                        col
                        status='do'
                        descriptionImg='Botões responsáveis por confirmar a ação devem ficar ao lado direito da tela.'
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={dont3}
                        col
                        status='dont'
                        descriptionImg='Não utilize botões que dificultem o entendimento do usuário. Ex. Botão cancelar não faz sentido em caixas de diálogo informativas.'
                    />
                    <HowToUseFigure
                        srcImg={do2}
                        col
                        status='do'
                        descriptionImg='Botão único deve ser utilizado quando a caixa de diálogo exibir somente um informativa sem que o usuário precise tomar alguma decisão.'
                    />
                    <hr />
                    <HowToUseFigure
                        srcImg={dont4}
                        col
                        status='dont'
                        descriptionImg='Não use texto de ações que falham ao indicar o que a seleção irá fazer.'
                    />
                    <HowToUseFigure
                        srcImg={do3}
                        col
                        status='do'
                        descriptionImg='A ação ‘Descartar’ indica o resultado da decisão. Até mesmo se o usuário não leu o título, o botão está descrito a ação.'
                    />
                </HowToUse>
                <HowToUse
                    title='Hierarquia'
                    description='As caixas de diálogos devem ser independentes de outros componentes, para manter todas as suas propriedades, permanecer sempre visível e acima de todos os outros componentes. <br/>  Os diálogos não devem ofuscados por outros elementos, ou aparecer parcialmente na tela.'
                />
                <HowToUse
                    title='Comportamento'
                    description='Caixas de diálogos ficam visíveis atraindo o foco, desabilitando todas outras funcionalidades e tarefas que estavam ocorrendo, até que a ação seja tomada.'
                >
                    <HowToUseFigure
                        srcImg={gif}
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Ignorando caixas de diálogo'
                    description='Diálogos devem ser ignorados ao: <br /> • Clicar fora da caixa de diálogo. <br /> • Clicar no botão cancelar <br /> • Pressionar a tecla esc'
                />
                <HowToUse
                    title='Variações'
                    subtitle='Informativo'
                >
                    <HowToUseFigure
                        srcImg={informativo}
                    />
                </HowToUse>
                <HowToUse subtitle='Sucesso'
                >
                    <HowToUseFigure
                        srcImg={sucesso}
                    />
                </HowToUse>
                <HowToUse subtitle='Erro'
                >
                    <HowToUseFigure
                        srcImg={erro}
                    />
                </HowToUse>
                <HowToUse subtitle='Neutro'
                >
                    <HowToUseFigure
                        srcImg={neutro}
                    />
                </HowToUse>
                <HowToUse subtitle='Alerta'
                >
                    <HowToUseFigure
                        srcImg={alerta}
                    />
                </HowToUse>


                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code

                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(DialogueBox);
