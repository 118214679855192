import React, { Component } from 'react';
import { connect } from 'react-redux';

class HowToUse extends Component {
    render() {
        const { title, subtitle, description, code, theme, children, both } = this.props;

        if ((theme === 'dark' && code) || (theme === 'light' && !code) || both) {
            return (
                <section className="how-to-use">
                    {title &&
                        <h2 className="section-title" id={title.toLowerCase()}> {title} </h2>
                    }
                    {subtitle &&
                        <h3 className="section-subtitle"> {subtitle} </h3>
                    }
                    {description &&
                        <p className="section-description" dangerouslySetInnerHTML={{ __html: description }} />
                    }
                    {React.Children.map(children, child => {
                        if (!child) {
                            return child;
                        }
                        return React.cloneElement(child, {title: title});
                    })}
                </section>
            )
        }

        return null;
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(HowToUse);
