import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

import classnames from 'classnames';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';

import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';

import anatomia from '../../assets/img/components/chip/anatomia.png';
import gif1 from '../../assets/img/components/chip/gif1.gif';
import gif2 from '../../assets/img/components/chip/gif2.gif';
import dont1 from '../../assets/img/components/chip/dont1.png';
import chipcontido from '../../assets/img/components/chip/chipcontido.png';
import chipcontornado from '../../assets/img/components/chip/chipcontornado.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const files = [
    { 'less': ['chip.less'] },
    { 'react': ['Chip.tsx'] }
];

class Chip extends Component {


    state = {
        color: undefined,
        outlined: undefined,
        iconleft: undefined,
        iconright: undefined,
        interactive: undefined,
        center: undefined,
        toast: false,
        toastVisible: false,
        currentIndex: undefined,
        scroll: false,
        rightDirectionScroll: false,
        inline: false,
        nowrap: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Chip');
        this.setState({ currentIndex: index });
    }

    onChange = newState => {
        this.setState(newState, () => {
            this.showToast();
        });
    }

    showToast = () => {
        const { toast } = this.state;
        if (toast) {
            this.setState({ toastVisible: true });
            setTimeout(() => {
                this.setState({ toastVisible: false })
            }, 3000)
        }
    }

    renderChips = () => {
        const {
            color,
            outlined,
            iconleft,
            iconright,
            center,
            toast,
            toastVisible,
            inline,
            scroll,
            nowrap
        } = this.state;

        const classNamesChip = classnames(
            "chip-container",
            color,
            outlined,
            "interactive",
            {"inline" : inline || scroll},
            center,
            { toast },
            { 'toast-visible': toastVisible },
            { nowrap }
        )

        let chips = [];

        for (let i = 0; i < 20; i++) {
            chips.push(
                <div className={classNamesChip} key={i}>
                    {iconleft && !toast &&
                        <FontAwesomeIcon icon="user" className="chip-icon-left" />
                    }
                    {toast &&
                        <span className="fa fa-lightbulb-o chip-icon-left" />
                    }
                    <p className="chip-text">Lorem Ipsum Dolor</p>
                    {iconright &&
                        <FontAwesomeIcon icon="times-circle" className="chip-icon-right" />
                    }
                </div>
            )
        }

        return chips;
    }

    renderComponent = () => {
        const {
            color,
            outlined,
            iconleft,
            iconright,
            interactive,
            inline,
            center,
            toast,
            toastVisible,
            scroll,
            rightDirectionScroll,
            nowrap
        } = this.state;

        const classNamesChip = classnames(
            "chip-container",
            color,
            outlined,
            interactive,
            center,
            { inline },
            { toast },
            { 'toast-visible': toastVisible },
            { nowrap }
        )



        return (
            <>
                {!scroll &&
                    <div className={nowrap ? "col-md-6" : "col-md-12"}>
                        <div className={classNamesChip}>
                            {iconleft && !toast &&
                                <FontAwesomeIcon icon="user" className="chip-icon-left" />
                            }
                            {toast &&
                                <span className="fa fa-lightbulb-o chip-icon-left" />
                            }
                            <p className="chip-text" title={nowrap ? 'Lorem Ipsum DolorLorem Ipsum DolorLorem Ipsum DolorLorem Ipsum DolorLorem Ipsum DolorLorem Ipsum DolorLorem Ipsum Dolor' : ''}>Lorem Ipsum Dolor
                            {nowrap &&
                                <>
                                    Lorem Ipsum DolorLorem Ipsum DolorLorem Ipsum DolorLorem Ipsum DolorLorem Ipsum DolorLorem Ipsum DolorLorem Ipsum Dolor
                                </>
                            } </p>
                            {iconright &&
                                <FontAwesomeIcon icon="times-circle" className="chip-icon-right" />
                            }
                        </div>
                        {inline &&
                            <> 
                                <div className={classNamesChip}>
                                    {iconleft && !toast &&
                                        <FontAwesomeIcon icon="user" className="chip-icon-left" />
                                    }
                                    {toast &&
                                        <span className="fa fa-lightbulb-o chip-icon-left" />
                                    }
                                    <p className="chip-text">Lorem Ipsum Dolor</p>
                                    {iconright &&
                                        <FontAwesomeIcon icon="times-circle" className="chip-icon-right" />
                                    }
                                </div>
                                <div className={classNamesChip}>
                                    {iconleft && !toast &&
                                        <FontAwesomeIcon icon="user" className="chip-icon-left" />
                                    }
                                    {toast &&
                                        <span className="fa fa-lightbulb-o chip-icon-left" />
                                    }
                                    <p className="chip-text">Lorem Ipsum Dolor</p>
                                    {iconright &&
                                        <FontAwesomeIcon icon="times-circle" className="chip-icon-right" />
                                    }
                                </div>
                                <div className={classNamesChip}>
                                    {iconleft && !toast &&
                                        <FontAwesomeIcon icon="user" className="chip-icon-left" />
                                    }
                                    {toast &&
                                        <span className="fa fa-lightbulb-o chip-icon-left" />
                                    }
                                    <p className="chip-text">Lorem Ipsum Dolor</p>
                                    {iconright &&
                                        <FontAwesomeIcon icon="times-circle" className="chip-icon-right" />
                                    }
                                </div>
                            </>
                        }
                    </div>
                }
                {scroll &&
                    <div className="row">
                        <div className="col-md-12">
                            <div className="chip-scroll" id="chip-scroll">
                                {!rightDirectionScroll &&
                                    <div className="chip-container">
                                        <p className="chip-text">Lorem Ipsum Dolor</p>
                                    </div>
                                }
                                <div className={`chip-scroll-container ${rightDirectionScroll ? 'right-direction' : ''}`} id="chip-scroll-container">
                                    {this.renderChips()}
                                </div>
                                {rightDirectionScroll &&
                                    <div className="chip-container">
                                        <p className="chip-text">Lorem Ipsum Dolor</p>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                }
            </>
        );
    }

    renderOptionalClasses = () => {
        return (
            <React.Fragment>
                <SwitchOptionsGroup label="Cores" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="color" value="highlight" stateName="color" label="Highlight" />
                    <SwitchOptions attr="color" value="positive" stateName="color" label="Positive/Módulo" />
                    <SwitchOptions attr="color" value="negative" stateName="color" label="Negative" />
                    <SwitchOptions attr="color" value="warning" stateName="color" label="Warning" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Tipo" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="outlined" value="outlined" stateName="outlined" label="Só Bordas" />
                    <SwitchOptions attr="interactive" value="interactive" stateName="interactive" label="Interativo" />
                    <SwitchOptions attr="toast" value={true} stateName="toast" label="Estilo Toast" />
                    <SwitchOptions attr="center" value="center" stateName="center" label="Largura Máxima" />
                    <SwitchOptions attr="inline" value={true} stateName="inline" label="Lado a lado" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Ícones" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="iconleft" value="iconleft" stateName="iconleft" label="Ícone à esquerda" />
                    <SwitchOptions attr="iconright" value="iconright" stateName="iconright" label="Ícone à direita" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Variação" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="scroll" value={true} stateName="scroll" label="Com scroll" />
                    <SwitchOptions attr="scrollRightDirection" value={true} stateName="rightDirectionScroll" label="Com scroll alinhado a direita" />
                    <SwitchOptions attr="nowrap" value={true} stateName="nowrap" label="Limite no texto" />

                </SwitchOptionsGroup>
            </React.Fragment>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    files={files}
                    theme={theme}
                    mainTitle="Chip"
                    mainDescription="Chips são elementos compactos que representam uma entrada, atributo ou ação."
                >
                <HowToUse
                    title="Uso"
                    description="Os chips permitem aos usuários inserir informações, fazer seleções, filtrar conteúdo ou acionar ações. Eles devem aparecer dinamicamente como um grupo de vários elementos interativos. Os botões por outro lado devem ser uma chamada à ação consistente e familiar onde o usuário espera a mesma ação na mesma região da tela."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Ícone (Opcional) <br /> 3. Label <br /> 4. Ícone de Exclusão (Opcional)"
                    />
                </HowToUse>
                <HowToUse
                    title="Hierarquia"
                    subtitle="Compacto"
                    description="Chips são componentes compactos que representam informações discretas."
                />
                <HowToUse
                    subtitle="Relevante"
                    description="Chips devem ter um relacionamento claro e útil com o conteúdo ou tarefa que eles representam."
                />
                <HowToUse
                    subtitle="Focado"
                    description="Chips devem facilitar a conclusão das tarefas ou a classificação do conteúdo."
                />
                <HowToUse
                    title="Aplicação"
                    subtitle="Chips em input"
                    description="Os chips de entrada representam informações usadas em campos, como uma entidade ou atributos diferentes. <br /> Também representam uma informação complexa em formato compacto, como uma entidade (pessoa, local ou coisa) ou texto. Eles habilitam a entrada do usuário e verificam essa entrada convertendo texto em chips."
                >
                    <HowToUseFigure
                        srcImg={gif1}
                        captionImg="Chips em input"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Chips de filtro"
                    description="Os chips de filtro representam filtros para uma coleção e usam tags ou palavras descritivas para filtrar o conteúdo. Estes delineiam e exibem claramente as opções em uma área compacta. Eles são uma boa alternativa para toggles ou checkboxes."
                >
                    <HowToUseFigure
                        srcImg={gif2}
                        captionImg="Chips de filtro"
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    subtitle="Exclusão"
                    description="Em um chip, o seu ícone direito destina-se apenas para casos de remoção ou exclusão do mesmo."
                >
                    <HowToUseFigure
                        srcImg={dont1}
                        status="dont"
                        descriptionImg="Não use o ícone direito para outros propósitos"
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Chip Contido"
                    description="Um chip que possui background. Mais indicado para ser usado em grupos de chips."
                >
                    <HowToUseFigure
                        srcImg={chipcontido}
                        captionImg="Chip contido"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Chip Contornado"
                    description="Um chip que NÃO possui background. Mais indicado para ser usado sozinho (como um label de status), desde que o fundo não atrapalhe a leitura do conteúdo."
                >
                    <HowToUseFigure
                        srcImg={chipcontornado}
                        captionImg="Chip contornado"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}
function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Chip);
