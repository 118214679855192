import Keycloak from "keycloak-js";

let instance = undefined;

export const init = (config) => {
  if (instance === undefined) {
    instance = Keycloak(config);
    instance.onTokenExpired = () => console.debug("Token expired!");
    instance.onAuthRefreshSuccess = () => console.debug("Token refreshed!");
  }
  return instance.init({ onLoad: config.onLoad });
};

export const getInstance = () => instance;

export const getToken = () => instance.token;

export const refreshToken = (minValidity = 5, url = "", idpHint = "") => {
  return new Promise((resolve, reject) => {
    if (instance.authenticated) {
      instance
        .updateToken(minValidity)
        .then(() => resolve(true))
        .catch((error) => {
          console.error("Erro ao renovar token!", error);
          instance
            .login({ idpHint })
            .then(() => resolve(true))
            .catch((error) => reject(error));
        });
    } else {
      instance
        .login({ idpHint })
        .then(() => resolve())
        .catch((error) => reject(error));
    }
  });
};

/**
 * Executa o 'logout' da aplicação.
 * @param options Logout options.
 * @param options.redirectUri Especifica a URL de redirecionamento após executar o 'logout'.
 *
 * Exemplo de utilização:
 * ```
 * KeycloakService.logout({
 *   redirectUri: URL_PARA_REDIRECIONAMENTO
 * });
 * ```
 */
export const logout = (options) => instance.logout(options);
