import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';

import anatomia from '../../assets/img/components/datepicker/anatomia.png';
import datetime from '../../assets/img/components/datepicker/datetime.png';
import daterange from '../../assets/img/components/datepicker/daterange.png';
import datepickergif from '../../assets/img/components/datepicker/datapicker.gif';
import datetimegif from '../../assets/img/components/datepicker/datetime.gif';
import inputdaterange from '../../assets/img/components/datepicker/inputdaterange.png';
import rangegif from '../../assets/img/components/datepicker/rangegif.gif';

const months = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
];

const files = [
    { 'less': ['datepicker.less'] },
    { 'less': ['subtitles.less'] },
    { 'react': ['DatePicker.tsx'] }
];

class DatePicker extends Component {

    state = {
        initial: undefined,
        final: undefined,
        year: 2019,
        month: 7,
        openDate: false,
        openTime: false,
        timeHour: 0,
        timeMin: 0,
        date: 'normal',
        input: false,
        currentIndex: undefined,
        withSelect: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Data Picker');
        this.setState({ currentIndex: index });
    }

    toggleSelection = e => {
        console.log('toggleSelection');
        if (e.target.classList.contains('initial') || e.target.classList.contains('final') || e.target.classList.contains('interval')) {
            this.clearAll()
        }

        e.target.classList.toggle('selected');

        if (!e.target.classList.contains('selected')) {
            e.target.classList.remove('initial');
            e.target.classList.remove('final');
            e.target.classList.remove('interval');
            e.target.classList.remove('dragging');
        }
    }

    onDragStart = e => {
        console.log('onDragStart');

        this.clearAll();
        if (!e.target.classList.contains('initial') && !e.target.classList.contains('selected')) {
            e.target.classList.add('initial');
            e.target.classList.add('selected');
            this.setState({ 'initial': parseInt(e.target.textContent) })
        }
    }

    onDrop = e => {
        const { initial } = this.state;

        if (!e.target.classList.contains('initial') && !e.target.classList.contains('blocked') && initial < parseInt(e.target.textContent)) {
            e.target.classList.add('selected');
            e.target.classList.remove('interval');
            e.target.classList.remove('dragging');
            e.target.classList.add('final');
        } else {
            this.clearAll();
        }
    }

    onDragEnter = e => {
        console.log('onDragEnter');

        e.target.classList.add('dragging');
        e.target.classList.remove('interval')

        this.checkItems(e);
    }

    onDragLeave = e => {
        e.stopPropagation();

        console.log('onDragLeave');
        e.target.classList.remove('dragging');
    }

    onDragOver = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    handleHour = s => {
        this.setState(state => {
            if (s === "+" && state.timeHour < 23) {
                return {
                    timeHour: state.timeHour + 1
                }
            } else if (s === "-" && state.timeHour > 0) {
                return {
                    timeHour: state.timeHour - 1
                }
            } else if (s === "+" && state.timeHour >= 23) {
                return {
                    timeHour: 0
                }
            } else if (s === "-" && state.timeHour >= 0) {
                return {
                    timeHour: 23
                }
            }
        });
    }

    handleMin = s => {
        this.setState(state => {
            if (s === "+" && state.timeMin < 55) {
                return {
                    timeMin: state.timeMin + 5
                }
            } else if (s === "-" && state.timeMin > 0) {
                return {
                    timeMin: state.timeMin - 5
                }
            } else if (s === "+" && state.timeMin >= 55) {
                return {
                    timeMin: 0
                }
            } else if (s === "-" && state.timeMin >= 0) {
                return {
                    timeMin: 55
                }
            }
        });
    }

    openTime = () => {
        this.setState(state => {
            return {
                openTime: !state.openTime
            }
        })
    }

    checkItems = e => {
        const { initial } = this.state;
        let items = document.querySelectorAll('.datepicker-calendar > .item');


        items.forEach((el, i) => {
            if (!el.classList.contains('initial') && !el.classList.contains('blocked') && !el.classList.contains('negative')) {
                if (parseInt(el.textContent) > initial && parseInt(el.textContent) < parseInt(e.target.textContent)) {
                    items[i].classList.add('selected');
                    items[i].classList.add('interval');
                } else {
                    items[i].classList.remove('selected');
                    items[i].classList.remove('interval');
                }
            }

        })
    }

    clearAll = () => {
        document.querySelectorAll('.datepicker-calendar > .item').forEach(el => {
            el.classList.remove('selected');
            el.classList.remove('dragging');
            el.classList.remove('interval');
            el.classList.remove('initial');
            el.classList.remove('final');
        })
    }

    nextYear = () => {
        this.setState(state => {
            return {
                'year': state.year + 1
            }
        })
    }

    prevYear = () => {
        this.setState(state => {
            return {
                'year': state.year - 1
            }
        })
    }

    nextMonth = () => {
        this.setState(state => {
            if (state.month < 11)
                return {
                    'month': state.month + 1
                }
        })
    }

    prevMonth = () => {
        this.setState(state => {
            if (state.month > 0)
                return {
                    'month': state.month - 1
                }
        })
    }

    listMonthOptions = () => {
        let mappedMonths = [];
        months.map((e, index) => {
            if(index === this.state.month){
                mappedMonths.push(<option selected>{e}</option>);
            } else {
                mappedMonths.push(<option>{e}</option>);
            }
            return e
        })
        return mappedMonths;
    }

    listYearOptions = () => {
        let mappedYears = [];
        let Years = [];

        for (let i = 1990; i <= 2040; i++) {
            Years.push(i);
        }

        Years.map(e => {
            if(e === this.state.year){
                mappedYears.push(<option selected>{e}</option>);
            } else {
                mappedYears.push(<option>{e}</option>);
            }
            return e
        })
        return mappedYears;
    }

    renderClasses = () => {
        return (
            <>
                <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="date" value="normal" stateName="date" label="Somente data" />
                    <SwitchOptions attr="date" value="datetime" stateName="date" label="Data e hora" />
                    <SwitchOptions attr="date" value="range" stateName="date" label="Intervalo entre datas" />
                    <SwitchOptions attr="date" value="monthyear" stateName="date" label="Mês e Ano" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Variação" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="input" value={true} stateName="input" label="Com Input" />
                    <SwitchOptions attr="withSelect" value={true} stateName="withSelect" label="Com Select" />
                </SwitchOptionsGroup>
            </>
        )
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderCode = () => {
        const { month, year, date, openTime, timeMin, timeHour, input, withSelect } = this.state;
        return (
            <>
                {date === 'normal' &&
                    <div className={`${input ? 'col-md-3' : 'col-md-4'} mb-xs`} style={{ height: input ? '400px' : '' }}>
                        {input &&
                            <>
                                <input id="field" className="datepicker-opener" type="text" placeholder="00/00/0000" />
                                <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                            </>
                        }
                        <div className={`datepicker open ${!input ? 'visible' : ''}`}>
                            <div className="datepicker-header">
                                <div className="datepicker-header-year">
                                    <em className="fa fa-chevron-left" onClick={this.prevYear}></em>
                                    {!withSelect &&
                                        <b className="year-number">{year}</b>
                                    }
                                    {withSelect &&
                                        <select type className="year-number">
                                            {this.listYearOptions()}
                                        </select>
                                    }
                                    <em className="fa fa-chevron-right" onClick={this.nextYear}></em>
                                </div>
                                <div className="datepicker-header-month">
                                    <em className="fa fa-chevron-left" onClick={this.prevMonth}></em>
                                    {!withSelect &&
                                        <b className="month-name">{months[month]}</b>
                                    }
                                    {withSelect &&
                                        <select type className="month-name">
                                            {this.listMonthOptions()}
                                        </select>
                                    }
                                    <em className="fa fa-chevron-right" onClick={this.nextMonth}></em>
                                </div>
                            </div>
                            <ul className="datepicker-calendar open">
                                <ul className="datepicker-calendar-header">
                                    <li className="item">Dom</li>
                                    <li className="item">Seg</li>
                                    <li className="item">Ter</li>
                                    <li className="item">Qua</li>
                                    <li className="item">Qui</li>
                                    <li className="item">Sex</li>
                                    <li className="item">Sab</li>
                                </ul>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>30</li>
                                <li className="item neutral" onDrop={this.onDrop} onDragOver={this.onDragOver}>1</li>
                                <li className="item neutral" onDrop={this.onDrop} onDragOver={this.onDragOver}>2</li>
                                <li className="item negative" onDrop={this.onDrop} onDragOver={this.onDragOver}>3</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >4</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >5</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >6</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >7</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >8</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >9</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >10</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >11</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >12</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >13</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >14</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >15</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >16</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >17</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >18</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >19</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >20</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >21</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >22</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >23</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >24</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >25</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >26</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >27</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >28</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >29</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >30</li>
                                <li className="item" draggable="true" onClick={this.toggleSelection} onDragEnter={this.onDragEnter} onDragLeave={this.onDragLeave} onDrop={this.onDrop} onDragOver={this.onDragOver} onDragStart={this.onDragStart} >31</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>1</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>2</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>3</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>4</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>5</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>6</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>7</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>8</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>9</li>
                                <li className="item not-this-month blocked" onDrop={this.onDrop} onDragOver={this.onDragOver}>10</li>
                            </ul>
                            <div className="subtitles">
                                <div className="subtitle large">Disponível</div>
                                <div className="subtitle large selected">Marcado</div>
                                <div className="subtitle large neutral">Fechado</div>
                                <div className="subtitle large negative">Integrado</div>
                            </div>
                        </div>
                    </div>
                }

                {date === 'datetime' &&
                    <div className={`${input ? 'col-md-3' : 'col-md-4'} mb-xs`} style={{ height: input ? '400px' : '' }}>
                        {input &&
                            <>
                                <input id="field" className="datepicker-opener" type="text" placeholder="00/00/0000 00:00" />
                                <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                            </>
                        }
                        <div className={`datetimepicker open ${!input ? 'visible' : ''}`}>
                            <div className={`datepicker ${!openTime ? 'visible' : ''}`}>
                                <div className="datepicker-header">
                                    <div className="datepicker-header-year">
                                        <em className="fa fa-chevron-left" onClick={this.prevYear}></em>
                                        {!withSelect &&
                                            <b className="year-number">{year}</b>
                                        }
                                        {withSelect &&
                                            <select type className="year-number">
                                                {this.listYearOptions()}
                                            </select>
                                        }
                                        <em className="fa fa-chevron-right" onClick={this.nextYear}></em>
                                    </div>

                                    <div className="datepicker-header-month">
                                        <em className="fa fa-chevron-left" onClick={this.prevMonth}></em>
                                        {!withSelect &&
                                            <b className="month-name">{months[month]}</b>
                                        }
                                        {withSelect &&
                                            <select type className="month-name">
                                                {this.listMonthOptions()}
                                            </select>
                                        }
                                        <em className="fa fa-chevron-right" onClick={this.nextMonth}></em>
                                    </div>

                                    <div className="timepicker-button" onClick={this.openTime}>
                                        <em className="fa fa-clock-o"></em>
                                    </div>
                                </div>
                                <div className="datepicker-calendar">
                                    <div className="datepicker-calendar-header">
                                        <li className="item">Dom</li>
                                        <li className="item">Seg</li>
                                        <li className="item">Ter</li>
                                        <li className="item">Qua</li>
                                        <li className="item">Qui</li>
                                        <li className="item">Sex</li>
                                        <li className="item">Sab</li>
                                    </div>
                                    <li className="item not-this-month">25</li><li className="item not-this-month">26</li><li className="item not-this-month">27</li><li className="item not-this-month">28</li><li className="item not-this-month">29</li><li className="item not-this-month">30</li><li className="item">1</li>
                                    <li className="item">2</li><li className="item">3</li><li className="item">4</li><li className="item">5</li><li className="item">6</li><li className="item">7</li><li className="item">8</li>
                                    <li className="item">9</li><li className="item">10</li><li className="item">11</li><li className="item">12</li><li className="item">13</li><li className="item">14</li><li className="item">15</li>
                                    <li className="item">16</li><li className="item">17</li><li className="item">18</li><li className="item">19</li><li className="item">20</li><li className="item">21</li><li className="item">22</li>
                                    <li className="item">23</li><li className="item">24</li><li className="item">25</li><li className="item">26</li><li className="item">27</li><li className="item">28</li><li className="item">29</li>
                                    <li className="item">30</li><li className="item">31</li><li className="item not-this-month">1</li><li className="item not-this-month">2</li><li className="item not-this-month">3</li><li className="item not-this-month">4</li><li className="item not-this-month">5</li>
                                </div>
                            </div>
                            <div className={`timepicker ${openTime ? 'visible' : ''}`}>
                                <div className="timepicker-header">
                                    <div className="datepicker-button" onClick={this.openTime}>
                                        <em className="fa fa-calendar"></em>
                                    </div>
                                    <div className="timepicker-header-hour">
                                        <em className="fa fa-chevron-up" onClick={() => this.handleHour("+")}></em>
                                        <input type="number" className="hour" defaultValue="20" value={timeHour} onChange={this.handleHourChange} min="00" max="23" />
                                        <em className="fa fa-chevron-down" onClick={() => this.handleHour("-")}></em>
                                    </div>
                                    :
                                            <div className="timepicker-header-minute">
                                        <em className="fa fa-chevron-up" onClick={() => this.handleMin("+")}></em>
                                        <input type="number" className="minute" defaultValue="20" value={timeMin} onChange={this.handleMinChange} min="00" max="59" />
                                        <em className="fa fa-chevron-down" onClick={() => this.handleMin("-")}></em>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                }

                {date === 'range' &&
                    <div className={`${input ? 'col-md-3' : 'col-md-8'} mb-xs`} style={{ height: input ? '400px' : '' }}>
                        {input &&
                            <>
                                <input id="field" className="datepicker-opener" type="text" placeholder="00/00/0000 - 00/00/0000" />
                                <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                            </>
                        }
                        <div className={`daterangepicker open ${!input ? 'visible' : ''}`}>
                            <div className="daterangepicker-selector">
                                <select id="field" className="undefined false">
                                    <option value="option_1">Customizado</option>
                                    <option value="option_1">Esta semana</option>
                                    <option value="option_2">Este mês</option>
                                    <option value="option_2">Este mês, até agora</option>
                                    <option value="option_3">Mês anterior</option>
                                    <option value="option_3">Esse trimestre</option>
                                    <option value="option_3">Esse trimestre, até agora</option>
                                    <option value="option_3">Trimestre Anterior</option>
                                    <option value="option_4">Este Ano</option>
                                    <option value="option_4">Este Ano, até agora</option>
                                    <option value="option_4">Ano Anterior</option>
                                    <option value="option_4">Últimos 30 dias</option>
                                    <option value="option_4">Últimos 28 dias</option>
                                    <option value="option_4">Últimos 14 dias</option>
                                    <option value="option_4">Últimos 7 dias</option>
                                </select>
                            </div>
                            <div className="daterangepicker-header">
                                <b className="daterangepicker-title">DATA INICIAL</b>
                                <b className="daterangepicker-title">DATA FINAL</b>
                            </div>
                            <div className="datepicker-container">
                                <div className="datepicker visible">
                                    <div className="datepicker-header">
                                        <div className="datepicker-header-year">
                                            <em className="fa fa-chevron-left" onClick={this.prevYear}></em>
                                            {!withSelect &&
                                               <b className="year-number">{year}</b>
                                            }
                                            {withSelect &&
                                                <select type className="year-number">
                                                    {this.listYearOptions()}
                                                </select>
                                            }
                                            <em className="fa fa-chevron-right" onClick={this.nextYear}></em>
                                        </div>
                                        <div className="datepicker-header-month">
                                            <em className="fa fa-chevron-left" onClick={this.prevMonth}></em>
                                            {!withSelect &&
                                               <b className="month-name">{months[month]}</b>
                                            }
                                            {withSelect &&
                                                <select type className="month-name">
                                                    {this.listMonthOptions()}
                                                </select>
                                            }
                                            <em className="fa fa-chevron-right" onClick={this.nextMonth}></em>
                                        </div>
                                    </div>
                                    <div className="datepicker-calendar">
                                        <div className="datepicker-calendar-header">
                                            <li className="item">Dom</li>
                                            <li className="item">Seg</li>
                                            <li className="item">Ter</li>
                                            <li className="item">Qua</li>
                                            <li className="item">Qui</li>
                                            <li className="item">Sex</li>
                                            <li className="item">Sab</li>
                                        </div>
                                        <li className="item not-this-month">25</li><li className="item not-this-month">26</li><li className="item not-this-month">27</li><li className="item not-this-month">28</li><li className="item not-this-month">29</li><li className="item not-this-month">30</li><li className="item">1</li>
                                        <li className="item">2</li><li className="item">3</li><li className="item">4</li><li className="item">5</li><li className="item">6</li><li className="item">7</li><li className="item">8</li>
                                        <li className="item">9</li><li className="item">10</li><li className="item">11</li><li className="item">12</li><li className="item">13</li><li className="item">14</li><li className="item">15</li>
                                        <li className="item">16</li><li className="item">17</li><li className="item">18</li><li className="item">19</li><li className="item">20</li><li className="item">21</li><li className="item">22</li>
                                        <li className="item">23</li><li className="item">24</li><li className="item">25</li><li className="item">26</li><li className="item">27</li><li className="item">28</li><li className="item">29</li>
                                        <li className="item">30</li><li className="item">31</li><li className="item not-this-month"></li><li className="item not-this-month"></li><li className="item not-this-month"></li><li className="item not-this-month"></li><li className="item not-this-month"></li>
                                    </div>
                                </div>
                                <div className="datepicker visible">
                                    <div className="datepicker-header">
                                        <div className="datepicker-header-year">
                                            <em className="fa fa-chevron-left" onClick={this.prevYear}></em>
                                            {!withSelect &&
                                                <b className="year-number">{year}</b>
                                            }
                                            {withSelect &&
                                                <select type className="year-number">
                                                    {this.listYearOptions()}
                                                </select>
                                            }
                                            <em className="fa fa-chevron-right" onClick={this.nextYear}></em>
                                        </div>
                                        <div className="datepicker-header-month">
                                            <em className="fa fa-chevron-left" onClick={this.prevMonth}></em>
                                            {!withSelect &&
                                                <b className="month-name">{months[month + 1]}</b>
                                            }
                                            {withSelect &&
                                                <select type className="month-name">
                                                    {this.listMonthOptions()}
                                                </select>
                                            }
                                            <em className="fa fa-chevron-right" onClick={this.nextMonth}></em>
                                        </div>
                                    </div>
                                    <div className="datepicker-calendar">
                                        <div className="datepicker-calendar-header">
                                            <li className="item">Dom</li>
                                            <li className="item">Seg</li>
                                            <li className="item">Ter</li>
                                            <li className="item">Qua</li>
                                            <li className="item">Qui</li>
                                            <li className="item">Sex</li>
                                            <li className="item">Sab</li>
                                        </div>
                                        <li className="item not-this-month"></li><li className="item not-this-month"></li><li className="item ">1</li><li className="item ">2</li><li className="item ">3</li><li className="item ">4</li><li className="item">5</li>
                                        <li className="item">6</li><li className="item">7</li><li className="item">8</li><li className="item">9</li><li className="item">10</li><li className="item">11</li><li className="item">12</li>
                                        <li className="item">13</li><li className="item">14</li><li className="item">15</li><li className="item">16</li><li className="item">17</li><li className="item">18</li><li className="item">19</li>
                                        <li className="item">20</li><li className="item">21</li><li className="item">22</li><li className="item">23</li><li className="item">24</li><li className="item">25</li><li className="item">26</li>
                                        <li className="item">27</li><li className="item">28</li><li className="item">29</li><li className="item">30</li><li className="item not-this-month">1</li><li className="item not-this-month">2</li><li className="item not-this-month">3</li>
                                    </div>
                                </div>
                            </div>
                            <div className="daterangepicker-footer">
                                <button className="btn neutral">LIMPAR</button>
                                <button className="btn module-color" onClick={this.openDateTime}>APLICAR</button>
                            </div>
                        </div>
                    </div>
                }
                {date === 'monthyear' &&
                    <div className={`${input ? 'col-md-3' : 'col-md-4'} mb-xs`} style={{ height: input ? '200px' : '' }}>
                        {input &&
                            <>
                                <input id="field" className="datepicker-opener" type="text" placeholder="00/0000" />
                                <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                            </>
                        }
                        <div className={`datemonthyearpicker open ${!input ? 'visible' : ''}`}>
                                <div className="datepicker-header">
                                    <div className="datepicker-header-year">
                                        <em className="fa fa-chevron-left" onClick={this.prevYear}></em>
                                        {!withSelect &&
                                            <b className="year-number">{year}</b>
                                        }
                                        {withSelect &&
                                            <select type className="year-number">
                                                {this.listYearOptions()}
                                            </select>
                                        }
                                        <em className="fa fa-chevron-right" onClick={this.nextYear}></em>
                                    </div>
                                    <div className="datepicker-header-month">
                                        <em className="fa fa-chevron-left" onClick={this.prevMonth}></em>
                                            {!withSelect &&
                                               <b className="month-name">{months[month]}</b>
                                            }
                                            {withSelect &&
                                                <select type className="month-name">
                                                    {this.listMonthOptions()}
                                                </select>
                                            }
                                        <em className="fa fa-chevron-right" onClick={this.nextMonth}></em>
                                    </div>
                                </div>
                        </div>
                    </div>
                }

            </>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderCode}
                    optionalClasses={this.renderClasses}
                    theme={theme}
                    mainTitle="Date Picker"
                    mainDescription="Date Picker permite que o usuário selecione uma data, ou um intervalos entre datas."
                >
                <HowToUse
                    title="Uso"
                    description="Date picker permitem que o usuário selecione uma data, ou um intervalo entre datas. Deve ser adequado conforme o contexto em que irá aparecer.<br /> Date pickers podem ser adicionados em: <br />• Caixas de Diálogo <br />• Campos de textos dropdown"
                />
                <HowToUse
                    title="Anatomia"
                    subtitle="Date picker"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Data filtrada para seleção <br /> 3. Mês filtrado para seleção <br /> 4. Dia disabilitado para seleção (opcional) <br /> 5. Dia disabilitado para seleção (opcional) <br /> 6. Dia selecionado <br /> 7. Seleção de hora (opcional)"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Date time"
                >
                    <HowToUseFigure
                        srcImg={datetime}
                        descriptionImg="1. Container <br /> 2. Seleção de data (opcional) <br /> 3. Seleção de hora <br /> 4. Seleção de minutos"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Date range"
                >
                    <HowToUseFigure
                        srcImg={daterange}
                        descriptionImg="1. Container <br /> 2. Filtros pré definidos <br /> 3. Intervalo selecionado <br /> 4. Botões de seleção"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicação"
                    description="Normalmente date picker, range ou time, são chamados através de inputs, porém a casos em que o date picker permanece sempre visível possibilitando a seleção."
                >
                    <HowToUseFigure
                        srcImg={datepickergif}
                        captionImg="Data"
                        descriptionImg="Campos do tipo data permitem a digitação e a seleção através de um calendário, facilitando assim a usabilidade."
                    />
                    <HowToUseFigure
                        srcImg={datetimegif}
                        captionImg="Data e Hora"
                        descriptionImg="Campos do tipo datetime permitem a digitação e/ou a seleção da data e hora."
                    />
                    <HowToUseFigure
                        srcImg={inputdaterange}
                        captionImg="Data com intervalo"
                        descriptionImg="Campos de data com intervalo permitem a digitação e seleção entre intervalos e possui filtros já definidos."
                    />
                    <HowToUseFigure
                        srcImg={rangegif}
                        captionImg="Data com Intervalo sem input"
                        descriptionImg="Campo de data com intervalo sendo utilizado para filtrar entre os dias selecionados de um determinado mês, sem a utilização de um input. <br /> Neste exemplo também há a integração com os subtitles."
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(DatePicker);
