import React, { Component } from 'react';
import ReactDOMServer from 'react-dom/server';
import SyntaxHighlighter from 'react-syntax-highlighter';
import Pretty from 'pretty';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const customStyles = {
    "hljs": {
        "display": "block",
    },
    "hljs-strong": {
        "color": "#3c3c3b"
    },
    "hljs-emphasis": {
        "color": "#3c3c3b",
        "fontStyle": "italic"
    },
    "hljs-bullet": {
        "color": "#00AFCB"
    },
    "hljs-quote": {
        "color": "#00AFCB"
    },
    "hljs-link": {
        "color": "#00AFCB"
    },
    "hljs-number": {
        "color": "#00AFCB"
    },
    "hljs-regexp": {
        "color": "#00AFCB"
    },
    "hljs-literal": {
        "color": "#00AFCB"
    },
    "hljs-code": {
        "color": "#a6e22e"
    },
    "hljs-selector-class": {
        "color": "#a6e22e"
    },
    "hljs-keyword": {
        "color": "#970300"
    },
    "hljs-selector-tag": {
        "color": "#970300"
    },
    "hljs-section": {
        "color": "#970300"
    },
    "hljs-attribute": {
        "color": "#970300"
    },
    "hljs-name": {
        "color": "#970300"
    },
    "hljs-variable": {
        "color": "#970300"
    },
    "hljs-params": {
        "color": "#b9b9b9"
    },
    "hljs-string": {
        "color": "#970300"
    },
    "hljs-subst": {
        "color": "#e0c46c"
    },
    "hljs-type": {
        "color": "#e0c46c"
    },
    "hljs-built_in": {
        "color": "#e0c46c"
    },
    "hljs-builtin-name": {
        "color": "#e0c46c"
    },
    "hljs-symbol": {
        "color": "#e0c46c"
    },
    "hljs-selector-id": {
        "color": "#e0c46c"
    },
    "hljs-selector-attr": {
        "color": "#e0c46c"
    },
    "hljs-selector-pseudo": {
        "color": "#e0c46c"
    },
    "hljs-template-tag": {
        "color": "#e0c46c"
    },
    "hljs-template-variable": {
        "color": "#e0c46c"
    },
    "hljs-addition": {
        "color": "#e0c46c"
    },
    "hljs-comment": {
        "color": "#3c3c3b"
    },
    "hljs-deletion": {
        "color": "#3c3c3b"
    },
    "hljs-meta": {
        "color": "#3c3c3b"
    }
}

class Coder extends Component {

    state = {
        copied: false
    }

    copyHtml = () => {
        const copyText = this.codeTag.textContent;
        const textArea = document.createElement('textarea');
        textArea.textContent = copyText;
        document.body.append(textArea);
        textArea.select();
        document.execCommand("copy");
        textArea.remove();
        this.setState({
            copied: true,
        }, () => {
            setTimeout(() => {
                this.setState({
                    copied: false
                })
            }, 1000)
        })

    }

    render() {

        const { codeToRender } = this.props;

        return (
            <React.Fragment>
                <div className="btn-save">
                    <button className="btn hint clean md" type="button" onClick={this.copyHtml}>
                        <FontAwesomeIcon icon={`${!this.state.copied ? 'copy' : 'check'}`} />
                        <div className="hint-content">Copiar Código </div>
                    </button>
                    <button className={`btn hint fa ${!this.state.copied ? 'fa-copy' : 'fa-check'}`} type="button" onClick={this.copyHtml}>
                        <div className="hint-content">Copiar Código </div>
                    </button>
                </div>

                <div className="code active" ref={(code) => this.codeTag = code}>
                    <SyntaxHighlighter language="html" wrapLines={true} style={customStyles}>
                        {` ${Pretty(ReactDOMServer.renderToStaticMarkup(codeToRender()), { 'extra_liners': ['button', 'em'], unformatted: ['code', 'pre'] })} `}
                    </SyntaxHighlighter>
                </div>
            </React.Fragment>
        )

    }

}

Coder.propTypes = {
    codeToRender: PropTypes.func.isRequired
}

export default Coder;
