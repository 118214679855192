import React, { Component } from 'react';
import slugify from 'react-slugify';
import { connect } from 'react-redux';
import BoxContainerTemplate from './../componentes/Templates/BoxContainerTemplate';
import BoxItemTemplate from '../componentes/Templates/BoxItemTemplate';

class Components extends Component {

    componentDidMount() {
        window.scrollTo(0,0);
    }

    componentDidUpdate() {
        window.scrollTo(0,0);
    }

    render() {
        const {components, theme} = this.props;

        return (
            <>
                <article>
                    <p className="main-description">Veja nossos componentes</p>
                    <BoxContainerTemplate>
                        {components.map(component => {
                            return (
                                <BoxItemTemplate
                                    key={slugify(component.name)}
                                    link={`/componentes/${slugify(component.name)}${theme === 'dark' ? '#codigo' : ''}`}
                                    name={component.name}
                                    svg={component.svg ? component.svg : undefined}
                                    newComponent={component.new}
                                />
                            )
                        })}
                    </BoxContainerTemplate>
                </article>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Components);
