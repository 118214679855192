import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';

import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import anatomia1 from '../../assets/img/components/color-selector/anatomia1.png';
import anatomia2 from '../../assets/img/components/color-selector/anatomia2.png';
import padrao from '../../assets/img/components/color-selector/padrao.png';
import gif from '../../assets/img/components/color-selector/gif.gif';

const files = [{ 'less': ['colorpicker.less'] }, { 'js': ['colopicker.js'] }];


class ColorPicker extends Component {

    componentDidMount() {
        window.scrollTo(0, 0);
        this.initColors();
        const index = ListMenu.findIndex(item => item.name === 'Seletor de Cores');
        this.setState({ currentIndex: index });
    }

    componentDidUpdate() {
        this.initColors();
    }

    initColors = () => {
        const { theme } = this.props;
        const { type } = this.state;

        if (theme === 'dark' && type === 'custom') {
            //MOSTRA MODAL DE CORES
            var colorpicker = document.querySelector('.color-picker.custom');
            if (!colorpicker) {
                return
            }
            colorpicker.addEventListener('mouseenter', () => {
                colorpicker.classList.add('on');
            })

            colorpicker.addEventListener('touchstart', () => {
                colorpicker.classList.add('on');
            })

            //ESCONDE MODAL DE CORES
            colorpicker.addEventListener('mouseleave', () => {
                colorpicker.classList.remove('on');
            })

            //ATRIBUI AS CORES DO HTML DATA-COLOR PARA O CSS
            var items = document.querySelectorAll('.color-picker.custom .cp-item');
            items.forEach(item => {
                var color = item.getAttribute('data-color');
                var description = item.getAttribute('data-description');
                item.style.background = color;
                item.addEventListener('click', () => {
                    removeAll(function () {
                        item.classList.add('on');
                        var main = document.querySelector('.color-picker.custom .main-color');
                        main.classList.add('selected');

                        main.classList.add('on');
                        main.style.background = color;
                        main.style.borderColor = color;

                        document.querySelector('.color-picker.custom .colors').style.borderColor = color;
                        document.querySelector('.color-picker.custom .main-color span').textContent = description;
                        document.querySelector('.color-picker.custom .main-color i').textContent = color;
                        document.querySelector('.color-picker.custom .main-color .arrow').style.borderColor = 'transparent transparent transparent' + color;

                        colorpicker.classList.remove('on');
                    })
                })
            })

            function removeAll(callback) {
                items.forEach(item => {
                    item.classList.remove('on');
                })
                callback();
            }
        }
    }

    state = {
        type: 'custom',
        onHover: false,
        currentIndex: undefined
    }

    toggleHover = () => {
        this.setState(state => {
            return {
                onHover: !state.onHover
            }
        })
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderComponent = () => {
        const { type, onHover } = this.state;

        return (
            <div className={`color-picker ${type !== 'normal' ? type : ''} ${onHover ? 'on' : ''}`} onMouseEnter={this.toggleHover} onMouseLeave={this.toggleHover}>
                {type === 'normal' && (
                    <React.Fragment>
                        <div className="cp-item" data-color="black">
                        </div>
                        <div className="cp-item" data-color="navy">
                        </div>
                        <div className="cp-item active" data-color="blue">
                        </div>
                        <div className="cp-item" data-color="mediumturquoise">
                        </div>
                        <div className="cp-item" data-color="orange">
                        </div>
                        <div className="cp-item" data-color="red">
                        </div>
                        <div className="cp-item" data-color="firebrick">
                        </div>
                        <div className="cp-item" data-color="coral">
                        </div>
                        <div className="cp-item" data-color="brown">
                        </div>
                        <div className="cp-item" data-color="deeppink">
                        </div>
                        <div className="cp-item" data-color="indigo">
                        </div>
                        <div className="cp-item" data-color="mediumseagreen">
                        </div>
                        <div className="cp-item" data-color="green">
                        </div>
                        <div className="cp-item" data-color="darkcyan">
                        </div>
                        <div className="cp-item" data-color="darkslategrey">
                        </div>
                        <div className="cp-item" data-color="royalblue">
                        </div>
                        <div className="cp-item" data-color="dimgray">
                        </div>
                        <div className="cp-item" data-color="mediumvioletred">
                        </div>
                        <div className="cp-item" data-color="teal">
                        </div>
                        <div className="cp-item" data-color="olivedrab">
                        </div>
                    </React.Fragment>
                )}
                {type === 'custom' && (
                    <React.Fragment>
                        <div className="main-color">
                            <span>vermelho</span>
                            <i>#a5002f</i>
                            <div className="arrow"></div>
                        </div>
                        <div className="colors">
                            <div className="cp-item" data-color="#a5002f" data-description="vermelho" ></div>
                            <div className="cp-item" data-color="#a80094" data-description="violeta" ></div>
                            <div className="cp-item" data-color="#6300aa" data-description="roxo" ></div>
                            <div className="cp-item" data-color="#007bac" data-description="azul" ></div>
                            <div className="cp-item" data-color="#00aa55" data-description="verde" ></div>
                            <div className="cp-item" data-color="#a8a300" data-description="amarelo" ></div>
                            <div className="cp-item" data-color="#a52f00" data-description="abóbora" ></div>
                            <div className="cp-item" data-color="#00a39f" data-description="ciano" ></div>
                            <div className="cp-item" data-color="#9e5200" data-description="marron" ></div>
                        </div>
                    </React.Fragment>
                )}
            </div>
        )
    }

    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="type" value="normal" stateName="type" label="Normal" />
                <SwitchOptions attr="type" value="custom" stateName="type" label="Reduzido" />
            </SwitchOptionsGroup>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Seleção de Cores"
                    mainDescription="Permite a seleção de uma cor dentre múltiplas."
                >
                <HowToUse
                    title="Uso"
                    description="Componente específico para seleção de cores, sendo utilizado sempre que for necessário customização de cores."
                />
                <HowToUse
                    title="Anatomia"
                    subtitle="Padrão"
                >
                    <HowToUseFigure
                        srcImg={anatomia1}
                        descriptionImg="1. Container <br /> 2. Cores para seleção <br /> 3. Cor selecionada"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Reduzido"
                >
                    <HowToUseFigure
                        srcImg={anatomia2}
                        descriptionImg="1. Container <br /> 2. Cores para seleção <br /> 3. Cor selecionada, nome e hexadecimal"
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Padrão"
                    description="Disponibiliza todas as cores possíveis de seleção."
                >
                    <HowToUseFigure
                        srcImg={padrao}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Reduzido"
                    description="Disponibiliza as opções possíveis de seleção ao passar o mouse, e mostra qual opção selecionada junto com seu nome e hexadecimal."
                >
                    <HowToUseFigure
                        srcImg={gif}
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code

                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(ColorPicker);
