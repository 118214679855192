import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import FilesTemplate from '../Templates/FilesTemplate';
import BoxContainerTemplate from '../Templates/BoxContainerTemplate';
import classnames from 'classnames';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import { connect } from 'react-redux';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';

import anatomia from '../../assets/img/components/tabela/anatomia.png';
import iterativos from '../../assets/img/components/tabela/iterativos.png';
import textos from '../../assets/img/components/tabela/textos.png';
import ordenacao from '../../assets/img/components/tabela/ordenacao.png';
import botoes from '../../assets/img/components/tabela/botoes.png';
import focus from '../../assets/img/components/tabela/focus.png';
import paginacao from '../../assets/img/components/tabela/paginacao.png';
import do1 from '../../assets/img/components/tabela/do1.png';
import do2 from '../../assets/img/components/tabela/do2.png';
import do3 from '../../assets/img/components/tabela/do3.png';
import do4 from '../../assets/img/components/tabela/do4.png';
import caution1 from '../../assets/img/components/tabela/caution1.png';
import caution2 from '../../assets/img/components/tabela/caution2.png';
import caution3 from '../../assets/img/components/tabela/caution3.png';
import caution4 from '../../assets/img/components/tabela/caution4.png';
import dont1 from '../../assets/img/components/tabela/dont1.png';
import dont2 from '../../assets/img/components/tabela/dont2.png';
import dont3 from '../../assets/img/components/tabela/dont3.png';
import dont4 from '../../assets/img/components/tabela/dont4.png';
import dont5 from '../../assets/img/components/tabela/dont5.png';
import dont6 from '../../assets/img/components/tabela/dont6.png';
import tipo1 from '../../assets/img/components/tabela/tipo1.png';
import tipo2 from '../../assets/img/components/tabela/tipo2.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const files = [{ 'less': ['tables.less'] }, { 'js': ['table-horizontal-scroll.js', 'float-list-actions.js'] }, { 'angular': ['elo-data-table.module.ts'] }, { 'react': ['Table.tsx'] }];

class Tables extends Component {

    editableInput = React.createRef();

    state = {
        sortable: false,
        desc: false,
        asc: false,
        columnSelector: false,
        textAlign: '',
        showColumnSelectorOptions: false,
        buttons: false,
        trType: '',
        border: false,
        inverted: false,
        striped: false,
        status: false,
        fixed: false,
        footer: false,
        checkbox: false,
        radio: false,
        searchFilters: false,
        hasQuickView: false,
        hasQuickViewNew: false,
        expanded: false,
        headerButton: false,
        scrollx: false,
        extraInfo: false,
        // footerWithTransparency: false,
        subtitles: false,
        editable: false,
        editableAuto: false,
        editing: false,
        onEdit: false,
        level: false,
        highlight: undefined,
        changed: undefined,
        currentIndex: undefined,
        footerAlternative: false,
        placeholderLoader: false,
        fancyTotals: false,
        tableDiv: false,
        manyHeaderButtons: false,
        customHeader: false,
        wordBreak: false,
        lockable: false,
        blurContent: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Tabela');
        
        this.setState({ currentIndex: index }, () => {
            this.handleFloatListActions();
        });

        const { scrollx, buttons, expanded, fixed, tableDiv } = this.state;

        if (scrollx) {
            if (buttons === 'reduced') {
                if(tableDiv) {
                    this.calcButtonsHeightOnTableEditableWithHorizontalScroll();
                } else {
                    this.calcButtonsHeightOnTableWithHorizontalScroll();
                }
            }

            if (fixed) {
                if(tableDiv) {
                    this.calcPositionToShowButtonsOnTableEditableScrollAndFixed();
                } else {
                    this.calcPositionToShowButtonsOnTableScrollAndFixed();
                }
            }

            if (expanded) {
                this.calcWidthOfChildTableOnTableEditableWithHorizontalScroll();
            }
        }
    }

    componentDidUpdate() {
        const { scrollx, buttons, fixed, tableDiv, expanded } = this.state;

        if (scrollx && buttons && buttons !== 'reduced') {
            this.setState({ buttons: buttons ? 'reduced' : '' });
        }

        if (scrollx) {
            if (buttons === 'reduced') {
                if(tableDiv) {
                    this.calcButtonsHeightOnTableEditableWithHorizontalScroll();
                } else {
                    this.calcButtonsHeightOnTableWithHorizontalScroll();
                }
            }

            if (fixed) {
                if(tableDiv) {
                    this.calcPositionToShowButtonsOnTableEditableScrollAndFixed();
                } else {
                    this.calcPositionToShowButtonsOnTableScrollAndFixed();
                }
            }

            if (expanded) {
                this.calcWidthOfChildTableOnTableEditableWithHorizontalScroll();
            }
        }
        this.handleFloatListActions();
    }

    // TABLE WITH HORIZONTAL SCROLL
    calcButtonsHeightOnTableWithHorizontalScroll = () => {
        var btns = document.querySelectorAll('.scroll-x tr.reduced .has-btn-actions');
        for (var btn of btns) {
            btn.querySelector('.btn-actions-inner').style.height = (btn.parentElement.offsetHeight - 2) + 'px';
        }
    }

    calcPositionToShowButtonsOnTableScrollAndFixed = () => {
        var rows = document.querySelectorAll('.scroll-x .panel-table-outer table.fixed > tbody > tr:not(.child-table)')
        for (var row of rows) {
            row.addEventListener('mouseover', event => {
                this.onMouseOverTable(event);
            })
        }
    }

    onMouseOverTable = event => {
        var target = event.target.closest('tr');
        var offsetTop = target.offsetTop;
        var scrollTop = target.parentElement.parentElement.parentElement.scrollTop;

        //FIREFOX BUG
        if (target.parentElement.querySelector('tr:first-child').offsetTop === 0) {
            offsetTop += target.parentElement.parentElement.querySelector('thead').offsetHeight
        }

        var button = target.querySelector('.has-btn-actions');
        button.style.top = (offsetTop - scrollTop) + 'px';
    }

    renderExtraThToScrollx = () => {
        let ths = [];
        for (var x = 0; x < 7; x++) {
            ths.push(<th key={x}>Lorem lorem lorem</th>);
        }
        return ths;
    }

    renderExtraTdToScrollx = () => {
        const {manyHeaderButtons} = this.state;

        let tds = [];
        for (var x = 0; x < 7; x++) {
            tds.push(<td key={x} colSpan={manyHeaderButtons && x === 6 ? '2' : ''}>Lorem lorem lorem lorem</td>);
        }
        return tds;
    }

    renderExtraTfooterScrollx = () => {
        let tdsf = [];
        for (var x = 0; x < 7; x++) {
            tdsf.push(<td key={x}><span>Lorem lorem lorem lorem</span></td>);
        }
        return tdsf;
    }

    toggleSortable = () => {
        if (this.state.sortable) {
            this.setState(state => {
                if (state.desc) {
                    return {
                        'desc': false,
                        'asc': true
                    }
                }
                return {
                    'desc': true,
                    'asc': false
                }
            })
        }
    }

    toggleColumnSelectorOptions = () => {
        this.setState(state => {
            return { showColumnSelectorOptions: !state.showColumnSelectorOptions }
        })
    }

    toggleExpanded = () => {
        this.setState(state => {
            return {
                showExpanded: !state.showExpanded
            }
        })
    }

    toggleQuickView = () => {
        this.setState(state => {
            return {
                openedQuickView: !state.openedQuickView
            }
        })
    }

    handleButtonQuickViewEnter = (e) => {
        const { openedQuickView } = this.state;

        if(!openedQuickView){
            this.toggleQuickView();
        }

        let quickview = document.querySelector('.quick-view.new');
        
        if (quickview) {
            let quickviewHeight = quickview.offsetHeight;
            let quickviewWidth = quickview.offsetWidth;
            let bounding = e.currentTarget.getBoundingClientRect();
            let offsetTop = bounding.y;
            let offsetLeft = bounding.x;
            let windowHeight = window.innerHeight;

            e.currentTarget.focus();

            if (quickviewHeight > windowHeight) {
                quickviewHeight = windowHeight - 310;
            }
            if (offsetTop + quickviewHeight > windowHeight) {
                offsetTop = offsetTop - ((offsetTop + quickviewHeight) - windowHeight) - 5;
            }
            quickview.style.left = offsetLeft - quickviewWidth + 'px';
            quickview.style.top = offsetTop + 'px';

        }
    }

    handleButtonQuickViewLeave = () => {
        const { openedQuickView } = this.state;
        if(openedQuickView){ 
            this.toggleQuickView();
        }
    }
    
    renderQuickviewNew = () => {
        const { openedQuickView } = this.state;
        return(
            <div className={`quick-view new ${openedQuickView ? 'open' : ''}`} onMouseEnter={this.toggleQuickView} onMouseLeave={this.toggleQuickView}>
                <div className="quick-header">
                    Base de Cálculo
                </div>
                <div className="panel-body ">
                    <div className="table-editable panel-table-outer">
                        <div className="table-editable-header">
                            <div className="row">
                                <div className="col-sm-6 column"> <span> Coluna 1 </span></div>
                                <div className="col-sm-6 column undefined"> <span> Coluna 2 </span></div>
                            </div>
                        </div>
                        <div className="table-editable-content">
                            <div className="row reduced  ">
                                <div className="column col-sm-6  undefined" data-header="Coluna"><span>Lorem ipsum dolor</span></div>
                                <div className="column col-sm-6  undefined" data-header="Coluna"><span>Lorem ipsum dolor</span></div>
                            </div>
                            <div className="row reduced  ">
                                <div className="column col-sm-6  undefined" data-header="Coluna"><span>Lorem ipsum dolor</span></div>
                                <div className="column col-sm-6  undefined" data-header="Coluna"><span>Lorem ipsum dolor</span></div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div className="quick-footer">
                    <button className="btn text module-color">Ver Detalhes</button> 
                </div>
            </div>
        )
    }

    handleOnEdit = () => {
        this.setState(state => {
            return {
                onEdit: !state.onEdit
            }
        }, () => {
            if (this.state.onEdit)
                this.editableInput.current.focus();
        });
    }

    renderFloatListActions = () => {
        return(
            <ul id="float-list-actions">
                <li className="float-list-actions-item big">
                    {/* <i className="fas fa-eye icon"></i> */}
                    <FontAwesomeIcon icon="eye" className="icon"/>
                    Visualizar registro
                </li>
                <li className="float-list-actions-item">
                    <i className="fa fa-pencil icon"></i>
                    Editar registro
                </li>
                <li className="float-list-actions-item">
                    <i className="fa fa-pencil icon"></i>
                    Editar registro
                </li>
            </ul>
        )
    }
    
    handleFloatListActions = (e) => {
        const table = document.querySelector('.table');
        const listActions = document.querySelector("#float-list-actions");
        
        if(table){

            table.oncontextmenu = (e) => {
                e.preventDefault()
            }
        }

        if(listActions){

            listActions.oncontextmenu = (e) => {
                e.preventDefault()
            }
        }

        document.addEventListener('click', e => {
            let isRightMB = undefined;
       
            if ("which" in e) {
                isRightMB = e.which === 3;  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
            } else if ("button" in e) {
                isRightMB = e.button === 2; // IE, Opera 
            }
            if(!isRightMB) {
                this.clickOutsideWithFloatListActionsActive();
            }
        });
    }

    clickOutsideWithFloatListActionsActive = e => {
        if (document.querySelector('#float-list-actions.active')) {
            this.closeFloatListActions()
        }
    }

    closeFloatListActions = () => {
        const listActions = document.querySelector("#float-list-actions");
        listActions.classList.remove('active');
        let rowSelected = document.querySelector('tr.rowSelected');
        if(rowSelected) {
            rowSelected.classList.remove('rowSelected');
        }
    }

    openFloatListActions = (e) => {
        const listActions = document.querySelector("#float-list-actions");
        let isRightMB = undefined;
       
        if ("which" in e) {
            isRightMB = e.which === 3;  // Gecko (Firefox), WebKit (Safari/Chrome) & Opera
        } else if ("button" in e) {
            isRightMB = e.button === 2; // IE, Opera 
        }

        if (isRightMB) {
            let rowSelected = document.querySelector('tr.rowSelected');
            
            if(rowSelected) {
                rowSelected.classList.remove('rowSelected');
            }

            e.currentTarget.closest("tr").classList.add('rowSelected');
            listActions.classList.add("active");
    
            if (listActions) {
                let listActionsHeight = listActions.offsetHeight;
                let listActionsWidth = listActions.offsetWidth;
                let cursorX = e.clientX;
                let cursorY = e.clientY;
                let windowHeight = window.innerHeight;
                let windowWidth = window.innerWidth;

                let finalY = 0;
                let finalX = 0;

                e.currentTarget.focus();

                if (listActionsHeight + cursorY > windowHeight) {
                    finalY = cursorY - 15 - ((cursorY + listActionsHeight) - windowHeight);
                } else {
                    finalY = cursorY;
                }
                if (listActionsWidth + cursorX > windowWidth) {
                    finalX = cursorX - listActionsWidth;
                } else {
                    finalX = cursorX;
                }
                listActions.style.left = finalX + 1 + 'px';
                listActions.style.top = finalY + 1 + 'px';

            }
        }
    }

    renderComponent = () => {

        const { sortable, desc, asc, columnSelector, textAlign, showColumnSelectorOptions, buttons, trType, border, inverted, striped, status, fixed, footer, checkbox,
            radio, openedQuickView, searchFilters, hasQuickView, hasQuickViewNew, expanded, showExpanded, headerButton, scrollx, extraInfo, footerWithTransparency, subtitles, editable, editableAuto, onEdit, level, highlight, changed, footerAlternative, placeholderLoader, manyHeaderButtons, customHeader, wordBreak, lockable, blurContent } = this.state;

        const panelClasses = classnames(
            'panel-table-outer',
            {
                'has-column-selector': columnSelector,
                'has-header-button': headerButton,
                'blur': blurContent
            }
        );

        const thClasses = classnames(
            textAlign,
            { sortable , desc , asc , lockable },
            
        );

        const trClasses = classnames(
            {
                'reduced': buttons === 'reduced'
            }
        )

        const tdClasses = classnames(textAlign);

        const tableClasses = classnames('panel-table', {
            border,
            inverted,
            striped,
            fixed,
            'tfoot-fixed': fixed && footer,
            'transparent-footer': footerWithTransparency,
            'tree tree-arrow': level,
            'placeholder-loader': placeholderLoader,
            'word-break': wordBreak
        })
//shinkei
        const panelBodyClasses = classnames('panel-body', {
            fixed,
            'scroll-x': scrollx
        })

        const childTableClasses = classnames('panel-table', {
            border,
            inverted,
            striped
        })

        const statusClasses = classnames(status, 'center', 'chip-container', 'outlined');

        let colSpan = null;

        if ((columnSelector || headerButton) && (!fixed && !buttons && !scrollx)) {
            colSpan = 2;
        }

        if(columnSelector && headerButton && buttons && !scrollx) {
            colSpan=2;
        }

        if (!fixed && !buttons && columnSelector && headerButton && !scrollx) {
            colSpan = 3;
        }

        if( manyHeaderButtons && !scrollx) {
            colSpan = 2;
        }

        let colSpanFooter = null;

        if ((!fixed && buttons) || (fixed && buttons && buttons !== 'reduced') || (!fixed && !buttons && headerButton) || (!fixed && !buttons && columnSelector)) {
            if (!scrollx) {
                colSpanFooter = 2;
            }
        }

        let colSpanChildTable = null;

        if ((columnSelector || headerButton || buttons) && (checkbox || radio)) {
            colSpanChildTable = 6;
        } else {
            colSpanChildTable = 5;
        }

        if (scrollx) {
            colSpanChildTable = 11;
            if (columnSelector || headerButton) {
                colSpanChildTable += 1;
            }
        }

        return (
            <>
                {this.renderFloatListActions()}
                <div className="panel table table-responsive">
                    <div className={panelBodyClasses}>
                        {searchFilters &&
                            <div className="panel-table-filter">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <select aria-label="search input">
                                                <option>Código</option>
                                                <option>Requerente</option>
                                                <option>CPF/CNPJ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <select aria-label="search input">
                                                <option>Contém</option>
                                                <option>Igual</option>
                                                <option>Diferente de</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group"><input aria-label="search button" type="text" name="" placeholder="Digite o filtro..." className="button-input" />

                                            <button className="btn filter ">Filtrar</button></div>
                                    </div>
                                </div>
                                <div className="panel-filter-tags"><span className="filter-tag">Isso é um filtro<i className="fa fa-times remove"></i></span></div>
                            </div>
                        }
                        {hasQuickView &&
                            <div className={`quick-view ${openedQuickView ? 'open' : ''}`}>
                                <em className="fa fa-times" onClick={this.toggleQuickView}></em>
                                <div className="row">
                                    <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                                    <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                                </div>
                            </div>
                        }
                        
                        {customHeader && 
                            <div className="row custom-header">
                                <div className="col-md-7 pl-none">
                                    <button className="btn circle ml-none inline">
                                        <em className="fa fa-upload"></em>
                                        <div className="btn-actions-label"> Novo </div>
                                    </button>
                                    <button className="btn circle ml-none inline">
                                        <em className="fa fa-plus"></em>
                                        <div className="btn-actions-label"> Novo </div>
                                    </button>
                                    <button className="btn circle ml-none inline">
                                        <em className="fa fa-ban"></em>
                                        <div className="btn-actions-label"> Bloquear </div>
                                    </button>
                                </div>
                                <div className="col-md-5 right">
                                    <p>Fichas Selecionadas: <b className="mr-xs primary-color">5</b> Total Líquido Selecionado: <b className="primary-color">R$ 100.000,00</b></p>
                                </div>  
                            </div>
                        }

                        {hasQuickViewNew &&
                            this.renderQuickviewNew()
                        }
                        
                        <div className={panelClasses}>
                            <table className={tableClasses}>
                                <thead>
                                    <tr>
                                        {checkbox && !radio &&
                                            <th className="column-checkbox">
                                                <input className="input-checkbox" type="checkbox" id="checkbox1" />
                                                <label htmlFor="checkbox1"></label>
                                            </th>
                                        }
                                        {radio && !checkbox &&
                                            <th className="column-radio" />
                                        }
                                        {!lockable &&
                                            <>
                                                <th className={`${thClasses}`} onClick={this.toggleSortable}>Coluna 1</th>
                                                <th className={`${thClasses} ${highlight}`} onClick={this.toggleSortable}>Coluna 2</th>
                                                <th className={`${thClasses}`} onClick={this.toggleSortable}>Coluna 3</th>
                                                <th className={`${thClasses}`} onClick={this.toggleSortable}>Coluna 4</th>
                                            </>
                                        }
                                        {lockable &&
                                            <>
                                                <th className={`${thClasses} `}>Coluna 1</th>
                                                <th className={`${thClasses} locked`}>Coluna 2</th>
                                                <th className={`${thClasses} disabled`}>Coluna 3</th>
                                                <th className={`${thClasses} `}>Coluna 4</th>
                                            </>
                                        }
                                        {scrollx && this.renderExtraThToScrollx()}
                                        {headerButton && !manyHeaderButtons &&
                                            <th className={`header-button ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`} data-position-scroll={scrollx ? '1' : ''}>
                                                <div className="btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn circle" type="button">
                                                            <i className="fa fa-map-marker" />
                                                            <div className="btn-actions-label">Todos Endereços</div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </th>
                                        }
                                        {columnSelector && !manyHeaderButtons &&
                                            <th className={`column-selector ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`} data-position-scroll={scrollx && headerButton ? '0' : ''}>
                                                <div className={`hint fa-columns top-right sm ${!inverted ? 'clean module-color' : ''}`} onClick={this.toggleColumnSelectorOptions}>
                                                    <div className="hint-content"> Seletor de Colunas </div>
                                                </div>
                                                <ul className={`options ${showColumnSelectorOptions ? 'actived' : ''}`}>
                                                    <li>
                                                        <input id="column-1" className="input-checkbox" type="checkbox" />
                                                        <label htmlFor="column-1">Coluna 1</label>
                                                    </li>
                                                    <li>
                                                        <input id="column-2" className="input-checkbox" type="checkbox" />
                                                        <label htmlFor="column-2">Coluna 2</label>
                                                    </li>
                                                    <li>
                                                        <input id="column-3" className="input-checkbox" type="checkbox" />
                                                        <label htmlFor="column-3">Coluna 3</label>
                                                    </li>
                                                    <li>
                                                        <input id="column-4" className="input-checkbox" type="checkbox" />
                                                        <label htmlFor="column-4">Coluna 4</label>
                                                    </li>
                                                </ul>
                                            </th>
                                        }
                                        {scrollx && manyHeaderButtons &&
                                            <th className="empty-column"></th>
                                        }
                                        {manyHeaderButtons &&
                                            <th className="many-header-buttons">
                                                <i className="fa fa-ellipsis-v many-header-buttons-icon" />
                                                <div className="many-header-buttons-options">
                                                    <div className={`header-button ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`}>
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn circle" type="button">
                                                                    <i className="fa fa-map-marker" />
                                                                    <div className="btn-actions-label">Todos Endereços</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`header-button ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`}>
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn circle" type="button">
                                                                    <i className="fa fa-plus" />
                                                                    <div className="btn-actions-label">Adicionados</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`header-button ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`}>
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn circle" type="button">
                                                                    <i className="fa fa-ban" />
                                                                    <div className="btn-actions-label">Bloqueados</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={`column-selector ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`}>
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn circle" type="button" onClick={this.toggleColumnSelectorOptions}>
                                                                    <i className="fa fa-columns" />
                                                                    <div className="btn-actions-label">Seletor de Colunas</div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <ul className={`options ${showColumnSelectorOptions ? 'actived' : ''}`}>
                                                            <li>
                                                                <input id="column-1" className="input-checkbox" type="checkbox" />
                                                                <label htmlFor="column-1">Coluna 1</label>
                                                            </li>
                                                            <li>
                                                                <input id="column-2" className="input-checkbox" type="checkbox" />
                                                                <label htmlFor="column-2">Coluna 2</label>
                                                            </li>
                                                            <li>
                                                                <input id="column-3" className="input-checkbox" type="checkbox" />
                                                                <label htmlFor="column-3">Coluna 3</label>
                                                            </li>
                                                            <li>
                                                                <input id="column-4" className="input-checkbox" type="checkbox" />
                                                                <label htmlFor="column-4">Coluna 4</label>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </th>
                                        }
                                        
                                        {fixed && !columnSelector && buttons && buttons !== 'reduced' && !headerButton && <th></th>}
                                        {!fixed && !columnSelector && buttons && !headerButton && !scrollx && <th className="has-btn-actions"></th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={`${trClasses} ${trType} ${openedQuickView ? 'rowSelected' : ''}`} onMouseUp={(e) => this.openFloatListActions(e)}>
                                        {checkbox && !radio &&
                                            <td className="column-checkbox">
                                                <input className="input-checkbox" type="checkbox" id="checkbox2" />
                                                <label htmlFor="checkbox2"></label>
                                            </td>
                                        }
                                        {radio && !checkbox &&
                                            <td className="column-radio">
                                                <input className="input-radio" type="radio" id="radio2" />
                                                <label htmlFor="radio2"></label>
                                            </td>
                                        }

                                        <td className={`${tdClasses} ${changed}`}>
                                            <b className="td-label">Lorem ipsum</b>
                                            <span className={`td-content ${extraInfo ? 'inline-flex' : ''}`}>
                                                {extraInfo &&
                                                    <div className="extra-info split-letter" data-letter="E">
                                                        <p>Extra Informação</p>
                                                    </div>
                                                }
                                                Lorem ipsum lorem ipsum lorem ipsum lorem
                                            </span>
                                        </td>
                                        <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                            {!status &&
                                                <React.Fragment>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </React.Fragment>
                                            }
                                            {status &&
                                                <div className={statusClasses}>
                                                    <p className="chip-text">LOREM IPSUM</p>
                                                </div>
                                            }
                                        </td>
                                        <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                            <b className="td-label">Lorem ipsum</b>
                                            {!editable &&
                                                <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                            }
                                            {editable &&
                                                <div className="editable">
                                                    {!onEdit &&
                                                        <>
                                                            <span className="td-content">Lorem ipsum lorem ipsum lorem ipsum lorem</span>
                                                            <div className="hint clean inline module-color fa fa-pencil sm" onClick={this.handleOnEdit}>
                                                                <div className="hint-content">Editar</div>
                                                            </div>
                                                        </>
                                                    }
                                                    {onEdit && !editableAuto &&
                                                        <input type="text" defaultValue="Lorem ipsum lorem ipsum lorem ipsum lorem" ref={this.editableInput} onBlur={this.handleOnEdit} />
                                                    }
                                                    {onEdit && editableAuto &&
                                                        <React.Fragment>
                                                            <input onBlur={this.handleOnEdit} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                            <i className="fa fa-search input-icon"></i>
                                                            <ul className="autocomplete">
                                                                <li className="autocomplete-item">01 - Curitiba</li>
                                                                <li className="autocomplete-item">02 - Londrina</li>
                                                                <li className="autocomplete-item">03 - Maringá</li>
                                                                <li className="autocomplete-item">04 - Ponta Grossa</li>
                                                                <li className="autocomplete-item">05 - Foz do Iguaçú</li>
                                                                <li className="autocomplete-item">06 - Cianorte</li>
                                                                <li className="autocomplete-item">07 - Campo Mourão</li>
                                                            </ul>
                                                        </React.Fragment>
                                                    }
                                                </div>
                                            }
                                        </td>
                                        <td className={tdClasses} colSpan={colSpan}>
                                            <b className="td-label">Lorem ipsum</b>
                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                        </td>
                                        {scrollx && this.renderExtraTdToScrollx()}
                                        {buttons &&
                                            <td className="has-btn-actions">
                                                <div className="btn-actions">
                                                    <div className="btn-actions-inner">
                                                        {expanded &&
                                                            <button className={`btn ${showExpanded ? 'active' : ''}`} onClick={this.toggleExpanded}>
                                                                <i className="fa fa-list-ul"></i>
                                                                <div className="btn-actions-label">Detalhes</div>
                                                            </button>
                                                        }
                                                        {hasQuickView &&
                                                            <button className="btn" onClick={this.toggleQuickView}>
                                                                <i className="fa fa-eye"></i>
                                                                <div className="btn-actions-label">Visualizar</div>
                                                            </button>
                                                        }
                                                        {hasQuickViewNew &&
                                                            <button className="btn" onMouseEnter={this.handleButtonQuickViewEnter} onMouseLeave={this.handleButtonQuickViewLeave}>
                                                                <i className="fa fa-eye"></i>
                                                                <div className="btn-actions-label">Visualizar</div>
                                                            </button>
                                                        }
                                                        <button className="btn error">
                                                            <i className="fa fa-envelope"></i>
                                                            <div className="btn-actions-label">Enviar</div>
                                                        </button>
                                                        <button className="btn warning">
                                                            <i className="fa fa-flag"></i>
                                                            <div className="btn-actions-label">Marcar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links" tabIndex="-1">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                        {fixed && !buttons && columnSelector && !scrollx &&
                                            <td className="column-selector"></td>
                                        }
                                        {fixed && !buttons && headerButton && !scrollx &&
                                            <td className="header-button"></td>
                                        }
                                    </tr>
                                    {expanded && showExpanded &&
                                        <tr className="child-table">
                                            <td colSpan={colSpanChildTable}>
                                                <div className="panel">
                                                    <div className="panel-body">
                                                        <div className="panel-table-outer">
                                                            <table className={childTableClasses}>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Coluna 1</th>
                                                                        <th>Coluna 2</th>
                                                                        <th>Coluna 3</th>
                                                                        <th>Coluna 4</th>
                                                                        {buttons && !scrollx && !fixed && <th></th>}
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr className={trClasses}>
                                                                        <td>Coluna 1</td>
                                                                        <td>Coluna 2</td>
                                                                        <td>Coluna 3</td>
                                                                        <td>Coluna 4</td>
                                                                        {buttons &&
                                                                            <td className="has-btn-actions">
                                                                                <div className="btn-actions">
                                                                                    <div className="btn-actions-inner">
                                                                                        {hasQuickView &&
                                                                                            <button className="btn" onClick={this.toggleQuickView}>
                                                                                                <i className="fa fa-eye"></i>
                                                                                                <div className="btn-actions-label">Visualizar</div>
                                                                                            </button>
                                                                                        }
                                                                                        {hasQuickViewNew &&
                                                                                            <button className="btn" onMouseEnter={this.handleButtonQuickViewEnter} onMouseLeave={this.handleButtonQuickViewLeave}>
                                                                                                <i className="fa fa-eye"></i>
                                                                                                <div className="btn-actions-label">Visualizar</div>
                                                                                            </button>
                                                                                        }
                                                                                        <button className="btn">
                                                                                            <i className="fa fa-envelope"></i>
                                                                                            <div className="btn-actions-label">Enviar</div>
                                                                                        </button>
                                                                                        <button className="btn">
                                                                                            <i className="fa fa-flag"></i>
                                                                                            <div className="btn-actions-label">Marcar</div>
                                                                                        </button>
                                                                                        <button className="btn">
                                                                                            <i className="fa fa-filter"></i>
                                                                                            <div className="btn-actions-label">Filtrar</div>
                                                                                        </button>
                                                                                        <button className="btn">
                                                                                            <i className="fa fa-trash"></i>
                                                                                            <div className="btn-actions-label">Excluir</div>
                                                                                        </button>
                                                                                        <button className="btn links" tabIndex="-1">
                                                                                            <i className="fa fa-eye"></i>
                                                                                            <div className="btn-actions-label">
                                                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                                                            </div>
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        }
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    <tr className={trClasses} onMouseUp={(e) => this.openFloatListActions(e)}>
                                        {checkbox && !radio &&
                                            <td className="column-checkbox">
                                                <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                <label htmlFor="checkbox3"></label>
                                            </td>
                                        }
                                        {radio && !checkbox &&
                                            <td className="column-radio">
                                                <input className="input-radio" type="radio" id="radio3" />
                                                <label htmlFor="radio3"></label>
                                            </td>
                                        }
                                        <td className={tdClasses}>
                                            <b className="td-label">Lorem ipsum</b>
                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                        </td>
                                        <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                            {!status &&
                                                <React.Fragment>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </React.Fragment>
                                            }
                                            {status &&
                                                <div className={statusClasses}>
                                                    <p className="chip-text">LOREM IPSUM</p>
                                                </div>
                                            }
                                        </td>
                                        <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                            <b className="td-label">Lorem ipsum</b>
                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                        </td>
                                        <td className={tdClasses} colSpan={colSpan}>
                                            <b className="td-label">Lorem ipsum</b>
                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                        </td>
                                        {scrollx && this.renderExtraTdToScrollx()}
                                        {buttons &&
                                            <td className="has-btn-actions">
                                                <div className="btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-pencil"></i>
                                                            <div className="btn-actions-label">Editar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-download"></i>
                                                            <div className="btn-actions-label">Baixar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-envelope"></i>
                                                            <div className="btn-actions-label">Enviar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-flag"></i>
                                                            <div className="btn-actions-label">Marcar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links" tabIndex="-1">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        }
                                        {fixed && !buttons && columnSelector && !scrollx &&
                                            <td className="column-selector"></td>
                                        }
                                        {fixed && !buttons && headerButton && !scrollx &&
                                            <td className="header-button"></td>
                                        }
                                    </tr>
                                    {level &&
                                        <>
                                            <tr className={`${trClasses} father open level-1`}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                        </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>
                                            <tr className={`${trClasses} father open level-2`}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                    </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>
                                            <tr className={`${trClasses} level-2`}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                    </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>
                                        </>
                                    }
                                    {fixed &&
                                        <>
                                            <tr className={trClasses}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>
                                            <tr className={trClasses}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>
                                            <tr className={trClasses}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>
                                            <tr className={trClasses}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>
                                            <tr className={trClasses}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>
                                            <tr className={trClasses}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>
                                            <tr className={trClasses}>
                                                {checkbox && !radio &&
                                                    <td className="column-checkbox">
                                                        <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                        <label htmlFor="checkbox3"></label>
                                                    </td>
                                                }
                                                {radio && !checkbox &&
                                                    <td className="column-radio">
                                                        <input className="input-radio" type="radio" id="radio3" />
                                                        <label htmlFor="radio3"></label>
                                                    </td>
                                                }
                                                <td className={tdClasses}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={`${tdClasses} ${highlight} ${lockable ? 'locked':''}`}>
                                                    {!status &&
                                                        <React.Fragment>
                                                            <b className="td-label">Lorem ipsum</b>
                                                            <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                        </React.Fragment>
                                                    }
                                                    {status &&
                                                        <div className={statusClasses}>
                                                            LOREM IPSUM
                                                </div>
                                                    }
                                                </td>
                                                <td className={`${tdClasses} ${lockable ? 'disabled':''}`}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                <td className={tdClasses} colSpan={colSpan}>
                                                    <b className="td-label">Lorem ipsum</b>
                                                    <span className="td-content"> Lorem ipsum lorem ipsum lorem ipsum lorem </span>
                                                </td>
                                                {scrollx && this.renderExtraTdToScrollx()}
                                                {buttons &&
                                                    <td className="has-btn-actions">
                                                        <div className="btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-pencil"></i>
                                                                    <div className="btn-actions-label">Editar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-download"></i>
                                                                    <div className="btn-actions-label">Baixar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-envelope"></i>
                                                                    <div className="btn-actions-label">Enviar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-flag"></i>
                                                                    <div className="btn-actions-label">Marcar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                }
                                                {fixed && !buttons && columnSelector && !scrollx &&
                                                    <td className="column-selector"></td>
                                                }
                                                {fixed && !buttons && headerButton && !scrollx &&
                                                    <td className="header-button"></td>
                                                }
                                            </tr>

                                        </>
                                    }
                                </tbody>
                                {footer &&
                                    <tfoot>
                                        <tr>
                                            {checkbox && !radio && fixed &&
                                                <td className="column-checkbox"></td>
                                            }
                                            {radio && !checkbox && fixed &&
                                                <td className="column-radio"></td>
                                            }
                                            <td colSpan={`${(checkbox || radio) && !fixed ? '2' : ''}`}>Lorem ipsum lorem</td>
                                            <td>Lorem ipsum lorem</td>
                                            <td>Lorem ipsum lorem</td>
                                            <td className={tdClasses} colSpan={colSpanFooter}>Lorem ipsum lorem </td>
                                            {scrollx &&
                                                this.renderExtraTfooterScrollx()
                                            }
                                            {((buttons && buttons === 'reduced') || !buttons) && columnSelector && fixed &&
                                                <td className="column-selector"></td>
                                            }
                                            {((buttons && buttons === 'reduced') || !buttons) && headerButton && fixed &&
                                                <td className="header-button"></td>
                                            }
                                        </tr>
                                    </tfoot>
                                }
                            </table>
                        </div>
                    </div>
                    {subtitles &&
                     <div className="subtitles">
                        <div className="subtitle">Médias</div>
                        <div className="subtitle positive">Proventos</div>
                        <div className="subtitle negative">Descontos</div>
                        <div className="subtitle highlight">Totais</div>
                    </div>

                    }
                    {footerAlternative &&
                        <div className="altfooter-container hasInput">
                            <div className="altfooter-container-item">
                                <div className="switch-container">

                                    <em className="switch flat module-color "></em><span className="switch-caption nowrap">Lorem ipsum</span></div>
                            </div>
                            <div className="altfooter-container-item side-border flex">
                                <div className="col-md-2">
                                    <div className="form-group"><label className="label" for="field">Data Estorno</label><input type="text" id="field" /></div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group"><label className="label" for="field">Processo</label><input type="text" id="field" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group"><label className="label" for="field">Motivo</label><input type="text" id="field" /></div>
                                </div>
                            </div>
                            <div className="altfooter-container-item side-border">
                                <div className="altfooter-item-textsimple">1000</div>
                            </div>
                            <div className="altfooter-container-item side-border">
                                <div className="altfooter-item-textcomplex"><span>Valor:</span>
                                    <p>R$199,00</p>
                                </div>
                            </div>
                            <div className="altfooter-container-item side-border">
                                <div className="altfooter-item-textcomplex inline"><span>Valor:</span>
                                    <p>R$199,00</p>
                                </div>
                            </div>
                            <div className="altfooter-container-item side-border">

                                <button className="btn module-color">Botão</button></div>
                        </div>
                    }
                </div>
            </>
        );
    }

    renderClasses = () => {
        return (
            <>
                <SwitchOptionsGroup label="Tipo Tabela" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="tableDiv" value={true} stateName="tableDiv" label="Tabela Tipo Div" />
                    <SwitchOptions attr="blurContent" value={true} stateName="blurContent" label="Conteúdo oculto" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="sortable" value={true} stateName="sortable" label="Com ordenação" />
                    <SwitchOptions attr="buttons" value='normal' stateName="buttons" label="Com botões visíveis" />
                    <SwitchOptions attr="buttons" value='reduced' stateName="buttons" label="Com botões escondidos" />
                    <SwitchOptions attr="border" value={true} stateName="border" label="Com borda" />
                    <SwitchOptions attr="inverted" value={true} stateName="inverted" label="Com cor invertida" hidden />
                    <SwitchOptions attr="fixed" value={true} stateName="fixed" label="Com header fixo" />
                    <SwitchOptions attr="footer" value={true} stateName="footer" label="Com footer" />
                    <SwitchOptions attr="footerWithTransparency" value={true} stateName="footerWithTransparency" label="Com footer transparente" hidden/>
                    <SwitchOptions attr="subtitles" value={true} stateName="subtitles" label="Com legenda" />
                    <SwitchOptions attr="searchFilters" value={true} stateName="searchFilters" label="Com Filtro" />
                    <SwitchOptions attr="expanded" value={true} stateName="expanded" label="Com outra table dentro" />
                    <SwitchOptions attr="hasQuickViewNew" value={true} stateName="hasQuickViewNew" label="Quick view novo" />
                    <SwitchOptions attr="level" value={true} stateName="level" label="Com Níveis" />
                    <SwitchOptions attr="footerAlternative" value={true} stateName="footerAlternative" label="Com rodapé alternativo" />
                    <SwitchOptions attr="customHeader" value={true} stateName="customHeader" label="Com header customizado"/>
                    <SwitchOptions attr="placeholderLoader" value={true} stateName="placeholderLoader" label="Carregando" />
                    <SwitchOptions attr="wordBreak" value={true} stateName="wordBreak" label="Com quebra de linha" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Opções de Linha" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="trType" value='success' stateName="trType" label="Com destaque (success)" />
                    <SwitchOptions attr="trType" value='highlight' stateName="trType" label="Com destaque" />
                    <SwitchOptions attr="trType" value='error' stateName="trType" label="Com erro" />
                    <SwitchOptions attr="trType" value='warning' stateName="trType" label="Com aviso" />
                    <SwitchOptions attr="trType" value='rowSelected' stateName="trType" label="Selecionada" />
                    <SwitchOptions attr="scrollx" value={true} stateName="scrollx" label="Com scroll horizontal" />
                    <SwitchOptions attr="extraInfo" value={true} stateName="extraInfo" label="Com informação extra" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Opções de Coluna" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="headerButton" value={true} stateName="headerButton" label="Com botão no header" />
                    <SwitchOptions attr="columnSelector" value={true} stateName="columnSelector" label="Com seleção de colunas" />
                    <SwitchOptions attr="manyHeaderButtons" value={true} stateName="manyHeaderButtons" label="Com múltiplus botões no header" />
                    <SwitchOptions attr="checkbox" value={true} stateName="checkbox" label="Com checkbox" />
                    <SwitchOptions attr="radio" value={true} stateName="radio" label="Com radio button" />
                    <SwitchOptions attr="editable" value={true} stateName="editable" label="Editável" />
                    <SwitchOptions attr="editableAuto" value={true} stateName="editableAuto" label="Editável com Autocomplete" />
                    <SwitchOptions attr="textAlign" value="" stateName="textAlign" label="Alinhar à esquerda" hidden/>
                    <SwitchOptions attr="textAlign" value="center" stateName="textAlign" label="Alinhar ao centro" />
                    <SwitchOptions attr="textAlign" value="right" stateName="textAlign" label="Alinhar à direita" />
                    <SwitchOptions attr="status" value='module-color' stateName="status" label="Status Cor do Módulo" />
                    <SwitchOptions attr="status" value='positive' stateName="status" label="Status Cor Verde" />
                    <SwitchOptions attr="status" value='negative' stateName="status" label="Status Cor Vermelho" />
                    <SwitchOptions attr="highlight" value='highlight' stateName="highlight" label="Com Coluna Destacada" />
                    <SwitchOptions attr="changed" value='changed' stateName="changed" label="Com Coluna Alterada" />
                    <SwitchOptions attr="lockable" value={true} stateName="lockable" label="Com Coluna Travável" />
                </SwitchOptionsGroup>
            </>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    /* TABLE DIV */

    calcPositionToShowButtonsOnTableEditableScrollAndFixed = () => {
        var rows = document.querySelectorAll('.table-editable.fixed .table-editable-content .row');
        for (var row of rows) {
            row.addEventListener('mouseover', event => {
                this.onMouseOverTableEditable(event);
            })
        }
    }

    onMouseOverTableEditable = event => {
        var target = event.target.closest('.row');
        var offsetTop = target.offsetTop;
        var scrollTop = target.parentElement.parentElement.scrollTop;
        var button = target.querySelector('.column.btn-actions');

        //FIREFOX BUG
        if (target.parentElement.querySelector('.row:first-child').offsetTop === 0) {
            offsetTop += target.parentElement.parentElement.querySelector('.table-editable-content').offsetHeight
        }

        button.style.top = (offsetTop - scrollTop) + 'px';
    }

    calcButtonsHeightOnTableEditableWithHorizontalScroll = () => {
        const btns = document.querySelectorAll('.scroll-x .table-editable .table-editable-content .row.reduced .btn-actions');
        for (const btn of btns) {
            btn.style.height = (btn.parentElement.offsetHeight - 2) + 'px';
        }
    }

    calcWidthOfChildTableOnTableEditableWithHorizontalScroll = () => {
        var columns = document.querySelectorAll('.scroll-x .table-editable.panel-table-outer > .table-editable-header .column:not(.column-selector):not(.header-button)');
        var childTables = document.querySelectorAll('.table-editable.panel-table-outer .child-table');

        var widthScroll = 0;

        for (var column of columns) {
            var rect = column.getBoundingClientRect();
            widthScroll += rect.width;
        }

        for (var child of childTables) {
            if (child) {
                child.style.width = widthScroll + 'px';
            }
        }
    }

    renderExtraColumnToScrollx = () => {
        let ths = [];
        for (var x = 0; x < 7; x++) {
            ths.push(<div className="column col-sm-3" key={x}><span>lorem lorem lorem lorem</span></div>);
        }
        return ths;
    }

    onChange = newState => {
        
        if(newState.manyHeaderButtons) {
            newState.headerButton = false;
            newState.columnSelector = false;
        }

        if(newState.headerButton || newState.columnSelector) {
            newState.manyHeaderButtons = false;
        }

        if(newState.lockable){
            newState.highlight = false;
        }
        if(newState.highlight){
            newState.lockable = false;
        }
        this.setState(newState);
    }

    toggleSortable = () => {
        if (this.state.sortable) {
            this.setState(state => {
                if (state.desc) {
                    return {
                        'desc': false,
                        'asc': true
                    }
                }
                return {
                    'desc': true,
                    'asc': false
                }
            })
        }
    }

    toggleColumnSelectorOptions = () => {
        this.setState(state => {
            return { showColumnSelectorOptions: !state.showColumnSelectorOptions }
        })
    }

    toggleEditable = () => {

        this.setState(state => {
            return {
                editing: !state.editing
            }
        }, () => {
            if (this.state.editing) {
                this.editableInput.current.focus();
            }
        })
    }

    toggleQuickView = () => {
        this.setState(state => {
            return {
                openedQuickView: !state.openedQuickView
            }
        })
    }

    toggleExpanded = () => {
        this.setState(state => {
            return {
                showExpanded: !state.showExpanded
            }
        })
    }

    renderComponentDiv = () => {
        const { sortable, desc, asc, columnSelector, textAlign, showColumnSelectorOptions, buttons, trType, border, inverted, striped, status, fixed, footer, fancyTotals, editable, editableAuto, editing,
            checkbox, radio, openedQuickView, searchFilters, hasQuickView, hasQuickViewNew, expanded, showExpanded, headerButton, scrollx, extraInfo, footerWithTransparency, subtitles, level, highlight, changed, placeholderLoader, footerAlternative, manyHeaderButtons, customHeader, lockable, blurContent } = this.state;

        const tableClasses = classnames('table-editable', 'panel-table-outer', {
            border,
            striped,
            inverted,
            fixed,
            'transparent-footer': footerWithTransparency,
            'tree': level,
            'placeholder-loader': placeholderLoader,
            'blur': blurContent
        });

        const headerColumnClasses = classnames('column', textAlign, {
            lockable,
            sortable,
            desc,
            asc
        });

        const rowClasses = classnames('row', {
            'reduced': buttons === 'reduced'
        })

        const contentColumnClasses = classnames('column', 'col-sm-3', textAlign);

        const statusClasses = classnames(status, 'center', 'chip-container', 'outlined');

        return (
            <>
                <div className="panel table">
                    {hasQuickView && scrollx &&
                        <div className={`quick-view ${openedQuickView ? 'open' : ''}`}>
                            <em className="fa fa-times" onClick={this.toggleQuickView}></em>
                            <div className="row">
                                <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                                <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                            </div>
                        </div>
                    }
                    {hasQuickViewNew &&
                        this.renderQuickviewNew()
                    }
                    <div className={`panel-body ${scrollx ? 'scroll-x' : ''}`}>
                        {searchFilters &&
                            <div className="panel-table-filter">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <select aria-label="search input">
                                                <option>Código</option>
                                                <option>Requerente</option>
                                                <option>CPF/CNPJ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <select aria-label="search input">
                                                <option>Contém</option>
                                                <option>Igual</option>
                                                <option>Diferente de</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group"><input aria-label="campo de pesquisa" type="text" name="" placeholder="Digite o filtro..." className="button-input" />

                                            <button className="btn filter ">Filtrar</button></div>
                                    </div>
                                </div>
                                <div className="panel-filter-tags"><span className="filter-tag">Isso é um filtro<i className="fa fa-times remove"></i></span></div>
                            </div>
                        }
                        {customHeader && 
                            <div className="row custom-header">
                                <div className="col-md-7 pl-none">
                                    <button className="btn circle ml-none inline">
                                        <em className="fa fa-upload"></em>
                                        <div className="btn-actions-label"> Novo </div>
                                    </button>
                                    <button className="btn circle ml-none inline">
                                        <em className="fa fa-plus"></em>
                                        <div className="btn-actions-label"> Novo </div>
                                    </button>
                                    <button className="btn circle ml-none inline">
                                        <em className="fa fa-ban"></em>
                                        <div className="btn-actions-label"> Bloquear </div>
                                    </button>
                                </div>
                                <div className="col-md-5 right">
                                    <p>Fichas Selecionadas: <b className="mr-xs primary-color">5</b> Total Líquido Selecionado: <b className="primary-color">R$ 100.000,00</b></p>
                                </div>  
                            </div>
                        }
                        <div className={tableClasses}>
                                    <div className="table-editable-header">
                                        <div className="row">
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox1" />
                                                    <label htmlFor="checkbox1"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row" />
                                            }
                                            <div className={`col-sm-3 ${headerColumnClasses}`} onClick={this.toggleSortable}> <span> Coluna 1 </span></div>
                                            <div className={`col-sm-3 ${headerColumnClasses} ${highlight} ${lockable ? 'locked':''}`} onClick={this.toggleSortable}> <span> Coluna 2 </span></div>
                                            <div className={`col-sm-3 ${headerColumnClasses} ${lockable ? 'disabled':''}`} onClick={this.toggleSortable}> <span> Coluna 3 </span></div>
                                            <div className={`col-sm-3 ${headerColumnClasses}`} onClick={this.toggleSortable}> <span> Coluna 4 </span></div>
                                            {scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {buttons && buttons !== 'reduced' &&
                                                <div className="col-sm-1"></div>
                                            }
                                            {headerButton && !manyHeaderButtons &&
                                                <div className="col-md-1 header-button column" data-position-scroll={scrollx && columnSelector ? '1' : ''}>
                                                    <div className="btn-actions">
                                                        <div className="btn-actions-inner">
                                                            <button className="btn circle" type="button">
                                                                <i className="fa fa-map-marker" />
                                                                <div className="btn-actions-label">Todos Endereços</div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            {columnSelector && !manyHeaderButtons &&
                                                <div className="col-sm-1 column-selector column" data-position-scroll={scrollx && columnSelector ? '0' : ''}>
                                                    <div className={`hint fa-columns top-right sm ${!inverted ? 'clean module-color' : ''}`} onClick={this.toggleColumnSelectorOptions}>
                                                        <div className="hint-content"> Seletor de Colunas </div>
                                                    </div>
                                                    <ul className={`options ${showColumnSelectorOptions ? 'actived' : ''}`}>
                                                        <li>
                                                            <input id="column-1" className="input-checkbox" type="checkbox" />
                                                            <label htmlFor="column-1">Coluna 1</label>
                                                        </li>
                                                        <li>
                                                            <input id="column-2" className="input-checkbox" type="checkbox" />
                                                            <label htmlFor="column-2">Coluna 2</label>
                                                        </li>
                                                        <li>
                                                            <input id="column-3" className="input-checkbox" type="checkbox" />
                                                            <label htmlFor="column-3">Coluna 3</label>
                                                        </li>
                                                        <li>
                                                            <input id="column-4" className="input-checkbox" type="checkbox" />
                                                            <label htmlFor="column-4">Coluna 4</label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            }
                                            {manyHeaderButtons &&
                                                <div className="many-header-buttons">
                                                    <i className="fa fa-ellipsis-v many-header-buttons-icon" />
                                                    <div className="many-header-buttons-options">
                                                        <div className={`header-button ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`}>
                                                            <div className="btn-actions">
                                                                <div className="btn-actions-inner">
                                                                    <button className="btn circle" type="button">
                                                                        <i className="fa fa-map-marker" />
                                                                        <div className="btn-actions-label">Todos Endereços</div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`header-button ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`}>
                                                            <div className="btn-actions">
                                                                <div className="btn-actions-inner">
                                                                    <button className="btn circle" type="button">
                                                                        <i className="fa fa-plus" />
                                                                        <div className="btn-actions-label">Adicionados</div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`header-button ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`}>
                                                            <div className="btn-actions">
                                                                <div className="btn-actions-inner">
                                                                    <button className="btn circle" type="button">
                                                                        <i className="fa fa-ban" />
                                                                        <div className="btn-actions-label">Bloqueados</div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={`column-selector ${fixed && buttons && buttons !== 'reduced' ? 'fixed-with-visible-buttons' : ''}`}>
                                                            <div className="btn-actions">
                                                                <div className="btn-actions-inner">
                                                                    <button className="btn circle" type="button" onClick={this.toggleColumnSelectorOptions}>
                                                                        <i className="fa fa-columns" />
                                                                        <div className="btn-actions-label">Seletor de Colunas</div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <ul className={`options ${showColumnSelectorOptions ? 'actived' : ''}`}>
                                                                <li>
                                                                    <input id="column-1" className="input-checkbox" type="checkbox" />
                                                                    <label htmlFor="column-1">Coluna 1</label>
                                                                </li>
                                                                <li>
                                                                    <input id="column-2" className="input-checkbox" type="checkbox" />
                                                                    <label htmlFor="column-2">Coluna 2</label>
                                                                </li>
                                                                <li>
                                                                    <input id="column-3" className="input-checkbox" type="checkbox" />
                                                                    <label htmlFor="column-3">Coluna 3</label>
                                                                </li>
                                                                <li>
                                                                    <input id="column-4" className="input-checkbox" type="checkbox" />
                                                                    <label htmlFor="column-4">Coluna 4</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="table-editable-content">
                                        <div className={`${rowClasses} ${trType} ${openedQuickView ? 'rowSelected' : ''}`}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox2" />
                                                    <label htmlFor="checkbox2"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio2" />
                                                    <label htmlFor="radio2"></label>
                                                </div>

                                            }
                                            <div className={`${contentColumnClasses} ${extraInfo ? 'direction-row' : ''} ${changed}`} data-header="Coluna">
                                                {extraInfo &&
                                                    <div className="extra-info split-letter" data-letter="A">
                                                        <p>Lorem ipsum lorem ipsum lorem</p>
                                                    </div>
                                                }
                                                <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span>
                                            </div>
                                            <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        <p className="chip-text">LOREM IPSUM</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                    <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">Editar</div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} data-header="Coluna" />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item">03 - Maringá</li>
                                                            <li className="autocomplete-item">04 - Ponta Grossa</li>
                                                            <li className="autocomplete-item">05 - Foz do Iguaçú</li>
                                                            <li className="autocomplete-item">06 - Cianorte</li>
                                                            <li className="autocomplete-item">07 - Campo Mourão</li>
                                                        </ul>
                                                    </>
                                                }

                                                {!editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div>
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {headerButton && !scrollx && !buttons &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {columnSelector && !scrollx && !buttons &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }

                                            {headerButton && !scrollx && buttons === 'reduced' &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {columnSelector && !scrollx && buttons === 'reduced' &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            
                                            {buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        {hasQuickView &&
                                                            <button className="btn" onClick={this.toggleQuickView}>
                                                                <i className="fa fa-eye"></i>
                                                                <div className="btn-actions-label">Visualizar</div>
                                                            </button>
                                                        }
                                                        {hasQuickViewNew &&
                                                            <button className="btn" onMouseEnter={this.handleButtonQuickViewEnter} onMouseLeave={this.handleButtonQuickViewLeave}>
                                                                <i className="fa fa-eye"></i>
                                                                <div className="btn-actions-label">Visualizar</div>
                                                            </button>
                                                        }
                                                        {expanded &&
                                                            <button className={`btn ${showExpanded ? 'active' : ''}`} onClick={this.toggleExpanded}>
                                                                <i className="fa fa-list-ul"></i>
                                                                <div className="btn-actions-label">Detalhes</div>
                                                            </button>
                                                        }
                                                        <button className="btn error">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn warning">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links" tabIndex="-1">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                            {hasQuickView && !scrollx &&
                                                <div className={`quick-view ${openedQuickView ? 'open' : ''}`}>
                                                    <em className="fa fa-times" onClick={this.toggleQuickView}></em>
                                                    <div className="row">
                                                        <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                                                        <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                                                    </div>
                                                </div>
                                            }
                                            {hasQuickViewNew &&
                                                this.renderQuickviewNew()
                                            }
                                        </div>
                                        {expanded && showExpanded &&
                                            <div className="child-table">
                                                <div className="panel table">
                                                    <div className="panel-body">
                                                        <div className="table-editable">
                                                            <div className="table-editable-header">
                                                                <div className="row">
                                                                    <div className="col-md-4 column"><span>Coluna</span></div>
                                                                    <div className="col-md-4 column"><span>Coluna</span></div>
                                                                    <div className="col-md-4 column"><span>Coluna</span></div>
                                                                </div>
                                                            </div>
                                                            <div className="table-editable-content">
                                                                <div className={rowClasses}>
                                                                    <div className="col-md-4 column">Coluna</div>
                                                                    <div className="col-md-4 column">Coluna</div>
                                                                    <div className="col-md-4 column">Coluna</div>
                                                                    {buttons &&
                                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                                            <div className="btn-actions-inner">
                                                                                {hasQuickView &&
                                                                                    <button className="btn" onClick={this.toggleQuickView}>
                                                                                        <i className="fa fa-eye"></i>
                                                                                        <div className="btn-actions-label">Visualizar</div>
                                                                                    </button>
                                                                                }
                                                                                {hasQuickViewNew &&
                                                                                    <button className="btn" onMouseEnter={this.handleButtonQuickViewEnter} onMouseLeave={this.handleButtonQuickViewLeave}>
                                                                                        <i className="fa fa-eye"></i>
                                                                                        <div className="btn-actions-label">Visualizar</div>
                                                                                    </button>
                                                                                }
                                                                                {expanded &&
                                                                                    <button className="btn" onClick={this.toggleExpanded}>
                                                                                        <i className="fa fa-list-ul"></i>
                                                                                        <div className="btn-actions-label">Detalhes</div>
                                                                                    </button>
                                                                                }
                                                                                <button className="btn">
                                                                                    <i className="fa fa-trash"></i>
                                                                                    <div className="btn-actions-label">Excluir</div>
                                                                                </button>
                                                                                <button className="btn links" tabIndex="-1">
                                                                                    <i className="fa fa-eye"></i>
                                                                                    <div className="btn-actions-label">
                                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                                    </div>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                        <div className={rowClasses}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        <p className="chip-text">LOREM IPSUM</p>
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item">03 - Maringá</li>
                                                            <li className="autocomplete-item">04 - Ponta Grossa</li>
                                                            <li className="autocomplete-item">05 - Foz do Iguaçú</li>
                                                            <li className="autocomplete-item">06 - Cianorte</li>
                                                            <li className="autocomplete-item">07 - Campo Mourão</li>
                                                        </ul>
                                                    </>
                                                }

                                                {!editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div>
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {headerButton && !scrollx && !buttons &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {columnSelector && !scrollx && !buttons &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {headerButton && !scrollx && buttons === 'reduced' &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {columnSelector && !scrollx && buttons === 'reduced' &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links" tabIndex="-1">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        {fixed &&
                                            <>
                                                <div className={rowClasses}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <inputName onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item">03 - Maringá</li>
                                                                    <li className="autocomplete-item">04 - Ponta Grossa</li>
                                                                    <li className="autocomplete-item">05 - Foz do Iguaçú</li>
                                                                    <li className="autocomplete-item">06 - Cianorte</li>
                                                                    <li className="autocomplete-item">07 - Campo Mourão</li>
                                                                </ul>
                                                            </>
                                                        }

                                                        {!editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div>
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className={rowClasses}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item">03 - Maringá</li>
                                                                    <li className="autocomplete-item">04 - Ponta Grossa</li>
                                                                    <li className="autocomplete-item">05 - Foz do Iguaçú</li>
                                                                    <li className="autocomplete-item">06 - Cianorte</li>
                                                                    <li className="autocomplete-item">07 - Campo Mourão</li>
                                                                </ul>
                                                            </>
                                                        }

                                                        {
                                                            !editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div >
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {
                                                        scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {
                                                        headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {
                                                        columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {
                                                        buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                                <div className={rowClasses}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item" > 03 - Maringá</li >
                                                                    <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                                    <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                                    <li className="autocomplete-item" > 06 - Cianorte</li >
                                                                    <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                                </ul >
                                                            </>
                                                        }

                                                        {
                                                            !editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div >
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {
                                                        scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {
                                                        headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {
                                                        columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {
                                                        buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                                <div className={rowClasses}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item" > 03 - Maringá</li >
                                                                    <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                                    <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                                    <li className="autocomplete-item" > 06 - Cianorte</li >
                                                                    <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                                </ul >
                                                            </>
                                                        }

                                                        {
                                                            !editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div >
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {
                                                        scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {
                                                        headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {
                                                        columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {
                                                        buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                                <div className={rowClasses}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item" > 03 - Maringá</li >
                                                                    <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                                    <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                                    <li className="autocomplete-item" > 06 - Cianorte</li >
                                                                    <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                                </ul >
                                                            </>
                                                        }

                                                        {
                                                            !editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div >
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {
                                                        scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {
                                                        headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {
                                                        columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {
                                                        buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                                <div className={rowClasses}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item" > 03 - Maringá</li >
                                                                    <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                                    <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                                    <li className="autocomplete-item" > 06 - Cianorte</li >
                                                                    <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                                </ul >
                                                            </>
                                                        }

                                                        {
                                                            !editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div >
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {
                                                        scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {
                                                        headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {
                                                        columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {
                                                        buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                                <div className={rowClasses}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item" > 03 - Maringá</li >
                                                                    <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                                    <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                                    <li className="autocomplete-item" > 06 - Cianorte</li >
                                                                    <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                                </ul >
                                                            </>
                                                        }

                                                        {
                                                            !editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div >
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {
                                                        scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {
                                                        headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {
                                                        columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {
                                                        buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                            </>
                                        }
                                        {
                                            level &&
                                            <>
                                                <div className={`${rowClasses} father level-1`}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item">03 - Maringá</li>
                                                                    <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                                    <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                                    <li className="autocomplete-item" > 06 - Cianorte</li >
                                                                    <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                                </ul >
                                                            </>
                                                        }

                                                        {
                                                            !editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div >
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {
                                                        scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {
                                                        headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {
                                                        columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {
                                                        buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                                <div className={`${rowClasses} father level-2`}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item" > 03 - Maringá</li >
                                                                    <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                                    <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                                    <li className="autocomplete-item" > 06 - Cianorte</li >
                                                                    <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                                </ul >
                                                            </>
                                                        }

                                                        {
                                                            !editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div >
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {
                                                        scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {
                                                        headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {
                                                        columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {
                                                        buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                                <div className={`${rowClasses} level-2`}>
                                                    {checkbox && !radio &&
                                                        <div className="column checkbox direction-row">
                                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                            <label htmlFor="checkbox3"></label>
                                                        </div>
                                                    }
                                                    {radio && !checkbox &&
                                                        <div className="column radio direction-row">
                                                            <input type="text" className="input-radio" id="radio3" />
                                                            <label htmlFor="radio3"></label>
                                                        </div>

                                                    }
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                                    <div className={`${contentColumnClasses} ${highlight} ${lockable ? 'locked':''}`} data-header="Coluna">
                                                        {!status &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                        }
                                                        {status &&
                                                            <div className={statusClasses}>
                                                                LOREM IPSUM
                                                        </div>
                                                        }
                                                    </div>
                                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''} ${lockable ? 'disabled':''}`} data-header="Coluna">
                                                        {editable && !editing &&
                                                            <>
                                                                9387492
                                                            <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                                    <div className="hint-content">
                                                                        Editar
                                                                </div>
                                                                </div>
                                                            </>
                                                        }

                                                        {editing && !editableAuto &&
                                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                        }

                                                        {editing && editableAuto &&
                                                            <>
                                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                                <i className="fa fa-search input-icon"></i>
                                                                <ul className="autocomplete">
                                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                                    <li className="autocomplete-item" > 03 - Maringá</li >
                                                                    <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                                    <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                                    <li className="autocomplete-item" > 06 - Cianorte</li >
                                                                    <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                                </ul >
                                                            </>
                                                        }

                                                        {
                                                            !editable && !editing &&
                                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                        }
                                                    </div >
                                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                                    {
                                                        scrollx &&
                                                        this.renderExtraColumnToScrollx()
                                                    }
                                                    {
                                                        headerButton &&
                                                        <div className="col-sm-1 header-button"></div>
                                                    }
                                                    {
                                                        columnSelector &&
                                                        <div className="col-sm-1 column-selector"></div>
                                                    }
                                                    {
                                                        buttons &&
                                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                                            <div className="btn-actions-inner">
                                                                <button className="btn">
                                                                    <i className="fa fa-filter"></i>
                                                                    <div className="btn-actions-label">Filtrar</div>
                                                                </button>
                                                                <button className="btn">
                                                                    <i className="fa fa-trash"></i>
                                                                    <div className="btn-actions-label">Excluir</div>
                                                                </button>
                                                                <button className="btn links" tabIndex="-1">
                                                                    <i className="fa fa-eye"></i>
                                                                    <div className="btn-actions-label">
                                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                                    </div>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    }
                                                </div >
                                            </>
                                        }
                                    </div >
                                    {fancyTotals &&
                                        <div className="fancy-totals mb-xs">
                                            <div className="row">
                                                <div className="col-sm-2 col-sm-offset-4">
                                                    <div className="result positive">
                                                        <div className="key">Proventos</div>
                                                        <div className="value">R$ 1.285,72</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="result negative">
                                                        <div className="key">Descontos</div>
                                                        <div className="value">R$ 114,55</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="result neutral">
                                                        <div className="key">FGTS</div>
                                                        <div className="value"></div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-2">
                                                    <div className="result module-color">
                                                        <div className="key">Líquido</div>
                                                        <div className="value">R$ 1.171,17</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {footer &&
                                        <div className="table-editable-footer">
                                            <div className="row">
                                                <div className={contentColumnClasses} data-header="Coluna">
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span>
                                                </div>
                                                <div className={contentColumnClasses} data-header="Coluna">
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                </div>
                                                <div className={contentColumnClasses} data-header="Coluna">
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                </div>
                                                <div className={contentColumnClasses} data-header="Coluna">
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span>
                                                </div>
                                                {scrollx &&
                                                    this.renderExtraColumnToScrollx()
                                                }
                                                {!buttons && headerButton &&
                                                    <div className="header-button"></div>
                                                }
                                                {!buttons && columnSelector &&
                                                    <div className="column-selector"></div>
                                                }
                                                {buttons && buttons !== 'reduced' &&
                                                    <div className="col-sm-1"></div>
                                                }
                                            </div>
                                        </div>
                                    }
                        </div>
                    </div>
                    {subtitles &&
                            <div className="subtitles">
                                <div className="subtitle">Médias</div>
                                <div className="subtitle positive">Proventos</div>
                                <div className="subtitle negative">Descontos</div>
                                <div className="subtitle module-color">Totais</div>
                            </div>
                    }
                    {footerAlternative &&
                        <div className="altfooter-container hasInput">
                            <div className="altfooter-container-item">
                                <div className="switch-container">

                                    <em className="switch flat module-color "></em><span className="switch-caption nowrap">Lorem ipsum</span></div>
                            </div>
                            <div className="altfooter-container-item side-border flex">
                                <div className="col-md-2">
                                    <div className="form-group"><label className="label" for="field">Data Estorno</label><input type="text" id="field" /></div>
                                </div>
                                <div className="col-md-4">
                                    <div className="form-group"><label className="label" for="field">Processo</label><input type="text" id="field" /></div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group"><label className="label" for="field">Motivo</label><input type="text" id="field" /></div>
                                </div>
                            </div>
                            <div className="altfooter-container-item side-border">
                                <div className="altfooter-item-textsimple">1000</div>
                            </div>
                            <div className="altfooter-container-item side-border">
                                <div className="altfooter-item-textcomplex"><span>Valor:</span>
                                    <p>R$199,00</p>
                                </div>
                            </div>
                            <div className="altfooter-container-item side-border">
                                <div className="altfooter-item-textcomplex inline"><span>Valor:</span>
                                    <p>R$199,00</p>
                                </div>
                            </div>
                            <div className="altfooter-container-item side-border">

                                <button className="btn module-color">Botão</button></div>
                        </div>
                    }
                </div>
            </>
        )
    }

    renderClassesDiv = () => {
        return (
            <>
                <SwitchOptionsGroup label="Tipo Tabela" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="tableDiv" value={true} stateName="tableDiv" label="Tabela Tipo Div" />
                    <SwitchOptions attr="blurContent" value={true} stateName="blurContent" label="Conteúdo oculto" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="sortable" value={true} stateName="sortable" label="Com ordenação" />
                    <SwitchOptions attr="buttons" value='normal' stateName="buttons" label="Com botões visíveis" />
                    <SwitchOptions attr="buttons" value='reduced' stateName="buttons" label="Com botões escondidos" />
                    <SwitchOptions attr="border" value={true} stateName="border" label="Com borda" />
                    <SwitchOptions attr="inverted" value={true} stateName="inverted" label="Com cor invertida" hidden />
                    <SwitchOptions attr="fixed" value={true} stateName="fixed" label="Com header fixo" />
                    <SwitchOptions attr="footer" value={true} stateName="footer" label="Com footer" />
                    <SwitchOptions attr="footerWithTransparency" value={true} stateName="footerWithTransparency" label="Com footer transparente" hidden/>
                    <SwitchOptions attr="subtitles" value={true} stateName="subtitles" label="Com legenda" />
                    <SwitchOptions attr="fancyTotals" value={true} stateName="fancyTotals" label="Com totais customizados" />
                    <SwitchOptions attr="searchFilters" value={true} stateName="searchFilters" label="Com Filtro" />
                    <SwitchOptions attr="expanded" value={true} stateName="expanded" label="Com outra table dentro" />
                    <SwitchOptions attr="hasQuickViewNew" value={true} stateName="hasQuickViewNew" label="Quick view novo" />
                    <SwitchOptions attr="level" value={true} stateName="level" label="Com Níveis" />
                    <SwitchOptions attr="footerAlternative" value={true} stateName="footerAlternative" label="Com rodapé alternativo" />
                    <SwitchOptions attr="customHeader" value={true} stateName="customHeader" label="Com header customizado"/>
                    <SwitchOptions attr="placeholderLoader" value={true} stateName="placeholderLoader" label="Carregando" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Opções de Linha" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="trType" value='success' stateName="trType" label="Com destaque (success)" />
                    <SwitchOptions attr="trType" value='highlight' stateName="trType" label="Com destaque" />
                    <SwitchOptions attr="trType" value='error' stateName="trType" label="Com erro" />
                    <SwitchOptions attr="trType" value='warning' stateName="trType" label="Com aviso" />
                    <SwitchOptions attr="trType" value='rowSelected' stateName="trType" label="Selecionada" />
                    <SwitchOptions attr="scrollx" value={true} stateName="scrollx" label="Com scroll horizontal" />
                    <SwitchOptions attr="extraInfo" value={true} stateName="extraInfo" label="Com informação extra" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Opções de Coluna" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="headerButton" value={true} stateName="headerButton" label="Com botão no header" />
                    <SwitchOptions attr="columnSelector" value={true} stateName="columnSelector" label="Com seleção de coluna" />
                    <SwitchOptions attr="manyHeaderButtons" value={true} stateName="manyHeaderButtons" label="Com múltiplus botões no header" />
                    <SwitchOptions attr="checkbox" value={true} stateName="checkbox" label="Com checkbox" />
                    <SwitchOptions attr="radio" value={true} stateName="radio" label="Com radio button" />
                    <SwitchOptions attr="editable" value={true} stateName="editable" label="Editável" />
                    <SwitchOptions attr="editableAuto" value={true} stateName="editableAuto" label="Editável Com Autocomplete" />
                    <SwitchOptions attr="textAlign" value="" stateName="textAlign" label="Alinhar à esquerda" hidden/>
                    <SwitchOptions attr="textAlign" value="right" stateName="textAlign" label="Alinhar à direita" />
                    <SwitchOptions attr="status" value='module-color' stateName="status" label="Status Cor do Módulo" />
                    <SwitchOptions attr="status" value='positive' stateName="status" label="Status Cor Verde" />
                    <SwitchOptions attr="status" value='negative' stateName="status" label="Status Cor Vermelho" />
                    <SwitchOptions attr="highlight" value='highlight' stateName="highlight" label="Com Coluna Destacada" />
                    <SwitchOptions attr="changed" value='changed' stateName="changed" label="Com Coluna Alterada" />
                    <SwitchOptions attr="lockable" value={true} stateName="lockable" label="Com Coluna Travável" />
                </SwitchOptionsGroup>
            </>
        )
    }

    render() {
        const { theme } = this.props;
        const { tableDiv } = this.state;

        return (
            <>
                <ComponentTemplate
                    codeToRender={tableDiv ? this.renderComponentDiv : this.renderComponent}
                    optionalClasses={tableDiv ? this.renderClassesDiv : this.renderClasses}
                    theme={theme}
                    mainTitle="Tabela"
                    mainDescription="As tabelas exibem conjuntos de dados em linhas e colunas"
                >
                <HowToUse
                    title="Uso"
                    description="As tabelas de dados exibem informações em um formato de grade de linhas e colunas. Eles organizam as informações de uma maneira fácil de digitalizar, para que os usuários possam procurar padrões e informações. <br /> <br /> As tabelas de dados podem conter: <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Componentes interativos (como chips, botões ou menus) <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Elementos não interativos (como badges) <br />  &nbsp;&nbsp;&nbsp;&nbsp; • Ferramentas para consultar e manipular dados"
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Botão da linha (Opcional) <br /> 3. Ação extra / Seletor de Colunas (Opcional) <br /> 4. Ordenação (Opcional) <br/> 5. Título (Opcional) <br /> 6. Filtro (Opcional) <br /> 7. Destaque de Estado (Opcional) <br /> 8. Linhas <br />  9. Seletor de conteúdo (Opcional) <br /> 10. Rodapé (Opcional)"
                    />
                </HowToUse>
                <HowToUse
                    title="Hierarquia"
                    subtitle="Elementos de linha de base"
                    description="Os nomes de cabeçalho da coluna descrevem o tipo de conteúdo exibido em cada coluna. Cada linha contém dados relacionados a uma única informação."
                />
                <HowToUse
                    subtitle="Elementos interativos"
                    description="As tabelas de dados podem incluir elementos interativos, como: <br /> &nbsp;&nbsp;&nbsp;&nbsp;• Caixas de seleção (nas linhas) <br /> &nbsp;&nbsp;&nbsp;&nbsp;• Classificação (nas colunas) <br /> &nbsp;&nbsp;&nbsp;&nbsp;• Ícones que comunicam alertas <br /> Os controles que manipulam como os dados da tabela são exibidos (como paginação ou filtro) devem ser colocados diretamente acima ou abaixo de uma tabela."
                >
                    <HowToUseFigure
                        srcImg={iterativos}
                        descriptionImg="Tabela com filtro e paginação"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Texto"
                    description="O texto do cabeçalho da coluna usa uma fonte de peso médio (para diferenciá-lo do texto da linha). Quando temos um texto com a largura maior que a da coluna este é truncado com reticências. Ao passar o mouse, uma dica mostra o nome completo."
                >
                    <HowToUseFigure
                        srcImg={textos}
                        descriptionImg="Cabeçalhos de coluna longos são truncados com reticências. Passar o mouse sobre um cabeçalho de coluna truncado revela o texto completo, usando uma dica de ferramenta."
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Ordenação"
                    description="Para ajudar os usuários a classificar informações, uma coluna pode exibir a classificação por padrão. É utilizado para indicar qual coluna está classificada por padrão um ícone de seta para cima ou para baixo ao lado do nome do cabeçalho da coluna."
                >
                    <HowToUseFigure
                        srcImg={ordenacao}
                        captionImg="A ordem decrescente é indicada com uma seta para baixo."
                        descriptionImg="Para reverter a ordem de classificação de uma coluna, o usuário toca no nome do cabeçalho ou no ícone de seta. Após a classificação, o ícone da seta gira 180 graus."
                    />
                </HowToUse>
                <HowToUse
                    title="Comportamento"
                    subtitle="Exibindo botões escondidos"
                    description="Se houver botões de ações os mesmos serão exibidos quando o usuário passar o mouse sobre uma linha. <br /> A cor de fundo da linha será alterada para que a linha atual seja destacada."
                >
                    <HowToUseFigure
                        srcImg={botoes}
                        captionImg="Exibição dos botões ao passar o mouse."
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Focus"
                    description="Ao usar o foco do teclado, um indicador aparece como guia dos usuários através de itens interativos (como caixas de seleção)."
                >
                    <HowToUseFigure
                        srcImg={focus}
                        captionImg="Foco do teclado na caixa de seleção"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Paginação"
                    description="Os controles de paginação fornecem acesso rápido a todas as páginas, enquanto indicam que existem mais páginas. <br /> Eles exibem: <br /> &nbsp;&nbsp;&nbsp;&nbsp;• Número de linhas por página <br /> Número de linhas por página • Setas esquerda e direita para navegar nas páginas, e navegar para a primeira e última página Setas esquerda e direita para navegar nas páginas, e navegar para a primeira e última página <br /> &nbsp;&nbsp;&nbsp;&nbsp;• Número total de páginas <br /> A paginação é colocada na parte inferior de uma tabela."
                >
                    <HowToUseFigure
                        srcImg={paginacao}
                        captionImg="Dez linhas por página são exibidas, com um total de 110 linhas."
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Alinhamento"
                    description="O conteúdo da tabela de dados nunca deve ser centralizado. Para todos os nomes, títulos e outros textos, alinhe à esquerda. Os números com formatação de moeda ficam à direita. <br /> O conteúdo centralizado se torna mais difícil de escanear à medida que a tabela se torna mais densa. Dê ao usuário uma “linha”, mesmo que invisível, para acompanhar ao lado."
                >
                    <HowToUseFigure
                        col
                        status="dont"
                        srcImg={dont1}
                        descriptionImg="Não centralize o conteúdo em todas as células, independentemente do tamanho da tabela."
                    />
                    <HowToUseFigure
                        col
                        status="do"
                        srcImg={do1}
                        descriptionImg="Alinhe para a esquerda ou direita, dependendo do tipo de conteúdo."
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Formação de texto"
                    description="O texto em negrito é reservado para cabeçalhos, deve ser evitado nas células. Além disso, geralmente não é necessário formatar o texto pois muitos estilos diferentes podem deixar a tabela ilegível."
                >
                    <HowToUseFigure
                        col
                        status="dont"
                        srcImg={dont2}
                        descriptionImg="Não misture formatos em um esforço para chamar informações importantes."
                    />
                    <HowToUseFigure
                        col
                        status="do"
                        srcImg={do2}
                        descriptionImg="Crie dados que existem bem por si só."
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Múltiplas linhas"
                    description="A quebra de linha pode ocorrer ao redimensionar a tela, mas evite forçar uma quebra de linha. Cada célula deve conter apenas um pedaço de dados. O padrão é explicitar as coisas, mas se o espaço se tornar um problema, procure abreviar. Os números sempre devem ser cardinais, não 1º, 2º etc. Use números inteiros sempre que possível e inclua “%” sempre que for uma porcentagem. Frases completas nunca devem aparecer como dados da célula."
                >
                    <HowToUseFigure
                        col
                        srcImg={caution1}
                        status="caution"
                        descriptionImg="Evite usar quebras de linha para colocar toneladas de conteúdo em uma única célula."
                    />
                    <HowToUseFigure
                        col
                        srcImg={do4}
                        status="do"
                        descriptionImg="Mantenha as coisas breves para evitar quebras naturais."
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Tipos"
                    description="Tabelas podem ser construídas usando dois tipos de estrutura, sendo elas: <br /> &nbsp;&nbsp;&nbsp;&nbsp;• Utilizando estrutura com div <br /> &nbsp;&nbsp;&nbsp;&nbsp;• Utilizando a estrutura table padrão <br /> Não existe diferença visual entre elas, a diferença fica no comportamento da estrutura quando organizamos as colunas."
                >
                    <HowToUseFigure
                        col
                        captionImg="Tabela div"
                        srcImg={tipo1}
                    />
                    <HowToUseFigure
                        col
                        captionImg="Tabela table"
                        srcImg={tipo2}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Gerais"
                    description="Variações que alteram o comportamento estrutural geral da tabela, sendo elas: <br /> • Com ordenação <br /> • Com header fixo <br /> • Com rodapé, rodapé transparente ou rodapé alternativo <br /> • Com Filtro <br /> • Com Níveis <br /> • Com scroll horizontal <br /> • Loading"
                >
                    <HowToUseFigure
                        col
                        status="dont"
                        srcImg={dont3}
                        descriptionImg="Não use rodapé junto a uma paginação"
                    />
                    <HowToUseFigure
                        col
                        status="dont"
                        srcImg={dont4}
                        descriptionImg="Não utilize uma tabela sem paginação caso ela tenha mais conteúdo que uma página."
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Opções de Estilo"
                    descriptionImg="Variações que alteram o visual da tabela, modificando cores/estilos de partes comuns da tabela, sendo elas: <br /> • Com borda <br /> • Cor invertida <br /> • Com borda no thead"
                >
                    <HowToUseFigure
                        col
                        status="do"
                        srcImg={do3}
                        descriptionImg="Estilos diferentes devem ser usados para dar destaque a certos elementos."
                    />
                    <HowToUseFigure
                        col
                        status="dont"
                        srcImg={dont5}
                        descriptionImg="Não use muitos estilos ao mesmo tempo para não sobrecarregar a tela."
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Opções de Componentes Adicionais"
                    description="Variações que adicionam componentes extras na tabela, sendo elas: <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Com botões visíveis, escondidos e/ou no header <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Com Sub-table <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Com seleção de colunas <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Com checkbox <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Com radio button <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Status (Cor do módulo, sucesso ou erro)"
                >
                    <HowToUseFigure
                        col
                        srcImg={caution2}
                        status="caution"
                        descriptionImg="Utilize os componentes adicionais da mesma forma como são mostrados nos exemplos. Modificá-los pode impactar no seu design posteriormente"
                    />
                    <HowToUseFigure
                        col
                        srcImg={dont6}
                        descriptionImg="Evite sobrecarregar tabelas com componentes extras. Se isso é necessário, reconsidere e tente analisar outra forma de exibir o conteúdo."
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Opções de Linha"
                    description="Variações que alteram o estilo específico das linhas da tabela, sendo elas: <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Com destaque (Cor do módulo, sucesso, erro ou aviso) <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Selecionada <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Listrada <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Com informação extra"
                >
                    <HowToUseFigure
                        status="caution"
                        descriptionImg="Não use muitas variações ao mesmo tempo para não sobrecarregar o estilo das linhas."
                        srcImg={caution3}
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Opções de Coluna"
                    description="Variações que alteram o estilo específico das colunas da tabela, sendo elas: <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Editável <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Alinhar (Esquerda, centro, e direita) <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Com Coluna Destacada <br /> &nbsp;&nbsp;&nbsp;&nbsp; • Com Coluna Alterada"
                >
                    <HowToUseFigure
                        srcImg={caution4}
                        status="caution"
                        descriptionImg="Não use muitas variações ao mesmo tempo para não sobrecarregar o estilo das colunas."
                    />
                </HowToUse>

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}
export default connect(mapStateToProps)(Tables);
