import React, { Component } from 'react';
import classnames from 'classnames';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import ComponentTemplate from '../Templates/ComponentTemplate';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import AdvancedFilter from './../AdvancedFilter/AdvancedFilter';

import anatomia from '../../assets/img/components/filters/anatomia.png';
import anatomia2 from '../../assets/img/components/filters/anatomia2.png';

const files = [
    { 'less': ['tables.less'] },
    { 'less': ['advanced-filter.less'] },
    { 'angular': ['elo-filter.module.ts'] },
    { 'react': ['SearchFilter.tsx'] }
];


class Filter extends Component {

    state = {
        filter1: 'Código',
        filter2: 'Contém',
        filter3: undefined,
        filters: ['Isso é um filtro'],
        hasDateTime: false,
        type: 'default',
        currentIndex: undefined,
        more: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Filtros');
        this.setState({ currentIndex: index });
    }

    toggleMore = () => {
        this.setState(state => {
            return {
                more: !state.more
            }
        })
    }

    changeFilter = (event, id) => {
        const returnObj = {};
        returnObj[`filter${id}`] = event.target.value;
        this.setState(returnObj);
    }

    applyFilter = () => {
        this.setState(state => {
            return {
                filters: [...state.filters, state.filter1 + ' ' + state.filter2 + ' ' + state.filter3]
            }
        })
    }

    removeFilter = id => {
        const { filters } = this.state;
        filters.splice(id, 1);
        this.setState({ filters: filters })
    }

    renderFilters = (filter, id) => {
        return (
            <span className="filter-tag" key={`filterkey ${id}`}>
                {filter}
                <i className="fa fa-times remove" onClick={() => this.removeFilter(id)}></i>
            </span>
        )
    }

    activeCategory = event => {
        const elem = event.target;

        if (!elem.classList.contains('active') && !elem.parentElement.classList.contains('active')) {
            elem.classList.add('active');
        } else {
            elem.classList.remove('active');
            elem.parentElement.classList.remove('active');
        }
    }

    renderComponent = () => {
        const { filter1, filter2, filters, hasDateTime, type, more } = this.state;

        const panelFilterClasses = classnames('panel-table-filter', { 'category': type === 'category' })

        return (
            <>
                {type !== 'advanced' && type !== 'category' &&
                    <div className="panel table">
                        <div className="panel-title"></div>
                        <div className="panel-body">
                            <div className="panel-table-filter">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <select aria-label="search input" onChange={event => this.changeFilter(event, '1')} value={filter1}>
                                                <option>
                                                    Código
                                        </option>
                                                <option>
                                                    Requerente
                                        </option>
                                                <option>
                                                    CPF/CNPJ
                                        </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <select aria-label="search input" onChange={event => this.changeFilter(event, '2')} value={filter2}>
                                                <option>
                                                    Contém
                                        </option>
                                                <option>
                                                    Igual
                                        </option>
                                                <option>
                                                    Diferente de
                                        </option>
                                            </select>
                                        </div>
                                    </div>
                                    {!hasDateTime &&
                                        <div className="col-sm-5">
                                            <div className="form-group">
                                                <input aria-label="campo de pesquisa" type="text" name="" placeholder="Digite o filtro..." required="" className="button-input" onChange={event => this.changeFilter(event, '3')} />
                                                <button className={`btn filter ${hasDateTime ? 'has-input-datetime' : ''}`} onClick={this.applyFilter}>Filtrar</button>
                                            </div>
                                        </div>
                                    }
                                    {hasDateTime &&
                                        <React.Fragment>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <div className="group-datetime row">
                                                        <div className="col-md-3">
                                                            <input type="text" placeholder="00/00/0000" /><i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                                                            <div className="datepicker right">
                                                                <div className="datepicker-header">
                                                                    <div className="datepicker-header-year">

                                                                        <em className="fa fa-chevron-left"></em><b className="year-number">2016</b>

                                                                        <em className="fa fa-chevron-right"></em></div>
                                                                    <div className="datepicker-header-month">

                                                                        <em className="fa fa-chevron-left"></em><b className="month-name">Outubro</b>

                                                                        <em className="fa fa-chevron-right"></em></div>
                                                                </div>
                                                                <div className="datepicker-calendar">
                                                                    <div className="datepicker-calendar-header">
                                                                        <li className="item">Dom</li>
                                                                        <li className="item">Seg</li>
                                                                        <li className="item">Ter</li>
                                                                        <li className="item">Qua</li>
                                                                        <li className="item">Qui</li>
                                                                        <li className="item">Sex</li>
                                                                        <li className="item">Sab</li>
                                                                    </div>
                                                                    <li className="item not-this-month">25</li>
                                                                    <li className="item not-this-month">26</li>
                                                                    <li className="item not-this-month">27</li>
                                                                    <li className="item not-this-month">28</li>
                                                                    <li className="item not-this-month">29</li>
                                                                    <li className="item not-this-month">30</li>
                                                                    <li className="item">1</li>
                                                                    <li className="item">2</li>
                                                                    <li className="item">3</li>
                                                                    <li className="item">4</li>
                                                                    <li className="item">5</li>
                                                                    <li className="item">6</li>
                                                                    <li className="item">7</li>
                                                                    <li className="item">8</li>
                                                                    <li className="item">9</li>
                                                                    <li className="item">10</li>
                                                                    <li className="item">11</li>
                                                                    <li className="item">12</li>
                                                                    <li className="item">13</li>
                                                                    <li className="item">14</li>
                                                                    <li className="item">15</li>
                                                                    <li className="item">16</li>
                                                                    <li className="item">17</li>
                                                                    <li className="item">18</li>
                                                                    <li className="item">19</li>
                                                                    <li className="item">20</li>
                                                                    <li className="item">21</li>
                                                                    <li className="item">22</li>
                                                                    <li className="item">23</li>
                                                                    <li className="item">24</li>
                                                                    <li className="item">25</li>
                                                                    <li className="item">26</li>
                                                                    <li className="item">27</li>
                                                                    <li className="item">28</li>
                                                                    <li className="item">29</li>
                                                                    <li className="item">30</li>
                                                                    <li className="item">31</li>
                                                                    <li className="item not-this-month">1</li>
                                                                    <li className="item not-this-month">2</li>
                                                                    <li className="item not-this-month">3</li>
                                                                    <li className="item not-this-month">4</li>
                                                                    <li className="item not-this-month">5</li>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <input className="has-input-icon" type="time" />
                                                            <i className="fa fa-clock-o input-icon" />
                                                        </div>
                                                    </div>
                                                    <button className={`btn filter ${hasDateTime ? 'has-input-datetime' : ''}`} onClick={this.applyFilter}>Filtrar</button>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    }
                                </div>
                                <div className="panel-filter-tags">
                                    {filters.map((filter, id) => this.renderFilters(filter, id))}
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {type === 'advanced' &&
                    <AdvancedFilter />
                }
                {type === 'category' &&
                    <div className="panel table">
                        <div className="panel-title"></div>
                        <div className="panel-body">
                            <div className={panelFilterClasses}>
                                <AdvancedFilter category />
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="categories typography-size-12px">
                                            <h4 className="typography-bold">Categoria 1</h4>
                                            <ul>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                {more &&
                                                    <>
                                                        <li onClick={this.activeCategory}>
                                                            Lorem ipsum
                                                            <i className="fa fa-times-circle icon"></i>
                                                        </li>
                                                        <li onClick={this.activeCategory}>
                                                            Lorem ipsum
                                                            <i className="fa fa-times-circle icon"></i>
                                                        </li>
                                                        <li onClick={this.activeCategory}>
                                                            Lorem ipsum
                                                            <i className="fa fa-times-circle icon"></i>
                                                        </li>
                                                        <li onClick={this.activeCategory}>
                                                            Lorem ipsum
                                                            <i className="fa fa-times-circle icon"></i>
                                                        </li>
                                                        <li onClick={this.activeCategory}>
                                                            Lorem ipsum
                                                            <i className="fa fa-times-circle icon"></i>
                                                        </li>
                                                        <li onClick={this.activeCategory}>
                                                            Lorem ipsum
                                                            <i className="fa fa-times-circle icon"></i>
                                                        </li>
                                                        <li onClick={this.activeCategory}>
                                                            Lorem ipsum
                                                            <i className="fa fa-times-circle icon"></i>
                                                        </li>
                                                    </>
                                                }
                                            </ul>
                                            <button className="btn text module-color typography-underline" onClick={this.toggleMore}>
                                                {more ? 'Ver Menos' :'Ver Mais'}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="categories typography-size-12px">
                                            <h4 className="typography-bold">Categoria 2</h4>
                                            <ul>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                                <li onClick={this.activeCategory}>
                                                    Lorem ipsum
                                                   <i className="fa fa-times-circle icon"></i>
                                                </li>
                                            </ul>
                                            <button className="btn text module-color typography-underline">
                                                Ver Mais
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <button className="btn text module-color typography-underline mt-xs pull-right">
                                            Mais Categorias
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderClasses = () => {
        return (
            <SwitchOptionsGroup label="Variação" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="has-input-datetime" value={true} stateName="hasDateTime" label="Tem filtro do tipo data" />
                <SwitchOptions attr="type" value="advanced" stateName="type" label="Filtro avançado" />
                <SwitchOptions attr="type" value="category" stateName="type" label="Filtro com categorias" />
            </SwitchOptionsGroup>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderClasses}
                    theme={theme}
                    mainTitle="Filtros"
                    mainDescription="Encontre, separe, visualize apenas conteúdo desejado"
                >
                <HowToUse
                    title="Uso"
                    description="Filtros são utilizados para separar e encontrar informação dentro de uma massa de dados maior que “uma página”."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Informações do filtro: Campo (informação que será filtrada), Modificador (Maior que, Menor que, igual), Filtro (Valor a ser filtrado) <br /> 3. Botão para adicionar o filtro à lista de filtro <br /> 4. Chip representando um item na lista de filtro, com o seu valor"
                    />
                    <HowToUseFigure
                        srcImg={anatomia2}
                        descriptionImg="1. Container <br /> 2. Filtros utilizados recentemente <br /> 3. Chip representando um item na lista de filtro, com o seu valor <br />4. Botão para adicionar o filtro à lista de filtro"
                    />
                </HowToUse>

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Filter);
