import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { KeycloakService } from "./service";
import { Provider } from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import store from "./store";

let initOptions = {
  url: "https://openid.dev.elotech.com.br/auth",
  realm: "arcdesign",
  resource: "arcdesign-frontend",
  clientId: "arcdesign-frontend",
  "auth-server-url": "/auth",
  "ssl-required": "external",
  "public-client": true,
  onLoad: "login-required",
};

KeycloakService.init(initOptions)
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      console.info("Authenticated");
    }

    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      document.getElementById("root")
    );

    setTimeout(() => {
      KeycloakService.refreshToken()
        .then((refreshed) => {
          if (refreshed) {
            console.debug("Token refreshed" + refreshed);
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  KeycloakService.getInstance().tokenParsed.exp +
                    KeycloakService.getInstance().timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 5000);
  })
  .catch(() => {
    console.error("Authenticated Failed");
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
