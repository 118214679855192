import React, { Component } from 'react';

import areaImg from '../../assets/img/modules/administracao.png';
import moduleImg from '../../assets/img/modules/administracao-almoxarifadologistica.png';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class MenuJuggernaut extends Component {
    searchInputMenu = React.createRef();

    state = {
        open: false,
        sidebarToggle: false,
        currentIndex: undefined,
        type: undefined,
        openDrop: false,
        focusedSearchInput: false
    }

    toggleDropdownList = () => {
        this.setState(state => {
            return { openDrop: !state.openDrop }
        })
    }

    onChange = newState => {
        return this.setState(newState);
    }

    checkVisibleSubMenu = () => {
        return (!this.props.floatType && window.innerWidth > 768) || window.innerWidth < 769 || (this.props.floatType && this.focusedSearchInput);
    }

    handleMouseEnterMenuFloatType = (e) => {
        const { focusedSearchInput } = this.state;

        let aside = document.querySelector('aside.sidebar-toggle');
        if (this.props.floatType && window.innerWidth > 1024 && !focusedSearchInput) {
            aside.classList.add('hover');
            let child = e.currentTarget.querySelector('ul');
            if (child) {
                let childHeight = child.offsetHeight;

                let bounding = e.currentTarget.getBoundingClientRect();
                let offsetTop = bounding.y;
                console.log('offsetTop => ', offsetTop);
                let windowHeight = window.innerHeight;
                console.log('windowHeight => ', windowHeight);
                if (childHeight > windowHeight) {
                    console.log('childHeight is bigger than windowHeight')
                    childHeight = windowHeight - 310;
                }
                if (offsetTop + childHeight > windowHeight) {
                    offsetTop = offsetTop - ((offsetTop + childHeight) - windowHeight) - 5;
                    console.log('new offsetTop => ', offsetTop);
                }
                child.style.left = bounding.x + bounding.width + 'px';
                child.style.maxHeight = childHeight + 'px';
                child.style.top = offsetTop + 'px';
            }
        }
    }

    handleMouseLeaveMenuFloatType = (e) => {
        const { focusedSearchInput } = this.state;
        let aside = document.querySelector('aside.sidebar-toggle');
        if (this.props.floatType && window.innerWidth > 1024 && !focusedSearchInput) {
            let child = e.currentTarget.querySelector('ul');
            e.stopPropagation();
            if (child) {
                e.currentTarget.querySelector('ul').style.top = 0;
            }
            aside.classList.remove('hover');
        }
    }

    toggleSubItensWhenMenuIsOpen = (e) => {
        const currentElement = e.currentTarget;
        const nextSibling = currentElement.nextSibling ? currentElement.nextSibling : '';
        const classes = currentElement.classList;

        console.log('next > ', currentElement.nextSibling);
       
        if (classes.contains('open')) {
            classes.remove('open');
            if(nextSibling) {
                nextSibling.classList.remove('visible-block');
            }
        } else {
            classes.add('open');
            nextSibling.classList.add('visible-block');
        }
    }

    handleOnFocus = () => {
        this.setState({ focusedSearchInput: true }, () => {
            setTimeout(() => {
                this.searchInputMenu.current.focus();
            }, 100)
            this.handleClickOnFocusedSearchInput();
        });
    }

    handleClickOnFocusedSearchInput = e => {
        const { focusedSearchInput } = this.state;

        document.addEventListener('click', e => {
            if (focusedSearchInput) {
                if (e.target.classList.contains('main-content') || e.target.closest('.main-content')) {
                    this.setState({ focusedSearchInput: false });
                }
            }
        })
    }

    renderLi = () => {
        return (
            <>

                <li className="on" onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                    <a href="#menu">
                        <i className="fa fa-home"></i>
                        <b>Início</b>
                    </a>
                </li>
                <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                    <a href="#menu" className="dropdown-arrow" onClick={this.toggleSubItensWhenMenuIsOpen}>
                        <i className="fa fa-plus-square"></i><b> Cadastros </b>
                    </a>
                    <ul attr-parent="Cadastros">
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu" className="dropdown-arrow" onClick={this.toggleSubItensWhenMenuIsOpen}>
                                <i className="fa iconmoon fa-user"></i>
                                <b>Pessoa</b>
                            </a>
                            <ul attr-parent="Pessoa">
                                <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                                    <a href="#menu">
                                        <i className="fa iconmoon fa-file"></i>
                                        <b>Física</b>
                                    </a>
                                </li>
                                <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                                    <a href="#menu">
                                        <i className="fa iconmoon fa-file"></i>
                                        <b>Jurídica</b>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-file"></i>
                                <b>Documento</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li><li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                        <li onMouseEnter={this.handleMouseEnterMenuFloatType} onMouseLeave={this.handleMouseLeaveMenuFloatType}>
                            <a href="#menu">
                                <i className="fa iconmoon fa-map"></i>
                                <b>Logradouro</b>
                            </a>
                        </li>
                    </ul>
                </li>
            </>
        )
    }

    render() {

        const { open, type, upAction, floatType } = this.props;
        const { openDrop, focusedSearchInput } = this.state;

        const sidebarClasses = classnames(
            'sidebar',
            {
                'fancy': type === 'fancy',
                'styled': type === 'styled',
                'float-type': floatType && !focusedSearchInput,
                'sidebar-toggle': open,
                'new': type === 'new',
                'focused-search': focusedSearchInput
            }
        )

        const btnDropdownClasses = classnames(
            'btn-dropdown',
            { 'open': openDrop }

        )

        const btnClasses = classnames(
            'btn circle',
            { 'module-color': open }
        )

        return (
            <>
                <aside className={sidebarClasses}>
                    {type !== 'new' &&
                        <header className="sidebar-header">
                            {type === "styled" &&
                                <button className="header-toggle-sidebar toggle-control sidebar-toggle" aria-label="Botão para expandir o menu" onClick={upAction}>
                                    <em className="fa fa-bars"></em>
                                </button>
                            }
                            
                            <div className="sidebar-module">
                                <img className="module-main" src={areaImg} alt="Imagem da Área" />
                                <img className="module-module" src={moduleImg} alt="Imagem do Módulo" />
                            </div>


                            <div className="sidebar-title">
                                Almoxarifado
                                    {type === "styled" &&
                                    <small> Prefeitura Municipal de Maringá </small>
                                }
                            </div>
                        </header>
                    }

                    {type === 'fancy' &&
                        <div className="sidebar-user-info">
                            <figure style={{ backgroundImage: 'url(https://images.pexels.com/photos/450271/pexels-photo-450271.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260)' }}></figure>
                            <div className={btnDropdownClasses} onClick={this.toggleDropdownList}>
                                <button className={btnClasses}>
                                    <em className="fa fa-cogs"></em>
                                </button>
                                <ul className="btn-dropdown-list">
                                    <li>
                                        <a href="#menu"> Lorem ipsum </a>
                                    </li>
                                    <li>
                                        <a href="#menu"> Lorem ipsum </a>
                                    </li>
                                    <li>
                                        <a href="#menu"> Lorem ipsum </a>
                                    </li>
                                    <li>
                                        <a href="#menu"> Lorem ipsum </a>
                                    </li>
                                </ul>
                            </div>

                            <article>
                                Olá, <span>João da Silva</span>
                            </article>
                        </div>
                    }
                    
                    <div className="sidebar-search" onClick={this.handleOnFocus}>
                        <input id="sidebar-search-input" aria-label="input-search" placeholder="Buscar em Menu.." ref={ this.searchInputMenu } />
                        <i aria-hidden="true" className="fa fa-search"></i>
                    </div>
                    <ul className="sidebar-itens">
                        {floatType &&
                            <div>
                                {this.renderLi()}
                            </div>
                        }

                        {!floatType &&
                            <>
                                {this.renderLi()}
                            </>
                        }

                    </ul>
                    {type === 'new' &&
                        <button className="header-toggle-sidebar toggle-control hidden-xs hidden-sm" aria-label="Botão para expandir o menu" onClick={upAction}>
                            {open && 
                                <FontAwesomeIcon icon="angle-double-right" />
                            }
                            {!open &&
                                <>
                                    <FontAwesomeIcon icon="angle-double-left" className="mr-xs"/>
                                    Recolher Menu
                                </>
                            }
                        </button>
                    }
                </aside>
            </>
                )
            }
        }
        
        export default MenuJuggernaut;
