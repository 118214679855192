import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import classnames from 'classnames';
import { connect } from 'react-redux';
import HowToUseFigure from '../Templates/HowToUseFigure';
import HowToUse from './../Templates/HowToUse';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import anatomia from '../../assets/img/components/wizard/anatomia.png';
import footerfixo from '../../assets/img/components/wizard/footerfixo.png';
import headerfixo from '../../assets/img/components/wizard/headerfixo.png';
import error from '../../assets/img/components/wizard/error.png';

const files = [
    { 'less': ['wizard.less'] },
    { 'js': ['wizard-fixed.js'] },
    { 'angular': ['wizard-control.module.ts'] },
    { 'react': ['Wizard.jsx'] }
];

class Wizard extends Component {

    state = {
        stepActived: 1,
        error: false,
        type: '',
        hasExtraButtons: false,
        fixed: false,
        currentIndex: undefined,
        vertical: undefined
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Passo a Passo');
        this.setState({ currentIndex: index });
    }

    componentDidUpdate() {

        const { theme } = this.props;
        if (this.state.fixed && theme === 'dark' && !this.state.vertical) {
            document.querySelector('.wizard-content').addEventListener('scroll', () => {
                var scrollTop = document.querySelector('.wizard-content').scrollTop;
                var element = document.querySelector('.wizard.fixed');

                var step1 = document.querySelector('#titulo-passo-1').offsetTop;
                var step2 = document.querySelector('#titulo-passo-2').offsetTop;
                var step3 = document.querySelector('#titulo-passo-3').offsetTop;

                if (scrollTop > 58 && !element.classList.contains('onscroll')) {
                    element.classList.add('onscroll');
                }

                if (scrollTop === 0) {
                    element.classList.remove('onscroll');
                }

                if (scrollTop < step1) {
                    document.querySelector('.wizard-header .step-1').classList.add('active')
                }

                if (scrollTop < step2) {
                    document.querySelector('.wizard-header .step-2').classList.remove('active')
                }

                if (scrollTop >= (step2 - 60)) {
                    document.querySelector('.wizard-header .step-2').classList.add('active')
                }

                if (scrollTop < step3) {
                    document.querySelector('.wizard-header .step-3').classList.remove('active')
                }

                if (scrollTop >= (step3 - 60)) {
                    document.querySelector('.wizard-header .step-3').classList.add('active')
                }
            })
        }
    }


    nextStep = () => {
        const { stepActived } = this.state;

        if (stepActived === 3) {
            return this.setState({ stepActived: 1 })
        }

        this.setState({ stepActived: stepActived + 1 })

    }

    prevStep = () => {
        const { stepActived } = this.state;
        if (stepActived > 1) {
            this.setState({ stepActived: stepActived - 1 })
        }
    }

    handleStep = (step) => {
        this.setState({ stepActived: step });
    }

    renderComponent = () => {
        const { stepActived, error, type, hasExtraButtons, fixed, vertical } = this.state;
        const wizardClasses = classnames(
            'wizard',
            type,
            { fixed },
            { vertical }
        )
        return (
            <div className={wizardClasses} role="group">
                {!vertical &&
                    <>
                        <ul className="wizard-header">
                            <li className={`step step-1 ${stepActived ? 'active' : ''} ${stepActived === 1 ? 'active-mobile' : ''}`} data-error={!!error} onClick={() => this.handleStep(1)}>
                                <em className="fa fa-user" />
                                <div className="error-content" role="tooltip">Campos Obrigatórios</div>
                                <h3 className="title">
                                    Título Passo 1
                                </h3>
                            </li>
                            <li className={`step step-2 ${stepActived === 2 || stepActived === 3 ? 'active' : ''} ${stepActived === 2 ? 'active-mobile' : ''}`} onClick={() => this.handleStep(2)}>
                                <em className="fa fa-user" />
                                <h3 className="title">
                                    Título Passo 2
                                </h3>
                            </li>
                            <li className={`step step-3 ${stepActived === 3 ? 'active' : ''} ${stepActived === 3 ? 'active-mobile' : ''}`} onClick={() => this.handleStep(3)}>
                                <em className="fa fa-user" />
                                <h3 className="title">
                                    Título Passo 3
                                </h3>
                            </li>
                        </ul>
                        <ul className="wizard-content">
                            {!fixed &&
                                <>
                                    <li className={`step step-1 ${stepActived === 1 ? 'active' : ''}`}>
                                        step 1
                                    </li>
                                    <li className={`step step-2 ${stepActived === 2 ? 'active' : ''}`}>
                                        step 2
                                    </li>
                                    <li className={`step step-3 ${stepActived === 3 ? 'active' : ''}`}>
                                        step 3
                                    </li>
                                </>
                            }
                            {fixed &&
                                <li className="step active">
                                    <div className="typography-title-t4" id="titulo-passo-1">Título Passo 1</div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div className="typography-title-t4" id="titulo-passo-2">Título Passo 2</div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div className="typography-title-t4" id="titulo-passo-3">Título Passo 3</div>
                                </li>
                            }
                        </ul>
                        <div className="footer-wizard" role="navigation">
                            <button className="btn btn-step-backward neutral inline" onClick={this.prevStep}>
                                Anterior
                            </button>
                            <button className="btn btn-step-forward module-color" onClick={this.nextStep}>
                                {stepActived !== 3 && 'Próximo'} {stepActived === 3 && 'Finalizar'}
                            </button>
                            {hasExtraButtons &&
                                <div className="extra-buttons" role="group">
                                    <button className="btn circle pull-right module-color">
                                        <em className="fa fa-print"></em>
                                        <div className="btn-actions-label" role="tooltip"> Imprimir </div>
                                    </button>
                                </div>
                            }
                        </div>
                    </>
                }
                {vertical &&
                    <ul className="wizard-steps">
                        <li className={`step negative step-1 ${stepActived ? 'active' : ''} ${stepActived === 1 ? 'active-mobile' : ''}`} data-error={!!error} onClick={() => this.handleStep(1)}>
                            <em className="fa fa-user" />
                            <div className="text">
                                <div className="error-content" role="tooltip">Campos Obrigatórios</div>
                                <h3 className="title">
                                    Título Passo 1
                                </h3>
                                <p className="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt distinctio blanditiis quo facere, vel, repellendus officiis deleniti deserunt animi nemo error asperiores quasi nisi unde, saepe corrupti fuga officia culpa.</p>
                            </div>
                        </li>
                        <li className={`step warning step-2 ${stepActived >= 2 ? 'active' : ''} ${stepActived >= 2 ? 'active-mobile' : ''}`} onClick={() => this.handleStep(2)}>
                            <em className="fa fa-user" />
                            <div className="text">
                                <h3 className="title">
                                    Título Passo 2
                                </h3>
                            </div>
                        </li>
                        <li className={`step loading step-3 ${stepActived >= 3 ? 'active' : ''} ${stepActived >= 3 ? 'active-mobile' : ''}`} onClick={() => this.handleStep(3)}>
                            <em className="fa fa-user" />
                            <div className="text">
                                <h3 className="title">
                                    Título Passo 3
                                </h3>
                                <p className="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt distinctio blanditiis quo facere, vel, repellendus officiis deleniti deserunt animi nemo error asperiores quasi nisi unde, saepe corrupti fuga officia culpa.</p>
                            </div>
                        </li>
                        <li className={`step positive step-4 ${stepActived >= 4 ? 'active' : ''} ${stepActived >= 4 ? 'active-mobile' : ''}`} onClick={() => this.handleStep(4)}>
                            <em className="fa fa-user" />
                            <div className="text">
                                <h3 className="title">
                                    Título Passo 4
                                </h3>
                                <p className="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt distinctio blanditiis quo facere, vel, repellendus officiis deleniti deserunt animi nemo error asperiores quasi nisi unde, saepe corrupti fuga officia culpa.</p>
                            </div>
                        </li>
                        <li className={`step neutral step-5 ${stepActived >= 5 ? 'active' : ''} ${stepActived >= 5 ? 'active-mobile' : ''}`} onClick={() => this.handleStep(5)}>
                            <em className="fa fa-user" />
                            <div className="text">
                                <h3 className="title">
                                    Título Passo 5
                                </h3>
                                <p className="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt distinctio blanditiis quo facere, vel, repellendus officiis deleniti deserunt animi nemo error asperiores quasi nisi unde, saepe corrupti fuga officia culpa.</p>
                            </div>
                        </li>
                        <li className={`step step-6 ${stepActived >= 6 ? 'active' : ''} ${stepActived >= 6 ? 'active-mobile' : ''}`} onClick={() => this.handleStep(6)}>
                            <em className="fa fa-user" />
                            <div className="text">
                                <h3 className="title">
                                    Título Passo 6
                                </h3>
                                <p className="description">Lorem ipsum, dolor sit amet consectetur adipisicing elit. Incidunt distinctio blanditiis quo facere, vel, repellendus officiis deleniti deserunt animi nemo error asperiores quasi nisi unde, saepe corrupti fuga officia culpa.</p>
                            </div>
                        </li>
                    </ul>
                }
            </div>
        )
    }

    onChange = newState => {
        this.setState(newState);
    }

    renderOptionalClasses = () => {
        return (
            <React.Fragment>
                <SwitchOptionsGroup label="Validações" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="error" value="error" stateName="error" label="Erro" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="type" value="title" stateName="type" label="Com Título" />
                    <SwitchOptions attr="hasExtraButtons" value={true} stateName="hasExtraButtons" label="Com botões extras" />
                    <SwitchOptions attr="fixed" value={true} stateName="fixed" label="Com Header Fixo Sem Navegação" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="vertical" value="vertical" stateName="vertical" label="Vertical" />
                </SwitchOptionsGroup>
            </React.Fragment>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Passo a Passo"
                    mainDescription="O Passo a Passo transmite o progresso através de etapas numeradas. Ele fornece um fluxo de trabalho semelhante a um assistente."
                    panel
                >
                <HowToUse
                    title="Uso"
                    description="O Passo a Passo exibe o progresso através de uma sequência de etapas lógicas e numeradas. Ele também pode ser usados para navegação e, quando necessário, exibir uma mensagem de feedback transitória depois que uma etapa é salva."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container Rodapé <br /> 2. Passo atual <br /> 3. Passo seguinte <br /> 4. Botão para o próximo passo ou finalizar <br /> 5. Botão para o passo anterior"
                    />
                </HowToUse>
                <HowToUse
                    title="Variações"
                    subtitle="Footer Fixo"
                    description="O passo a passo com footer é utilizado quando separamos o conteúdo em seus respectivos passos, como se estivessem em páginas diferentes."
                >
                    <HowToUseFigure
                        srcImg={footerfixo}
                        captionImg="Tela com passo a passo de footer fixo"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Header Fixo"
                    description="O passo a passo com header é utilizado quando separamos o conteúdo em passos, mas precisamos manter tudo em uma mesma tela."
                >
                    <HowToUseFigure
                        srcImg={headerfixo}
                        captionImg="Passo a passo com header fixo"
                    />
                </HowToUse>
                <HowToUse
                    subtitle="Validação de Erros"
                    description="Caso não seja possível ir para próxima etapa por alguma inconsistência, deve-se evidenciar o passo com problema."
                >
                    <HowToUseFigure
                        srcImg={error}
                        captionImg="Passo com validação de campos obrigatórios não preenchidos"
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Wizard);
