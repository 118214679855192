import React from 'react';

import Tabs from './componentes/Tabs/Tabs';
import Button from './componentes/Button/Button';
import AccessModule from './componentes/AccessModule/AccessModule';
import ProgressBar from './componentes/ProgressBar/ProgressBar';
import Panel from './componentes/Panel/Panel';
import Breadcrumb from './componentes/Breadcrumb/Breadcrumb';
import DialogueBox from './componentes/DialogueBox/DialogueBox';
import Fields from './componentes/Fields/Fields';
import Hint from './componentes/Hint/Hint';
import DisplayData from './componentes/DisplayData/DisplayData';
import Filter from './componentes/Filter/Filter';
import Info from './componentes/Info/Info';
import Notification from './componentes/Notification/Notification';
import Wizard from './componentes/Wizard/Wizard';
import Modal from './componentes/Modal/Modal';
import Pagination from './componentes/Pagination/Pagination';
import ColorPicker from './componentes/ColorPicker/ColorPicker';
import Timeline from './componentes/Timeline/Timeline';
import Widget from './componentes/Widget/Widget';
import Dashboard from './componentes/Dashboard/Dashboard';
import TitleDashboard from './componentes/TitleDashboard/TitleDashboard';
import FloatingActionButton from './componentes/FloatingActionButton/FloatingActionButton';
import DragDrop from './componentes/DragDrop/DragDrop';
import FloatSidebar from './componentes/FloatSidebar/FloatSidebar';
import Tables from './componentes/Tables/Tables';
import TableReport from './componentes/TableReport/TableReport';
import AdvancedSelector from './componentes/AdvancedSelector/AdvancedSelector';
import Chip from './componentes/Chip/Chip';
import AdvancedTree from './componentes/AdvancedTree/AdvancedTree';
import Typography from './componentes/Typography/Typography';
import ShortcutWheel from './componentes/ShortcutWheel/ShortcutWheel'
import Workflow from './componentes/Workflow/Workflow';
import EmptyState from './componentes/EmptyState/EmptyState';
import HeaderJuggernaut from './componentes/HeaderJuggernaut/HeaderJuggernaut';
import DatePicker from './componentes/DatePicker/DatePicker';
import Card from './componentes/Card/Card';
import ControlSelectors from './componentes/ControlSelectors/ControlSelectors';
import Introduction from './componentes/Introduction/Introduction';
import Loaders from './componentes/Loaders/Loaders'

import { ReactComponent as SVGADVANCEDSELECTOR } from './assets/img/svgs/ADVANCED-SELECTOR.svg';
import { ReactComponent as SVGADVANCEDTREE } from './assets/img/svgs/ADVANCED-TREE.svg';
import { ReactComponent as SVGBREADCRUMB } from './assets/img/svgs/BREADCRUMB.svg';
import { ReactComponent as SVGBUTTONS } from './assets/img/svgs/BUTTONS.svg';
import { ReactComponent as SVGCALENDAR } from './assets/img/svgs/CALENDAR.svg';
import { ReactComponent as SVGCARDS } from './assets/img/svgs/CARDS.svg';
import { ReactComponent as SVGCHIP } from './assets/img/svgs/CHIP.svg';
import { ReactComponent as SVGCOLORPICKER } from './assets/img/svgs/COLOR-PICKER.svg';
import { ReactComponent as SVGDASHTITLE } from './assets/img/svgs/DASH-TITLE.svg';
import { ReactComponent as SVGDASHBOARD } from './assets/img/svgs/DASHBOARD.svg';
import { ReactComponent as SVGDIALOGUE } from './assets/img/svgs/DIALOGUE.svg';
import { ReactComponent as SVGDISPLAY } from './assets/img/svgs/DISPLAY.svg';
import { ReactComponent as SVGDRAGDROP } from './assets/img/svgs/DRAGDROP.svg';
import { ReactComponent as SVGEMPTY } from './assets/img/svgs/EMPTY.svg';
import { ReactComponent as SVGFAB } from './assets/img/svgs/FAB.svg';
import { ReactComponent as SVGFIELDS } from './assets/img/svgs/FIELDS.svg';
import { ReactComponent as SVGFILTER } from './assets/img/svgs/FILTER.svg';
import { ReactComponent as SVGHINT } from './assets/img/svgs/HINT.svg';
import { ReactComponent as SVGINFO } from './assets/img/svgs/INFO.svg';
import { ReactComponent as SVGLAYOUT } from './assets/img/svgs/LAYOUT.svg';
import { ReactComponent as SVGMODAL } from './assets/img/svgs/MODAL.svg';
import { ReactComponent as SVGMODULEACCESS } from './assets/img/svgs/MODULE-ACCESS.svg';
import { ReactComponent as SVGNOTIFICATION } from './assets/img/svgs/NOTIFICATION.svg';
import { ReactComponent as SVGPAGINATION } from './assets/img/svgs/PAGINATION.svg';
import { ReactComponent as SVGPANEL } from './assets/img/svgs/PANEL.svg';
import { ReactComponent as SVGWIDGET } from './assets/img/svgs/WIDGET.svg';
import { ReactComponent as SVGPROGRESSBAR } from './assets/img/svgs/PROGRESS-BAR.svg';
import { ReactComponent as SVGSECTIONTITLE } from './assets/img/svgs/SECTION-TITLE.svg';
import { ReactComponent as SVGSELECTORS } from './assets/img/svgs/SELECTORS.svg';
import { ReactComponent as SVGSIDEBAR } from './assets/img/svgs/SIDEBAR.svg';
import { ReactComponent as SVGTABLE } from './assets/img/svgs/TABLE.svg';
import { ReactComponent as SVGTABS } from './assets/img/svgs/TABS.svg';
import { ReactComponent as SVGTIMELINE } from './assets/img/svgs/TIMELINE.svg';
import { ReactComponent as SVGWIZARD } from './assets/img/svgs/WIZARD.svg';
import { ReactComponent as SVGWHEEL } from './assets/img/svgs/WHEEL.svg';
import { ReactComponent as SVGWORKFLOW } from './assets/img/svgs/WORKFLOW.svg';
import { ReactComponent as SVGREPORT } from './assets/img/svgs/REPORT.svg';
import { ReactComponent as SVGINTRO } from './assets/img/svgs/INTRO.svg';
import { ReactComponent as SVGLOADER } from './assets/img/svgs/LOADERS.svg';

const ListMenu = [
    {
        name: 'Abas',
        component: <Tabs />,
        new: false,
        keywords: ['tabs', 'Abas'],
        svg: <SVGTABS />
    },
    {
        name: 'Acesso aos Módulos',
        component: <AccessModule />,
        new: false,
        keywords: ['access module', 'Acesso', 'modulos'],
        svg: <SVGMODULEACCESS />
    },
    {
        name: 'Árvore Avançada',
        component: <AdvancedTree />,
        new: false,
        keywords: ['advanced tree', 'Árvore'],
        svg: <SVGADVANCEDTREE />
    },
    {
        name: 'Barra de Progresso',
        component: <ProgressBar />,
        new: false,
        keywords: ['progress bar', 'Barra'],
        svg: <SVGPROGRESSBAR />
    },
    {
        name: 'Blocos',
        component: <Panel />,
        new: false,
        keywords: ['panel', 'title', 'expansive', 'Blocos'],
        svg: <SVGPANEL />
    },
    {
        name: 'Botões',
        component: <Button />,
        new: false,
        keywords: ['button', 'btn', 'Botões', 'botao'],
        svg: <SVGBUTTONS />
    },
    {
        name: 'Botões Flutuantes',
        component: <FloatingActionButton />,
        new: false,
        keywords: ['fab', 'floating', 'action', 'button', 'Botões', 'botao'],
        svg: <SVGFAB />
    },
    {
        name: 'Breadcrumb',
        component: <Breadcrumb />,
        new: false,
        keywords: ['nav', 'Breadcrumb'],
        svg: <SVGBREADCRUMB />
    },
    {
        name: 'Caixa de Diálogo',
        component: <DialogueBox />,
        new: false,
        keywords: ['popup', 'dialogue box', 'Caixa'],
        svg: <SVGDIALOGUE />
    },
    {
        name: 'Campos',
        component: <Fields />,
        new: false,
        keywords: ['input', 'checkbox', 'radio button', 'fields', 'textarea', 'autocomplete', 'select', 'upload', 'file', 'imagens', 'obrigatorio', 'placeholder', 'label', 'data', 'date', 'Campos'],
        svg: <SVGFIELDS />
    },
    {
        name: 'Card',
        component: <Card />,
        new: false,
        keywords: ['cartão', 'cards'],
        svg: <SVGCARDS />
    },
    {
        name: 'Carregadores',
        component: <Loaders />,
        new: false,
        keywords: ['loading', 'spinner', 'carregamento', 'carregar', 'loader'],
        svg: <SVGLOADER />
    },
    {
        name: 'Chip',
        component: <Chip />,
        new: false,
        keywords: ['status', 'situacao', 'Chip'],
        svg: <SVGCHIP />
    },
    {
        name: 'Controles de Seleção',
        component: <ControlSelectors />,
        new: false,
        keywords: ['seletores', 'controles', 'seleção', 'control', 'selectors'],
        svg: <SVGSELECTORS />
    },
    {
        name: 'Dashboard',
        component: <Dashboard />,
        new: false,
        keywords: ['inicio', 'Dashboard'],
        svg: <SVGDASHBOARD />
    },
    {
        name: 'Date Picker',
        component: <DatePicker />,
        new: false,
        keywords: ['seletor', 'data', 'range', 'Date'],
        svg: <SVGCALENDAR />
    },
    {
        name: 'Dicas',
        component: <Hint />,
        new: false,
        keywords: ['hint', 'Dicas'],
        svg: <SVGHINT />
    },
    {
        name: 'Display Data',
        component: <DisplayData />,
        new: false,
        keywords: ['dados', 'Display'],
        svg: <SVGDISPLAY />
    },
    {
        name: 'DragDrop',
        component: <DragDrop />,
        new: false,
        keywords: ['upload', 'arquivos', 'arrastar', 'soltar', 'DragDrop'],
        svg: <SVGDRAGDROP />
    },
    {
        name: 'Estados Vazios',
        component: <EmptyState />,
        new: false,
        keywords: ['empty', 'state', 'Estados'],
        svg: <SVGEMPTY />
    },
    {
        name: 'Filtros',
        component: <Filter />,
        new: false,
        keywords: ['filter', 'panel-table-filter', 'Filtros', 'advanced', 'avancado'],
        svg: <SVGFILTER />
    },
    {
        name: 'Float Sidebar',
        component: <FloatSidebar />,
        new: false,
        keywords: ['float', 'sidebar', 'flutuante', 'menu', 'SideBar'],
        svg: <SVGSIDEBAR />
    },
    {
        name: 'Fluxo de Processo',
        component: <Workflow />,
        new: false,
        keywords: ['workflow', 'Fluxo'],
        svg: <SVGWORKFLOW />
    },
    {
        name: 'Informativos',
        component: <Info />,
        new: false,
        keywords: ['mensagens', 'Informativos'],
        svg: <SVGINFO />
    },
    {
        name: 'Introdução',
        component: <Introduction />,
        new: false,
        keywords: ['Onboarding', 'Introdução'],
        svg: <SVGINTRO />
    },
    {
        name: 'Layout',
        component: <HeaderJuggernaut />,
        new: false,
        keywords: ['header', 'nav', 'top', 'Topo','menu', 'aside', 'item', 'Menu', 'Layout', 'float', 'styled'],
        svg: <SVGLAYOUT />
    },
    {
        name: 'Modal',
        component: <Modal />,
        new: false,
        keywords: ['popup', 'alert', 'Modal'],
        svg: <SVGMODAL />
    },
    {
        name: 'Notificação',
        component: <Notification />,
        new: false,
        keywords: ['alertas', 'Notificação'],
        svg: <SVGNOTIFICATION />
    },
    {
        name: 'Paginação',
        component: <Pagination />,
        new: false,
        keywords: ['pagination', 'Paginação'],
        svg: <SVGPAGINATION />
    },
    {
        name: 'Passo a Passo',
        component: <Wizard />,
        new: false,
        keywords: ['wizard', 'step', 'Passo'],
        svg: <SVGWIZARD />
    },
    {
        name: 'Relatório',
        component: <TableReport />,
        new: false,
        keywords: ['report', 'table', 'Tabela'],
        svg: <SVGREPORT />
    },
    {
        name: 'Roda de Atalhos',
        component: <ShortcutWheel />,
        new: false,
        keywords: ['shortcut', 'wheel', 'Roda'],
        svg: <SVGWHEEL />
    },
    {
        name: 'Seletor Avançado',
        component: <AdvancedSelector />,
        new: false,
        keywords: ['advanced', 'selector', 'lotes', 'Seletor'],
        svg: <SVGADVANCEDSELECTOR />
    },
    {
        name: 'Seletor de Cores',
        component: <ColorPicker />,
        new: false,
        keywords: ['color', 'picker', 'Seletor'],
        svg: <SVGCOLORPICKER />
    },
    {
        name: 'Tabela',
        component: <Tables />,
        new: false,
        keywords: ['tables', 'colunas', 'tbody', 'thead', 'checkbox', 'radio button', 'tfoot', 'footer', 'editable', 'Tabela'],
        svg: <SVGTABLE />
    },
    {
        name: 'Timeline',
        component: <Timeline />,
        new: false,
        keywords: ['linha', 'tempo', 'Timeline'],
        svg: <SVGTIMELINE />
    },
    {
        name: 'Título Seção',
        component: <Typography />,
        new: false,
        keywords: ['titulo', 'typography', 'Tipografia', 'secao', 'seção'],
        svg: <SVGSECTIONTITLE />
    },
    {
        name: 'Título Dashboard',
        component: <TitleDashboard />,
        new: false,
        keywords: ['titulo', 'Title', 'dashboard'],
        svg: <SVGDASHTITLE />
    },
    {
        name: 'Widget',
        component: <Widget />,
        new: false,
        keywords: ['Widget'],
        svg: <SVGWIDGET />
    },
];

export default ListMenu;
