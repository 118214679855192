import React, { Component } from 'react';
import { connect } from 'react-redux';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from '../Templates/SwitchOptions';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';

import anatomia from '../../assets/img/components/campos/anatomia.png';
import dont1 from '../../assets/img/components/campos/dont1.png';
import dont2 from '../../assets/img/components/campos/dont2.png';
import do1 from '../../assets/img/components/campos/do1.png';
import gif from '../../assets/img/components/campos/gif.gif';
import error from '../../assets/img/components/campos/error.png';
import disabled from '../../assets/img/components/campos/disabled.png';
import required from '../../assets/img/components/campos/required.png';
import focus from '../../assets/img/components/campos/focus.png';
import placeholderLoader from '../../assets/img/components/campos/placeholder-loader.gif';
import text from '../../assets/img/components/campos/text.png';
import textarea from '../../assets/img/components/campos/textarea.png';
import select from '../../assets/img/components/campos/select.png';
import gifdata from '../../assets/img/components/campos/gifdata.gif';
import gifdatetime from '../../assets/img/components/campos/gifdatetime.gif';
import daterange from '../../assets/img/components/campos/daterange.png';
import gifautocomplete from '../../assets/img/components/campos/gifautocomplete.gif';
import autocomplete from '../../assets/img/components/campos/autocomplete.png';
import readonly from '../../assets/img/components/campos/readonly.png';
import prefix from '../../assets/img/components/campos/prefix.png';
import sufix from '../../assets/img/components/campos/sufix.png';

const files = [
    { 'less': ['fields.less'] },
    { 'less': ['datepicker.less'] },
    { 'less': ['autocomplete.less'] },
    { 'js': ['datepicker.js'] }
];

class Fields extends Component {

    state = {
        type: 'text',
        validation: undefined,
        openDate: false,
        openTime: false,
        timeHour: 0,
        timeMin: 0,
        openDateTime: false,
        arquivoErrorMessage: undefined,
        arquivo: undefined,
        hint: false,
        fix: undefined,
        required: false,
        right: false,
        placeholderLoader: false,
        currentIndex: undefined,
        helpMessage: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Campos');
        this.setState({ currentIndex: index });
    }

    componentDidUpdate() {
        
        document.querySelectorAll(".btn.filter, .btn.search, .btn.input").forEach(function(e){
            var btnWidth = e.offsetWidth;
            e.previousElementSibling.style.width = "calc( 100% - " + btnWidth + "px)";
            e.previousElementSibling.classList.add("button-input");
        });
        
    }

    onChange = newState => {
        if(newState.placeholderLoader) {
            newState.validation = "disabled";
        }
        this.setState(newState);
        
    }

    openDate = () => {
        this.setState(state => {
            return {
                openDate: !state.openDate
            }
        })
    }

    openTime = () => {
        this.setState(state => {
            return {
                openTime: !state.openTime
            }
        })
    }

    openDateTime = () => {
        this.setState(state => {
            return {
                openDateTime: !state.openDateTime
            }
        })
    }

    handleHour = s => {
        this.setState(state => {
            if (s === "+" && state.timeHour < 23) {
                return {
                    timeHour: state.timeHour + 1
                }
            } else if (s === "-" && state.timeHour > 0) {
                return {
                    timeHour: state.timeHour - 1
                }
            } else if (s === "+" && state.timeHour >= 23) {
                return {
                    timeHour: 0
                }
            } else if (s === "-" && state.timeHour >= 0) {
                return {
                    timeHour: 23
                }
            }
        });
    }

    handleMin = s => {
        this.setState(state => {
            if (s === "+" && state.timeMin < 55) {
                return {
                    timeMin: state.timeMin + 5
                }
            } else if (s === "-" && state.timeMin > 0) {
                return {
                    timeMin: state.timeMin - 5
                }
            } else if (s === "+" && state.timeMin >= 55) {
                return {
                    timeMin: 0
                }
            } else if (s === "-" && state.timeMin >= 0) {
                return {
                    timeMin: 55
                }
            }
        });
    }

    handleHourChange = (e) => {
        this.setState({
            timeHour: e.currentTarget.value
        });
    }

    handleMinChange = (e) => {
        this.setState({
            timeMin: e.currentTarget.value
        });
    }

    onUploadFile = event => {
        const { files } = event.target;

        const isValid = !(files && files[0] && files[0].size > 50000000);

        const errorObj = isValid
            ? { arquivoErrorMessage: undefined }
            : {
                arquivoErrorMessage: 'Arquivo maior que 50Mb'
            };

        this.setState({ arquivo: files, ...errorObj });
    };

    onUploadImage = event => {
        const { files } = event.target;

        const isValid = !(files && files[0] && files[0].size > 50000000);

        const errorObj = isValid
            ? { arquivoErrorMessage: undefined }
            : {
                arquivoErrorMessage: 'Arquivo maior que 50Mb'
            };

        let reader = new FileReader();

        reader.onloadend = () => {

            const result = reader.result;

            this.setState({ arquivo: result, ...errorObj });
        };

        reader.readAsDataURL(files[0]);

    }

    clear = () => {
        this.setState({ arquivo: undefined, arquivoErrorMessage: undefined })
    }

    renderComponent = () => {
        const { type, validation, arquivoErrorMessage, arquivo, openDate, openTime, openDateTime, timeHour, timeMin, hint, fix, required, right, placeholderLoader, helpMessage } = this.state;

        return (
            <div className={`${type === 'com botoes' ? 'col-md-12' : 'col-md-6'} ${placeholderLoader ? 'placeholder-loader' : ''}`} >
                <div className="form-group">
                    {type !== 'checkbox' && type !== 'radio' &&
                        <label className="label" htmlFor="field">
                            Lorem ipsum
                            {hint &&
                                <div className="hint inline clean fa-question-circle module-color sm">
                                    <div className="hint-content">
                                        Lorem ipsum lorem ipsum lorem ipsum
                                    </div>
                                </div>
                            }
                        </label>
                    }
                    {type === 'text' && !fix &&
                        <input id="field" type="text" defaultValue="Lorem ipsum" className={`${validation} ${right}`} disabled={validation === 'disabled'} required={required} />
                    }
                    {type === 'text' && fix &&
                        <>
                            <div className={fix}>
                                {fix === 'prefix' &&
                                    <label className="prefix-label">Lorem Ipsum</label>
                                }
                                <input
                                    id="field"
                                    type="text"
                                    className={fix + '-input'}
                                    disabled={validation === 'disabled'}
                                    defaultValue="Lorem Ipsum"
                                    required={required}
                                />
                                {fix === 'suffix' &&
                                    <label className="suffix-label">Lorem Ipsum</label>
                                }
                            </div>
                        </>
                    }
                    {type === 'number' &&
                        <input type="number" name="number" className={`${validation} ${right}`} required={required} />
                    }
                    {type === "text area" &&
                        <textarea id="field" cols="30" rows="3" className={`${validation} ${right}`} disabled={validation === 'disabled'} required={required}></textarea>
                    }
                    {type === "upload arquivo" &&
                        <div className="file-uploader">
                            <input
                                id="upload-file-input"
                                type="file"
                                className={`file-uploader-input ${
                                    arquivoErrorMessage ? ' error' : ''
                                    } ${validation}`}
                                title="Clique ou arraste para anexar"
                                onChange={this.onUploadFile}
                                disabled={validation === 'disabled'}
                            />

                            <label
                                htmlFor="upload-file-input"
                                className="input"
                                data-title={
                                    arquivo && arquivo.length > 0
                                        ? arquivo[0].name
                                        : 'Clique ou arraste para anexar'
                                }
                            />

                            <label
                                htmlFor="upload-file-input"
                                className="file-uploader-icon"
                            />

                        </div>
                    }
                    {type === 'upload imagem' &&
                        <div className="image-uploader">
                            <label className="image-uploader-label" htmlFor="image-uploader-input" />
                            <input id="image-uploader-input" type="file" className="image-uploader-input" accept="image/*" onChange={this.onUploadImage} />
                            {arquivo &&
                                <>
                                    <em className="image-uploader-remove" onClick={this.clear} />
                                    <div className="image-uploader-image" style={{ backgroundImage: `url(${arquivo})` }}></div>
                                </>
                            }
                        </div>
                    }

                    {type === 'upload imagem arredondado' &&
                        <div className={`image-uploader rounded fa fa-user ${arquivo ? 'has-image' : ''}`}>
                            <label className="image-uploader-label" htmlFor="image-uploader-input" />
                            <input id="image-uploader-input" type="file" className="image-uploader-input" accept="image/*" onChange={this.onUploadImage} />
                            {arquivo &&
                                <>
                                    <em className="image-uploader-remove" onClick={this.clear} />
                                    <div className="image-uploader-image" style={{ backgroundImage: `url(${arquivo})` }}></div>
                                </>
                            }
                        </div>
                    }

                    {(type === 'date' || type === 'dateChip') &&
                        <>
                            <input id="field" className={`datepicker-opener ${validation} ${type === 'dateChip' ? 'chip-container date' : ''}`} type="text" placeholder="00/00/0000" onFocus={this.openDate} disabled={validation === 'disabled'} />
                            <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                            <div className={`datepicker ${type === 'dateChip' ? 'left': 'right'} ${openDate ? 'open' : ''}`}>
                                <div className="datepicker-header">

                                    <div className="datepicker-header-year">
                                        <em className="fa fa-chevron-left"></em>
                                        <b className="year-number">
                                            2016
											</b>
                                        <em className="fa fa-chevron-right"></em>
                                    </div>

                                    <div className="datepicker-header-month">
                                        <em className="fa fa-chevron-left"></em>
                                        <b className="month-name">
                                            Outubro
											</b>
                                        <em className="fa fa-chevron-right"></em>
                                    </div>


                                </div>
                                <div className="datepicker-calendar">
                                    <div className="datepicker-calendar-header">
                                        <li className="item">
                                            Dom
											</li>
                                        <li className="item">
                                            Seg
											</li>
                                        <li className="item">
                                            Ter
											</li>
                                        <li className="item">
                                            Qua
											</li>
                                        <li className="item">
                                            Qui
											</li>
                                        <li className="item">
                                            Sex
											</li>
                                        <li className="item">
                                            Sab
											</li>
                                    </div>
                                    <li className="item not-this-month">25</li><li className="item not-this-month">26</li><li className="item not-this-month">27</li><li className="item not-this-month">28</li><li className="item not-this-month">29</li><li className="item not-this-month">30</li><li className="item">1</li>
                                    <li className="item">2</li><li className="item">3</li><li className="item">4</li><li className="item">5</li><li className="item">6</li><li className="item">7</li><li className="item">8</li>
                                    <li className="item">9</li><li className="item">10</li><li className="item">11</li><li className="item">12</li><li className="item">13</li><li className="item">14</li><li className="item">15</li>
                                    <li className="item">16</li><li className="item">17</li><li className="item">18</li><li className="item">19</li><li className="item">20</li><li className="item">21</li><li className="item">22</li>
                                    <li className="item">23</li><li className="item">24</li><li className="item">25</li><li className="item">26</li><li className="item">27</li><li className="item">28</li><li className="item">29</li>
                                    <li className="item">30</li><li className="item">31</li><li className="item not-this-month">1</li><li className="item not-this-month">2</li><li className="item not-this-month">3</li><li className="item not-this-month">4</li><li className="item not-this-month">5</li>
                                </div>
                            </div>
                        </>
                    }
                    {type === 'checkbox' &&
                        <>
                            <input id="input-checkbox" className="input-checkbox" type="checkbox" disabled={validation === 'disabled'} />
                            <label htmlFor="input-checkbox">Checkbox</label>
                        </>
                    }
                    {type === 'radio' &&
                        <>
                            <input id="input-radio" className="input-radio" type="radio" disabled={validation === 'disabled'} />
                            <label htmlFor="input-radio">Radio</label>
                        </>
                    }
                    {type === 'com dica' &&
                        <>
                            <div className="hint input">
                                <div className="hint-content">
                                    Mensagem <a href="#campos">Link</a>
                                </div>
                            </div>
                            <input id="field" type="text" placeholder="Placeholder" className={validation} disabled={validation === 'disabled'} />
                        </>
                    }
                    {type === 'com botoes' &&
                        <div className="row">
                            <div className="col-md-3">
                                <input id="field" type="text" placeholder="Digite..." className={`button-input ${validation}`} disabled={validation === 'disabled'} />
                                <button className="btn filter">Filtrar</button>
                            </div>
                            <div className="col-md-3">
                                <input aria-label="search field" type="text" placeholder="Digite..." className={`button-input ${validation}`} disabled={validation === 'disabled'} />
                                <button className="btn search">Pesquisar</button>
                            </div>
                            <div className="col-md-3">
                                <select aria-label="search field" className={`button-input ${validation}`} disabled={validation === 'disabled'}>
                                    <option disabled="" selected="">Selecionar</option>
                                    <option>Item</option>
                                    <option>Item</option>
                                    <option>Item</option>
                                </select>
                                <button className="btn input">Livre <i className="fa fa-circle"></i></button>
                            </div>
                        </div>
                    }
                    {type === 'autocomplete' &&
                        <>
                            <input id="field" className={`has-input-icon  autocomplete-input ${validation}`} type="text" name="" placeholder="Cidade" disabled={validation === 'disabled'} required={required} />
                            <i className="fa fa-search input-icon"></i>
                            <ul className="autocomplete">
                                <li className="autocomplete-item">
                                    01 - Curitiba
									</li>
                                <li className="autocomplete-item">
                                    02 - Londrina
									</li>
                                <li className="autocomplete-item">
                                    03 - Maringá
									</li>
                                <li className="autocomplete-item">
                                    04 - Ponta Grossa
									</li>
                                <li className="autocomplete-item">
                                    05 - Foz do Iguaçú
									</li>
                                <li className="autocomplete-item">
                                    06 - Cianorte
									</li>
                                <li className="autocomplete-item">
                                    07 - Campo Mourão
									</li>
                            </ul>
                        </>
                    }
                    {type === 'autocomplete-hint' &&
                        <>
                            <input id="field" className={`has-input-icon  autocomplete-input ${validation}`} type="text" name="" placeholder="Cidade" disabled={validation === 'disabled'} required={required} />
                            <i className="fa fa-search input-icon"></i>
                            <ul className="autocomplete">
                                <li className="autocomplete-item">
                                    <div className="extra-info split-letter" data-letter="E">
                                        <p>Extra Informação</p>
                                    </div>
                                    01 - Curitiba
                                </li>
                                <li className="autocomplete-item">
                                    <div className="extra-info split-letter" data-letter="E">
                                        <p>Extra Informação</p>
                                    </div>
                                    02 - Londrina
                                </li>
                                <li className="autocomplete-item">
                                    <div className="extra-info split-letter" data-letter="E">
                                        <p>Extra Informação</p>
                                    </div>
                                    03 - Maringá
                                </li>
                                <li className="autocomplete-item">
                                    <div className="extra-info split-letter" data-letter="E">
                                        <p>Extra Informação</p>
                                    </div>
                                    04 - Ponta Grossa
                                </li>
                                <li className="autocomplete-item">
                                    <div className="extra-info split-letter" data-letter="E">
                                        <p>Extra Informação</p>
                                    </div>
                                    05 - Foz do Iguaçú
                                </li>
                                <li className="autocomplete-item">
                                    <div className="extra-info split-letter" data-letter="E">
                                        <p>Extra Informação</p>
                                    </div>
                                    06 - Cianorte
                                </li>
                                <li className="autocomplete-item">
                                    07 - Campo Mourão
                                    <div className="extra-info split-letter right" data-letter="E">
                                        <p>Extra Informação</p>
                                    </div>
                                </li>
                            </ul>
                        </>
                    }
                    {type === 'autocomplete-detailed' &&
                        <>
                            <input id="field" type="text" className={`has-input-icon autocomplete-input`} disabled={validation === 'disabled'} required={required} />
                            <i className="fa fa-search input-icon" />
                            <ul className="autocomplete">
                                <li className="autocomplete-item">
                                    <figure className="autocomplete-item-figure" style={{ backgroundImage: 'url(https://i.imgbox.com/u36Nd8Pj.jpg)' }}>
                                    </figure>
                                    <div className="autocomplete-item-text">
                                        <h3 className="autocomplete-item-title">
                                            Marina Alves
                                        </h3>
                                        <article className="autocomplete-item-description">
                                            Professora
                                        </article>
                                    </div>
                                </li>
                                <li className="autocomplete-item">
                                    <figure className="autocomplete-item-figure" style={{ backgroundImage: 'url(https://i.imgbox.com/u36Nd8Pj.jpg)' }}>
                                    </figure>
                                    <div className="autocomplete-item-text">
                                        <h3 className="autocomplete-item-title">
                                            Roberta da Silva
                                        </h3>
                                        <article className="autocomplete-item-description">
                                            Professora
                                        </article>
                                    </div>
                                </li>
                                <li className="autocomplete-item">
                                    <figure className="autocomplete-item-figure" style={{ backgroundImage: 'url(https://i.imgbox.com/u36Nd8Pj.jpg)' }}>
                                    </figure>
                                    <div className="autocomplete-item-text">
                                        <h3 className="autocomplete-item-title">
                                            Ana de Brito
                                        </h3>
                                        <article className="autocomplete-item-description">
                                            Diretora
                                        </article>
                                    </div>
                                </li>
                                <li className="autocomplete-item">
                                    <figure className="autocomplete-item-figure" style={{ backgroundImage: 'url(https://i.imgbox.com/u36Nd8Pj.jpg)' }}>
                                    </figure>
                                    <div className="autocomplete-item-text">
                                        <h3 className="autocomplete-item-title">
                                            Amanda Oliveira
                                        </h3>
                                        <article className="autocomplete-item-description">
                                            Supervisora
                                        </article>
                                    </div>
                                </li>
                            </ul>
                        </>
                    }
                    {type === 'select' && !fix &&
                        <select id="field" className={`${validation} ${right}`} disabled={validation === 'disabled'} required={required}>
                            <option value="option_1">Option 1</option>
                            <option value="option_2">Option 2</option>
                            <option value="option_3">Option 3</option>
                            <option value="option_4">Option 4</option>
                        </select>
                    }
                    {type === 'select' && fix &&
                        <>
                            <div className={fix}>
                                {fix === 'prefix' &&
                                    <label className="prefix-label">Lorem Ipsum</label>
                                }
                                <select id="field" className={fix + '-input ' + validation} disabled={validation === 'disabled'} required={required}>
                                    <option value="option_1">Option 1</option>
                                    <option value="option_2">Option 2</option>
                                    <option value="option_3">Option 3</option>
                                    <option value="option_4">Option 4</option>
                                </select>
                                {fix === 'suffix' &&
                                    <label className="suffix-label">Lorem Ipsum</label>
                                }
                            </div>
                        </>
                    }
                    {type === 'loading' &&
                        <>
                            <input id="field" type="text" value="17AF56" name="" placeholder="Carregando" className={`loading ${validation}`} disabled={validation === 'disabled'} />
                            <i className="fa fa-circle-o-notch spinner"></i>
                        </>
                    }
                    {type === 'readonly' &&
                        <>
                            <input className="readonly" defaultValue="Lorem ipsum lorem ipsum" readOnly />
                        </>
                    }
                    {type === 'datetime-local' &&
                        <>
                            <input id="field" className={`datepicker-opener ${validation}`} type="text" placeholder="00/00/0000 - 00:00" onFocus={this.openDateTime} disabled={validation === 'disabled'} />
                            <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                            <div className={`datetimepicker right ${openDateTime ? 'open' : ''}`}>
                                <div className={`datepicker ${!openTime ? 'visible' : ''}`}>
                                    <div className="datepicker-header">
                                        <div className="datepicker-header-year">
                                            <em className="fa fa-chevron-left"></em>
                                            <b className="year-number">2016</b>
                                            <em className="fa fa-chevron-right"></em>
                                        </div>

                                        <div className="datepicker-header-month">
                                            <em className="fa fa-chevron-left"></em>
                                            <b className="month-name">Outubro</b>
                                            <em className="fa fa-chevron-right"></em>
                                        </div>

                                        <div className="timepicker-button" onClick={this.openTime}>
                                            <em className="fa fa-clock-o"></em>
                                        </div>
                                    </div>
                                    <div className="datepicker-calendar">
                                        <div className="datepicker-calendar-header">
                                            <li className="item">Dom</li>
                                            <li className="item">Seg</li>
                                            <li className="item">Ter</li>
                                            <li className="item">Qua</li>
                                            <li className="item">Qui</li>
                                            <li className="item">Sex</li>
                                            <li className="item">Sab</li>
                                        </div>
                                        <li className="item not-this-month">25</li><li className="item not-this-month">26</li><li className="item not-this-month">27</li><li className="item not-this-month">28</li><li className="item not-this-month">29</li><li className="item not-this-month">30</li><li className="item">1</li>
                                        <li className="item">2</li><li className="item">3</li><li className="item">4</li><li className="item">5</li><li className="item">6</li><li className="item">7</li><li className="item">8</li>
                                        <li className="item">9</li><li className="item">10</li><li className="item">11</li><li className="item">12</li><li className="item">13</li><li className="item">14</li><li className="item">15</li>
                                        <li className="item">16</li><li className="item">17</li><li className="item">18</li><li className="item">19</li><li className="item">20</li><li className="item">21</li><li className="item">22</li>
                                        <li className="item">23</li><li className="item">24</li><li className="item">25</li><li className="item">26</li><li className="item">27</li><li className="item">28</li><li className="item">29</li>
                                        <li className="item">30</li><li className="item">31</li><li className="item not-this-month">1</li><li className="item not-this-month">2</li><li className="item not-this-month">3</li><li className="item not-this-month">4</li><li className="item not-this-month">5</li>
                                    </div>
                                </div>
                                <div className={`timepicker ${openTime ? 'visible' : ''}`}>
                                    <div className="timepicker-header">
                                        <div className="datepicker-button" onClick={this.openTime}>
                                            <em className="fa fa-calendar"></em>
                                        </div>
                                        <div className="timepicker-header-hour">
                                            <em className="fa fa-chevron-up" onClick={() => this.handleHour("+")}></em>
                                            <input type="number" className="hour" defaultValue="20" value={timeHour} onChange={this.handleHourChange} min="00" max="23" />
                                            <em className="fa fa-chevron-down" onClick={() => this.handleHour("-")}></em>
                                        </div>
                                        :
                                        <div className="timepicker-header-minute">
                                            <em className="fa fa-chevron-up" onClick={() => this.handleMin("+")}></em>
                                            <input type="number" className="minute" defaultValue="20" value={timeMin} onChange={this.handleMinChange} min="00" max="59" />
                                            <em className="fa fa-chevron-down" onClick={() => this.handleMin("-")}></em>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {type === 'daterange' &&
                        <>
                            <input id="field" className={`datepicker-opener ${validation}`} type="text" placeholder="00/00/0000 - 00/00/0000" onFocus={this.openDateTime} disabled={validation === 'disabled'} />
                            <i aria-hidden="true" className="fa fa-calendar input-icon"></i>
                            <div className={`daterangepicker left ${openDateTime ? 'open' : ''}`}>
                                <div className="daterangepicker-selector">
                                    <select id="field" className="undefined false">
                                        <option value="option_1">Customizado</option>
                                        <option value="option_1">Esta semana</option>
                                        <option value="option_2">Este mês</option>
                                        <option value="option_2">Este mês, até agora</option>
                                        <option value="option_3">Mês anterior</option>
                                        <option value="option_3">Esse trimestre</option>
                                        <option value="option_3">Esse trimestre, até agora</option>
                                        <option value="option_3">Trimestre Anterior</option>
                                        <option value="option_4">Este Ano</option>
                                        <option value="option_4">Este Ano, até agora</option>
                                        <option value="option_4">Ano Anterior</option>
                                        <option value="option_4">Últimos 30 dias</option>
                                        <option value="option_4">Últimos 28 dias</option>
                                        <option value="option_4">Últimos 14 dias</option>
                                        <option value="option_4">Últimos 7 dias</option>
                                    </select>
                                </div>
                                <div className="daterangepicker-header">
                                    <b className="daterangepicker-title">DATA INICIAL</b>
                                    <b className="daterangepicker-title">DATA FINAL</b>
                                </div>
                                <div className="datepicker-container">
                                    <div className="datepicker visible">
                                        <div className="datepicker-header">
                                            <div className="datepicker-header-year">
                                                <em className="fa fa-chevron-left"></em>
                                                <b className="year-number">2019</b>
                                                <em className="fa fa-chevron-right"></em>
                                            </div>
                                            <div className="datepicker-header-month">
                                                <em className="fa fa-chevron-left"></em>
                                                <b className="month-name">Outubro</b>
                                                <em className="fa fa-chevron-right"></em>
                                            </div>
                                        </div>
                                        <div className="datepicker-calendar">
                                            <div className="datepicker-calendar-header">
                                                <li className="item">Dom</li>
                                                <li className="item">Seg</li>
                                                <li className="item">Ter</li>
                                                <li className="item">Qua</li>
                                                <li className="item">Qui</li>
                                                <li className="item">Sex</li>
                                                <li className="item">Sab</li>
                                            </div>
                                            <li className="item not-this-month">25</li><li className="item not-this-month">26</li><li className="item not-this-month">27</li><li className="item not-this-month">28</li><li className="item not-this-month">29</li><li className="item not-this-month">30</li><li className="item">1</li>
                                            <li className="item">2</li><li className="item">3</li><li className="item">4</li><li className="item">5</li><li className="item">6</li><li className="item">7</li><li className="item">8</li>
                                            <li className="item">9</li><li className="item">10</li><li className="item">11</li><li className="item">12</li><li className="item">13</li><li className="item">14</li><li className="item">15</li>
                                            <li className="item">16</li><li className="item">17</li><li className="item">18</li><li className="item">19</li><li className="item">20</li><li className="item">21</li><li className="item">22</li>
                                            <li className="item">23</li><li className="item">24</li><li className="item">25</li><li className="item">26</li><li className="item">27</li><li className="item">28</li><li className="item">29</li>
                                            <li className="item">30</li><li className="item">31</li><li className="item not-this-month"></li><li className="item not-this-month"></li><li className="item not-this-month"></li><li className="item not-this-month"></li><li className="item not-this-month"></li>
                                        </div>
                                    </div>
                                    <div className="datepicker visible">
                                        <div className="datepicker-header">
                                            <div className="datepicker-header-year">
                                                <em className="fa fa-chevron-left"></em>
                                                <b className="year-number">2019</b>
                                                <em className="fa fa-chevron-right"></em>
                                            </div>
                                            <div className="datepicker-header-month">
                                                <em className="fa fa-chevron-left"></em>
                                                <b className="month-name">Novembro</b>
                                                <em className="fa fa-chevron-right"></em>
                                            </div>
                                        </div>
                                        <div className="datepicker-calendar">
                                            <div className="datepicker-calendar-header">
                                                <li className="item">Dom</li>
                                                <li className="item">Seg</li>
                                                <li className="item">Ter</li>
                                                <li className="item">Qua</li>
                                                <li className="item">Qui</li>
                                                <li className="item">Sex</li>
                                                <li className="item">Sab</li>
                                            </div>
                                            <li className="item not-this-month"></li><li className="item not-this-month"></li><li className="item ">1</li><li className="item ">2</li><li className="item ">3</li><li className="item ">4</li><li className="item">5</li>
                                            <li className="item">6</li><li className="item">7</li><li className="item">8</li><li className="item">9</li><li className="item">10</li><li className="item">11</li><li className="item">12</li>
                                            <li className="item">13</li><li className="item">14</li><li className="item">15</li><li className="item">16</li><li className="item">17</li><li className="item">18</li><li className="item">19</li>
                                            <li className="item">20</li><li className="item">21</li><li className="item">22</li><li className="item">23</li><li className="item">24</li><li className="item">25</li><li className="item">26</li>
                                            <li className="item">27</li><li className="item">28</li><li className="item">29</li><li className="item">30</li><li className="item not-this-month">1</li><li className="item not-this-month">2</li><li className="item not-this-month">3</li>
                                        </div>
                                    </div>
                                </div>
                                <div className="daterangepicker-footer">
                                    <button className="btn neutral">LIMPAR</button>
                                    <button className="btn module-color" onClick={this.openDateTime}>APLICAR</button>
                                </div>
                            </div>
                        </>
                    }
                    {validation === 'error' && <div className="help-message">Este campo contém erros</div>}
                    {helpMessage && <div className="help-message gray-60 right col-md-12">500</div>}
                </div>
            </div >
        )

    }

    renderClasses = () => {
        return (
            <>
                <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="type" value="text" stateName="type" label="Text" />
                    <SwitchOptions attr="type" value="number" stateName="type" label="Número" />
                    <SwitchOptions attr="type" value="text area" stateName="type" label="Text Area" />
                    <SwitchOptions attr="type" value="select" stateName="type" label="Select" />
                    <SwitchOptions attr="type" value="upload arquivo" stateName="type" label="Upload Arquivo" />
                    <SwitchOptions attr="type" value="upload imagem" stateName="type" label="Upload Imagem" />
                    <SwitchOptions attr="type" value="upload imagem arredondado" stateName="type" label="Upload Imagem Arredondado" />
                    <SwitchOptions attr="type" value="date" stateName="type" label="Data" />
                    <SwitchOptions attr="type" value="dateChip" stateName="type" label="Data Tipo Chip" />
                    <SwitchOptions attr="type" value="checkbox" stateName="type" label="Checkbox" />
                    <SwitchOptions attr="type" value="radio" stateName="type" label="Radio Button" />
                    <SwitchOptions attr="type" value="com dica" stateName="type" label="Com Dica" />
                    <SwitchOptions attr="hint" value="hint" stateName="hint" label="Com Dica no Label" />
                    <SwitchOptions attr="type" value="com botoes" stateName="type" label="Com Botões" />
                    <SwitchOptions attr="type" value="autocomplete" stateName="type" label="Autocomplete" />
                    <SwitchOptions attr="type" value="autocomplete-hint" stateName="type" label="Autocomplete com Hint" />
                    <SwitchOptions attr="type" value="autocomplete-detailed" stateName="type" label="Autocomplete Detalhado" />
                    <SwitchOptions attr="type" value="loading" stateName="type" label="Com Loading" />
                    <SwitchOptions attr="type" value="readonly" stateName="type" label="Somente Leitura" />
                    <SwitchOptions attr="type" value="datetime-local" stateName="type" label="DateTime" />
                    <SwitchOptions attr="type" value="daterange" stateName="type" label="Date Range" />
                    <SwitchOptions attr="fix" value="suffix" stateName="fix" label="Sufixo" />
                    <SwitchOptions attr="fix" value="prefix" stateName="fix" label="Prefixo" />
                    <SwitchOptions attr="placeholder" value={true} stateName="placeholderLoader" label="Placeholder Loader" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Validação" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="validation" value="error" stateName="validation" label="Com Erro" />
                    <SwitchOptions attr="helpMessage" value={true} stateName="helpMessage" label="Com Mensagem de Ajuda" />
                    <SwitchOptions attr="validation" value="disabled" stateName="validation" label="Desabilitado" />
                    <SwitchOptions attr="required" value={true} stateName="required" label="Obrigatório" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Alinhamento" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="right" value="right" stateName="right" label="À Direita" />
                </SwitchOptionsGroup>
            </>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderClasses}
                    theme={theme}
                    panel
                    mainTitle='Campos'
                    mainDescription='Campos permitem que o usuário insira e edite textos.'
                >
                <HowToUse
                    title='Uso'
                    description='Campos permitem que o usuário insira texto na interface. Eles normalmente aparecem em formulários e caixas de diálogos.'
                />
                <HowToUse
                    title='Anatomia'
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg='1. Container <br /> 2. Label <br /> 3. Dica (opcional) <br /> 4. Texto <br /> 5. Ícone (opcional) <br /> 6. Texto de ajuda'
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Label'
                    description='Texto do label é usado para informar o usuário qual informação deve ser preenchida no campo. Todo campo deve ter um label. <br/> Label deve ser alinhado com o campo e sempre visível, e o texto deve ser auto explicativo, podendo conter uma dica caso precise exibir mais informações.'
                >
                    <HowToUseFigure
                        srcImg={dont1}
                        status='dont'
                        col
                        descriptionImg='O texto do label não deve ser extenso e nem limitado. Deve ser curto, claro e totalmente visível.'
                    />
                    <HowToUseFigure
                        srcImg={dont2}
                        status='dont'
                        col
                        descriptionImg='O texto do label não deve ocupar múltiplas linhas.'
                    />
                    <HowToUseFigure
                        srcImg={do1}
                        status='do'
                        descriptionImg='Utilize o hint para explicar sobre o que deve ser preenchido no campos, caso o espaço do label não seja o suficiente.'
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Ícone'
                    description='Existem tipos de campos diferentes que podem ser utilizados, alguns possuem um ícone ao final do campos para indicar o tipo do campo facilitando a identificação, e/ou fornecendo alguma dica, ou feedback em caso de usar um ícone de loading.'
                />
                <HowToUse
                    subtitle='Texto de Ajuda'
                    description='Para campos que validam seu conteúdo (como senhas) substituir o texto de ajuda pelo texto de erro. <br /> A mensagem pode descrever como evitar o erro, como também pode descrever como resolver o erro.'
                >
                    <HowToUseFigure
                        srcImg={gif}
                    />
                </HowToUse>
                <HowToUse
                    title='Variações'
                    subtitle='Estados'
                >
                    <HowToUseFigure
                        srcImg={error}
                        captionImg='Erro'
                        descriptionImg='Deve ser utilizados para indicar erros e soluções, com mensagens curtas e claras.'
                    />
                    <HowToUseFigure
                        srcImg={disabled}
                        captionImg='Desabilitado'
                        descriptionImg='Campo desabilitado serve para em determinado momento bloquear a edição do usuário. <br /> Não deve usar o campo desabilitado para mostrar informações que nunca são alteradas, nem para campos vazios. <br /> Campos que nunca serão alterados devem ser do tipo somente visualização.'
                    />
                    <HowToUseFigure
                        srcImg={required}
                        captionImg='Obrigatório'
                        descriptionImg='Campos obrigatórios são indicados pelo asterisco.'
                    />
                    <HowToUseFigure
                        srcImg={focus}
                        captionImg='Focado'
                        descriptionImg='Campos focados escurecem a borda para melhor identificação.'
                    />
                    <HowToUseFigure
                        srcImg={placeholderLoader}
                        captionImg='Placeholder Loader'
                        descriptionImg='Campos quando estão sendo carregados utilizam esse estilo para indicar o carregamento.'
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Tipos'
                >
                    <HowToUseFigure
                        srcImg={text}
                        captionImg='Texto'
                        descriptionImg='Campos do tipo texto aceitam todo tipo de caracteres inclusive números.'
                    />
                    <HowToUseFigure
                        srcImg={textarea}
                        captionImg='Área de Texto'
                        descriptionImg='Campos do tipo área de texto são utilizados quando o dado requerido normalmente é extenso, como uma descrição.'
                    />
                    <HowToUseFigure
                        srcImg={select}
                        captionImg='Campos de Seleção'
                        descriptionImg='Campos de seleção são utilizados quando as opções são definidas, e não há muitas opções. <br /> Caso haja muitas opções deve ser utilizado um autocomplete.'
                    />
                    <HowToUseFigure
                        srcImg={gifdata}
                        captionImg='Data'
                        descriptionImg='Campos do tipo data permitem a digitação e a seleção através de um calendário, facilitando assim a usabilidade.'
                    />
                    <HowToUseFigure
                        srcImg={gifdatetime}
                        captionImg='Data e Hora'
                        descriptionImg='Campos do tipo datetime permitem a digitação e/ou a seleção da data e hora.'
                    />
                    <HowToUseFigure
                        srcImg={daterange}
                        captionImg='Data com Intervalo'
                        descriptionImg='Campos de data com intervalo permitem a digitação e seleção entre intervalos e possui filtros já definidos.'
                    />
                    <HowToUseFigure
                        srcImg={gifautocomplete}
                        captionImg='Autocomplete'
                        descriptionImg='Campos autocomplete são utilizados quando há muitas opções para seleção. <br /> As opções devem ser carregadas conforme o que foi digitado.'
                    />
                    <HowToUseFigure
                        srcImg={autocomplete}
                        captionImg='Autocomplete Detalhado'
                        descriptionImg='Fornece mais informações sobre o conteúdo a ser selecionado.'
                    />
                    <HowToUseFigure
                        srcImg={readonly}
                        captionImg='Somente Leitura'
                        descriptionImg='Utilizado para campos que em nenhum momento são permitidos editar.'
                    />
                    <hr />
                    <HowToUseFigure
                        col
                        srcImg={prefix}
                        captionImg='Prefixo'
                        descriptionImg='Campo texto com símbolo da moeda como prefixo.'
                    />
                    <HowToUseFigure
                        col
                        srcImg={sufix}
                        captionImg='Sufixo'
                        descriptionImg='Campo texto com peso como sufixo.'
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Fields);
