import React, { Component } from 'react';
import { BrowserRouter, Switch as SwitchRoute, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectTheme } from './actions/index';
import { bindActionCreators } from 'redux';
import test from './assets/img/backgrounds/combustivel-bg.jpg';

import slugify from 'react-slugify';

import './assets/less/compiled/arcdesign.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';

import Header from './componentes/Header/Header';
import Menu from './componentes/Menu/Menu';
import SearchMenu from './componentes/SearchMenu/SearchMenu';
import Tabs from './componentes/Tabs/Tabs';
import Button from './componentes/Button/Button';
import AccessModule from './componentes/AccessModule/AccessModule';
import ProgressBar from './componentes/ProgressBar/ProgressBar';
import Panel from './componentes/Panel/Panel';
import Breadcrumb from './componentes/Breadcrumb/Breadcrumb';
import DialogueBox from './componentes/DialogueBox/DialogueBox';
import Fields from './componentes/Fields/Fields';
import Hint from './componentes/Hint/Hint';
import DisplayData from './componentes/DisplayData/DisplayData';
import Filter from './componentes/Filter/Filter';
import Info from './componentes/Info/Info';
import Notification from './componentes/Notification/Notification';
import Wizard from './componentes/Wizard/Wizard';
import Modal from './componentes/Modal/Modal';
import Pagination from './componentes/Pagination/Pagination';
import ColorPicker from './componentes/ColorPicker/ColorPicker';
import Timeline from './componentes/Timeline/Timeline';
import Widget from './componentes/Widget/Widget';
import Dashboard from './componentes/Dashboard/Dashboard';
import TitleDashboard from './componentes/TitleDashboard/TitleDashboard';
import FloatingActionButton from './componentes/FloatingActionButton/FloatingActionButton';
import DragDrop from './componentes/DragDrop/DragDrop';
import FloatSidebar from './componentes/FloatSidebar/FloatSidebar';
import Tables from './componentes/Tables/Tables';
import TableEditable from './componentes/TableEditable/TableEditable';
import TableReport from './componentes/TableReport/TableReport';
import AdvancedSelector from './componentes/AdvancedSelector/AdvancedSelector';
import Chip from './componentes/Chip/Chip';
import AdvancedTree from './componentes/AdvancedTree/AdvancedTree';
import Typography from './componentes/Typography/Typography';
import ShortcutWheel from './componentes/ShortcutWheel/ShortcutWheel'
import Workflow from './componentes/Workflow/Workflow';
import EmptyState from './componentes/EmptyState/EmptyState';
import HeaderJuggernaut from './componentes/HeaderJuggernaut/HeaderJuggernaut';
import DatePicker from './componentes/DatePicker/DatePicker';
import Card from './componentes/Card/Card';
import ControlSelectors from './componentes/ControlSelectors/ControlSelectors';
import Introduction from './componentes/Introduction/Introduction';
import Loaders from './componentes/Loaders/Loaders';

import Components from './pages/Components';
import News from './pages/News';

import ListMenu from './ListMenu';
import Example from './componentes/Example/Example';

import classnames from 'classnames';

import { faBars, faSearch, faTh, faCircle, faCode, faCopy, faCheck, faPlusCircle, faMinusCircle, faCaretRight, faChevronLeft, faSpinner, faTools, faUser, faBuilding, faCalendarAlt, faSearchPlus, faSearchMinus, faExpand, faAdjust, faUserCircle, faPlus, faMinus, faUniversalAccess, faAngleDoubleLeft, faAngleDoubleRight, faWheelchair, faPaperPlane, faTimesCircle, faTimes, faChartBar, faEye, faSortAmountDown, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
library.add(fab, faBars, faSearch, faTh, faCircle, faCode, faCopy, faCheck, faPlusCircle, faMinusCircle, faCaretRight, faChevronLeft, faSpinner, faTools, faUser, faBuilding, faCalendarAlt, faSearchPlus, faSearchMinus, faExpand, faAdjust, faUserCircle, faPlus, faMinus, faUniversalAccess, faAngleDoubleLeft, faAngleDoubleRight, faWheelchair, faPaperPlane, faTimesCircle, faTimes, faChartBar, faEye, faSortAmountDown, faClipboardCheck );

class App extends Component {

    searchInput = React.createRef();
    listOption = React.createRef();
    observer = null;


    componentDidMount() {
        const { selectTheme } = this.props;

        const hash = document.location.hash;
        if (hash === '#codigo') {
            selectTheme('dark');
        }

        this.listenerKeyDown();
        setTimeout(() => {
            this.removeLoading();
        }, 2000)
    }

    removeLoading = () => {
        document.querySelector('body').classList.remove('loading');
        document.querySelector('figure.loading img').classList.add('off');
    };

    state = {
        open: false,
        ListMenu: ListMenu,
        selectedIndex: 0,
        activeMessage: false
    }

    _scrollTo = (el, top) => {
        el.scrollTop = top;
    }

    _scrollIntoView = (parentElement, childFocusedElement) => {

        const parentRect = parentElement.getBoundingClientRect();
        const focusedRect = childFocusedElement.getBoundingClientRect();
        const overScroll = childFocusedElement.offsetHeight / 3;

        if (focusedRect.bottom + overScroll > parentRect.bottom) {
            this._scrollTo(
                parentElement,
                Math.min(
                    childFocusedElement.offsetTop +
                    childFocusedElement.clientHeight -
                    parentElement.offsetHeight +
                    overScroll,
                    parentElement.scrollHeight
                )
            );
        } else if (focusedRect.top - overScroll < parentRect.top) {
            this._scrollTo(
                parentElement,
                Math.max(childFocusedElement.offsetTop - overScroll, 0)
            );
        }
    }

    listenerKeyDown = () => {
        document.addEventListener('keydown', event => {
            if (this.state.open) {
                if (event.key === 'Escape') {
                    this.clearSearch();
                    this.searchInput.current.blur();
                }

                if (this.state.ListMenu.length) {
                    if (event.key === 'Enter') {
                        event.preventDefault();
                        this.onSelect();
                    }

                    if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {

                        if (event.key === 'ArrowUp') {
                            this.setState(state => {
                                return {
                                    selectedIndex: state.selectedIndex - 1
                                }
                            }, () => {
                                this.handleObserver()
                            });
                        } else {
                            this.setState(state => {
                                return {
                                    selectedIndex: state.selectedIndex + 1
                                }
                            }, () => {
                                this.handleObserver();
                            });
                        }
                    }
                }
            }
            if ((event.keyCode === 70 && event.ctrlKey) || (event.metaKey && event.keyCode === 70)) {
                event.preventDefault();
                this.toggleMenu();
            }
        })

    }

    handleObserver = () => {
        const { open, selectedIndex, ListMenu } = this.state;

        if (open) {
            if (selectedIndex === Object.keys(ListMenu).length) {
                this.setState({ selectedIndex: Object.keys(ListMenu).length - 1 })
            } else if (selectedIndex >= 0) {
                this._scrollIntoView(this.listOption.current, this.listOption.current.querySelector('li a.active'));
            } else if (selectedIndex < 0) {
                this.setState({ selectedIndex: 0 });
            }
        }
    }

    onSelect = () => {
        this._scrollTo(this.listOption.current, 0);
        const actived = this.listOption.current.querySelector('li a.active');
        actived.click();
        window.location.href = actived.getAttribute('href');

        this.clearSearch();
    }

    clearSearch = () => {
        this.setState({ ListMenu: ListMenu }, () => {
            this.searchInput.current.value = '';
            this.searchInput.current.blur();
        })
    }

    focusInput = () => {
        if (this.searchInput.current && this.state.open) this.searchInput.current.focus();
        if (!this.state.open) this.clearSearch();
    }

    toggleMenu = () => {
        this.setState({ open: !this.state.open, selectedIndex: 0 }, () => {
            setTimeout(() => {
                this.focusInput();
            }, 100)
        });
    }

    searchMenu = (event) => {
        const term = slugify(event.target.value);
        let matchItems = undefined;

        this.setState({ selectedIndex: 0 }, () => {

            if (term) {
                matchItems = ListMenu.filter((item) => {
                    return slugify(item.keywords).search(term) !== -1;
                })
            }

            this.setState({
                ListMenu: matchItems ? matchItems : ListMenu
            })

        })
    }

    showMessage = () => {
        var messageRead = sessionStorage.getItem('messageRead');
        if (!messageRead) {
            this.setState({
                activeMessage: true
            })
        }
    }

    hiddenMessage = () => {
        sessionStorage.setItem('messageRead', true);
        this.setState({
            activeMessage: false
        })
    }

    renderMessage = () => {
        const { activeMessage } = this.state;

        return (
            <>
                {activeMessage &&
                    <div className="container">
                        <div className="row mt-xs">
                            <div className="col-md-12">
                                <div className="info neutral icon">
                                    <div className="icon">
                                        <em className="fa  fa-check-circle" />
                                    </div>
                                    <article>
                                        <h4>O Arc Design agora está na versão 3!</h4>
                                        <p>Componentes, fontes e cores foram atualizados.</p>
                                    </article>
                                    <button className="button-close" onClick={this.hiddenMessage}>
                                        <em className="fa fa-times" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </>
        )
    }

    render() {
        const { selectedIndex, open } = this.state;
        const { theme, fullScreen } = this.props;

        const mainClasses = classnames(
            'arc-main',
            { 'fullscreen': fullScreen }
        )

        return (
            <BrowserRouter>
                {!fullScreen &&
                    <>
                        <img alt="LOGO" src={test} className="hidden" />
                        <svg className="background-logo" viewBox="217 300 300 217" >
                            <path className="cls-4" d="M367.5,167C256.77,167,167,256.77,167,367.5S256.77,568,367.5,568,568,478.23,568,367.5,478.23,167,367.5,167Zm67.07,32.77a181,181,0,0,1,67.23,46.94H422.29Zm-67-12.86a180.66,180.66,0,0,1,46.58,6.1l-14.05,53.7H338.48l-14.26-54.5A181.23,181.23,0,0,1,367.58,186.91Zm-63.87,11.67,12.59,48.13H233.37A180.91,180.91,0,0,1,303.71,198.58ZM519.4,269.41l-60.79,35.1H276.35l-60.78-35.1ZM187,367.47a180.17,180.17,0,0,1,8.86-55.88l39.77,68.88L188.88,393.3A181.78,181.78,0,0,1,187,367.47Zm6.16,46.9,53.55-14.69,30.83,53.39L237.5,492.66A180.15,180.15,0,0,1,193.18,414.37Zm60.12,92.84,35.35-34.93,41.48,71.84A179.81,179.81,0,0,1,253.3,507.21ZM358.64,548.1,206.71,285,267.5,320l91.14,157.87ZM305,331.34H430.16L367.58,439.72Zm71.51,146.57,91.25-158,60.79-35.09-152,263.33ZM405,544.12l39.79-68.92,34.49,34.07A179.88,179.88,0,0,1,405,544.12ZM495.37,495l-39.45-39,30.85-53.43,54.33,14.9A180.36,180.36,0,0,1,495.37,495Zm2.49-111.62,41.43-71.76a180.34,180.34,0,0,1,6.5,84.91Z" />
                        </svg>
                        <Header toggleMenu={this.toggleMenu} open={open} />
                        <aside className={`arc-aside ${open ? 'open' : ''}`} >
                            <SearchMenu
                                searchMenu={this.searchMenu}
                                searchInputRef={this.searchInput}
                                toggleMenu={this.toggleMenu}
                            />
                            <Menu
                                selectInput={this.onSelect}
                                toggleMenu={this.toggleMenu}
                                listMenu={this.state.ListMenu}
                                listOption={this.listOption}
                                selectedIndex={selectedIndex}
                                theme={theme}
                            />
                        </aside>
                    </>
                }
                <main className={mainClasses}>
                    <section className="arc-section">
                        {!fullScreen && this.renderMessage()}
                        <SwitchRoute>
                            <Redirect from="/" to="/componentes" exact />
                            <Route path="/versoes" exact component={News} />
                            <Route path="/componentes" exact
                                render={
                                    props => <><h1 className="main-title center"> <small> Bem-vindo ao </small> Arc Design</h1><Components components={ListMenu} {...props} /></>
                                }
                            />
                            <Route path="/componentes/abas/" component={Tabs} />
                            <Route path="/componentes/acesso-aos-modulos/" component={AccessModule} />
                            <Route path="/componentes/arvore-avancada" component={AdvancedTree} />
                            <Route path="/componentes/barra-de-progresso" component={ProgressBar} />
                            <Route path="/componentes/blocos" component={Panel} />
                            <Route path="/componentes/breadcrumb" component={Breadcrumb} />
                            <Route path="/componentes/caixa-de-dialogo" component={DialogueBox} />
                            <Route path="/componentes/botoes" component={Button} />
                            <Route path="/componentes/campos" component={Fields} />
                            <Route path="/componentes/card" component={Card} />
                            <Route path="/componentes/botoes-flutuantes" component={FloatingActionButton} />
                            <Route path="/componentes/chip" component={Chip} />
                            <Route path="/componentes/date-picker" component={DatePicker} />
                            <Route path="/componentes/dashboard" component={Dashboard} />
                            <Route path="/componentes/display-data" component={DisplayData} />
                            <Route path="/componentes/dicas" component={Hint} />
                            <Route path="/componentes/dragdrop" component={DragDrop} />
                            <Route path="/componentes/estados-vazios" component={EmptyState} />
                            <Route path="/componentes/fluxo-de-processo" component={Workflow} />
                            <Route path="/componentes/filtros" component={Filter} />
                            <Route path="/componentes/notificacao" component={Notification} />
                            <Route path="/componentes/informativos" component={Info} />
                            <Route path="/componentes/paginacao" component={Pagination} />
                            <Route path="/componentes/passo-a-passo" component={Wizard} />
                            <Route path="/componentes/roda-de-atalhos" component={ShortcutWheel} />
                            <Route path="/componentes/seletor-avancado" component={AdvancedSelector} />
                            <Route path="/componentes/controles-de-selecao" component={ControlSelectors} />
                            <Route path="/componentes/seletor-de-cores" component={ColorPicker} />
                            <Route path="/componentes/float-sidebar" component={FloatSidebar} />
                            <Route path="/componentes/tabela-table" component={Tables} />
                            <Route path="/componentes/tabela-div" component={TableEditable} />
                            <Route path="/componentes/timeline" component={Timeline} />
                            <Route path="/componentes/titulo-secao" component={Typography} />
                            <Route path="/componentes/titulo-dashboard" component={TitleDashboard} />
                            <Route path="/componentes/layout" component={HeaderJuggernaut} />
                            <Route path="/componentes/modal" component={Modal} />
                            <Route path="/componentes/widget" component={Widget} />
                            <Route path="/componentes/relatorio" component={TableReport} />
                            <Route path="/componentes/tabela" component={Tables} />
                            <Route path="/componentes/introducao" component={Introduction} />
                            <Route path="/componentes/carregadores" component={Loaders} />
                        </SwitchRoute>
                    </section>
                </main>
                <Route path="/example" component={Example} />
            </BrowserRouter>
        );
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme,
        fullScreen: state.fullScreen
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ selectTheme: selectTheme }, dispatch);
}


export default connect(mapStateToProps, mapDispatchToProps)(App);
