import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from './../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';

import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';

import anatomia from '../../assets/img/components/breadcrumb/anatomia.png';
import doit from '../../assets/img/components/breadcrumb/doit.png';
import dont from '../../assets/img/components/breadcrumb/dont.png';
import aplicacao from '../../assets/img/components/breadcrumb/aplicacao.png';
import gif from '../../assets/img/components/breadcrumb/gif.gif';
import adicao from '../../assets/img/components/breadcrumb/adicao.png';
import edicao from '../../assets/img/components/breadcrumb/edicao.png';
import detalhes from '../../assets/img/components/breadcrumb/detalhes.png';
import extra from '../../assets/img/components/breadcrumb/extra.gif';
import botoes from '../../assets/img/components/breadcrumb/botoes.png';
import dontButton from '../../assets/img/components/breadcrumb/dont-button.png';
import doitButton from '../../assets/img/components/breadcrumb/doit-button.png';

import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

const files = [
    { 'less': ['nav-breadcrumbs.less'] },
    { 'angular': ['breadcrumb.module.ts'] },
    { 'react': ['Breadcrumb.jsx'] }
];

class Breadcrumbs extends Component {
    state = {
        variation: undefined,
        buttons: false,
        collapsed: false,
        currentIndex: undefined,
        openCollapse: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Breadcrumb');
        this.setState({ currentIndex: index });
    }

    resetState = () => {
        this.setState({ variation: false })
    }

    openCollapse = () => {
        this.setState(state => {
            return {
                openCollapse: !state.openCollapse
            }
        })
    }

    renderComponent = () => {
        const { variation, buttons, collapsed, openCollapse } = this.state;

        return (
            <>
                <nav aria-label="Breadcrumbs" className="nav-breadcrumbs">
                    <ol>
                        <li>
                            <a href="/#breadcrumb" aria-current={!variation ? 'page' : ''} onClick={this.resetState}>
                                <i className="fa fa-list-alt" />
                                <span>Pedidos de Compra</span>
                            </a>
                        </li>
                        {collapsed &&
                            <li>
                                <div className="bc-collapsed" data-title="Código / Exercício">
                                    <i className="fa fa-ellipsis-h" onClick={this.openCollapse}/>
                                    <ol className={`${openCollapse ? 'open' : ''}`}>
                                        <li >
                                            <a href="/#breadcrumb">
                                                <i className="fa fa-newspaper-o" />
                                                <span>Processo Administrativo</span>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="/#breadcrumb" >
                                                <i className="fa fa-circle" />
                                                <span>Lorem ipsum</span>
                                            </a>
                                        </li>
                                    </ol>
                                </div>
                            </li>
                        }
                        {variation === 'edit' &&
                            <li>
                                <a href="/#breadcrumb" aria-current={variation === 'edit' ? 'page' : ''}>
                                    <i className="fa fa-edit" />
                                    <span>Edição</span>
                                    <div className="extra" data-title="Código / Exercício">
                                        <span>
                                            1324 / 2019
                                        </span>
                                    </div>
                                </a>
                            </li>
                        }
                        {variation === 'new' &&
                            <li>
                                <a href="/#breadcrumb" aria-current={variation === 'new' ? 'page' : ''}>
                                    <i className="fa fa-plus" />
                                    <span>Novo</span>
                                    <div className="extra" data-title="Código / Exercício">
                                        <span>
                                            1325 / 2019
                                        </span>
                                    </div>
                                </a>
                            </li>
                        }
                        {variation === 'more' &&
                            <>
                                <li>
                                    <a href="/#breadcrumb">
                                        <i className="fa fa-newspaper-o" />
                                        <span>Lorem ipsum</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="/#breadcrumb" aria-current="page">
                                        <i className="fa fa-circle" />
                                        <span>Lorem ipsum</span>
                                    </a>
                                </li>
                            </>
                        }
                    </ol>
                    {buttons &&
                        <div className="nav-buttons" role="group">
                            <button className="hint clean module-color fa fa-star bottom-right">
                                <div className="hint-content">
                                    Lorem ipsum
                                </div>
                            </button>
                            <button className="hint clean module-color fa fa-expand bottom-right">
                                <div className="hint-content">
                                    Lorem ipsum
                                </div>
                            </button>
                        </div>
                    }
                </nav>
            </>
        )
    }
    onChange = newState => {
        this.setState(newState);
    }
    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="variation" value="edit" stateName="variation" label="Edição" />
                <SwitchOptions attr="variation" value="new" stateName="variation" label="Adição" />
                <SwitchOptions attr="variation" value="more" stateName="variation" label="Mais Níveis" />
                <SwitchOptions attr="collapsed" value={true} stateName="collapsed" label="Compacto" />
                <SwitchOptions attr="buttons" value={true} stateName="buttons" label="Com Botões" />
            </SwitchOptionsGroup>
        )
    }
    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    mainTitle='Breadcrumbs'
                    mainDescription='O breadcrumb auxilia o sistema de navegação, identificando o local em que o usuário se encontra.'
                    theme={theme}
                >
                <HowToUse
                    title='Uso'
                    description='O breadcrumb auxilia o sistema de navegação, identificando o local em que o usuário se encontra.'
                />
                <HowToUse
                    title='Anatomia'
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg='1. Container <br /> 2. ícone de identificação <br /> 3. Texto de identificação do local <br /> 4. Local antecessor <br /> 5. Local atual <br /> 6. Informação extra do local atual (opcional)'
                    />

                </HowToUse>
                <HowToUse
                    title='Hierarquia'
                    description='Cada identificação do breadcrumb, ou seja cada página, deve coincidir com o nome e ícone que está no menu.'
                >
                    <HowToUseFigure
                        srcImg={doit}
                        status='dont'
                        col={1}
                        descriptionImg='Não utilize nomes e ícones diferentes para referênciar o mesmo local'
                    />
                    <HowToUseFigure
                        srcImg={dont}
                        status='do'
                        col={1}
                        descriptionImg='Mantenha a relação entre nome e ícone.'
                    />
                </HowToUse>
                <HowToUse
                    title='Aplicação'
                    description='Breadcrumb deve sempre estar ao topo da página logo abaixo do header.'
                >
                    <HowToUseFigure
                        srcImg={aplicacao}
                    />
                </HowToUse>
                <HowToUse
                    title='Comportamento'
                    subtitle='Navegação'
                    description='Usuário pode clicar para navegar entre os locais.'
                >
                    <HowToUseFigure
                        srcImg={gif}
                    />
                </HowToUse>
                <HowToUse
                    title='Variações'
                    description='Existe rotas que por padrão já tem breadcrumb definidos, representando exatamente em que etapa o usuário está, mantendo assim o padrão em todas as aplicações.'
                />
                <HowToUse
                    subtitle='Adição'
                    description='Deve-se sempre utilizar essa variação para páginas de novos cadastros. Seguindo o padrão do texto e ícone.'
                >
                    <HowToUseFigure
                        srcImg={adicao}
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Edição'
                    description='Deve-se sempre utilizar essa variação para páginas de edição de cadastros. Seguindo o padrão do texto e ícone.'
                >
                    <HowToUseFigure
                        srcImg={edicao}
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Detalhes'
                    description='Em casos onde a página contém uma listagem de vários registros e cada registro possui detalhes utilizamos o breadcrumb de detalhes. Seguindo o padrão do texto e ícone.'
                >
                    <HowToUseFigure
                        srcImg={detalhes}
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Informação Extra'
                    description='O breadcrumb pode conter uma informação extra, podendo ser o número de identificação de um cadastro ou algo relevante a página atual, e que necessite ficar visível.'
                >
                    <HowToUseFigure
                        srcImg={extra}
                        captionImg='Informação extra com uma dica ao passar o mouse'
                    />
                </HowToUse>
                <HowToUse
                    subtitle='Botões'
                    description='O breadcrumb pode conter um ou mais botões para ações relacionadas a página.'
                >
                    <HowToUseFigure
                        srcImg={botoes}
                    />

                    <HowToUseFigure
                        status='dont'
                        col
                        srcImg={dontButton}
                        descriptionImg='Não utilize ações que deveriam estar dentro da página.'
                    />
                    <HowToUseFigure
                        status='do'
                        col
                        srcImg={doitButton}
                        descriptionImg='Utilize opções relacionadas a rota, como favoritar a página.'
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Breadcrumbs);
