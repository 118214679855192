import React, { Component } from 'react';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
import { connect } from 'react-redux';
import ComponentTemplate from '../Templates/ComponentTemplate';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';

import anatomia from '../../assets/img/components/empty-state/anatomia.png';
import do1 from '../../assets/img/components/empty-state/do1.png';
import dont1 from '../../assets/img/components/empty-state/dont1.png';

const files = [{ 'less': ['not-found.less'] }];

class EmptyState extends Component {

    state = {
        type: undefined,
        see: false,
        currentIndex: undefined,
        neutral: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Estados Vazios');
        this.setState({currentIndex: index});
    }

    onChange = newState => {
        return this.setState(newState);
    }

    handleExpand = () => {
        this.setState(state => {
            return { see: !state.see }
        });
    }

    renderComponent = () => {

        const {
            type,
            see,
            neutral
        } = this.state;

        return (
            <React.Fragment>
                {!type &&
                    <div className={`not-found ${neutral ? 'neutral' : ''}`}>
                        <span className="fa fa-cogs"></span>
                        <h2> Lorem ipsum dolor, sit amet consectetur adipisicing elit.</h2>
                        <small> Lorem ipsum dolor, sit amet consectetur adipisicing elit. </small>
                        <a href="/">
                            <button className="btn module-color icon-right center mt-xs">
                                Lorem ipsum dolor
                        </button>
                        </a>
                    </div>
                }
                {type &&
                    <div className="not-found boundary">
                        <span className="fa fa-exclamation-circle"></span>
                        <h2> Algo deu errado.</h2>
                        <small> O erro foi: </small>
                        <small className="boundary-text-error mb-xs"> Lorem ipsum dolor sit amet </small>
                        {!see &&
                            <h5 className="boundary-text-action" onClick={this.handleExpand}> Ver mais </h5>
                        }
                        {see &&
                            <React.Fragment>
                                <h5 className="boundary-text-action" onClick={this.handleExpand}> Ver Menos </h5>
                                <small className="boundary-text-error"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Maxime, quo! Earum eum sunt commodi, blanditiis numquam voluptatibus modi at iure, ullam accusamus asperiores quis, laborum fugiat officia repellendus temporibus illo. </small>
                            </React.Fragment>
                        }
                        <a href="/">
                            <button className="btn module-color icon-right center mt-xs">
                                VOLTAR AO INÍCIO
                        </button>
                        </a>
                    </div>
                }
            </React.Fragment>
        )
    }


    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="type" value="true" stateName="type" label="Com Fundo" />
                <SwitchOptions attr="neutral" value="true" stateName="neutral" label="Sem cor" />
            </SwitchOptionsGroup>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Estado Vazio"
                    mainDescription="Estados vazios ocorrem quando o conteúdo de um item não pode ser exibido."
                >

                <HowToUse
                    title="Anatomia"
                    description="O estado vazio mais básico consiste em um ícone não interativo e uma frase"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="Mas pode conter os itens: <br /> 1. Ícone <br /> 2. Frase <br /> 3. Texto auxiliar (Opcional) <br /> 4. Botão (Opcional)"
                    />
                </HowToUse>

                <HowToUse
                    title="Aplicação"
                    description="<b>Use um ícone que:</b> <br /> • Tem um tom neutro <br /> • Representa uma ação ou comportamento desejado <br /> <b>Inclua uma frase que:</b> <br /> • Tem uma mensagem útil <br /> • Transmite o objetivo da tela, sem parecer acionável"
                >
                    <HowToUseFigure
                        col
                        status="dont"
                        srcImg={dont1}
                        descriptionImg="Imagens que expressam urgência ou confusão devem ser evitadas."
                    />
                    <HowToUseFigure
                        col
                        status="do"
                        srcImg={do1}
                        descriptionImg="Use um ícone que transmita a finalidade da tela, sem parecer acionável."
                    />
                </HowToUse>

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(EmptyState);
