import React, { Component } from 'react';

import { connect } from 'react-redux';

import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import ComponentTemplate from '../Templates/ComponentTemplate';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import FilesTemplate from './../Templates/FilesTemplate';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';

import anatomia from '../../assets/img/components/section-title/anatomia.png';
import aplicacao from '../../assets/img/components/section-title/aplicacao.png';

import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';
const files = [{ 'less': ['typography.less'] }];

class Typography extends Component {

    state = {
        currentIndex: undefined,
        hasButton: false,
        space: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Título Seção');
        this.setState({currentIndex: index});
    }

    renderComponent = () => {
        const { hasButton, space } = this.state;
        return (
            <h4 className={`typography-section-title ${space ? 'mt-xs' : ''}`}>
                Título
                {hasButton && 
                    <button className="btn module-color icon-left">
                        <em className="fa fa-plus"/>
                        Botão
                    </button> 
                }
            </h4>
        );
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    onChange = newState => {
        return this.setState(newState);
    }

    renderOptionalClasses = () => {
        return (
            <SwitchOptionsGroup label="Variação" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr="hasButton" value={true} stateName="hasButton" label="Com botão" />
                <SwitchOptions attr="space" value={true} stateName="space" label="Com margin ao topo" />
            </SwitchOptionsGroup>
        )
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    theme={theme}
                    mainTitle="Título de Seção"
                    mainDescription="Responsável por identificar uma determinada seção de um formulário"
                >
                <HowToUse
                    title="Uso"
                    description="É indicado agrupar em seções lógicas formulários com mais de 6 campos. <br /> O uso da seção facilita a identificação do conteúdo assim como o seu preenchimento e agrupa informações similares."
                />
                <HowToUse
                    title="Anatomia"
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg="1. Container <br /> 2. Título"
                    />
                </HowToUse>
                <HowToUse
                    title="Aplicacao"
                >
                    <HowToUseFigure
                        srcImg={aplicacao}
                        captionImg="Título de seção utilizado no formulário."
                    />
                </HowToUse>
                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }

}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(Typography);
