import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import SwitchOptionsGroup from './../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import classnames from 'classnames';
import { connect } from 'react-redux';

class TableEditable extends Component {

    editableInput = React.createRef();

    state = {
        sortable: false,
        desc: false,
        asc: false,
        columnSelector: false,
        textAlign: '',
        showColumnSelectorOptions: false,
        buttons: false,
        trType: '',
        border: false,
        inverted: false,
        fancy: false,
        striped: false,
        status: false,
        fixed: false,
        footer: false,
        checkbox: false,
        radio: false,
        searchFilters: false,
        hasQuickView: false,
        expanded: false,
        headerButton: false,
        scrollx: false,
        extraInfo: false,
        footerWithTransparency: false,
        editable: false,
        editableAuto: false,
        editing: false,
        level: false,
        highlight: undefined,
        changed: undefined,
        fancyTotals: false
    }

    componentDidUpdate() {
        window.scrollTo(0, 0);
        const { scrollx, buttons, expanded, fixed } = this.state;

        if (scrollx) {
            if (buttons === 'reduced') {
                this.calcButtonsHeightOnTableEditableWithHorizontalScroll();
            }

            if (fixed) {
                this.calcPositionToShowButtonsOnTableEditableScrollAndFixed();
            }

            if (expanded) {
                this.calcWidthOfChildTableOnTableEditableWithHorizontalScroll();
            }
        }
    }

    // TABLE FIXED

    calcPositionToShowButtonsOnTableEditableScrollAndFixed = () => {
        var rows = document.querySelectorAll('.table-editable.fixed .table-editable-content .row');
        for (var row of rows) {
            row.addEventListener('mouseover', event => {
                this.onMouseOverTableEditable(event);
            })
        }
    }

    onMouseOverTableEditable = event => {
        var target = event.target.closest('.row');
        var offsetTop = target.offsetTop;
        var scrollTop = target.parentElement.parentElement.scrollTop;
        var button = target.querySelector('.column.btn-actions');

        //FIREFOX BUG
        console.log('=>', target, target.parentElement)
        if (target.parentElement.querySelector('.row:first-child').offsetTop === 0) {
            offsetTop += target.parentElement.parentElement.querySelector('.table-editable-content').offsetHeight
        }

        button.style.top = (offsetTop - scrollTop) + 'px';
    }

    calcButtonsHeightOnTableEditableWithHorizontalScroll = () => {
        const btns = document.querySelectorAll('.scroll-x .table-editable .table-editable-content .row.reduced .btn-actions');
        for (const btn of btns) {
            btn.style.height = (btn.parentElement.offsetHeight - 2) + 'px';
        }
    }

    calcWidthOfChildTableOnTableEditableWithHorizontalScroll = () => {
        var columns = document.querySelectorAll('.scroll-x .table-editable.panel-table-outer > .table-editable-header .column:not(.column-selector):not(.header-button)');
        var childTables = document.querySelectorAll('.table-editable.panel-table-outer .child-table');

        var widthScroll = 0;

        for (var column of columns) {
            var rect = column.getBoundingClientRect();
            widthScroll += rect.width;
        }

        for (var child of childTables) {
            if (child) {
                child.style.width = widthScroll + 'px';
            }
        }
    }


    renderExtraColumnToScrollx = () => {
        let ths = [];
        for (var x = 0; x < 7; x++) {
            ths.push(<div className="column col-sm-3" key={x}><span>lorem lorem lorem lorem</span></div>);
        }
        return ths;
    }

    onChange = newState => {
        this.setState(newState);
    }

    toggleSortable = () => {
        if (this.state.sortable) {
            this.setState(state => {
                if (state.desc) {
                    return {
                        'desc': false,
                        'asc': true
                    }
                }
                return {
                    'desc': true,
                    'asc': false
                }
            })
        }
    }

    toggleColumnSelectorOptions = () => {
        this.setState(state => {
            return { showColumnSelectorOptions: !state.showColumnSelectorOptions }
        })
    }

    toggleEditable = () => {

        this.setState(state => {
            return {
                editing: !state.editing
            }
        }, () => {
            if (this.state.editing) {
                this.editableInput.current.focus();
            }
        })
    }

    toggleQuickView = () => {
        this.setState(state => {
            return {
                openedQuickView: !state.openedQuickView
            }
        })
    }

    toggleExpanded = () => {
        this.setState(state => {
            return {
                showExpanded: !state.showExpanded
            }
        })
    }

    renderComponent = () => {
        const { sortable, desc, asc, columnSelector, textAlign, showColumnSelectorOptions, buttons, trType, border, inverted, fancy, striped, status, fixed, footer, fancyTotals, editable, editableAuto, editing,
            checkbox, radio, openedQuickView, searchFilters, hasQuickView, expanded, showExpanded, headerButton, scrollx, extraInfo, footerWithTransparency, level, highlight, changed } = this.state;

        const tableClasses = classnames('table-editable', 'panel-table-outer', {
            border,
            fancy,
            striped,
            inverted,
            fixed,
            'transparent-footer': footerWithTransparency,
            'tree': level
        });

        const headerColumnClasses = classnames('column', textAlign, {
            sortable,
            desc,
            asc
        });

        const rowClasses = classnames('row', {
            'reduced': buttons === 'reduced'
        })

        const contentColumnClasses = classnames('column', 'col-sm-3', textAlign);

        const statusClasses = classnames(status, 'center', 'chip-container', 'outlined');

        return (
            <>
                <div className="panel table">
                    {hasQuickView && scrollx &&
                        <div className={`quick-view ${openedQuickView ? 'open' : ''}`}>
                            <em className="fa fa-times" onClick={this.toggleQuickView}></em>
                            <div className="row">
                                <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                                <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                            </div>
                        </div>
                    }
                    <div className={`panel-body ${scrollx ? 'scroll-x' : ''}`}>
                        {searchFilters &&
                            <div className="panel-table-filter">
                                <div className="row">
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <select aria-label="search input">
                                                <option>Código</option>
                                                <option>Requerente</option>
                                                <option>CPF/CNPJ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-2">
                                        <div className="form-group">
                                            <select aria-label="search input">
                                                <option>Contém</option>
                                                <option>Igual</option>
                                                <option>Diferente de</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="form-group"><input aria-label="campo de pesquisa" type="text" name="" placeholder="Digite o filtro..." className="button-input" />

                                            <button className="btn filter ">Filtrar</button></div>
                                    </div>
                                </div>
                                <div className="panel-filter-tags"><span className="filter-tag">Isso é um filtro<i className="fa fa-times remove"></i></span></div>
                            </div>
                        }
                        <div className={tableClasses}>
                            <div className="table-editable-header">
                                <div className="row">
                                    {checkbox && !radio &&
                                        <div className="column checkbox direction-row">
                                            <input className="input-checkbox" type="checkbox" id="checkbox1" />
                                            <label htmlFor="checkbox1"></label>
                                        </div>
                                    }
                                    {radio && !checkbox &&
                                        <div className="column radio direction-row" />
                                    }
                                    <div className={`col-sm-3 ${headerColumnClasses}`} onClick={this.toggleSortable}> <span> Coluna 1 </span></div>
                                    <div className={`col-sm-3 ${headerColumnClasses} ${highlight}`} onClick={this.toggleSortable}> <span> Coluna 2 </span></div>
                                    <div className={`col-sm-3 ${headerColumnClasses}`} onClick={this.toggleSortable}> <span> Coluna 3 </span></div>
                                    <div className={`col-sm-3 ${headerColumnClasses}`} onClick={this.toggleSortable}> <span> Coluna 4 </span></div>
                                    {scrollx &&
                                        this.renderExtraColumnToScrollx()
                                    }
                                    {buttons && buttons !== 'reduced' &&
                                        <div className="col-sm-1"></div>
                                    }
                                    {columnSelector &&
                                        <div className="col-sm-1 column-selector column">
                                            <div className={`hint fa-columns top-right sm ${!inverted ? 'clean module-color' : ''}`} onClick={this.toggleColumnSelectorOptions}>
                                                <div className="hint-content"> Seletor de Colunas </div>
                                            </div>
                                            <ul className={`options ${showColumnSelectorOptions ? 'actived' : ''}`}>
                                                <li>
                                                    <input id="column-1" className="input-checkbox" type="checkbox" />
                                                    <label htmlFor="column-1">Coluna 1</label>
                                                </li>
                                                <li>
                                                    <input id="column-2" className="input-checkbox" type="checkbox" />
                                                    <label htmlFor="column-2">Coluna 2</label>
                                                </li>
                                                <li>
                                                    <input id="column-3" className="input-checkbox" type="checkbox" />
                                                    <label htmlFor="column-3">Coluna 3</label>
                                                </li>
                                                <li>
                                                    <input id="column-4" className="input-checkbox" type="checkbox" />
                                                    <label htmlFor="column-4">Coluna 4</label>
                                                </li>
                                            </ul>
                                        </div>
                                    }
                                    {headerButton &&
                                        <div className="col-md-1 header-button column">
                                            <div className="btn-actions">
                                                <div className="btn-actions-inner">
                                                    <button className="btn circle" type="button">
                                                        <i className="fa fa-map-marker" />
                                                        <div className="btn-actions-label">Todos Endereços</div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="table-editable-content">
                                <div className={`${rowClasses} ${trType} ${openedQuickView ? 'rowSelected' : ''}`}>
                                    {checkbox && !radio &&
                                        <div className="column checkbox direction-row">
                                            <input className="input-checkbox" type="checkbox" id="checkbox2" />
                                            <label htmlFor="checkbox2"></label>
                                        </div>
                                    }
                                    {radio && !checkbox &&
                                        <div className="column radio direction-row">
                                            <input type="text" className="input-radio" id="radio2" />
                                            <label htmlFor="radio2"></label>
                                        </div>

                                    }
                                    <div className={`${contentColumnClasses} ${extraInfo ? 'direction-row' : ''} ${changed}`} data-header="Coluna">
                                        {extraInfo &&
                                            <div className="extra-info split-letter" data-letter="A">
                                                <p>Lorem ipsum lorem ipsum lorem</p>
                                            </div>
                                        }
                                        <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span>
                                    </div>
                                    <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                        {!status &&
                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                        }
                                        {status &&
                                            <div className={statusClasses}>
                                                <p className="chip-text">LOREM IPSUM</p>
                                            </div>
                                        }
                                    </div>
                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                        {editable && !editing &&
                                            <>
                                                9387492
                                                <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                    <div className="hint-content">Editar</div>
                                                </div>
                                            </>
                                        }

                                        {editing && !editableAuto &&
                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} data-header="Coluna" />
                                        }

                                        {editing && editableAuto &&
                                            <>
                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input" name="" placeholder="Cidade" />
                                                <i className="fa fa-search input-icon"></i>
                                                <ul className="autocomplete">
                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                    <li className="autocomplete-item">03 - Maringá</li>
                                                    <li className="autocomplete-item">04 - Ponta Grossa</li>
                                                    <li className="autocomplete-item">05 - Foz do Iguaçú</li>
                                                    <li className="autocomplete-item">06 - Cianorte</li>
                                                    <li className="autocomplete-item">07 - Campo Mourão</li>
                                                </ul>
                                            </>
                                        }

                                        {!editable && !editing &&
                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                        }
                                    </div>
                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                    {scrollx &&
                                        this.renderExtraColumnToScrollx()
                                    }
                                    {headerButton && !scrollx &&
                                        <div className="col-sm-1 header-button"></div>
                                    }
                                    {columnSelector && !scrollx &&
                                        <div className="col-sm-1 column-selector"></div>
                                    }
                                    {buttons &&
                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                            <div className="btn-actions-inner">
                                                {hasQuickView &&
                                                    <button className="btn" onClick={this.toggleQuickView}>
                                                        <i className="fa fa-eye"></i>
                                                        <div className="btn-actions-label">Visualizar</div>
                                                    </button>
                                                }
                                                {expanded &&
                                                    <button className={`btn ${showExpanded ? 'active' : ''}`} onClick={this.toggleExpanded}>
                                                        <i className="fa fa-list-ul"></i>
                                                        <div className="btn-actions-label">Detalhes</div>
                                                    </button>
                                                }
                                                <button className="btn error">
                                                    <i className="fa fa-filter"></i>
                                                    <div className="btn-actions-label">Filtrar</div>
                                                </button>
                                                <button className="btn warning">
                                                    <i className="fa fa-trash"></i>
                                                    <div className="btn-actions-label">Excluir</div>
                                                </button>
                                                <button className="btn links">
                                                    <i className="fa fa-eye"></i>
                                                    <div className="btn-actions-label">
                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                    {hasQuickView && !scrollx &&
                                        <div className={`quick-view ${openedQuickView ? 'open' : ''}`}>
                                            <em className="fa fa-times" onClick={this.toggleQuickView}></em>
                                            <div className="row">
                                                <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                                                <div className="col-md-6">Lorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum loremLorem ipsum lorem</div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {expanded && showExpanded &&
                                    <div className="child-table">
                                        <div className="panel table">
                                            <div className="panel-body">
                                                <div className="table-editable">
                                                    <div className="table-editable-header">
                                                        <div className="row">
                                                            <div className="col-md-4 column"><span>Coluna</span></div>
                                                            <div className="col-md-4 column"><span>Coluna</span></div>
                                                            <div className="col-md-4 column"><span>Coluna</span></div>
                                                        </div>
                                                    </div>
                                                    <div className="table-editable-content">
                                                        <div className={rowClasses}>
                                                            <div className="col-md-4 column">Coluna</div>
                                                            <div className="col-md-4 column">Coluna</div>
                                                            <div className="col-md-4 column">Coluna</div>
                                                            {buttons &&
                                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                                    <div className="btn-actions-inner">
                                                                        {hasQuickView &&
                                                                            <button className="btn" onClick={this.toggleQuickView}>
                                                                                <i className="fa fa-eye"></i>
                                                                                <div className="btn-actions-label">Visualizar</div>
                                                                            </button>
                                                                        }
                                                                        {expanded &&
                                                                            <button className="btn" onClick={this.toggleExpanded}>
                                                                                <i className="fa fa-list-ul"></i>
                                                                                <div className="btn-actions-label">Detalhes</div>
                                                                            </button>
                                                                        }
                                                                        <button className="btn">
                                                                            <i className="fa fa-trash"></i>
                                                                            <div className="btn-actions-label">Excluir</div>
                                                                        </button>
                                                                        <button className="btn links">
                                                                            <i className="fa fa-eye"></i>
                                                                            <div className="btn-actions-label">
                                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                                            </div>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <div className={rowClasses}>
                                    {checkbox && !radio &&
                                        <div className="column checkbox direction-row">
                                            <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                            <label htmlFor="checkbox3"></label>
                                        </div>
                                    }
                                    {radio && !checkbox &&
                                        <div className="column radio direction-row">
                                            <input type="text" className="input-radio" id="radio3" />
                                            <label htmlFor="radio3"></label>
                                        </div>

                                    }
                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                    <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                        {!status &&
                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                        }
                                        {status &&
                                            <div className={statusClasses}>
                                                <p className="chip-text">LOREM IPSUM</p>
                                            </div>
                                        }
                                    </div>
                                    <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                        {editable && !editing &&
                                            <>
                                                9387492
                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                    <div className="hint-content">
                                                        Editar
                                            </div>
                                                </div>
                                            </>
                                        }

                                        {editing && !editableAuto &&
                                            <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                        }

                                        {editing && editableAuto &&
                                            <>
                                                <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                <i className="fa fa-search input-icon"></i>
                                                <ul className="autocomplete">
                                                    <li className="autocomplete-item">01 - Curitiba</li>
                                                    <li className="autocomplete-item">02 - Londrina</li>
                                                    <li className="autocomplete-item">03 - Maringá</li>
                                                    <li className="autocomplete-item">04 - Ponta Grossa</li>
                                                    <li className="autocomplete-item">05 - Foz do Iguaçú</li>
                                                    <li className="autocomplete-item">06 - Cianorte</li>
                                                    <li className="autocomplete-item">07 - Campo Mourão</li>
                                                </ul>
                                            </>
                                        }

                                        {!editable && !editing &&
                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                        }
                                    </div>
                                    <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                    {scrollx &&
                                        this.renderExtraColumnToScrollx()
                                    }
                                    {headerButton &&
                                        <div className="col-sm-1 header-button"></div>
                                    }
                                    {columnSelector &&
                                        <div className="col-sm-1 column-selector"></div>
                                    }
                                    {buttons &&
                                        <div className="col-sm-1 col-xs-12 column btn-actions">
                                            <div className="btn-actions-inner">
                                                <button className="btn">
                                                    <i className="fa fa-filter"></i>
                                                    <div className="btn-actions-label">Filtrar</div>
                                                </button>
                                                <button className="btn">
                                                    <i className="fa fa-trash"></i>
                                                    <div className="btn-actions-label">Excluir</div>
                                                </button>
                                                <button className="btn links">
                                                    <i className="fa fa-eye"></i>
                                                    <div className="btn-actions-label">
                                                        <a href="#tabela-div" target="_blank">Option One</a>
                                                        <a href="#tabela-div" target="_blank">Option Two</a>
                                                    </div>
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {fixed &&
                                    <>
                                        <div className={rowClasses}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <inputName onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item">03 - Maringá</li>
                                                            <li className="autocomplete-item">04 - Ponta Grossa</li>
                                                            <li className="autocomplete-item">05 - Foz do Iguaçú</li>
                                                            <li className="autocomplete-item">06 - Cianorte</li>
                                                            <li className="autocomplete-item">07 - Campo Mourão</li>
                                                        </ul>
                                                    </>
                                                }

                                                {!editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div>
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={rowClasses}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item">03 - Maringá</li>
                                                            <li className="autocomplete-item">04 - Ponta Grossa</li>
                                                            <li className="autocomplete-item">05 - Foz do Iguaçú</li>
                                                            <li className="autocomplete-item">06 - Cianorte</li>
                                                            <li className="autocomplete-item">07 - Campo Mourão</li>
                                                        </ul>
                                                    </>
                                                }

                                                {
                                                    !editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div >
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {
                                                scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {
                                                headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {
                                                columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {
                                                buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div >
                                        <div className={rowClasses}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item" > 03 - Maringá</li >
                                                            <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                            <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                            <li className="autocomplete-item" > 06 - Cianorte</li >
                                                            <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                        </ul >
                                                    </>
                                                }

                                                {
                                                    !editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div >
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {
                                                scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {
                                                headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {
                                                columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {
                                                buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div >
                                        <div className={rowClasses}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item" > 03 - Maringá</li >
                                                            <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                            <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                            <li className="autocomplete-item" > 06 - Cianorte</li >
                                                            <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                        </ul >
                                                    </>
                                                }

                                                {
                                                    !editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div >
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {
                                                scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {
                                                headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {
                                                columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {
                                                buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div >
                                        <div className={rowClasses}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item" > 03 - Maringá</li >
                                                            <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                            <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                            <li className="autocomplete-item" > 06 - Cianorte</li >
                                                            <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                        </ul >
                                                    </>
                                                }

                                                {
                                                    !editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div >
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {
                                                scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {
                                                headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {
                                                columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {
                                                buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div >
                                        <div className={rowClasses}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item" > 03 - Maringá</li >
                                                            <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                            <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                            <li className="autocomplete-item" > 06 - Cianorte</li >
                                                            <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                        </ul >
                                                    </>
                                                }

                                                {
                                                    !editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div >
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {
                                                scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {
                                                headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {
                                                columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {
                                                buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div >
                                        <div className={rowClasses}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item" > 03 - Maringá</li >
                                                            <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                            <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                            <li className="autocomplete-item" > 06 - Cianorte</li >
                                                            <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                        </ul >
                                                    </>
                                                }

                                                {
                                                    !editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div >
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {
                                                scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {
                                                headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {
                                                columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {
                                                buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div >
                                    </>
                                }
                                {
                                    level &&
                                    <>
                                        <div className={`${rowClasses} father level-1`}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item">03 - Maringá</li>
                                                            <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                            <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                            <li className="autocomplete-item" > 06 - Cianorte</li >
                                                            <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                        </ul >
                                                    </>
                                                }

                                                {
                                                    !editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div >
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {
                                                scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {
                                                headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {
                                                columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {
                                                buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div >
                                        <div className={`${rowClasses} father level-2`}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item" > 03 - Maringá</li >
                                                            <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                            <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                            <li className="autocomplete-item" > 06 - Cianorte</li >
                                                            <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                        </ul >
                                                    </>
                                                }

                                                {
                                                    !editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div >
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {
                                                scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {
                                                headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {
                                                columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {
                                                buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div >
                                        <div className={`${rowClasses} level-2`}>
                                            {checkbox && !radio &&
                                                <div className="column checkbox direction-row">
                                                    <input className="input-checkbox" type="checkbox" id="checkbox3" />
                                                    <label htmlFor="checkbox3"></label>
                                                </div>
                                            }
                                            {radio && !checkbox &&
                                                <div className="column radio direction-row">
                                                    <input type="text" className="input-radio" id="radio3" />
                                                    <label htmlFor="radio3"></label>
                                                </div>

                                            }
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span></div>
                                            <div className={`${contentColumnClasses} ${highlight}`} data-header="Coluna">
                                                {!status &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                                }
                                                {status &&
                                                    <div className={statusClasses}>
                                                        LOREM IPSUM
                                                    </div>
                                                }
                                            </div>
                                            <div className={`${contentColumnClasses} ${editable ? 'editable' : ''}`} data-header="Coluna">
                                                {editable && !editing &&
                                                    <>
                                                        9387492
                                                        <div className="hint clean inline module-color sm fa fa-pencil" onClick={this.toggleEditable}>
                                                            <div className="hint-content">
                                                                Editar
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {editing && !editableAuto &&
                                                    <input type="text" defaultValue={'9387492'} onBlur={this.toggleEditable} ref={this.editableInput} />
                                                }

                                                {editing && editableAuto &&
                                                    <>
                                                        <input onBlur={this.toggleEditable} ref={this.editableInput} type="text" id="field" className="has-input-icon  autocomplete-input undefined" name="" placeholder="Cidade" />
                                                        <i className="fa fa-search input-icon"></i>
                                                        <ul className="autocomplete">
                                                            <li className="autocomplete-item">01 - Curitiba</li>
                                                            <li className="autocomplete-item">02 - Londrina</li>
                                                            <li className="autocomplete-item" > 03 - Maringá</li >
                                                            <li className="autocomplete-item" > 04 - Ponta Grossa</li >
                                                            <li className="autocomplete-item" > 05 - Foz do Iguaçú</li >
                                                            <li className="autocomplete-item" > 06 - Cianorte</li >
                                                            <li className="autocomplete-item" > 07 - Campo Mourão</li >
                                                        </ul >
                                                    </>
                                                }

                                                {
                                                    !editable && !editing &&
                                                    <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                                }
                                            </div >
                                            <div className={contentColumnClasses} data-header="Coluna"> <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span></div>
                                            {
                                                scrollx &&
                                                this.renderExtraColumnToScrollx()
                                            }
                                            {
                                                headerButton &&
                                                <div className="col-sm-1 header-button"></div>
                                            }
                                            {
                                                columnSelector &&
                                                <div className="col-sm-1 column-selector"></div>
                                            }
                                            {
                                                buttons &&
                                                <div className="col-sm-1 col-xs-12 column btn-actions">
                                                    <div className="btn-actions-inner">
                                                        <button className="btn">
                                                            <i className="fa fa-filter"></i>
                                                            <div className="btn-actions-label">Filtrar</div>
                                                        </button>
                                                        <button className="btn">
                                                            <i className="fa fa-trash"></i>
                                                            <div className="btn-actions-label">Excluir</div>
                                                        </button>
                                                        <button className="btn links">
                                                            <i className="fa fa-eye"></i>
                                                            <div className="btn-actions-label">
                                                                <a href="#tabela-div" target="_blank">Option One</a>
                                                                <a href="#tabela-div" target="_blank">Option Two</a>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div >
                                    </>
                                }
                            </div >
                            {fancyTotals &&
                                <div className="fancy-totals mb-xs">
                                    <div className="row">
                                        <div className="col-sm-2 col-sm-offset-4">
                                            <div className="result positive">
                                                <div className="key">Proventos</div>
                                                <div className="value">R$ 1.285,72</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="result negative">
                                                <div className="key">Descontos</div>
                                                <div className="value">R$ 114,55</div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="result neutral">
                                                <div className="key">FGTS</div>
                                                <div className="value"></div>
                                            </div>
                                        </div>
                                        <div className="col-sm-2">
                                            <div className="result module-color">
                                                <div className="key">Líquido</div>
                                                <div className="value">R$ 1.171,17</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                footer &&
                                <div className="table-editable-footer">
                                    <div className="row">
                                        <div className={contentColumnClasses} data-header="Coluna">
                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  1 </span>
                                        </div>
                                        <div className={contentColumnClasses} data-header="Coluna">
                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  2 </span>
                                        </div>
                                        <div className={contentColumnClasses} data-header="Coluna">
                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  3 </span>
                                        </div>
                                        <div className={contentColumnClasses} data-header="Coluna">
                                            <span> Lorem ipsum dolor sit amet consectetur adipisicing elit.  4 </span>
                                        </div>
                                        {scrollx &&
                                            this.renderExtraColumnToScrollx()
                                        }
                                        {!buttons && headerButton &&
                                            <div className="header-button"></div>
                                        }
                                        {!buttons && columnSelector &&
                                            <div className="column-selector"></div>
                                        }
                                        {buttons && buttons !== 'reduced' &&
                                            <div className="col-sm-1"></div>
                                        }
                                    </div>
                                </div>
                            }
                        </div >
                    </div >
                </div >
            </>
        )
    }

    renderClasses = () => {
        return (
            <>
                <SwitchOptionsGroup label="Variações" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="sortable" value={true} stateName="sortable" label="Com ordenação" />
                    <SwitchOptions attr="buttons" value='normal' stateName="buttons" label="Com botões visíveis" />
                    <SwitchOptions attr="buttons" value='reduced' stateName="buttons" label="Com botões escondidos" />
                    <SwitchOptions attr="border" value={true} stateName="border" label="Com borda" />
                    <SwitchOptions attr="inverted" value={true} stateName="inverted" label="Com cor invertida" hidden />
                    <SwitchOptions attr="fancy" value={true} stateName="fancy" label="Com borda no thead" />
                    <SwitchOptions attr="fixed" value={true} stateName="fixed" label="Com header fixo" />
                    <SwitchOptions attr="footer" value={true} stateName="footer" label="Com footer" />
                    <SwitchOptions attr="footerWithTransparency" value={true} stateName="footerWithTransparency" label="Com footer transparente" />
                    <SwitchOptions attr="fancyTotals" value={true} stateName="fancyTotals" label="Com totais customizados" />
                    <SwitchOptions attr="searchFilters" value={true} stateName="searchFilters" label="Com Filtro" />
                    <SwitchOptions attr="expanded" value={true} stateName="expanded" label="Com outra table dentro" />
                    <SwitchOptions attr="hasQuickView" value={true} stateName="hasQuickView" label="Com quick view" />
                    <SwitchOptions attr="level" value={true} stateName="level" label="Com Níveis" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Opções de Linha" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="trType" value='success' stateName="trType" label="Com destaque (success)" />
                    <SwitchOptions attr="trType" value='highlight' stateName="trType" label="Com destaque" />
                    <SwitchOptions attr="trType" value='error' stateName="trType" label="Com erro" />
                    <SwitchOptions attr="trType" value='warning' stateName="trType" label="Com aviso" />
                    <SwitchOptions attr="trType" value='rowSelected' stateName="trType" label="Selecionada" />
                    <SwitchOptions attr="striped" value={true} stateName="striped" label="Listrada" />
                    <SwitchOptions attr="scrollx" value={true} stateName="scrollx" label="Com scroll horizontal" />
                    <SwitchOptions attr="extraInfo" value={true} stateName="extraInfo" label="Com informação extra" />
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Opções de Coluna" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr="headerButton" value={true} stateName="headerButton" label="Com botão no header" />
                    <SwitchOptions attr="columnSelector" value={true} stateName="columnSelector" label="Com seleção de coluna" />
                    <SwitchOptions attr="checkbox" value={true} stateName="checkbox" label="Com checkbox" />
                    <SwitchOptions attr="radio" value={true} stateName="radio" label="Com radio button" />
                    <SwitchOptions attr="editable" value={true} stateName="editable" label="Editável" />
                    <SwitchOptions attr="editableAuto" value={true} stateName="editableAuto" label="Editável Com Autocomplete" />
                    <SwitchOptions attr="textAlign" value="" stateName="textAlign" label="Alinhar à esquerda" />
                    <SwitchOptions attr="textAlign" value="right" stateName="textAlign" label="Alinhar à direita" />
                    <SwitchOptions attr="status" value='module-color' stateName="status" label="Status Cor do Módulo" />
                    <SwitchOptions attr="status" value='positive' stateName="status" label="Status Cor Verde" />
                    <SwitchOptions attr="status" value='negative' stateName="status" label="Status Cor Vermelho" />
                    <SwitchOptions attr="highlight" value='highlight' stateName="highlight" label="Com Coluna Destacada" />
                    <SwitchOptions attr="changed" value='changed' stateName="changed" label="Com Coluna Alterada" />
                </SwitchOptionsGroup>
            </>
        )
    }

    render() {
        const { theme } = this.props;
        return (
            <ComponentTemplate
                codeToRender={this.renderComponent}
                optionalClasses={this.renderClasses}
                theme={theme}
            />
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(TableEditable);
