import React, { Component } from 'react';
import ComponentTemplate from '../Templates/ComponentTemplate';
import classnames from 'classnames';
import SwitchOptionsGroup from '../Templates/SwitchOptionsGroup';
import SwitchOptions from './../Templates/SwitchOptions';
import slugify from 'react-slugify';
import ListMenu from '../../ListMenu';

import { connect } from 'react-redux';
import HowToUse from '../Templates/HowToUse';
import HowToUseFigure from '../Templates/HowToUseFigure';
import BoxContainerTemplate from './../Templates/BoxContainerTemplate';
import BoxItemTemplate from './../Templates/BoxItemTemplate';
import FilesTemplate from './../Templates/FilesTemplate';

import anatomia from '../../assets/img/components/progress-bar/anatomia.png';
import linear from '../../assets/img/components/progress-bar/linear.png';
import circle from '../../assets/img/components/progress-bar/circle.png';

import {ReactComponent as SVGADM} from '../../assets/img/svgs/oxy-administrativo.svg';
import {ReactComponent as SVGARR} from '../../assets/img/svgs/oxy-arrecadacao.svg';
import {ReactComponent as SVGCTB} from '../../assets/img/svgs/oxy-contabil.svg';
import {ReactComponent as SVGSOCIAL} from '../../assets/img/svgs/oxy-social.svg';
import {ReactComponent as SVGRHU} from '../../assets/img/svgs/oxy-recursos-humanos.svg';
import {ReactComponent as SVGEDU} from '../../assets/img/svgs/oxy-educacional.svg';

const files = [
    { 'less': ['progressbar.less'] },
    { 'js': ['progressbar.js'] }
];

class ProgressBar extends Component {

    state = {
        color: undefined,
        size: undefined,
        effect: undefined,
        type: undefined,
        icon: undefined,
        iconType: 'rhu',
        active: false,
        currentIndex: undefined,
        text: false
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        const index = ListMenu.findIndex(item => item.name === 'Estados Vazios');
        this.setState({ currentIndex: index });
    }

    toggleActive = () => {
        this.setState(state => {
            return {
                active: !state.active
            }
        })
    }


    renderComponent = () => {
        const { color, size, effect, type, icon, iconType, active, text } = this.state;

        const parentClasses = classnames(
            size,
            // iconType,
            {
                'progressbar': !type,
                'progressbar-circle': type === 'circle',
                [`${color}`]: type === 'circle' ? color : '',
                icon,
                active
            }
        );

        const childClasses = classnames(
            effect,
            {
                'progressbar-reacher': !type,
                'progressbar-circle-reacher': type === 'circle',
                [`${color}`]: !type
            }
        );

        return (
            <div className={`${parentClasses}`} onClick={this.toggleActive}>
                <div className={`reach-77 ${childClasses}`}>
                    {text && !type &&
                        <>
                            110 de 450 lotes convertidos
                        </>
                    }
                </div>
                {type &&
                    (
                        <div className="progressbar-circle-content-outer">
                            <div className="progressbar-circle-content">
                                {icon &&
                                    <>
                                        {iconType === 'adm' &&
                                            <SVGADM />
                                        }
                                        {iconType === 'arr' &&
                                            <SVGARR />
                                        }
                                        {iconType === 'ctb' &&
                                            <SVGCTB />
                                        }
                                        {iconType === 'social' &&
                                           <SVGSOCIAL />
                                        }
                                        {iconType === 'rhu' &&
                                            <SVGRHU />
                                        }
                                        {iconType === 'edu' && 
                                            <SVGEDU />
                                        }
                                    </>
                                }
                                <h2 className="progressbar-circle-title">77%</h2>
                            </div>
                        </div>
                    )
                }
            </div>
        )
    }

    onChange = (newState) => {
        this.setState(newState);
    }

    renderColorOptions = () => {
        return (
            <SwitchOptionsGroup label="Cores" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr='color' value='negative' stateName='color' label='Vermelho'></SwitchOptions>
                <SwitchOptions attr='color' value='positive' stateName='color' label='Verde'></SwitchOptions>
                <SwitchOptions attr='color' value='neutral' stateName='color' label='Cinza'></SwitchOptions>
                {this.state.type && <SwitchOptions attr='color' value='module-color' stateName='color' label='Cor do Módulo'></SwitchOptions>}
                <SwitchOptions attr='color' value='striped' stateName='color' label='Listrado'></SwitchOptions>
            </SwitchOptionsGroup>
        )
    }

    renderSizeOptions = () => {
        return (
            <SwitchOptionsGroup label="Tamanhos" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr='size' value='medium' stateName='size' label='Médio'></SwitchOptions>
                <SwitchOptions attr='size' value='big' stateName='size' label='Grande'></SwitchOptions>
            </SwitchOptionsGroup>
        )
    }

    renderEffectsOptions = () => {
        return (
            <SwitchOptionsGroup label="Efeitos" state={this.state} onChange={this.onChange}>
                <SwitchOptions attr='effect' value='hover' stateName='effect' label='Ao passar o mouse'></SwitchOptions>
                <SwitchOptions attr='effect' value='animated' stateName='effect' label='Com Animação'></SwitchOptions>
            </SwitchOptionsGroup>
        )
    }

    renderTypesOptions = () => {
        return (
            <>
                <SwitchOptionsGroup label="Tipos" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr='type' value='circle' stateName='type' label="Em Círculo" ></SwitchOptions>
                    <SwitchOptions attr='icon' value={true} stateName='icon' label="Com Ícone" ></SwitchOptions>
                    <SwitchOptions attr='text' value={true} stateName='text' label='Com Texto'/>
                </SwitchOptionsGroup>
                <SwitchOptionsGroup label="Tipos de Ícone" state={this.state} onChange={this.onChange}>
                    <SwitchOptions attr='iconType' value='rhu' stateName='iconType' label="Ícone Recursos Humanos" ></SwitchOptions>
                    <SwitchOptions attr='iconType' value='social' stateName='iconType' label="Ícone Social" ></SwitchOptions>
                    <SwitchOptions attr='iconType' value='arr' stateName='iconType' label="Ícone Arrecadação" ></SwitchOptions>
                    <SwitchOptions attr='iconType' value='adm' stateName='iconType' label="Ícone Administrativo" ></SwitchOptions>
                    <SwitchOptions attr='iconType' value='ctb' stateName='iconType' label="Ícone Contábil" ></SwitchOptions>
                    <SwitchOptions attr='iconType' value='edu' stateName='iconType' label="Ícone Educacional" ></SwitchOptions>
                </SwitchOptionsGroup>
            </>
        )
    }

    renderOptionalClasses = () => {
        return (
            <React.Fragment>
                {this.renderColorOptions()}
                {this.renderSizeOptions()}
                {this.renderEffectsOptions()}
                {this.renderTypesOptions()}
            </React.Fragment>
        )
    }

    renderBoxItems() {
        const { currentIndex } = this.state;
        const { theme } = this.props;
        let initIndex = currentIndex > 0 ? currentIndex - 1 : currentIndex;
        let endIndex = currentIndex > 0 ? initIndex + 4 : initIndex + 3;
        let boxes = [];

        for (let x = initIndex; x < endIndex; x++) {
            if (x !== currentIndex && ListMenu[x]) {
                let name = ListMenu[x].name;
                let nameSlug = slugify(name);
                let svg = ListMenu[x].svg;
                boxes.push(
                    <BoxItemTemplate
                        key={nameSlug}
                        link={`/componentes/${nameSlug}${theme === 'dark' ? '#codigo' : ''}`}
                        name={name}
                        svg={svg}
                    />
                )
            }
        }
        return boxes;
    }

    render() {
        const { theme } = this.props;

        return (
            <>
                <ComponentTemplate
                    codeToRender={this.renderComponent}
                    optionalClasses={this.renderOptionalClasses}
                    files={files}
                    mainTitle='Barra de Progresso'
                    mainDescription='Os indicadores de progresso informam os usuários do status do processo em andamento'
                    theme={theme}
                >
                <HowToUse
                    title='Uso'
                    description='Os indicadores de progresso comumente conhecidos como “spinners”, expressam um tempo de espera não especificado ou exibem a duração de um processo.'
                />

                <HowToUse
                    title='Anatomia'
                >
                    <HowToUseFigure
                        srcImg={anatomia}
                        descriptionImg='1. Container  <br /> 2. Progresso atual'
                    />
                </HowToUse>

                <HowToUse
                    title="Aplicação"
                    description="A colocação de um indicador de progresso linear pode indicar o escopo de um processo. Por exemplo: <br /> • Uma barra de progresso no centro da tela pode indicar o carregamento de todo o conteúdo da tela <br /> • Uma barra de progresso anexado a um contêiner, como um bloco, pode indicar que o processo se aplica a esse item específico (e que a interação com o restante da interface do usuário é possível) <br /><br /> Os indicadores circulares de progresso são posicionados para indicar o processo que eles representam. <br /> • Quando centralizados na tela, eles indicam o carregamento inicial do conteúdo da tela. <br/> • Quando colocados acima ou abaixo do conteúdo existente, chamam a atenção para onde o novo conteúdo aparecerá "
                />

                <HowToUse
                    title="Comportamento"
                    description="Os indicadores de progresso linear exibem o progresso animando um indicador ao longo de uma faixa fixa e visível. O comportamento do indicador depende se o progresso de um processo é conhecido."
                />

                <HowToUse
                    title="Variações"
                    subtitle="Linear e circular"
                    description="Temos dois tipos de indicadores de progresso visualmente distintos: indicadores de progresso lineares e circulares. Apenas um tipo deve representar cada tipo de atividade em um aplicativo. Por exemplo, se uma ação de atualização exibir um indicador circular em uma tela, essa mesma ação não deverá usar um indicador linear em outro lugar do aplicativo."
                >
                    <HowToUseFigure
                        col
                        srcImg={linear}
                        captionImg="Barra de Progresso Linear"
                    />
                    <HowToUseFigure
                        col
                        srcImg={circle}
                        captionImg="Barra de Progresso Circular"
                    />
                </HowToUse>

                <HowToUse
                    title='Arquivos'
                    code
                >
                    <FilesTemplate files={files} />
                </HowToUse>

                <HowToUse
                    title='Implementação'
                    description='1. Utilize o componente criado em seu projeto (Angular ou React).<br /> 2. Utilize o pacote npm do Arc para ter acesso as imagens e estilos.<br /> 3. Dúvidas entre em contato pelo canal de UX no mattermost.'
                    code
                />

                <HowToUse
                    title='Outros Componentes'
                    both
                >
                    <BoxContainerTemplate seeAll>
                        {this.renderBoxItems()}
                    </BoxContainerTemplate>
                </HowToUse>
                </ComponentTemplate>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        theme: state.theme
    }
}

export default connect(mapStateToProps)(ProgressBar);
